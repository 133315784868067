import { P, match } from 'ts-pattern';
import { useMemo } from 'react';
import { UserEnrollmentState } from './use-get-user-enrollment-state';
import type { IEnrollmentPlan } from '../use-get-enrollment-plan';
import { EnrollmentPlanEspp } from '../use-get-enrollment-plan';
import {
  EnrollmentPeriodsConcurrency,
  EnrollmentPlanCashless,
} from '../use-get-enrollment-plan/use-get-enrollment-plan';

/** This enum is used to determine the next available action in the enrollment flow. */
export enum UserFlowState {
  NoEnrollmentActionsAvailable = 'NO_ENROLLMENT_ACTIONS_AVAILABLE',
  NoEnrollmentActionsAvailableDueToIneligible = 'NO_ENROLLMENT_ACTIONS_AVAILABLE_INELIGIBLE',
  ReadyToEnroll = 'READY_TO_ENROLL',
  ReadyToAddCashlessMatch = 'READY_TO_ADD_CASHLESS_MATCH',
  ReadyToAddCashlessParticipation = 'READY_TO_ADD_CASHLESS_PARTICIPATION',
  EnrollmentCompleted = 'ENROLLMENT_COMPLETED',
}

export const useGetUserFlowState = (
  enrollmentPlan: IEnrollmentPlan,
  isEsppEnrollmentOpen: boolean,
  isCashlessEnrollmentOpen: boolean,
  isScheduledEnrollmentOpen: boolean,
  userEnrollmentState: UserEnrollmentState,
): UserFlowState | undefined =>
  useMemo(() => {
    const flowState: UserFlowState | undefined = match({
      ...enrollmentPlan,
      userEnrollmentState,
      isEsppEnrollmentOpen,
      isCashlessEnrollmentOpen,
      isScheduledEnrollmentOpen,
    })
      .with(
        {
          espp: EnrollmentPlanEspp.EXTERNAL_ESPP,
          concurrency: EnrollmentPeriodsConcurrency.NON_CONCURRENT,
          userEnrollmentState: UserEnrollmentState.UserNotEnrolled,
        },
        {
          isEsppEnrollmentOpen: false,
          isScheduledEnrollmentOpen: false,
          userEnrollmentState: UserEnrollmentState.UserNotEnrolled,
        },
        () => UserFlowState.NoEnrollmentActionsAvailable,
      )
      .with(
        {
          userEnrollmentState: UserEnrollmentState.UserIneligibleForEspp,
        },
        {
          espp: EnrollmentPlanEspp.EXTERNAL_ESPP,
          cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
          userEnrollmentState:
            UserEnrollmentState.UserIneligibleToAddCashlessParticipation,
        },
        () => UserFlowState.NoEnrollmentActionsAvailableDueToIneligible,
      )
      .with(
        {
          espp: EnrollmentPlanEspp.INTERNAL_ESPP,
          userEnrollmentState: UserEnrollmentState.UserNotEnrolled,
        },
        () =>
          (enrollmentPlan.cashless ===
          EnrollmentPlanCashless.INTERNAL_CASHLESS_MATCH
            ? UserFlowState.ReadyToAddCashlessMatch
            : UserFlowState.ReadyToEnroll),
      )
      .with(
        {
          espp: EnrollmentPlanEspp.EXTERNAL_ESPP,
          concurrency: EnrollmentPeriodsConcurrency.CONCURRENT_EXTERNAL,
          userEnrollmentState: P.union(UserEnrollmentState.UserNotEnrolled),
        },
        {
          cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
          userEnrollmentState: UserEnrollmentState.UserAlreadyEnrolledInESPP,
          isCashlessEnrollmentOpen: true,
        },
        {
          cashless: EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
          userEnrollmentState: UserEnrollmentState.UserAlreadyEnrolledInESPP,
          isScheduledEnrollmentOpen: true,
        },
        () => UserFlowState.ReadyToAddCashlessParticipation,
      )
      .with(
        {
          userEnrollmentState: UserEnrollmentState.UserAlreadyEnrolledInESPP,
          isCashlessEnrollmentOpen: false,
        },
        {
          userEnrollmentState: UserEnrollmentState.UserAlreadyEnrolledInESPP,
          isScheduledEnrollmentOpen: false,
        },
        {
          cashless: P.union(
            EnrollmentPlanCashless.NONE,
            EnrollmentPlanCashless.INTERNAL_CASHLESS_MATCH,
          ),
          userEnrollmentState: UserEnrollmentState.UserAlreadyEnrolledInESPP,
        },
        {
          userEnrollmentState:
            UserEnrollmentState.UserIneligibleToAddCashlessParticipation,
        },
        {
          cashless: P.union(
            EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION,
            EnrollmentPlanCashless.INTERNAL_CASHLESS_MATCH,
          ),
          userEnrollmentState: P.union(
            UserEnrollmentState.UserAlreadyEnrolledInCashlessParticipation,
            UserEnrollmentState.UserIneligibleToAddCashlessParticipationDueToMaxPercent,
          ),
        },
        () => UserFlowState.EnrollmentCompleted,
      )
      .otherwise(() => undefined);

    if (flowState === undefined) {
      console.error(
        `enrollmentPlan (${enrollmentPlan.espp}, ${enrollmentPlan.cashless}, ${enrollmentPlan.concurrency}) and userEnrollmentState (${userEnrollmentState}) were incompatible in useGetUserFlowState. This should never happen.`,
      );
    }

    const flowStateBasedOnEnrollmentState =
      !(isEsppEnrollmentOpen || isCashlessEnrollmentOpen) &&
      !isScheduledEnrollmentOpen &&
      flowState &&
      flowState !== UserFlowState.NoEnrollmentActionsAvailableDueToIneligible
        ? UserFlowState.NoEnrollmentActionsAvailable
        : flowState ??
          UserFlowState.NoEnrollmentActionsAvailableDueToIneligible;

    return flowStateBasedOnEnrollmentState;
  }, [
    enrollmentPlan,
    userEnrollmentState,
    isEsppEnrollmentOpen,
    isCashlessEnrollmentOpen,
    isScheduledEnrollmentOpen,
  ]);
