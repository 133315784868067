import {
  convertDotcmsString,
  convertDotcmsResponseToRecord,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type IHaveQuestionSection = IBaseObjectData & {
  label: string;
  email: string;
};

export const HaveQuestionGqlHelper: IGqlHelper<IHaveQuestionSection> = {
  contentTypeName: 'HaveQuestionSection',
  gqlFieldsQuery: `
      label
      email
    `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { label, email } = convertDotcmsResponseToRecord(gqlResponse);

    return {
      label: convertDotcmsString(label) || '',
      email: convertDotcmsString(email) || '',
    };
  },
};
