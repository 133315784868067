import { useState } from 'react';

export const getItem = (key: string) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');

const setItem = (key: string, value: string, numberOfDays?: number) => {
  const now = new Date();
  // set the time to be now + numberOfDays
  // This maintains legacy behavior from when this file was javascript,
  // where numberOfDays can be undefined, which in the old js code would
  // indirectly cause this to do: now.setTime(NaN), which makes it set the
  // 'expires' string like: "expires='Invalid Date'"
  // I'm not sure what the exact behavior of these cookies is when expires is set to "Invalid Date",
  // so to maintain whatever that behavior is, just continue setting it that way
  // even though whoever originally wrote this code probably didn't know what they were doing.
  now.setTime(
    numberOfDays !== undefined
      ? now.getTime() + numberOfDays * 60 * 60 * 24 * 1000
      : NaN,
  );
  document.cookie = `${key}=${value};     expires=${now.toUTCString()}; path=/`;
};

export function useCookie<T>(
  key: string,
  defaultValue: T,
): [string | T, (value: string, numberOfDays?: number) => void] {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: string, numberOfDays?: number) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };
  return [cookie, updateCookie];
}
