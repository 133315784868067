import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { HalfMagicContext } from './half-magic-link-context';
import useHalfMagicLinkHandlerInternalSingleton from './hooks/use-half-magic-link-handler';

export const HalfMagicLinkHandler: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isHalfMagicLoggedIn, loading } =
    useHalfMagicLinkHandlerInternalSingleton();

  // If this is not wrapped in useMemo, the context provider will triger a re-render
  // every time, because it will see that its props are different:
  const contextValue = useMemo(
    () => ({ halfMagicLoggedIn: isHalfMagicLoggedIn, loading }),
    [isHalfMagicLoggedIn, loading],
  );

  return (
    <HalfMagicContext.Provider value={contextValue}>
      {children}
    </HalfMagicContext.Provider>
  );
};
