import { useLocation } from 'react-router';
import type { UrlSearchParamsTypes } from './url-search-params';
import type UrlSearchParamKey from './url-search-params';

export type StringSearchParamKeys = {
  [Key in UrlSearchParamKey as UrlSearchParamsTypes[Key] extends string
    ? Key
    : never]: string;
};

/**
 * Fetch a URL search param that you're expecting to be a string.
 *
 * 'key' argument should be a string-type UrlSearchParamKey from
 * url-search-params.
 *
 * This will give you a type error if you try to call it for the wrong type
 * of search param. E.g. if the search param key is mapped to a boolean type
 * in url-search-params.ts, this will complain.
 */
export function useStringUrlSearchParam<
  SearchParamKey extends keyof StringSearchParamKeys,
>(key: SearchParamKey): string | null {
  const location = useLocation();
  const searchString = new URLSearchParams(location.search);
  return searchString.get(key);
}

type NumericSearchParamKeys = {
  [Key in UrlSearchParamKey as UrlSearchParamsTypes[Key] extends number
    ? Key
    : never]: number;
};

/**
 * Fetch a URL search param that you're expecting to be a number.
 *
 * 'key' argument should be a number-type UrlSearchParamKey from
 * url-search-params.
 *
 * This will give you a type error if you try to call it for the wrong type
 * of search param. E.g. if the search param key is mapped to a boolean type
 * in url-search-params.ts, this will complain.
 */
export function useNumericUrlSearchParam<
  SearchParamKey extends keyof NumericSearchParamKeys,
>(key: SearchParamKey): number | null {
  const location = useLocation();
  const searchString = new URLSearchParams(location.search);
  const rawValue = searchString.get(key);
  return rawValue !== null ? Number(rawValue) : null;
}

type BooleanSearchParamKeys = {
  [Key in UrlSearchParamKey as UrlSearchParamsTypes[Key] extends boolean
    ? Key
    : never]: boolean;
};

export function useBooleanUrlSearchParam<
  SearchParamKey extends keyof BooleanSearchParamKeys,
>(key: SearchParamKey): boolean {
  const location = useLocation();
  const searchString = new URLSearchParams(location.search);
  const rawValue = searchString.get(key);
  return rawValue !== null;
}

export const useStringArrayUrlSearchParams = <
  SearchParamKey extends keyof StringSearchParamKeys,
>(
    keys: SearchParamKey[],
  ): Record<SearchParamKey, string> => {
  const location = useLocation();
  const searchString = new URLSearchParams(location.search);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const result: Record<SearchParamKey, string> = {} as Record<
  SearchParamKey,
  string
  >;

  keys.forEach((key) => {
    const rawValue = searchString.get(key);

    if (rawValue !== null) {
      result[key] = rawValue;
    }
  });

  return result;
};
