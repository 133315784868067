type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HexColorString = `#${string}`;

export type ThemeColor = RGB | RGBA | HexColorString | 'inherit';

export interface ITheme {
  colors: {
    primary: ThemeColor;
    adminBlue: ThemeColor;
    adminBlueHighlight: ThemeColor;
    secondary: ThemeColor;
    textTertiary: ThemeColor;
    neutral: ThemeColor;
    neutralHover: ThemeColor;
    neutralBackgroundColor: ThemeColor;
    positive: ThemeColor;
    positiveHover: ThemeColor;
    positiveHighlight: ThemeColor;
    positiveBackground: ThemeColor;
    positiveOutline: ThemeColor;
    warning: ThemeColor;
    warningBackground: ThemeColor;
    warningOutline: ThemeColor;
    negative: ThemeColor;
    negativeHover: ThemeColor;
    negativeHighlight: ThemeColor;
    negativeBackground: ThemeColor;
    bannerYellow: ThemeColor;
    unvisitedLink: ThemeColor;
    hoverBackground: ThemeColor;
    eggshellBackground: ThemeColor;
    brightNaviBlue: ThemeColor;
    grayscale: {
      black: ThemeColor;
      inactiveGrey1: ThemeColor;
      outlineGrey: ThemeColor;
      lightGrey: ThemeColor;
      inactiveGrey2: ThemeColor;
      backgroundGrey: ThemeColor;
      white: ThemeColor;
      oldSilver: ThemeColor;
      tableHeaderGrey: ThemeColor;
    };
  };
}

export const theme: ITheme = {
  colors: {
    primary: '#000000',
    adminBlue: '#0C1E4E',
    adminBlueHighlight: '#3271CD',
    secondary: '#454140',
    textTertiary: '#78716F',
    neutral: '#1764BE',
    neutralHover: '#184E8D',
    neutralBackgroundColor: '#F2F7FE',
    positive: '#02785C',
    positiveHover: '#02624B',
    positiveHighlight: '#D7F3E6',
    positiveBackground: '#EDF7F4',
    positiveOutline: '#C3E4D7',
    warning: '#DD871D',
    warningBackground: '#FFF1E3',
    warningOutline: '#FFD4AC',
    negative: '#DB0606',
    negativeHover: '#B10000',
    negativeHighlight: '#FFC1C1',
    negativeBackground: '#FFE2E1',
    bannerYellow: '#FCFAE3',
    unvisitedLink: '#2159AD',
    hoverBackground: '#F7F7F7',
    eggshellBackground: '#FFFDFA',
    brightNaviBlue: '#1764be',
    grayscale: {
      black: '#000000',
      inactiveGrey1: '#979797',
      outlineGrey: '#CACACA',
      lightGrey: '#E3E3E3',
      inactiveGrey2: '#F0F0F0',
      backgroundGrey: '#F7F7F7',
      white: '#FFFFFF',
      oldSilver: '#828282',
      tableHeaderGrey: '#747170',
    },
  },
};
