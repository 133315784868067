import type { PropsWithChildren } from 'react';
import React from 'react';
import { useFetchOperationButtonData } from './hook';
import OperationButtonContext from './operation-button-context';

const OperationButtonContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const operationButtonData = useFetchOperationButtonData();
  return (
    <OperationButtonContext.Provider value={operationButtonData}>
      {children}
    </OperationButtonContext.Provider>
  );
};

export default OperationButtonContextProvider;
