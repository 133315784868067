import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { Duration } from 'luxon';
import type { LocaleContextData } from '../locale-context';
import { useLocalStorage } from '../../../utils/storage/localstorage/use-local-storage';
import { LocalStorageKey } from '../../../utils/storage/localstorage/localstorage';
import { authSelector } from '../../../selectors/index';
import { BASE_URL } from '../../../utils/constants';
import { useStringUrlSearchParam } from '../../../utils/url-search-params/use-query-url-search-params';
import UrlSearchParamKey from '../../../utils/url-search-params/url-search-params';
import { captureErrorInSentryWithCustomMessage } from '../../../utils/capture-error-in-sentry-with-custom-message';
import { useGetCurrentPageEnglishOnlyStatus } from '../../../app/pages-english-only-status';
import { useGetJurisdictionData } from '../../../hooks/use-get-jurisdiction-data';

/** This hook should only be called once in the whole application! Otherwise it will break stuff. */
const useLocaleContextProvider = (): LocaleContextData => {
  const {
    getValueFromLocalStorage: getUserLanguageSelectionFromLocalStorage,
    setValueToLocalStorage: setUserLanguageSelectionToLocalStorage,
  } = useLocalStorage(LocalStorageKey.USER_LANGUAGE_SELECTION);
  const {
    getValueFromLocalStorage: getUrlParamLanguageFromLocalStorage,
    setValueToLocalStorage: setUrlParamLanguageToLocalStorage,
  } = useLocalStorage(LocalStorageKey.LANGUAGE_FROM_URL_PARAMS);
  const jurisdictionData = useGetJurisdictionData();
  const pageEnglishOnlyStatus = useGetCurrentPageEnglishOnlyStatus();

  const [locale, setLocale] = useState('en-US');

  // We use userPickedLanguage to know whether to overwrite the user's existing
  // language preference when logging in.
  // E.g. if the user selects Spanish while unauthenticated, and then logs in,
  // we want to tell the backend to save their language as Spanish. But if the
  // user logs in without switching away from the default language, we don't
  // want to overwrite their language on the backend with the default one that
  // they didn't pick.
  const [userHasPickedLanguage, setUserHasPickedLanguage] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    isAuthenticated,
    currentUser: { officialLanguage },
  } = useSelector(authSelector);

  const maybeLanguageOverrideFromUrlParams = useStringUrlSearchParam(
    UrlSearchParamKey.LANGUAGE,
  );

  useEffect(() => {
    // If the country override was set in the URL params, save it to local storage
    // (so that it will work in other tabs):
    if (maybeLanguageOverrideFromUrlParams) {
      setUrlParamLanguageToLocalStorage({
        localeCode: maybeLanguageOverrideFromUrlParams,
      });
    }
  }, [maybeLanguageOverrideFromUrlParams, setUrlParamLanguageToLocalStorage]);

  // Set the current language:
  useEffect(() => {
    const maybeUserLanguageSelectionFromLocalStorage =
      getUserLanguageSelectionFromLocalStorage({
        maxAge: Duration.fromObject({ days: 7 }),
      })?.localeCode;
    const maybeLocaleFromUrlParamsFromLocalStorage =
      getUrlParamLanguageFromLocalStorage({
        maxAge: Duration.fromObject({ days: 1 }),
      })?.localeCode;

    // The value read directly from the URL should take precedence over
    // the value read from local storage:
    const urlParamLanguage =
      maybeLanguageOverrideFromUrlParams ||
      maybeLocaleFromUrlParamsFromLocalStorage;

    if (
      isAuthenticated &&
      officialLanguage &&
      typeof officialLanguage === 'string'
    ) {
      setLocale(officialLanguage);
    } else if (maybeUserLanguageSelectionFromLocalStorage) {
      setLocale(maybeUserLanguageSelectionFromLocalStorage);
      // We only set the locale to local storage if the user picks their language;
      // not if we get the language from other sources. So userPickedLanguage should
      // be true if it was set to local storage
      setUserHasPickedLanguage(true);
    } else if (urlParamLanguage) {
      // Set the language from URL params, but do not treat it as the user having
      // picked their language (probably we set this param in an email link, it may not be
      // their preference)
      setLocale(urlParamLanguage);
    } else if (jurisdictionData?.default_language) {
      // If none of the above conditions are met, default to the default_language from
      // their jurisdiction.
      setLocale(jurisdictionData.default_language);
    }
  }, [
    isAuthenticated,
    getUserLanguageSelectionFromLocalStorage,
    officialLanguage,
    getUrlParamLanguageFromLocalStorage,
    maybeLanguageOverrideFromUrlParams,
    setLocale,
    jurisdictionData?.default_language,
  ]);

  // Setter function that we return to the caller:
  const setLocaleOuterImpl = useCallback(
    (newLocale: string) => {
      setLocale(newLocale);
      setUserHasPickedLanguage(true);
      setUserLanguageSelectionToLocalStorage({ localeCode: newLocale });
      if (isAuthenticated) {
        // Also save the language on the backend.
        // Best-effort is fine. We don't need to check if it succeeded.
        fetch(`${BASE_URL}/update_language`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          cache: 'no-store',
          credentials: 'include',
          body: JSON.stringify({ preferredLanguage: newLocale }),
        }).catch((error) => {
          captureErrorInSentryWithCustomMessage(
            error,
            'Error when trying to set user language',
          );
          console.error('Error when trying to set user language', error);
        });
      }
    },
    [isAuthenticated, setUserLanguageSelectionToLocalStorage],
  );

  return {
    locale: pageEnglishOnlyStatus ? 'en-US' : locale,
    userHasPickedLanguage,
    setLocale: setLocaleOuterImpl,
  };
};

export default useLocaleContextProvider;
