import type { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useFlags as useFlagsLD } from 'launchdarkly-react-client-sdk';
import { useMemo, useState, useCallback } from 'react';
import { LocalStorageKey } from '../utils/storage/localstorage/localstorage';
import type { JSONSerializableObject } from '../utils/json-types';
import type { BooleanFeatureFlag } from './booleanFlags';
import { BOOLEAN_FLAG_DEFAULT_VALUES } from './booleanFlags';

// Based on https://github.com/launchdarkly/react-client-sdk/issues/53#issuecomment-1172808997
const useFlags = (): LDFlagSet => {
  const getOverridesFromStorage = useCallback((): LDFlagSet => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(
        localStorage?.getItem(LocalStorageKey.LAUNCHDARKLY_FLAG_OVERRIDES) ||
          '{}',
      );
    } catch (error) {
      console.error(
        'Error when reconciling LaunchDarkly flag manual overrides',
        error,
      );
      return {};
    }
  }, []);

  // Just set this on initial render only:
  const [overrides] = useState(getOverridesFromStorage());

  const ldFlags = useFlagsLD();

  const mergedFlags = useMemo(
    () => ({ ...ldFlags, ...overrides }),
    [ldFlags, overrides],
  );
  return mergedFlags;
};

/** Do not use this directly! Use one of the hooks for a specific feature flag type */
export const useJsonLaunchDarklyFlag = (
  flagName: string,
): JSONSerializableObject => {
  const flags = useFlags();
  const memoizedEmptyObject = useMemo(() => ({}), []);
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const deserializedFlagValue = flags[flagName];
    if (!deserializedFlagValue) {
      // eslint-disable-next-line no-console
      console.log(
        `launchDarkly returned a falsy value for a flag we expected to be JSON. It may just still be loading. The flag was: ${flagName}`,
      );
      return memoizedEmptyObject;
    }
    if (
      typeof deserializedFlagValue === 'object' &&
      !Array.isArray(deserializedFlagValue) &&
      deserializedFlagValue !== null
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return deserializedFlagValue;
    }
    console.error(
      'useJsonLaunchDarklyFlag: The invalid JSON serialized value was:',
      deserializedFlagValue,
      'which stringifies as:',
      JSON.stringify(deserializedFlagValue),
    );
    throw new Error(
      'useJsonLaunchDarklyFlag: The deserialized JSON value was not an Object.',
    );
  } catch (error) {
    console.error('Error when trying to read feature flag value', error);
  }
  return memoizedEmptyObject;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useBooleanLaunchDarklyFlag_internalDontUse = (
  flagName: BooleanFeatureFlag,
): boolean => {
  const flags = useFlags();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const deserializedFlagValue = flags[flagName];
  if (deserializedFlagValue === undefined) {
    // Bug: LaunchDarkly sometimes returns undefined for flags while it's loading,
    // even though we specify the default value. This works around that.
    return BOOLEAN_FLAG_DEFAULT_VALUES[flagName];
  }
  return !!deserializedFlagValue;
};

/** Do not use this directly! Use the hooks in featureFlags.ts */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const useStringLaunchDarklyFlag_internalDontUse = (
  flagName: string,
): string => {
  const flags = useFlags();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const deserializedFlagValue = flags[flagName];
  if (!deserializedFlagValue) {
    // Handle special case:
    // If you set a string flag to empty string in LaunchDarkly,
    // it seems to become undefined here.
    return '';
  }
  if (typeof deserializedFlagValue === 'string') {
    return deserializedFlagValue;
  }
  throw new Error(
    `Could not deserialize flag ${flagName} - expected it to be a string. Instead it was: ${JSON.stringify(
      deserializedFlagValue,
    )}`,
  );
};

// This is only exported for use by the flag editor. Normal application code
// should not call this function directly:
// eslint-disable-next-line @typescript-eslint/naming-convention
export const useFlags_onlyForFlagEditor_doNotUseThisFunction = useFlags;
