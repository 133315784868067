import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type IPageTitleData = IBaseObjectData & {
  titleDefault?: string;
  titleHomepage?: string;
  titleEsppResourcesPage?: string;
  titleCashlessParticipationPage?: string;
  titleEsppCalculatorPage?: string;
  titleLoginPage?: string;
  titlePrivacyPage?: string;
  titleTaxInformationPage?: string;
  titleImportantInformationPage?: string;
  titleTermsOfServicePage?: string;
  titleAccountStatusPage?: string;
  titleDocumentsPage?: string;
  titleContactInformationPage?: string;
  titleManageElectionPage?: string;
  titleStockInformationPage?: string;
  titlePurchaseSummaryPage?: string;
  titleEnrollmentPage?: string;
  titleEnrollmentConfirmationPage?: string;
  titleEnrollmentWelcomePage?: string;
  titleSuffixArticlesPages?: string;
  titleWealthManagementPage?: string;
  titleEmailVerificationCompletePage?: string;
  titleUnsubscribePage?: string;
  titleAdminDataExchangePage?: string;
  titleAdminDataUploadPage?: string;
  titleAdminEmployeeLookupPage?: string;
  titleAdminEnrollmentStatsPage?: string;
  titleAdminPlanOverviewPage?: string;
  titleAdminSurveyResultsPage?: string;
  titleAdminExchangeRatesPage?: string;
  titleReferralPage?: string;
  titleAdminPurchaseLookup?: string;
  titleAdminContributionsPage?: string;
  titleAdminConfiguration?: string;
  titleAutoSaleBrokerQuestionsPage?: string;
  titleAdminStockBasedCompensationPage?: string;
};

export const PageTitleListHelper: IGqlHelper<IPageTitleData> = {
  contentTypeName: 'PageTitleList',
  gqlFieldsQuery: `
    titleDefault
    titleHomepage
    titleEsppResourcesPage
    titleCashlessParticipationPage
    titleEsppCalculatorPage
    titleLoginPage
    titlePrivacyPage
    titlePrivacyPage
    titleTaxInformationPage
    titleImportantInformationPage
    titleTermsOfServicePage
    titleAccountStatusPage
    titleDocumentsPage
    titleContactInformationPage
    titleManageElectionPage
    titleStockInformationPage
    titlePurchaseSummaryPage
    titleEnrollmentPage
    titleEnrollmentConfirmationPage
    titleEnrollmentWelcomePage
    titleSuffixArticlesPages
    titleWealthManagementPage
    titleEmailVerificationCompletePage
    titleUnsubscribePage
    titleAdminDataExchangePage
    titleAdminDataUploadPage
    titleAdminEmployeeLookupPage
    titleAdminEnrollmentStatsPage
    titleAdminPlanOverviewPage
    titleAdminSurveyResultsPage
    titleAdminExchangeRatesPage
    titleReferralPage
    titleAdminPurchaseLookup
    titleAdminContributionsPage
    titleAdminConfiguration
    titleAutoSaleBrokerQuestionsPage
    titleAdminStockBasedCompensationPage
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const {
      titleDefault,
      titleHomepage,
      titleEsppResourcesPage,
      titleCashlessParticipationPage,
      titleEsppCalculatorPage,
      titleLoginPage,
      titlePrivacyPage,
      titleTaxInformationPage,
      titleImportantInformationPage,
      titleTermsOfServicePage,
      titleAccountStatusPage,
      titleDocumentsPage,
      titleContactInformationPage,
      titleManageElectionPage,
      titleStockInformationPage,
      titlePurchaseSummaryPage,
      titleEnrollmentPage,
      titleEnrollmentConfirmationPage,
      titleEnrollmentWelcomePage,
      titleSuffixArticlesPages,
      titleWealthManagementPage,
      titleEmailVerificationCompletePage,
      titleUnsubscribePage,
      titleAdminDataExchangePage,
      titleAdminDataUploadPage,
      titleAdminEmployeeLookupPage,
      titleAdminEnrollmentStatsPage,
      titleAdminPlanOverviewPage,
      titleAdminSurveyResultsPage,
      titleAdminExchangeRatesPage,
      titleReferralPage,
      titleAdminPurchaseLookup,
      titleAdminContributionsPage,
      titleAdminConfiguration,
      titleAutoSaleBrokerQuestionsPage,
      titleAdminStockBasedCompensationPage,
    } = convertDotcmsResponseToRecord(gqlResponse);

    return {
      titleDefault: convertDotcmsString(titleDefault),
      titleHomepage: convertDotcmsString(titleHomepage),
      titleEsppResourcesPage: convertDotcmsString(titleEsppResourcesPage),
      titleCashlessParticipationPage: convertDotcmsString(
        titleCashlessParticipationPage,
      ),
      titleEsppCalculatorPage: convertDotcmsString(titleEsppCalculatorPage),
      titleLoginPage: convertDotcmsString(titleLoginPage),
      titlePrivacyPage: convertDotcmsString(titlePrivacyPage),
      titleTaxInformationPage: convertDotcmsString(titleTaxInformationPage),
      titleImportantInformationPage: convertDotcmsString(
        titleImportantInformationPage,
      ),
      titleTermsOfServicePage: convertDotcmsString(titleTermsOfServicePage),
      titleAccountStatusPage: convertDotcmsString(titleAccountStatusPage),
      titleDocumentsPage: convertDotcmsString(titleDocumentsPage),
      titleContactInformationPage: convertDotcmsString(
        titleContactInformationPage,
      ),
      titleManageElectionPage: convertDotcmsString(titleManageElectionPage),
      titleStockInformationPage: convertDotcmsString(titleStockInformationPage),
      titlePurchaseSummaryPage: convertDotcmsString(titlePurchaseSummaryPage),
      titleEnrollmentPage: convertDotcmsString(titleEnrollmentPage),
      titleEnrollmentConfirmationPage: convertDotcmsString(
        titleEnrollmentConfirmationPage,
      ),
      titleEnrollmentWelcomePage: convertDotcmsString(
        titleEnrollmentWelcomePage,
      ),
      titleSuffixArticlesPages: convertDotcmsString(titleSuffixArticlesPages),
      titleWealthManagementPage: convertDotcmsString(titleWealthManagementPage),
      titleEmailVerificationCompletePage: convertDotcmsString(
        titleEmailVerificationCompletePage,
      ),
      titleUnsubscribePage: convertDotcmsString(titleUnsubscribePage),
      titleAdminDataExchangePage: convertDotcmsString(
        titleAdminDataExchangePage,
      ),
      titleAdminDataUploadPage: convertDotcmsString(titleAdminDataUploadPage),
      titleAdminEmployeeLookupPage: convertDotcmsString(
        titleAdminEmployeeLookupPage,
      ),
      titleAdminEnrollmentStatsPage: convertDotcmsString(
        titleAdminEnrollmentStatsPage,
      ),
      titleAdminPlanOverviewPage: convertDotcmsString(
        titleAdminPlanOverviewPage,
      ),
      titleAdminSurveyResultsPage: convertDotcmsString(
        titleAdminSurveyResultsPage,
      ),
      titleAdminExchangeRatesPage: convertDotcmsString(
        titleAdminExchangeRatesPage,
      ),
      titleReferralPage: convertDotcmsString(titleReferralPage),
      titleAdminPurchaseLookup: convertDotcmsString(titleAdminPurchaseLookup),
      titleAdminContributionsPage: convertDotcmsString(
        titleAdminContributionsPage,
      ),
      titleAdminConfiguration: convertDotcmsString(titleAdminConfiguration),
      titleAutoSaleBrokerQuestionsPage: convertDotcmsString(
        titleAutoSaleBrokerQuestionsPage,
      ),
      titleAdminStockBasedCompensationPage: convertDotcmsString(
        titleAdminStockBasedCompensationPage,
      ),
    };
  },
};
