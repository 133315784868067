import { useMemo } from 'react';
import { FooterGqlHelper } from '../../../../../dotcms/dotcms-models/dotcms-footer';
import { useBuildersToProcessGqlData } from '../../../../../dotcms/use-builders-to-process-gql-data';
import { GlobalLoadingStateOperation } from '../../../global-loading-context/global-loading-context';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';

const useFetchFooterSection = () => {
  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(FooterGqlHelper);
  const { data, loading, error } =
    useFetchIssuerDotcmsContentWithDefaultFallback(
      createGqlQueryForSingle,
      processGqlDataAsSingle,
      GlobalLoadingStateOperation.FOOTER_DOTCMS_FETCH,
      contentTypeName,
    );

  return useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, loading, error],
  );
};

export default useFetchFooterSection;
