import type { PropsWithChildren } from 'react';
import React from 'react';
import NavigationMenuContext from './navigation-menu-context';
import { useFetchNavigationMenuData } from './hooks';

const NavigationMenuContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigationMenuData = useFetchNavigationMenuData();

  return (
    <NavigationMenuContext.Provider value={navigationMenuData}>
      {children}
    </NavigationMenuContext.Provider>
  );
};

export default NavigationMenuContextProvider;
