import { isValueEmpty } from './remove-empty-keys';

/* Combines two objects based on the default object.
 * If a field exists in the issuer object and is not empty,
 * it will replace the same field in the default object using a deep merge.
 * If the issuer array is not empty, replace the default array with the issuer array.
 */
export const mergeObjectsForDotcmsFallback = (
  defaultObject: object,
  issuerObject: object,
): object => {
  if (
    typeof defaultObject !== 'object' ||
    isValueEmpty(defaultObject) ||
    !defaultObject ||
    typeof issuerObject !== 'object' ||
    isValueEmpty(issuerObject) ||
    !issuerObject
  ) {
    // Cases where one value should completely override the other.
    return !isValueEmpty(issuerObject) ? issuerObject : defaultObject;
  }

  if (
    Array.isArray(defaultObject) &&
    typeof defaultObject !== 'string' &&
    Array.isArray(issuerObject) &&
    typeof issuerObject !== 'string'
  ) {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line  @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
    return [...(issuerObject || defaultObject)];
  }

  const newObjectEntriesFromDefaultObject = Object.entries(defaultObject)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map(([key, value]) => [
      key,
      // Object.hasOwn is not implemented on the version of node we use,
      // so using it makes our unit tests fail.
      // Object.prototype.hasOwnProperty.call is equivalent:
      // https://stackoverflow.com/a/69561603/5602521
      Object.prototype.hasOwnProperty.call(issuerObject, key)
        ? // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
        mergeObjectsForDotcmsFallback(value, (issuerObject as any)[key])
        : value,
    ]);
  const newObjectEntriesFromIssuerObject = Object.entries(issuerObject).filter(
    ([key]) => !Object.prototype.hasOwnProperty.call(defaultObject, key),
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.fromEntries([
    ...newObjectEntriesFromDefaultObject,
    ...newObjectEntriesFromIssuerObject,
  ]);
};
