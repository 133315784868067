import { useSelector } from 'react-redux';
import { usePlanDetails } from '../usePlanDetails';
import { uiSelector } from '../../selectors';
import type { IPlanDetails } from '../../selectors/interfaces';

const isPlanDetailsEsppOnly = (planDetails: IPlanDetails | null) =>
  planDetails?.cashlessMethodology === null &&
  planDetails?.cashless_revenue_model === null;

const usePlanIsEsppOnly = () => {
  const planDetails = usePlanDetails();
  return (
    planDetails?.cashlessMethodology === null &&
    planDetails?.cashless_revenue_model === null
  );
};

export const useIssuerIsEsppOnly = () => {
  const { jurisdictionByCountryCode } = useSelector(uiSelector);
  if (jurisdictionByCountryCode) {
    const countriesNotEsppOnly = Object.keys(jurisdictionByCountryCode).filter(
      (countryCode) => {
        const planDetails =
          jurisdictionByCountryCode?.[countryCode]?.plan_details ?? null;
        return !isPlanDetailsEsppOnly(planDetails);
      },
    );
    return countriesNotEsppOnly.length === 0;
  }

  return false;
};

export default usePlanIsEsppOnly;
