import { Box, styled } from '@mui/material';

export const CENTERED_CONTENT_STYLES = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FullPageCenteredContentWrapper = styled(Box)({
  ...CENTERED_CONTENT_STYLES,
  flexDirection: 'column',
  minHeight: '100dvh',
  width: '100vw',
  padding: '24px',
  overflow: 'scroll',
});
