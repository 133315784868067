import { createContext } from 'react';

export type DeviceIdContextData = {
  deviceId: string;
};

// Defaults for context are only used if a component tries to fetch context that doesn't
// exist in its parent tree. This variable will probably only ever be read by tests:
const DEVICE_ID_DEFAULT: DeviceIdContextData = {
  deviceId: 'fake-uuid',
};

export const DeviceIdContext =
  createContext<DeviceIdContextData>(DEVICE_ID_DEFAULT);
