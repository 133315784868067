import type { Alpha3Code } from 'i18n-iso-countries';
import { createContext } from 'react';
import { DateTime } from 'luxon';
import type { LoginMethod } from '../../../selectors/interfaces';
import type { StartEndDates } from '../../../hooks/use-get-periods-info/use-get-all-periods';

export const US_COUNTRY_CODE: Alpha3Code = 'USA';

export const enum EnrollmentStep {
  WELCOME = 'WELCOME',
  ELECTIONS = 'ELECTIONS',
  ELIGIBILITY_QUESTIONS = 'ELIGIBILITY_QUESTIONS',
  REVIEW_CONTRACTS_SIGN_SUBMIT = 'REVIEW_CONTRACTS_SIGN_SUBMIT',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  SUCCESS = 'SUCCESS',
}

export enum ElectionUpdateType {
  NEW_ENROLLMENT = 'NEW_ENROLLMENT',
  SCHEDULED_ENROLLMENT = 'SCHEDULED_ENROLLMENT',
  EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS = 'EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS',
  EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS = 'EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS',
  EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS = 'EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS',
}

export enum ClearingBrokerQuestionYesNoResponse {
  YES = 'YES',
  NO = 'NO',
}

export enum USVisaType {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  F1 = 'F1',
  G4 = 'G4',
  H1B = 'H1B',
  L1 = 'L1',
  O1 = 'O1',
  TN1 = 'TN1',
  OTHER = 'OTHER',
}

/** from API */
export enum USCitizenTaxStatus {
  NO = 'NO',
  US_CITIZEN = 'US_CITIZEN',
  US_PERMANENT_RESIDENT = 'US_PERMANENT_RESIDENT',
  US_TAX_RESIDENT = 'US_TAX_RESIDENT',
}

export enum ClearingBrokerApexQuestionValidationError {
  MISSING_CONTROL_PERSON_TICKERS = 'MISSING_CONTROL_PERSON_TICKERS',
  MISSING_AFFILIATED_EXCHANGE_OR_FINRA_NAMES = 'MISSING_AFFILIATED_EXCHANGE_OR_FINRA_NAMES',
  MISSING_POLITICALLY_EXPOSED_IMMEDIATE_FAMILY = 'MISSING_POLITICALLY_EXPOSED_IMMEDIATE_FAMILY',
  MISSING_POLITICALLY_EXPOSED_ORGANIZATIONS = 'MISSING_POLITICALLY_EXPOSED_ORGANIZATIONS',
  MISSING_CITIZENSHIP_COUNTRY = 'MISSING_CITIZENSHIP_COUNTRY',
  MISSING_BIRTH_COUNTRY = 'MISSING_BIRTH_COUNTRY',
  MISSING_VISA_TYPE = 'MISSING_VISA_TYPE',
  MISSING_VISA_EXPIRATION_DATE = 'MISSING_VISA_EXPIRATION_DATE',
  MISSING_SSN = 'MISSING_SSN',
  MISSING_TIN = 'MISSING_TIN',
}

export type EnrollmentContextData = {
  currentStep: EnrollmentStep;
  electionUpdateType: ElectionUpdateType;
  offeringPeriodDates: StartEndDates;
  purchaseDate: DateTime;
  showEditableEsppInfo: boolean;
  showCashlessInfo: boolean;
  enrollmentStepsOrder: ReadonlyArray<EnrollmentStep>;
  getNextStep: () => EnrollmentStep | undefined;
  getPreviousStep: () => EnrollmentStep | undefined;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToPreviousSpecificStep: (step: EnrollmentStep) => void;
  isRolloverChange: boolean;
  setIsRolloverChange: (newValue: boolean) => void;
  enrolled: boolean;
  previouslyWithdrawn: boolean;
  previousContributionPercent: number | undefined;
  selectedContributionPercent: number;
  onSelectContributionPercent: (newPercentSelected: string) => void;
  estimatedPaycheckImpactInHundredths: number;
  previousCashlessEnabled: boolean | undefined;
  selectedCashlessEnabled: boolean;
  onEnableCashless: () => void;
  onDisableCashless: () => void;
  eSignature: string;
  onChangeESignature: (newSignature: string) => void;
  loginMethod: LoginMethod | null;
  isRedirectToLoginRequired: boolean;
  dob: DateTime | null;
  setDob: (newDob: DateTime | null) => void;
  dobValidationError: string | null;
  setDobValidationError: (newError: string | null) => void;
  onEnrollmentSuccess: () => void;
};

const ENROLLMENT_CONTEXT_FALLBACK: EnrollmentContextData = {
  currentStep: EnrollmentStep.WELCOME,
  enrollmentStepsOrder: [],
  electionUpdateType: ElectionUpdateType.NEW_ENROLLMENT,
  offeringPeriodDates: {
    start: DateTime.fromISO(''),
    end: DateTime.fromISO(''),
  },
  purchaseDate: DateTime.fromISO(''),
  showEditableEsppInfo: true,
  showCashlessInfo: true,
  getNextStep: () => undefined,
  getPreviousStep: () => undefined,
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  goToPreviousSpecificStep: () => {},
  isRolloverChange: false,
  setIsRolloverChange: () => {},
  enrolled: false,
  previouslyWithdrawn: false,
  previousContributionPercent: undefined,
  selectedContributionPercent: 1,
  onSelectContributionPercent: () => {},
  estimatedPaycheckImpactInHundredths: 0,
  previousCashlessEnabled: undefined,
  selectedCashlessEnabled: true,
  onEnableCashless: () => {},
  onDisableCashless: () => {},
  eSignature: 'string',
  onChangeESignature: () => {},
  loginMethod: null,
  isRedirectToLoginRequired: false,
  dob: null,
  setDob: () => {},
  dobValidationError: null,
  setDobValidationError: () => {},
  onEnrollmentSuccess: () => {},
};

export const EnrollmentContext = createContext<EnrollmentContextData>(
  ENROLLMENT_CONTEXT_FALLBACK,
);
