/** This is not super reliable and should only be used as a best-effort
 * way to try to avoid replacing image URLs when the debug_cms option
 * is enabled.
 */
export const cmsStringLooksLikeUrlOrImage = (maybeUrl: string) => {
  if (maybeUrl.startsWith('http:')) {
    return true;
  }
  if (maybeUrl.startsWith('https:')) {
    return true;
  }
  if (maybeUrl.startsWith('/dA/')) {
    return true;
  }
  if (maybeUrl.includes('/webp')) {
    return true;
  }
  if (maybeUrl.includes('.png')) {
    return true;
  }
  if (maybeUrl.startsWith('<svg')) {
    return true;
  }
  return false;
};
