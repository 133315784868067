import type { PropsWithChildren } from 'react';
import React, { useState, useEffect, useMemo } from 'react';
import PageTitleContext from './page-title-context';
import type { Page } from '../../../app/page-url-path';
import { useGetCurrentPage } from '../../../app/get-current-page';
import { PageTitleKey, PageTitleKeyForPage } from '../../../app/page-title-key';
import { useSetPageTitle } from './hook/use-set-page-title';
import { useGetTitleForPage } from './hook/use-get-title-for-page';

const PageTitleContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const currentPage: Page | undefined = useGetCurrentPage();
  const { getCmsTitleForPage } = useGetTitleForPage();
  const [overridePageTitle, setOverridePageTitle] = useState<
  string | undefined
  >();
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    const pageTitle =
      overridePageTitle ??
      (currentPage
        ? getCmsTitleForPage(PageTitleKeyForPage[currentPage])
        : undefined) ??
      getCmsTitleForPage(PageTitleKey.DEFAULT);
    setPageTitle(pageTitle);

    return () => setPageTitle();
  }, [currentPage, getCmsTitleForPage, overridePageTitle, setPageTitle]);

  // Return setOverridePageTitle as setOverridePageTitle_internalDontUse:
  const contextValue = useMemo(
    () => ({ setOverridePageTitle_internalDontUse: setOverridePageTitle }),
    [],
  );

  return (
    <PageTitleContext.Provider value={contextValue}>
      {children}
    </PageTitleContext.Provider>
  );
};

export default PageTitleContextProvider;
