import { useCallback, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Duration } from 'luxon';
import { useLocalStorage } from '../../../utils/storage/localstorage/use-local-storage';
import { LocalStorageKey } from '../../../utils/storage/localstorage/localstorage';
import { HALF_MAGIC_LINK_PARAMS } from '../../../utils/url-search-params/url-search-params';

/** Do not use this. This hook should only be called in one place in our entire app. */
const useHalfMagicLinkHandlerInternalSingleton = () => {
  const location = useLocation();
  const { getValueFromLocalStorage, setValueToLocalStorage } = useLocalStorage(
    LocalStorageKey.HALF_MAGIC_LINK_STATUS,
  );

  const [loading, setLoading] = useState(true);
  const [isHalfMagicLoggedIn, setIsHalfMagicLoggedIn] = useState(false);

  const handleHalfMagicUrl = useCallback(() => {
    setIsHalfMagicLoggedIn(true);
    setValueToLocalStorage({ halfMagicLoggedIn: true });
  }, [setValueToLocalStorage]);

  useLayoutEffect(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const searchParamsIncludesHalfMagic = HALF_MAGIC_LINK_PARAMS.some(
      (halfMagicLinkParam) => currentSearchParams.has(halfMagicLinkParam),
    );
    if (searchParamsIncludesHalfMagic) {
      handleHalfMagicUrl();
    }
  }, [handleHalfMagicUrl, location.search]);

  useLayoutEffect(() => {
    // Only count them as half magic logged in from local storage
    // if it was set < 1 day ago. (If we don't pick a duration, it'll
    // last forever):
    const valueFromLocalStorage = getValueFromLocalStorage({
      maxAge: Duration.fromObject({ days: 1 }),
    });
    if (valueFromLocalStorage?.halfMagicLoggedIn) {
      setIsHalfMagicLoggedIn(true);
    }
    // This useEffect does not run on first render, so we must have
    // a loading state. Otherwise, on first render, we can return
    // isHalfMagicLoggedIn: false, when it should be true, which
    // triggers a redirect to the login page.
    setLoading(false);
  }, [getValueFromLocalStorage]);

  return { isHalfMagicLoggedIn, loading };
};

export default useHalfMagicLinkHandlerInternalSingleton;
