import React from 'react';
import { DataElementId } from '../../../../constants/data-element-tracking-ids';
import { LoadingOverlay } from '../../loading-overlay/loading-overlay';
import useSessionTimeoutPopups from './hooks/useSessionTimeoutPopups';
import BasicPopup from '../basic-popup';

const SessionTimeoutPopup = () => {
  const {
    dialogData,
    dialogOpen,
    loading,
    isSessionEndModal,
    error,
    handleOnClose,
  } = useSessionTimeoutPopups();

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !dialogData) {
    return null;
  }

  return (
    <BasicPopup
      id={
        isSessionEndModal
          ? 'session-timeout-popup'
          : 'session-timeout-warning-popup'
      }
      dialogData={dialogData}
      dialogOpen={dialogOpen}
      loading={loading}
      cmsError={error}
      handleOnClose={handleOnClose}
      primaryButton={{
        content: dialogData.primaryButton?.text,
        onClick: handleOnClose,
        elementId: isSessionEndModal
          ? DataElementId.SESSION_TIMEOUT_POPUP_CLOSE_BUTTON
          : DataElementId.SESSION_TIMEOUT_WARNING_POPUP_CLOSE_BUTTON,
      }}
    />
  );
};

export default SessionTimeoutPopup;
