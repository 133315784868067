import type { LDFlagSet } from 'launchdarkly-js-sdk-common';
import type { JSONSerializableObject } from '../../json-types';

/**
 * It is generally a good idea to prefix your keys with "CE" because
 * we may import some other library that uses localstorage, and
 * prefixing will help us avoid name collisions.
 */
export enum LocalStorageKey {
  LAUNCHDARKLY_FLAG_OVERRIDES = 'CE_LAUNCHDARKLY_FLAG_OVERRIDES',
  // The last signature the user entered in the legacy enrollment form:
  ENROLLMENT_SIGNATURE = 'CE_LEGACY_ENROLLMENT_SIGNATURE',
  // If the user agreed to our terms in the legacy enrollment form:
  USER_AGREED_TO_TERMS = 'CE_LEGACY_USER_AGREED_TO_TERMS',
  // If the user saw the cashless only ineligible pop up and dismissed it:
  DISMISSED_CASHLESS_ONLY_INELIGIBLE_MODAL = 'CE_LEGACY_DISMISSED_CASHLESS_ONLY_INELIGIBLE_MODAL',
  // If the Aramark user saw the suspension pop up and dismissed it:
  ARAMARK_SUSPENSION_MODAL_OPEN = 'ARAMARK_SUSPENSION_MODAL_OPEN',
  // If the Aramark user saw the suspension pop up after planed date end and dismissed it:
  ARAMARK_SUSPENSION_MODAL_OPEN_AFTER_END_DATE = 'ARAMARK_SUSPENSION_MODAL_OPEN_AFTER_END_DATE',
  ARAMARK_EOI_CONSENT_COMPLETED = 'ARAMARK_EOI_CONSENT_COMPLETED',
  // The transaction amount:
  TRANSACTION_AMOUNT_MILLIONTHS = 'CE_TRANSACTION_AMOUNT_MILLIONTHS',
  // The user's language selection:
  USER_LANGUAGE_SELECTION = 'CE_LANGUAGE_SELECTION',
  // Half-magic link status:
  HALF_MAGIC_LINK_STATUS = 'CE_HALF_MAGIC_LINK_STATUS',
  // The language from URL params:
  LANGUAGE_FROM_URL_PARAMS = 'CE_LANGUAGE_FROM_URL_PARAMS',
  // The country and state from URL params:
  LOCATION_FROM_URL_PARAMS = 'CE_LOCATION_FROM_URL_PARAMS',
  /** The device ID generated for event logging. Don't directly
   * read this from local storage. There is a hook that provides this.
   * That hook should be the only thing that interacts with this local
   * storage key. */
  DEVICE_INFO_INTERNAL_DONT_USE = 'CE_DEVICE_INFO',
  // A set of alerts that can be displayed to the user.
  ALERT_BANNERS = 'CE_ALERT_BANNERS',
  /** The last session for which the enrollment reminder pop-up was shown. */
  REMINDER_TO_ENROLL_POPUP_LAST_SESSION = 'CE_REMINDER_TO_ENROLL_POPUP_LAST_SESSION',
  /** The last refresh timestamp and number of refresh retries */
  LAST_PAGE_RETRY_INFO = 'CE_LAST_PAGE_RETRY_INFO',
}

export type LocalStorageTimestamp = {
  // E.g. 2022-11-07T18:02:13.888Z
  valueSetAtIso: string;
};

// This EnforceLocalStorageTypeValues type enforces that all of the types
// in LocalStorageTypeDefs are JSON serializable objects:
// https://stackoverflow.com/a/74022234/5602521
type EnforceLocalStorageTypeValues<T extends JSONSerializableObject> = T;

/**
 * For each local storage key, this defines the type of its value
 * that will be stored in local storage.
 *
 * Note that a) if you change a type for an existing key,
 * OLD BROWSERS MAY STILL HAVE THE OLD TYPE,
 * and b) because of a), all changes should be backwards compatible.
 * (An example of a non-backwards-compatible change that you should not do:
 * if you have `[LocalStorageKey.A]: {someVal: string}`, and you change it to be
 * `{someVal: number}`, this will cause a runtime error when trying to parse it.)
 *
 * LocalStorageTypeDefs only supports using objects as value types, because we store
 * a timestamp alongside it by default for easy expiration. If you want to store a single
 * value type and not an object, you can just do:
 * [LocalStorageKey.FOO]: {value: string}
 *
 * Each value type in LocalStorageTypeDefs MUST NOT HAVE BEEN DEFINED AS AN "interface", it MUST
 * be defined as a "type".
 * E.g. if you say `interface IFoo {...}` and then do this:
 * `[LocalStorageKey.FOO]: IFoo`
 * it will not compile.
 */
type LocalStorageTypeDefs = {
  [LocalStorageKey.LAUNCHDARKLY_FLAG_OVERRIDES]: LDFlagSet;
  [LocalStorageKey.ENROLLMENT_SIGNATURE]: {
    signature: string;
  };
  [LocalStorageKey.USER_AGREED_TO_TERMS]: {
    agreedToTerms: boolean;
  };
  [LocalStorageKey.DISMISSED_CASHLESS_ONLY_INELIGIBLE_MODAL]: {
    dismissedModal: boolean;
  };
  [LocalStorageKey.ARAMARK_SUSPENSION_MODAL_OPEN]: {
    suspensionModal: boolean;
  };
  [LocalStorageKey.ARAMARK_SUSPENSION_MODAL_OPEN_AFTER_END_DATE]: {
    suspensionModalAfterEndDate: boolean;
  };
  [LocalStorageKey.ARAMARK_EOI_CONSENT_COMPLETED]: {
    eoiConsentCompleted: boolean;
  };
  [LocalStorageKey.TRANSACTION_AMOUNT_MILLIONTHS]: {
    amount: number;
  };
  [LocalStorageKey.USER_LANGUAGE_SELECTION]: {
    /** The locale code, generally language-COUNTRY e.g. "en-US" */
    localeCode: string;
  };
  [LocalStorageKey.HALF_MAGIC_LINK_STATUS]: {
    halfMagicLoggedIn: boolean;
  };
  [LocalStorageKey.LANGUAGE_FROM_URL_PARAMS]: {
    localeCode: string;
  };
  [LocalStorageKey.LOCATION_FROM_URL_PARAMS]: {
    countryCode: string;
    stateCode?: string;
  };
  [LocalStorageKey.DEVICE_INFO_INTERNAL_DONT_USE]: {
    deviceId: string;
  };
  [LocalStorageKey.ALERT_BANNERS]: {
    removedAlerts: string[];
  };
  [LocalStorageKey.REMINDER_TO_ENROLL_POPUP_LAST_SESSION]: {
    sessionId: string;
  };
  [LocalStorageKey.LAST_PAGE_RETRY_INFO]: {
    lastRetryTimestamp: number;
    lastRetryNumber: number;
  };
};

/**
 * This uses the type system to enforce that:
 * 1. All keys defined in LocalStorageKey have value types defined in LocalStorageTypeDefs
 * 2. All value types in LocalStorageTypeDefs are JSON-serializable
 *
 * If you get a weird type error in here, you probably added a new key to LocalStorageKey
 * but didn't define a type for its value in LocalStorageTypeDefs.
 *
 * https://stackoverflow.com/a/74022349/5602521
 */
export type LocalStorageTypes = {
  [Key in LocalStorageKey as LocalStorageTypeDefs[Key] extends JSONSerializableObject
    ? Key
    : never]: EnforceLocalStorageTypeValues<LocalStorageTypeDefs[Key]>;
};

/**
 * Mark true if a flag value should persist after logout. False otherwise.
 * Most should be false.
 */
export const SHOULD_PRESERVE_VALUES_ON_LOGOUT: Readonly<{
  [Key in LocalStorageKey]: boolean;
}> = {
  [LocalStorageKey.LAUNCHDARKLY_FLAG_OVERRIDES]: true,
  [LocalStorageKey.ARAMARK_SUSPENSION_MODAL_OPEN]: false,
  [LocalStorageKey.ARAMARK_SUSPENSION_MODAL_OPEN_AFTER_END_DATE]: false,
  [LocalStorageKey.DISMISSED_CASHLESS_ONLY_INELIGIBLE_MODAL]: false,
  [LocalStorageKey.ENROLLMENT_SIGNATURE]: false,
  [LocalStorageKey.USER_AGREED_TO_TERMS]: false,
  [LocalStorageKey.TRANSACTION_AMOUNT_MILLIONTHS]: false,
  [LocalStorageKey.USER_LANGUAGE_SELECTION]: false,
  [LocalStorageKey.HALF_MAGIC_LINK_STATUS]: false,
  [LocalStorageKey.LANGUAGE_FROM_URL_PARAMS]: false,
  [LocalStorageKey.LOCATION_FROM_URL_PARAMS]: false,
  [LocalStorageKey.DEVICE_INFO_INTERNAL_DONT_USE]: true,
  [LocalStorageKey.ALERT_BANNERS]: true,
  [LocalStorageKey.REMINDER_TO_ENROLL_POPUP_LAST_SESSION]: false,
  [LocalStorageKey.ARAMARK_EOI_CONSENT_COMPLETED]: true,
  [LocalStorageKey.LAST_PAGE_RETRY_INFO]: true,
};
