import { LOCAL_STORAGE_KEY } from '../../constants';
import type { LocalStorageKey } from './localstorage';
import { SHOULD_PRESERVE_VALUES_ON_LOGOUT } from './localstorage';

export const clearLocalStoragePostLogout = () => {
  // Only way to preserve typing when mapping over enum keys is to use 'as' :/
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (Object.keys(LOCAL_STORAGE_KEY) as Array<LocalStorageKey>).forEach(
    (localStorageKey) => {
      if (SHOULD_PRESERVE_VALUES_ON_LOGOUT[localStorageKey]) {
        return;
      }
      localStorage.removeItem(localStorageKey);
    },
  );
};
