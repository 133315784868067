import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import type { GlobalLoadingContextData } from '../global-loading-context';
import { GlobalLoadingStateOperation } from '../global-loading-context';
import { useBooleanUrlSearchParam } from '../../../../utils/url-search-params/use-query-url-search-params';
import UrlSearchParamKey from '../../../../utils/url-search-params/url-search-params';
import { useAreFeatureFlagsLoading } from '../../../../featureFlags/useAreFeatureFlagsLoading';

/** If an operation is marked as true in this map, it is considered
 * a render-blocking operation, and it will stop most components from rendering
 * in our app. (Near the root of our application, a loading component will be returned,
 * instead of the components that would render the page).
 *
 * If false, a loading screen will be displayed that blocks the screen, but is rendered *on top of*
 * the application, so the components underneath will still be called, they just won't be visible.
 *
 * In general, setting a value to true here should be avoided. If every value below were true,
 * our network requests would mostly happen in parallel instead of in serial.
 *
 * An operation should only be set to true below if it will cause errors in our
 * application if we try to render things before the operation has completed. (Mostly
 * legacy stuff. If you add a new operation, you should handle the case where its
 * data is not available yet without errors, so that you can mark it false below.)
 *
 * Eventually we should refactor all of our code so that rendering never needs to be blocked
 * for any operation, and then we can remove this map.
 */
const SHOULD_PREVENT_COMPONENTS_FROM_RENDERING: Readonly<{
  [key in GlobalLoadingStateOperation]: boolean;
}> = {
  [GlobalLoadingStateOperation.ABOUT_CASHLESS_DOTCMS_CONTENT_FETCH]: false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_CONTACT_INFO_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_DOCUMENTS_PAGE_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_MANAGE_ELECTION_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_ROOT_DOTCMS_CONTENT_FETCH]: false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_PURCHASE_DETAILS_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_STATUS_SUMMARY_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_STOCK_INFORMATION_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ACCOUNT_PAGE_WITHDRAWAL_REASONS_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ADMIN_PAGE_CONTRIBUTIONS_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ADMIN_ENROLLMENT_STATS_CMS_FETCH]: false,
  [GlobalLoadingStateOperation.ADMIN_EXCHANGE_RATES_CMS_FETCH]: false,
  [GlobalLoadingStateOperation.ADMIN_SURVEY_RESULTS_CMS_FETCH]: false,
  [GlobalLoadingStateOperation.ADMIN_PAGE_PLAN_OVERVIEW_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ADMIN_EXPERIENCE_HEADER_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ADMIN_PAGE_PURCHASE_LOOKUP_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ADMIN_PAGE_PLAN_ENROLLMENT_CONFIGURATION_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ADMIN_EXPERIENCE_EMPLOYEE_LOOKUP_TOOL_DOTCMS_FETCH]:
    false,
  [GlobalLoadingStateOperation.ADMIN_DATA_EXCHANGE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ADMIN_DATA_UPLOAD_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ALERT_BANNER_DOTCMS_CONTENT_FETCH]: false,
  [GlobalLoadingStateOperation.ANTI_BOT_BROKER_QUESTIONS_LANDING_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME_CONTENT_FETCH]:
    false,
  // currently, a lot of pages on the site will crash if we try to render them
  // before get_assets and verify_cookie have finished loading:
  [GlobalLoadingStateOperation.APIV2_GET_ASSETS]: true,
  [GlobalLoadingStateOperation.APIV2_VERIFY_COOKIE]: true,
  [GlobalLoadingStateOperation.APIV2_ISSUER_FEATURE]: false,
  [GlobalLoadingStateOperation.ARTICLE_PAGE_ARTICLE_DATA_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ARTICLE_PAGE_ROOT_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.AUTO_SALE_CLEARING_BROKER_APEX_ELIGIBILITY_QUESTIONS]:
    false,
  [GlobalLoadingStateOperation.CALCULATOR_DOTCMS_CONTENT_FETCH]: false,
  [GlobalLoadingStateOperation.CALCULATOR_PAGE_25K_LIMIT]: false,
  [GlobalLoadingStateOperation.COOKIE_BANNER_DOTCMS_CONTENT_FETCH]: false,
  [GlobalLoadingStateOperation.EMAIL_VERIFICATION_COMPLETE_PAGE_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.ENROLLMENT_CLEARING_BROKER_APEX_ELIGIBILITY_QUESTIONS]:
    false,
  [GlobalLoadingStateOperation.ENROLLMENT_CONFIRMATION_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ENROLLMENT_ELECTIONS_PAGE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ENROLLMENT_REVIEW_CONTRACTS_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ENROLLMENT_WELCOME_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ENROLLMENT_WRAPPER_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ESPP_DEFAULT_PAGE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.ESPP_RESOURCES_PAGE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.FOOTER_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.DISCLAIMER_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.TAX_INFORMATION_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.PRIVACY_POLICY_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.TERMS_OF_SERVICE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.GLOBAL_TEXT_VARIABLES_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.HEADER_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.HEADER_NAVIGATION_MENU_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.HOMEPAGE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.INACTIVITY_NOTICE_MODAL_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.IDENTITY_VERIFICATION_POPUP_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.LOGIN_INFO_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.LOGIN_FORM_SSO_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.LOGIN_FORM_DOB_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.PAGE_TITLE_LIST_HELPER_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.SESSION_TIMEOUT_POPUPS_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.OPERATION_BUTTON_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.DOCUMENT_TITLE_LIST_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.REDIRECTING_TO_EXTERNAL_PAGE]: false,
  [GlobalLoadingStateOperation.REFER_COWORKER_PAGE_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.REMINDER_TO_ENROLL_POPUP_DOTCMS_FETCH]: false,
  [GlobalLoadingStateOperation.RESOURCES_PAGE_25K_LIMIT]: false,
  [GlobalLoadingStateOperation.STOCK_PRICE_INFO_TRANSLATIONS_DOTCMS_FETCH]:
    false,
  [GlobalLoadingStateOperation.RESOURCES_PAGE_TAX_GUIDE]: false,
  [GlobalLoadingStateOperation.WEALTH_MANAGEMENT_PAGE_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.EMAIL_UNSUBSCRIBE_PAGE_DOTCMS_CONTENT_FETCH]:
    false,
  [GlobalLoadingStateOperation.EXPRESSION_OF_NOTICE_POPUPS_FETCH]: false,
  [GlobalLoadingStateOperation.AUTOMATIC_SIGNOUT_POPUP_DOTCMS_FETCH]: false,
};

type LoadingInfo = Readonly<{
  // Saving DateTime enables us to delay showing the loading screen until a certain
  // amount of time has passed, so that it doesn't show up as a tiny flicker for
  // things that load very quickly:
  beganAt: DateTime;
}>;

type LoadingStates = Readonly<
Partial<{ [key in GlobalLoadingStateOperation]: undefined | LoadingInfo }>
>;

const useGlobalLoadingContextProviderInternalSingleton =
  (): GlobalLoadingContextData => {
    const launchDarklyFlagsLoading = useAreFeatureFlagsLoading();

    // This state's value is only read from inside its own setter function:
    const [, setLoadingStates] = useState<LoadingStates>({});

    // This is undefined if no operations are currently loading.
    // Otherwise, it's the timestamp that marks how long things
    // have been loading.
    const [hasBeenLoadingSince, setHasBeenLoadingSince] = useState<
    DateTime | undefined
    >();

    const [
      displayRenderBlockingLoadingScreenRaw,
      setDisplayRenderBlockingLoadingScreen,
    ] = useState(false);
    const displayRenderBlockingLoadingScreen =
      displayRenderBlockingLoadingScreenRaw || launchDarklyFlagsLoading;

    const [, setIsInitialPageLoad] = useState(true);

    // This state is not read for anything - we just need to be able to trigger a re-render
    // after a delay. Setting this state will trigger a re-render.
    const [, setValueToForceComponentRerender] = useState(0);

    const [loadingOperationsForDebugging, setLoadingOperationsForDebugging] =
      useState<ReadonlyArray<string>>([]);

    const shouldTrackDebugInfo = useBooleanUrlSearchParam(
      UrlSearchParamKey.SHOW_LOADING_SCREEN_DEBUG,
    );

    const setOperationLoading = useCallback(
      (operation: GlobalLoadingStateOperation, operationIsLoading: boolean) => {
        const now = DateTime.now();

        if (operationIsLoading) {
          // A new operation has started loading.
          setHasBeenLoadingSince((previousLoadingSinceValue) => {
            if (previousLoadingSinceValue === undefined) {
              // If the start time was undefined, nothing was loading before.
              // Now something is loading, so we should set the 'loadingSince'
              // start time to now.
              // 200 ms from now, we should also force a re-render of this hook's component:
              setValueToForceComponentRerender((count) => count + 1);
              // Set start time to now:
              return now;
            }
            // If we got here, something already was loading before this call was made,
            // so we should not update the loadingSince time. Set the start time to its
            // previous value:
            return previousLoadingSinceValue;
          });

          if (SHOULD_PREVENT_COMPONENTS_FROM_RENDERING[operation]) {
            // This one should ignore the 200ms delay, otherwise the site
            // might have visible errors or crash during those 200ms:
            setDisplayRenderBlockingLoadingScreen(true);
          }
        }

        // A lot of the logic to change this component's state happens inside of
        // the setLoadingStates state setter callback function:
        setLoadingStates((oldLoadingStates) => {
          const newLoadingStates = {
            ...oldLoadingStates,
            [operation]: operationIsLoading ? { beganAt: now } : undefined,
          };

          if (shouldTrackDebugInfo) {
            const newLoadingStateDebugInfo = Object.entries(newLoadingStates)
              .filter(([, value]) => value)
              .map(
                ([key, value]) =>
                  `${key}: ${value.beganAt.toRelative() ?? '---'}`,
              )
              .sort();
            // eslint-disable-next-line no-console
            console.log('Loading state debug info: ', newLoadingStateDebugInfo);
            setLoadingOperationsForDebugging(newLoadingStateDebugInfo);
          }

          if (!operationIsLoading) {
            // This operation finished loading. We may need to set our loading states
            // back to false.
            delete newLoadingStates[operation];
            const nothingIsLoadingAnymore = !Object.values(
              GlobalLoadingStateOperation,
            ).some((operationToCheck) => !!newLoadingStates[operationToCheck]);

            if (nothingIsLoadingAnymore) {
              setHasBeenLoadingSince(undefined);
              setIsInitialPageLoad(false);
              setDisplayRenderBlockingLoadingScreen(false);
              return newLoadingStates;
            }

            // If we got here, something is loading, but we may still have to set
            // render blocking to false:
            const nothingRenderBlockingIsLoadingAnymore = !Object.values(
              GlobalLoadingStateOperation,
            ).some(
              (operationToCheck) =>
                !!newLoadingStates[operationToCheck] &&
                SHOULD_PREVENT_COMPONENTS_FROM_RENDERING[operationToCheck],
            );

            if (nothingRenderBlockingIsLoadingAnymore) {
              setDisplayRenderBlockingLoadingScreen(false);
            }
          }

          // This is returning the new value for setLoadingStates to set, since
          // we are still inside the setter:
          return newLoadingStates;
        });
      },
      [shouldTrackDebugInfo],
    );

    // if (shouldTrackDebugInfo && (displayRenderBlockingLoadingScreen)

    // Only show the overlay if something has been loading for > 200ms:
    const now = DateTime.now();
    const mostRecentLoadingStartTimeToConsider = now;
    const displayLoadingScreenOverlay =
      displayRenderBlockingLoadingScreen ||
      (!!hasBeenLoadingSince &&
        hasBeenLoadingSince <= mostRecentLoadingStartTimeToConsider);

    return useMemo(
      () => ({
        setOperationLoading,
        displayLoadingScreenOverlay,
        // Don't render until launchDarkly flags have loaded either.
        // (We don't have a way to get a callback when launchDarkly flags start/stop loading,
        // so this is a hack to achieve the same thing):
        displayRenderBlockingLoadingScreen,
        currentLoadingOperationsForDebugging: loadingOperationsForDebugging,
      }),
      [
        displayLoadingScreenOverlay,
        displayRenderBlockingLoadingScreen,
        loadingOperationsForDebugging,
        setOperationLoading,
      ],
    );
  };

export default useGlobalLoadingContextProviderInternalSingleton;
