import { useMemo } from 'react';
import { useBuildersToProcessGqlData } from '../../../../dotcms/use-builders-to-process-gql-data';
import { PageTitleListHelper } from '../../../../dotcms/dotcms-models/dotcms-page-title-list';
import { GlobalLoadingStateOperation } from '../../../abc/global-loading-context/global-loading-context';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';

export const useFetchPageTitleListDataFromCms = () => {
  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(PageTitleListHelper);
  const { data, loading, error } =
    useFetchIssuerDotcmsContentWithDefaultFallback(
      createGqlQueryForSingle,
      processGqlDataAsSingle,
      GlobalLoadingStateOperation.PAGE_TITLE_LIST_HELPER_DOTCMS_CONTENT_FETCH,
      contentTypeName,
    );

  return useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, loading, error],
  );
};
