import { useGetTheme } from '../../../../hooks/use-get-theme';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';
import { InactivityNoticeModalGqlHelper } from '../../../../dotcms/dotcms-models/dotcms-inactivity-notice-modal';
import { useBuildersToProcessGqlData } from '../../../../dotcms/use-builders-to-process-gql-data';
import { GlobalLoadingStateOperation } from '../../global-loading-context/global-loading-context';

const useInactivityNoticeModal = () => {
  const theme = useGetTheme();
  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(InactivityNoticeModalGqlHelper);
  const { data, loading, error } =
    useFetchIssuerDotcmsContentWithDefaultFallback(
      createGqlQueryForSingle,
      processGqlDataAsSingle,
      GlobalLoadingStateOperation.INACTIVITY_NOTICE_MODAL_DOTCMS_FETCH,
      contentTypeName,
    );

  return {
    theme,
    data,
    loading,
    error,
  };
};

export default useInactivityNoticeModal;
