import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalLoadingContext } from '../global-loading-context/global-loading-context';
import { LoadingSpinner } from './loading-spinner';
import { ElementZIndexOrdering } from '../../../component_library/utils/element-z-iIndex-ordering';

export const LoadingOverlay: React.FC = () => (
  <Box
    sx={{
      height: '100dvh',
      width: '100vw',
      position: 'absolute',
      zIndex: ElementZIndexOrdering.LOADING_OVERLAY,
      top: '0',
      left: '0',
    }}
  >
    <Box
      sx={{
        height: '100dvh',
        width: '100vw',
        position: 'sticky',
        zIndex: ElementZIndexOrdering.LOADING_OVERLAY,
        top: '0',
        left: '0',
      }}
    >
      <Box
        sx={{
          // Making it display the loading spinner in its center:
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // Making it cover its entire parent:
          position: 'sticky',
          height: '100dvh',
          width: '100vw',
          overflow: 'hidden',
          top: '0',
          left: '0',
          backgroundColor: 'white',
          zIndex: ElementZIndexOrdering.LOADING_OVERLAY,
        }}
      >
        <LoadingSpinner />
      </Box>
    </Box>
  </Box>
);

export const ConditionalLoadingOverlay: React.FC = () => {
  const { displayLoadingScreenOverlay } = useContext(GlobalLoadingContext);

  if (!displayLoadingScreenOverlay) {
    return null;
  }

  return <LoadingOverlay />;
};
