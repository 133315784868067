/** @jsxImportSource @emotion/react */
// see - https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=3%3A4
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { AriaButtonProps } from 'react-aria';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnstyledButton from './UnstyledButton';
import type { IButtonProps } from './button-style-helper';
import { getButtonStyles } from './button-style-helper';

/** Use onPress, not onClick */
const Button: React.FC<
PropsWithChildren<IButtonProps & Omit<AriaButtonProps<'button'>, 'onClick'>>
> = ({
  children,
  variant,
  color,
  size,
  icon,
  theme,
  additionalStyles = {},
  type,
  dataElementId,
  ...ariaButtonProps
}) => {
  const buttonClasses = getButtonStyles({
    variant,
    theme,
    size,
    color,
  });

  return (
    <UnstyledButton
      additionalStyles={[buttonClasses, additionalStyles]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ariaButtonProps}
      type={type}
      dataElementId={dataElementId}
    >
      {icon && (
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FontAwesomeIcon {...icon} style={{ marginRight: '8px' }} />
        </div>
      )}
      {children}
    </UnstyledButton>
  );
};

export default Button;
