import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useGetRouteToIssuerPage } from '../../../../../app/get-route-to-page';
import { Page } from '../../../../../app/page-url-path';

import { useGetIssuerShortname } from '../../../../../hooks/use-get-issuer-shortname';
import { useFeatureFlagEnabledBoolean } from '../../../../../featureFlags/use-boolean-flag';
import { BooleanFeatureFlag } from '../../../../../featureFlags/booleanFlags';
import { useBooleanUrlSearchParam } from '../../../../../utils/url-search-params/use-query-url-search-params';
import { useSetUrlSearchParam } from '../../../../../utils/url-search-params/use-set-url-search-param';
import UrlSearchParamKey from '../../../../../utils/url-search-params/url-search-params';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { fetchLogout } from '../../../../../slices/auth';
import { INACTIVITY_SIGNOUT_MS } from '../../../../../utils/constants';
import { useBuildersToProcessGqlData } from '../../../../../dotcms/use-builders-to-process-gql-data';
import { InactivityPopupsGqlHelper } from '../../../../../dotcms/dotcms-models/popup/dotcms-inactivity-popups';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';
import { GlobalLoadingStateOperation } from '../../../global-loading-context/global-loading-context';
import { authSelector } from '../../../../../selectors';
import { captureErrorInSentryWithCustomMessage } from '../../../../../utils/capture-error-in-sentry-with-custom-message';

const useAutomaticSignoutPopup = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isAuthenticated } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const loginUrl = useGetRouteToIssuerPage(Page.LOGIN_PAGE);
  const ticker = useGetIssuerShortname() ?? '';

  const [setSignedOutDueToInactivity] = useSetUrlSearchParam(
    UrlSearchParamKey.SIGNED_OUT_DUE_TO_INACTIVITY,
  );

  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(InactivityPopupsGqlHelper);
  const {
    data: cmsData,
    loading: cmsLoading,
    error: cmsError,
  } = useFetchIssuerDotcmsContentWithDefaultFallback(
    createGqlQueryForSingle,
    processGqlDataAsSingle,
    GlobalLoadingStateOperation.AUTOMATIC_SIGNOUT_POPUP_DOTCMS_FETCH,
    contentTypeName,
  );

  const signedOutDueToInactivity = useBooleanUrlSearchParam(
    UrlSearchParamKey.SIGNED_OUT_DUE_TO_INACTIVITY,
  );

  const shouldPreventInactivitySignout = useFeatureFlagEnabledBoolean(
    BooleanFeatureFlag.PREVENT_INACTIVITY_TIMEOUT_SIGNOUT,
  );

  useEffect(() => {
    setDialogOpen(signedOutDueToInactivity);
  }, [signedOutDueToInactivity]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleOnIdle = useCallback(async () => {
    if (isAuthenticated && !shouldPreventInactivitySignout) {
      setSignedOutDueToInactivity(true);
      await dispatch(
        fetchLogout({
          shortName: ticker,
          signedOutDueToInactivity: true,
        }),
      );
    }
  }, [
    dispatch,
    isAuthenticated,
    setSignedOutDueToInactivity,
    shouldPreventInactivitySignout,
    ticker,
  ]);

  useIdleTimer({
    timeout: INACTIVITY_SIGNOUT_MS,
    onIdle: () => {
      handleOnIdle().catch((error: unknown) =>
        captureErrorInSentryWithCustomMessage(
          error,
          'Failed to process onIdle logout handler',
        ));
    },
    debounce: 500,
    crossTab: true,
    syncTimers: 200,
    leaderElection: true,
  });

  return useMemo(
    () => ({
      dialogData: cmsData?.automaticSignoutPopup,
      cmsLoading,
      cmsError,
      loginUrl,
      dialogOpen,
      handleDialogClose,
    }),
    [cmsData, cmsLoading, cmsError, loginUrl, dialogOpen, handleDialogClose],
  );
};

export default useAutomaticSignoutPopup;
