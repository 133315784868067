import type { GridSize, Grid2Props as GridProps } from '@mui/material';
import { Grid2 as MaterialGrid } from '@mui/material';
import React from 'react';

const replaceZeroWithUndefined = (
  source: GridSize | undefined | null,
): GridSize | undefined | null => {
  if (source === 0) {
    return undefined;
  }
  return source;
};

// Set an undefined for column numbers instead of 0
// to maintain the same styles as Grid v1, which is now deprecated.
// Setting the value to 0 for Grid v2 does set the column to 0 width.
const replaceUndefinedInstead0 = (
  sourceSizeProp: GridProps['size'],
): GridProps['size'] => {
  if (sourceSizeProp === 0) {
    return undefined;
  }

  if (
    typeof sourceSizeProp === 'number' ||
    typeof sourceSizeProp === 'string' ||
    typeof sourceSizeProp === 'boolean' ||
    sourceSizeProp === undefined
  ) {
    return sourceSizeProp;
  }

  if (Array.isArray(sourceSizeProp)) {
    return sourceSizeProp;
  }

  return {
    desktop: replaceZeroWithUndefined(sourceSizeProp.desktop),
    tablet: replaceZeroWithUndefined(sourceSizeProp.tablet),
    tabletMini: replaceZeroWithUndefined(sourceSizeProp.tabletMini),
    mobile: replaceZeroWithUndefined(sourceSizeProp.mobile),
  };
};

/**
 * FYI, this component uses negative margins so it will look a little
 * weird/misaligned in the inspect element screen even if it is aligned correctly.
 */
// eslint-disable-next-line react/display-name
const GridItem = React.forwardRef<
HTMLDivElement,
Omit<GridProps, 'container' | 'columns'>
>((props, ref) => (
  <MaterialGrid
    ref={ref}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    size={replaceUndefinedInstead0(props.size)}
  />
));

export default GridItem;
