import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import type { DateTime } from 'luxon';
import { uiSelector, userSelector } from '../../selectors';
import type {
  IEnrollmentPeriodInfo,
  IOfferingPeriodInfo,
  IPeriodInfo,
  IPurchasePeriodInfo,
} from '../../selectors/interfaces';

export type StartEndDates = {
  start: DateTime;
  end: DateTime;
};

const useGetAllPeriods = <T>(
  getCurrent: (periodInfo: IPeriodInfo) => T | null,
  getFuture: (periodInfo: IPeriodInfo) => T,
): {
    allPeriods: (T | null)[];
    closestPeriod: T;
    currentPeriod: T | null;
    futurePeriod: T;
  } => {
  const { periodInfo: periodInfoForIssuer } = useSelector(uiSelector);
  const { periodInfo: periodInfoForUser } = useSelector(userSelector);

  const periodInfo = useMemo(
    () => periodInfoForUser || periodInfoForIssuer,
    [periodInfoForUser, periodInfoForIssuer],
  );
  const currentPeriod = useMemo(
    () => getCurrent(periodInfo),
    [getCurrent, periodInfo],
  );
  const futurePeriod = useMemo(
    () => getFuture(periodInfo),
    [getFuture, periodInfo],
  );

  return useMemo(
    () => ({
      allPeriods: [currentPeriod, futurePeriod],
      closestPeriod: currentPeriod ?? futurePeriod,
      currentPeriod,
      futurePeriod,
    }),
    [currentPeriod, futurePeriod],
  );
};

export const useGetAllEnrollmentPeriods = () => {
  const getCurrent = useCallback(
    (period: IPeriodInfo) =>
      (!!period.currentEnrollmentPeriod?.startDate &&
      !!period.currentEnrollmentPeriod?.endDate
        ? period.currentEnrollmentPeriod
        : null),
    [],
  );
  const getFuture = useCallback(
    (period: IPeriodInfo) => period.futureEnrollmentPeriod,
    [],
  );
  return useGetAllPeriods<IEnrollmentPeriodInfo>(getCurrent, getFuture);
};

export const useGetAllOfferingPeriods = () => {
  const getCurrent = useCallback(
    (period: IPeriodInfo) =>
      (!!period.currentOfferingPeriod?.offeringPeriodStartDate &&
      !!period.currentOfferingPeriod?.offeringPeriodEndDate
        ? period.currentOfferingPeriod
        : null),
    [],
  );
  const getFuture = useCallback(
    (period: IPeriodInfo) => period.futureOfferingPeriod,
    [],
  );
  return useGetAllPeriods<IOfferingPeriodInfo>(getCurrent, getFuture);
};

export const useGetAllPurchasePeriods = () => {
  const getCurrent = useCallback(
    (period: IPeriodInfo) =>
      (!!period.currentPurchasePeriod?.purchasePeriodStartDate &&
      !!period.currentPurchasePeriod?.purchasePeriodEndDate
        ? period.currentPurchasePeriod
        : null),
    [],
  );
  const getFuture = useCallback(
    (period: IPeriodInfo) => period.futurePurchasePeriod,
    [],
  );
  return useGetAllPeriods<IPurchasePeriodInfo>(getCurrent, getFuture);
};
