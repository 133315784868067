import type { PropsWithChildren } from 'react';
import React from 'react';
import { useFetchStockPriceLayoutData } from './hook/stock-price-layout-helpers';
import StockPriceLayoutContext from './stock-price-layout-context';

const StockPriceLayoutContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const stockPriceLayoutData = useFetchStockPriceLayoutData();
  return (
    <StockPriceLayoutContext.Provider value={stockPriceLayoutData}>
      {children}
    </StockPriceLayoutContext.Provider>
  );
};

export default StockPriceLayoutContextProvider;
