import type {
  JSONSerializableObject,
  JSONSerializableValue,
} from '../utils/json-types';
import { trimAny } from '../utils/string/trim-any';
import { cmsStringLooksLikeUrlOrImage } from './cms-string-looks-like-url';
import { processConditionalCmsStringsInContentFromCms } from './conditional-cms-strings';
import { NON_HUMAN_READABLE_CMS_FIELDS } from './non-human-readable-cms-fields';

type VisitableNode = Readonly<{
  // Keep track of field names so we can log a detailed error if we
  // encounter bad data inside a particular field.
  // E.g. if a node is 'leaf' in:
  // {
  //   someField: {
  //     innerField: {
  //       leaf: "leafValue",
  //     }
  //   }
  // }
  // then fieldNamesFromRoot should be:
  // ["someField", "innerField", "leaf"]
  fieldNamesFromRoot: ReadonlyArray<string>;
  node: JSONSerializableObject | JSONSerializableValue[];
}>;

/** We attach "_notranslate_" to words in the CMS that we don't want our auto-translation to
 * translate, and then remove that string here as post-processing:
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const removeNotranslate_internalImpl = (stringToProcess: string) =>
  stringToProcess.replace(/_notranslate_/gi, '');

interface IReplaceDynamicVariablesInString {
  stringToReplaceIn: string;
  replacementStringMapping: Record<string, string | number | undefined>;
  ignoredReplacementStringMapping?: string[] | undefined;
  metadataForLogging: {
    cmsContentTypeTitle: string;
    fieldNames: ReadonlyArray<string>;
  };
  replaceTextWithCmsDebugInfo?: boolean;
}

/** This is used to replace occurrences of dynamic variables in text from the CMS.
 * See the unit tests for example behavior.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const replaceDynamicVariablesInString_internalImpl = ({
  stringToReplaceIn,
  replacementStringMapping,
  ignoredReplacementStringMapping,
  metadataForLogging,
  replaceTextWithCmsDebugInfo,
}: IReplaceDynamicVariablesInString): string => {
  if (replaceTextWithCmsDebugInfo) {
    const currentFieldName =
      metadataForLogging.fieldNames[metadataForLogging.fieldNames.length - 1];
    // Don't replace config values with their field names or it will break stuff:
    if (
      !NON_HUMAN_READABLE_CMS_FIELDS.has(currentFieldName) &&
      !cmsStringLooksLikeUrlOrImage(stringToReplaceIn)
    ) {
      return `${
        metadataForLogging.cmsContentTypeTitle
      } > ${metadataForLogging.fieldNames
        .filter((fieldName) => fieldName !== '__html')
        .join(' > ')}`;
    }
  }
  return stringToReplaceIn.replace(
    /{{\s*[A-z0-9_]+\s*}}/g,
    (matchingSubstring) => {
      // matchingSubstring will look like "{{ some_key}}"; we want "some_key":
      const keyWithinMatchingSubstring = trimAny(matchingSubstring.trim(), [
        '{',
        '}',
      ]).trim();

      const replacementString: string | number | undefined =
        replacementStringMapping[keyWithinMatchingSubstring];
      if (replacementString !== undefined) {
        return String(replacementString);
      }

      // If we reach this point, we did not have a replacement in our mapping.

      const dynamicVariableNameExists = Object.keys(
        replacementStringMapping,
      ).includes(keyWithinMatchingSubstring);
      if (
        !dynamicVariableNameExists &&
        !ignoredReplacementStringMapping?.includes(keyWithinMatchingSubstring)
      ) {
        console.error(
          `A dynamic variable was used in the cms that the frontend does not recognize: "${matchingSubstring}". This occurred in cms content type: "${
            metadataForLogging.cmsContentTypeTitle
          }" in the field: "${metadataForLogging.fieldNames.join(' > ')}"`,
        );
      }
      // return the original substring unreplaced:
      return matchingSubstring;
    },
  );
};

/** Returns true iff the given value can have children (i.e. is an object or an array) */
const isValidNode = (
  value: JSONSerializableValue,
): value is JSONSerializableValue[] | JSONSerializableObject =>
  Array.isArray(value) || (typeof value === 'object' && value !== null);

interface IRecursivelyReplaceStringWithMapping<
  InputType extends JSONSerializableObject | JSONSerializableValue[],
> {
  objectToReplace: InputType;
  replacementStringMapping: Record<string, string | number | undefined>;
  conditionalValues: Readonly<{
    trueConditions: ReadonlyArray<string>;
    falseConditions: ReadonlyArray<string>;
  }>;
  ignoredReplacementStringMapping?: string[] | undefined;
  ignoredConditionalValues?: string[] | undefined;
  metadataForLogging?: Partial<{ cmsContentTypeTitle: string }>;
  replaceTextWithCmsDebugInfo?: boolean;
}

/** Parse CMS content */
export const recursivelyReplaceStringWithMapping = <
  InputType extends JSONSerializableObject | JSONSerializableValue[],
>({
    objectToReplace,
    replacementStringMapping,
    conditionalValues,
    ignoredReplacementStringMapping,
    ignoredConditionalValues,
    metadataForLogging,
    replaceTextWithCmsDebugInfo,
  }: IRecursivelyReplaceStringWithMapping<InputType>) => {
  const { cmsContentTypeTitle } = metadataForLogging ?? {};
  const parseCmsContent = (
    stringToReplaceIn: string,
    metadataForLoggingInner: {
      cmsContentTypeTitle: string;
      fieldNames: ReadonlyArray<string>;
    },
  ): string => {
    const stringWithConditionalsProcessed =
      processConditionalCmsStringsInContentFromCms(stringToReplaceIn, {
        ...conditionalValues,
        ...metadataForLoggingInner,
        ignoredConditions: ignoredConditionalValues ?? [],
      });

    const stringWithNotranslateProcessed = removeNotranslate_internalImpl(
      stringWithConditionalsProcessed,
    );

    const stringWithDynamicVariablesReplaced =
      replaceDynamicVariablesInString_internalImpl({
        stringToReplaceIn: stringWithNotranslateProcessed,
        replacementStringMapping,
        ignoredReplacementStringMapping,
        metadataForLogging: metadataForLoggingInner,
        replaceTextWithCmsDebugInfo,
      });

    return stringWithDynamicVariablesReplaced;
  };

  // This is the easiest way to make a deep copy of the object:
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const result = JSON.parse(JSON.stringify(objectToReplace)) as InputType;

  // Recursively visit all values in the object. If the value is not a string,
  // leave it alone. If it is a string, replace any dynamic vars.
  // We made a copy of objectToReplace so that we can mutate it directly.

  // Stack of nodes to visit:
  const toVisit: VisitableNode[] = [{ node: result, fieldNamesFromRoot: [] }];
  while (toVisit.length > 0) {
    const currentNodeWithMetadata = toVisit.pop();
    // currentNode will never be undefined; "?? {}" is just to make the type system
    // not complain:
    const currentNode = currentNodeWithMetadata?.node ?? {};
    const currentFieldNames = currentNodeWithMetadata?.fieldNamesFromRoot ?? [];
    if (Array.isArray(currentNode)) {
      // If the node is an array, we have to iterate through its indices:
      for (let i = 0; i < currentNode.length; i += 1) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const currentChild = currentNode[i];
        if (typeof currentChild === 'string') {
          // Replace any dynamic vars in the string:
          currentNode[i] = parseCmsContent(currentChild, {
            cmsContentTypeTitle: cmsContentTypeTitle || 'unknown',
            fieldNames: currentNodeWithMetadata?.fieldNamesFromRoot ?? [],
          });
        }
        if (isValidNode(currentChild)) {
          // A new node to visit:
          toVisit.push({
            node: currentChild,
            fieldNamesFromRoot: [...currentFieldNames, `[${i}]`],
          });
        }
      }
    } else {
      // Else, it's an object, so we iterate through its keys:
      // (Eslint disable because the "unsafe access" is just from
      // when it writes currentFieldNameForLogging)
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      Object.keys(currentNode).forEach((nodeKey) => {
        const currentChild = currentNode[nodeKey];
        if (typeof currentChild === 'string') {
          // Replace any dynamic vars in the string:
          currentNode[nodeKey] = parseCmsContent(currentChild, {
            cmsContentTypeTitle: cmsContentTypeTitle || 'unknown',
            fieldNames: [...currentFieldNames, nodeKey],
          });
        }
        if (isValidNode(currentChild)) {
          // A new node to visit:
          toVisit.push({
            node: currentChild,
            fieldNamesFromRoot: [...currentFieldNames, nodeKey],
          });
        }
      });
    }
  }

  return result;
};
