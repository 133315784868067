import { useCallback, useMemo } from 'react';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  faBarChart,
  faChartPie,
  faExchangeAlt,
  faGlobeEurope,
  faMagnifyingGlass,
  faTimeline,
  faFileInvoiceDollar,
  faMoneyBills,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { Page } from '../../../../app/page-url-path';
import { useGetPageConditionRequirementResult } from '../../../../app/pages-requiring-condition';
import type { PageInfo } from '../../../../utils/helpers/getPageToRedirectTo';
import type { IAdminExperienceHeaderData } from '../../../../dotcms/dotcms-models/dotcms-admin-experience-header';
import type { INavigationItemLink } from '../../../../dotcms/dotcms-models/dotcms-navigation-item-link';
import { useBetaPages } from '../../../../app/use-beta-pages';
import { getRouteToPage } from '../../../../app/get-route-to-page';
import { useGetIssuerShortname } from '../../../../hooks/use-get-issuer-shortname';

export type PageInfoWithLabelAndIcon = Partial<PageInfo> & {
  icon?: IconDefinition;
  label: string;
  isBeta: boolean;
  childrenPages?: Partial<Record<Page, PageInfoWithLabelAndIcon>>;
  cmsDataForLink?: Partial<Pick<INavigationItemLink, 'label' | 'icon'>>;
};

type PageConfiguration = {
  icon?: IconDefinition;
  label: string;
  isPageAvailable: boolean;
  childrenPages?: Partial<Record<Page, PageConfiguration>>;
};

type PagesInfoByCategoryAndOrder = {
  name: string | undefined;
  items: Partial<Record<Page, PageInfoWithLabelAndIcon>>;
};

export const isAdminPage = (page: Page) => Page[page].startsWith('ADMIN_');

export const useGetAdminPagesInfoByCategoriesAndOrderWithData = (
  adminHeaderData: IAdminExperienceHeaderData | undefined,
): PagesInfoByCategoryAndOrder[] => {
  const issuerShortName = useGetIssuerShortname();
  const { betaPages } = useBetaPages();
  const isBetaPage = useCallback(
    (page: Page) => betaPages.includes(page),
    [betaPages],
  );

  const isAdminPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_PAGE,
  );

  const isContributionsPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_DATA_CONTRIBUTIONS,
  );
  const isEnrollmentStatsPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_ENROLLMENT_STATS,
  );
  const isEmployeeLookupPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_EMPLOYEE_LOOKUP,
  );
  const isStockBasedCompensationPageAvailable =
    useGetPageConditionRequirementResult(Page.ADMIN_STOCK_BASED_COMPENSATION);
  const isSurveyResultsPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_SURVEY_RESULTS,
  );
  const isDataExchangePageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_DATA_EXCHANGE,
  );
  const isDataUploadPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_DATA_UPLOAD,
  );
  const isPlanOverviewPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_PLAN_OVERVIEW,
  );
  const isPurchaseDetailsAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_PURCHASE_LOOKUP,
  );
  const isExchangeRatesPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_EXCHANGE_RATES,
  );
  const isConfigurationPageAvailable = useGetPageConditionRequirementResult(
    Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION,
  );

  const pagesCategories = useMemo(
    () => [
      {
        name: adminHeaderData?.overviewCategoryName,
        pages: [
          Page.ADMIN_PLAN_OVERVIEW,
          Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION,
        ],
      },
      {
        name: adminHeaderData?.toolsCategoryName,
        pages: [
          Page.ADMIN_ENROLLMENT_STATS,
          Page.ADMIN_PURCHASE_LOOKUP,
          Page.ADMIN_SURVEY_RESULTS,
          Page.ADMIN_DATA_CONTRIBUTIONS,
          Page.ADMIN_STOCK_BASED_COMPENSATION,
        ],
      },
      {
        name: adminHeaderData?.employeeLookupCategoryName,
        pages: [Page.ADMIN_EMPLOYEE_LOOKUP],
      },
      {
        name: adminHeaderData?.dataTransferCategoryName,
        pages: [Page.ADMIN_DATA_EXCHANGE, Page.ADMIN_DATA_UPLOAD],
      },
      {
        name: adminHeaderData?.exchangeRatesCategoryName,
        pages: [Page.ADMIN_EXCHANGE_RATES],
      },
    ],
    [
      adminHeaderData?.dataTransferCategoryName,
      adminHeaderData?.employeeLookupCategoryName,
      adminHeaderData?.exchangeRatesCategoryName,
      adminHeaderData?.overviewCategoryName,
      adminHeaderData?.toolsCategoryName,
    ],
  );

  const defaultPagesConfiguration: Partial<Record<Page, PageConfiguration>> =
    useMemo(
      () => ({
        [Page.ADMIN_PLAN_OVERVIEW]: {
          icon: faTimeline,
          label: adminHeaderData?.planTimelineLinkTitle ?? 'Plan overview',
          isPageAvailable: isPlanOverviewPageAvailable,
        },
        [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: {
          icon: faFileLines,
          label:
            adminHeaderData?.planConfigurationLinkTitle ??
            'Plan & Enrollment Configuration',
          isPageAvailable: isConfigurationPageAvailable,
        },
        [Page.ADMIN_ENROLLMENT_STATS]: {
          icon: faChartPie,
          label:
            adminHeaderData?.enrollmentDetailsLinkTitle ?? 'Enrollment details',
          isPageAvailable: isEnrollmentStatsPageAvailable,
        },
        [Page.ADMIN_DATA_CONTRIBUTIONS]: {
          icon: faMoneyBills,
          label: adminHeaderData?.contributionsLinkTitle ?? 'Contributions',
          isPageAvailable: isContributionsPageAvailable,
        },
        [Page.ADMIN_PURCHASE_LOOKUP]: {
          icon: faFileInvoiceDollar,
          label:
            adminHeaderData?.purchaseDetailsLinkTitle ?? 'Purchase details',
          isPageAvailable: isPurchaseDetailsAvailable,
        },
        [Page.ADMIN_SURVEY_RESULTS]: {
          icon: faBarChart,
          label: adminHeaderData?.surveyResultsLinkTitle ?? 'Survey results',
          isPageAvailable: isSurveyResultsPageAvailable,
        },
        [Page.ADMIN_STOCK_BASED_COMPENSATION]: {
          icon: faFileLines,
          label:
            adminHeaderData?.stockBasedCompensationsLinkTitle ??
            'Stock-based compensation',
          isPageAvailable: isStockBasedCompensationPageAvailable,
        },
        [Page.ADMIN_EMPLOYEE_LOOKUP]: {
          icon: faMagnifyingGlass,
          label: adminHeaderData?.employeeLookupLinkTitle ?? 'Employee lookup',
          isPageAvailable: isEmployeeLookupPageAvailable,
        },
        [Page.ADMIN_DATA_EXCHANGE]: {
          icon: faExchangeAlt,
          label:
            adminHeaderData?.dataTransferNavigationItemName ?? 'Data transfer',
          isPageAvailable:
            isDataExchangePageAvailable || isDataUploadPageAvailable,
          childrenPages: {
            [Page.ADMIN_DATA_EXCHANGE]: {
              label: adminHeaderData?.dataDownloadLinkTitle ?? 'Download',
              isPageAvailable: isDataExchangePageAvailable,
            },
            [Page.ADMIN_DATA_UPLOAD]: {
              label: adminHeaderData?.dataTransferLinkTitle ?? 'Upload',
              isPageAvailable: isDataUploadPageAvailable,
            },
          },
        },
        [Page.ADMIN_EXCHANGE_RATES]: {
          icon: faGlobeEurope,
          label: adminHeaderData?.exchangeRatesLinkTitle ?? 'Exchange rates',
          isPageAvailable: isExchangeRatesPageAvailable,
        },
      }),
      [
        isConfigurationPageAvailable,
        isContributionsPageAvailable,
        isDataExchangePageAvailable,
        isDataUploadPageAvailable,
        isEmployeeLookupPageAvailable,
        isEnrollmentStatsPageAvailable,
        isExchangeRatesPageAvailable,
        isPlanOverviewPageAvailable,
        isPurchaseDetailsAvailable,
        isStockBasedCompensationPageAvailable,
        isSurveyResultsPageAvailable,
        adminHeaderData?.contributionsLinkTitle,
        adminHeaderData?.dataDownloadLinkTitle,
        adminHeaderData?.dataTransferLinkTitle,
        adminHeaderData?.dataTransferNavigationItemName,
        adminHeaderData?.employeeLookupLinkTitle,
        adminHeaderData?.enrollmentDetailsLinkTitle,
        adminHeaderData?.exchangeRatesLinkTitle,
        adminHeaderData?.planConfigurationLinkTitle,
        adminHeaderData?.planTimelineLinkTitle,
        adminHeaderData?.purchaseDetailsLinkTitle,
        adminHeaderData?.stockBasedCompensationsLinkTitle,
        adminHeaderData?.surveyResultsLinkTitle,
      ],
    );

  const getPageInfo = useCallback(
    (
      page: Page,
      sourceConfiguration: Partial<Record<Page, PageConfiguration>>,
      getChildrenPagesInfo: (
        itemsConfiguration: Partial<Record<Page, PageConfiguration>>,
      ) => Partial<Record<Page, PageInfoWithLabelAndIcon>>,
    ): Partial<Record<Page, PageInfoWithLabelAndIcon>> | undefined => {
      const configuration = sourceConfiguration[page];
      if (!configuration) {
        return undefined;
      }

      const childrenPages = getChildrenPagesInfo(
        configuration.childrenPages ?? {},
      );

      return {
        [page]: {
          route: getRouteToPage(page, issuerShortName, {}),
          isBeta:
            isBetaPage(page) ||
            (childrenPages &&
              Object.values(childrenPages).find((x) => x.isBeta)),
          isPageAvailable:
            isAdminPageAvailable && (configuration?.isPageAvailable ?? true),
          icon: configuration?.icon,
          label: configuration?.label,
          childrenPages,
        },
      };
    },
    [isAdminPageAvailable, isBetaPage, issuerShortName],
  );

  const getPagesInfo = useCallback(
    (
      itemsConfiguration: Partial<Record<Page, PageConfiguration>>,
    ): Partial<Record<Page, PageInfoWithLabelAndIcon>> =>
      Object.keys(itemsConfiguration)
        .map((page) =>
          getPageInfo(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            Page[page as keyof typeof Page],
            itemsConfiguration,
            getPagesInfo,
          ))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {}) ?? {},
    [getPageInfo],
  );

  return useMemo(
    () =>
      pagesCategories.map((category) => ({
        name: category.name,
        items: getPagesInfo(
          category.pages.reduce(
            (prev, curr) => ({
              ...prev,
              [curr]: defaultPagesConfiguration[curr],
            }),
            {},
          ),
        ),
      })),
    [defaultPagesConfiguration, getPagesInfo, pagesCategories],
  );
};
