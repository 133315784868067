import Cookies from 'js-cookie';

export const COOKIES_ALLOWED_NECESSARY = 1;
export const COOKIES_ALLOWED_ALL = 2;
export const COOKIES_SESSION_EXPIRATION_KEY = 'ce_session_expiration';
export const COOKIES_SESSION_ID_KEY = 'ce_session';
export const necessaryCookies = [
  'magic_link',
  'cp_id',
  'popup',
  COOKIES_SESSION_EXPIRATION_KEY,
  COOKIES_SESSION_ID_KEY,
  'session',
];

const hostParts = window.location.host.split('.');
const domainsByLocation = hostParts.reduce<string[]>(
  (acc, current, index) => [...acc, hostParts.slice(index).join('.')],
  [],
);

const domains = [
  ...domainsByLocation,
  '.test.espp.com',
  '.stage.espp.com',
  '.espp.com',
];

const cookieDesc = Object.getOwnPropertyDescriptor(
  Document.prototype,
  'cookie',
);

const removeCookies = (acceptableCookies: string[]) => {
  domains.forEach((domain) => {
    Object.keys(Cookies.get()).forEach((cookie) => {
      if (!acceptableCookies?.includes(cookie)) {
        Cookies.remove(cookie, { domain, path: '/' });
        Cookies.remove(cookie);
      }
    });
  });
};

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const cloudWatchRumClient = (window as any).cwr;
const allowAWSCookies = (value: boolean) => {
  if (typeof cloudWatchRumClient === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    cloudWatchRumClient('allowCookies', value);
  }
};

const defineCookies = (acceptableCookies: string[], useAll?: boolean) => {
  Object.defineProperty(document, 'cookie', {
    get() {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return cookieDesc?.get?.call(document);
    },
    set(val: string) {
      const cookieName = val.split('=')[0];
      if (acceptableCookies.includes(cookieName) || useAll) {
        cookieDesc?.set?.call(document, val);
      }
    },
    configurable: true,
  });
};

export const manageCookies = ({
  isLoading,
  isControlled,
}: {
  isLoading: boolean;
  isControlled: boolean;
}) => {
  const cookieAccepted = localStorage?.getItem('cookieAccept');
  const allowOnlyNecessary = () => {
    allowAWSCookies(false);
    removeCookies(necessaryCookies);
    defineCookies(necessaryCookies);
  };
  if (!isLoading && cookieDesc?.configurable) {
    if (
      (Number(cookieAccepted) === COOKIES_ALLOWED_NECESSARY ||
        !cookieAccepted) &&
      isControlled
    ) {
      allowOnlyNecessary();
    } else if (
      Number(cookieAccepted) === COOKIES_ALLOWED_ALL ||
      !isControlled
    ) {
      defineCookies([], true);
      allowAWSCookies(true);
    }
  }
};
