import { match } from 'ts-pattern';
import { Page, PageLoginRequirement } from './page-url-path';
import { useGetCurrentPage } from './get-current-page';
import { useIsHalfMagicLoggedIn } from '../hooks/useIsHalfMagicLoggedIn';
import { useAppSelector } from './hooks';
import { authSelector, userSelector } from '../selectors';
import { useIsInitialDataLoaded } from '../components/initial-page-load-handler/initial-page-load-handler';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';

// Keep alphabetized
const PAGE_LOGIN_REQUIREMENTS: Readonly<{
  [key in Page]: PageLoginRequirement;
}> = {
  [Page.ABOUT_CASHLESS]: PageLoginRequirement.AT_LEAST_HALF_MAGIC_REQUIRED,
  [Page.ADMIN_DATA_CONTRIBUTIONS]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_ENROLLMENT_STATS]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_EXCHANGE_RATES]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_SURVEY_RESULTS]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PLAN_OVERVIEW]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_DATA_EXCHANGE]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_DATA_UPLOAD]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_EMPLOYEE_LOOKUP]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PURCHASE_LOOKUP]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]:
    PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ADMIN_PAGE]: PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
  [Page.ACCOUNT_PAGE]: PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]:
    PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ACCOUNT_PAGE_DOCUMENTS]:
    PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]:
    PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]:
    PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]:
    PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]:
    PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]:
    PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]:
    PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.AUTO_SALE_BROKER_QUESTIONS]:
    PageLoginRequirement.AT_LEAST_FULL_MAGIC_LINK,
  [Page.CALCULATOR]: PageLoginRequirement.AT_LEAST_HALF_MAGIC_REQUIRED,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]:
    PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.DEFAULT]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.EMAIL_VERIFICATION_COMPLETE]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.ENROLLMENT_PAGE]: PageLoginRequirement.AT_LEAST_FULL_MAGIC_LINK,
  [Page.HOMEPAGE]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.IMPORTANT_INFORMATION]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.LOGIN_PAGE]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.PRIVACY_POLICY]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.REFERRAL]: PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
  [Page.RESOURCE_LIBRARY]: PageLoginRequirement.AT_LEAST_HALF_MAGIC_REQUIRED,
  [Page.RESOURCE_LIBRARY_ID]: PageLoginRequirement.AT_LEAST_HALF_MAGIC_REQUIRED,
  // Technically the user needs to be authed for this page's submission to the backend
  // to not fail, but 99% of the time, if they visit the link with a half magic param,
  // they'll be correctly authed to this page:
  [Page.STANDALONE_BROKER_QUESTIONS]:
    PageLoginRequirement.AT_LEAST_HALF_MAGIC_REQUIRED,
  [Page.TAX_INFORMATION]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.TEMP_PDF_VIEWER_TEST]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.TERM_OF_SERVICE]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.UNSUBSCRIBE]: PageLoginRequirement.NO_LOGIN_REQUIRED,
  [Page.WEALTH_MANAGEMENT]: PageLoginRequirement.NO_LOGIN_REQUIRED,
};

const IS_ADMIN_PAGE: Readonly<{
  [key in Page]: boolean;
}> = {
  [Page.ABOUT_CASHLESS]: false,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: true,
  [Page.ADMIN_DATA_EXCHANGE]: true,
  [Page.ADMIN_DATA_UPLOAD]: true,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: true,
  [Page.ADMIN_PURCHASE_LOOKUP]: true,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: true,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]: true,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: true,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: true,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]: true,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]: true,
  [Page.ADMIN_ENROLLMENT_STATS]: true,
  [Page.ADMIN_EXCHANGE_RATES]: true,
  [Page.ADMIN_PLAN_OVERVIEW]: true,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]: true,
  [Page.ADMIN_SURVEY_RESULTS]: true,
  [Page.ADMIN_PAGE]: true,
  [Page.ACCOUNT_PAGE]: false,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: false,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: false,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: false,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: false,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: false,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: false,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: false,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: false,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: false,
  [Page.CALCULATOR]: false,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: false,
  [Page.DEFAULT]: false,
  [Page.EMAIL_VERIFICATION_COMPLETE]: false,
  [Page.ENROLLMENT_PAGE]: false,
  [Page.HOMEPAGE]: false,
  [Page.IMPORTANT_INFORMATION]: false,
  [Page.LOGIN_PAGE]: false,
  [Page.PRIVACY_POLICY]: false,
  [Page.REFERRAL]: false,
  [Page.RESOURCE_LIBRARY]: false,
  [Page.RESOURCE_LIBRARY_ID]: false,
  [Page.STANDALONE_BROKER_QUESTIONS]: false,
  [Page.TAX_INFORMATION]: false,
  [Page.TEMP_PDF_VIEWER_TEST]: false,
  [Page.TERM_OF_SERVICE]: false,
  [Page.UNSUBSCRIBE]: false,
  [Page.WEALTH_MANAGEMENT]: false,
};

export const isAdminOnlyUserAllowedToAccessPage = (page: Page) => {
  // `adminAccessOnly` users can only access pages that require no
  // authentication (i.e. doesn't even require half-magic link),
  // or pages that are admin pages.
  if (IS_ADMIN_PAGE[page]) {
    return true;
  }
  if (
    PAGE_LOGIN_REQUIREMENTS[page] === PageLoginRequirement.NO_LOGIN_REQUIRED
  ) {
    return true;
  }
  return false;
};

export const useIsOnAdminPage = () => {
  const currentPage = useGetCurrentPage();
  if (!currentPage) {
    return false;
  }
  return IS_ADMIN_PAGE[currentPage];
};

export const getPageLoginRequirement = (page: Page | undefined) =>
  (page
    ? PAGE_LOGIN_REQUIREMENTS[page] ?? PageLoginRequirement.NO_LOGIN_REQUIRED
    : PageLoginRequirement.NO_LOGIN_REQUIRED);

export const useGetCurrentPageLoginRequirement = () => {
  const currentPage = useGetCurrentPage();

  // Temporary hardcoding to make Resource Lib not require half magic
  // for rbglobal:
  const shortname = useGetIssuerShortname() ?? '';
  if (
    currentPage &&
    [Page.RESOURCE_LIBRARY, Page.RESOURCE_LIBRARY_ID].includes(currentPage) &&
    shortname === 'rbglobal'
  ) {
    return PageLoginRequirement.NO_LOGIN_REQUIRED;
  }

  return getPageLoginRequirement(currentPage);
};

export const useGetPageLoginRequirementResult = (
  pageLoginRequirement: PageLoginRequirement,
) => {
  const { halfMagicLoggedIn, loading } = useIsHalfMagicLoggedIn();
  const {
    isIssuerAdmin,
    isIssuerAdminAuthenticated,
    magicLinkRestricted: isLoggedInWithMagicLink,
  } = useAppSelector(userSelector);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isAuthenticated } = useAppSelector(authSelector);
  const initialDataIsLoaded = useIsInitialDataLoaded();

  // We can't tell yet if the user is logged in.
  if (!initialDataIsLoaded) {
    return true;
  }

  const isPageUnavailable = match({ pageLoginRequirement })
    .with(
      {
        pageLoginRequirement:
          PageLoginRequirement.FULL_NON_MAGIC_LOGIN_REQUIRED,
      },
      () => !isAuthenticated || isLoggedInWithMagicLink,
    )
    .with(
      { pageLoginRequirement: PageLoginRequirement.AT_LEAST_FULL_MAGIC_LINK },
      () =>
        // If full login is required, redirect if the user is not fully logged in:
        !isAuthenticated,
    )
    .with(
      {
        pageLoginRequirement:
          PageLoginRequirement.TWO_FACTOR_AUTHENTICATION_REQUIRED,
      },
      () => !isAuthenticated || (isIssuerAdmin && !isIssuerAdminAuthenticated),
    )
    .with(
      {
        pageLoginRequirement: PageLoginRequirement.AT_LEAST_HALF_MAGIC_REQUIRED,
      },
      () =>
        // If it's a page that requires at least half magic, the user either needs to be
        // authenticated or to be on a half-magic login:
        !loading && !halfMagicLoggedIn && !isAuthenticated,
    )
    .with(
      { pageLoginRequirement: PageLoginRequirement.NO_LOGIN_REQUIRED },
      () => false,
    )
    .exhaustive();

  return !isPageUnavailable;
};
