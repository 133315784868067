import { createContext } from 'react';

export type LocaleContextData = {
  locale: string;
  setLocale: (newLocale: string) => void;
  /** True if the user chose this language (as opposed to being defaulted to it) */
  userHasPickedLanguage: boolean;
};

// Defaults for context are only used if a component tries to fetch context that doesn't
// exist in its parent tree. This variable will probably only ever be read by tests:
const LOCALE_DEFAULT: LocaleContextData = {
  locale: 'en-US',
  setLocale: () => {},
  userHasPickedLanguage: false,
};

export const LocaleContext = createContext<LocaleContextData>(LOCALE_DEFAULT);
