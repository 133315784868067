import type { PropsWithChildren } from 'react';
import React from 'react';
import { GlobalLoadingContext } from './global-loading-context';
import useGlobalLoadingContextProviderInternalSingleton from './hooks/use-global-loading-context-provider';

const GlobalLoadingContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const globalLoadingContextData =
    useGlobalLoadingContextProviderInternalSingleton();

  return (
    <GlobalLoadingContext.Provider value={globalLoadingContextData}>
      {children}
    </GlobalLoadingContext.Provider>
  );
};

export default GlobalLoadingContextProvider;
