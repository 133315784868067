import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import { useFetchPageTitleListDataFromCms } from './hook/use-fetch-page-title-list-data-from-cms';
import type { IPageTitleData } from '../../../dotcms/dotcms-models/dotcms-page-title-list';

interface IPageTitleDataFromDotcms {
  data: IPageTitleData | undefined;
  loading: boolean;
  error: unknown;
}

export const PageTitleListContext = createContext<IPageTitleDataFromDotcms>({
  data: undefined,
  loading: false,
  error: null,
});

export const PageTitleListContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const pageTitleListData = useFetchPageTitleListDataFromCms();

  return (
    <PageTitleListContext.Provider value={pageTitleListData}>
      {children}
    </PageTitleListContext.Provider>
  );
};
