import { useCallback } from 'react';
import type { Duration } from 'luxon';
import { DateTime } from 'luxon';
import type {
  LocalStorageKey,
  LocalStorageTimestamp,
  LocalStorageTypes,
} from './localstorage';
import type { RecursivePartial } from '../../recursive-partial';

interface IOptions {
  maxAge?: Duration;
}

/**
 * getValueFromLocalStorage returns a recursively partial object to help maintain
 * backwards compatibility with values that were set in LocalStorage by old versions of
 * the web application.
 *
 * Generally getValueFromLocalStorage should be wrapped in a useMemo when called,
 * cause reading from local storage and parsing a json object can be expensive.
 */
export function useLocalStorage<StorageKey extends LocalStorageKey>(
  key: StorageKey,
): // If you get an error here about StorageKey not being able to index LocalStorageTypes,
  // you probably tried to use an interface in LocalStorageTypes instead of a type.
  {
    getValueFromLocalStorage: (
      options?: IOptions,
    ) => RecursivePartial<
    LocalStorageTypes[StorageKey] & LocalStorageTimestamp
    > | null;
    setValueToLocalStorage: (value: LocalStorageTypes[StorageKey]) => void;
    deleteValueFromLocalStorage: () => void;
  } {
  const getValueFromLocalStorage = useCallback(
    (options: IOptions = {}) => {
      const { maxAge } = options;
      const rawValueFromLocalStorage = localStorage?.getItem(key);
      if (
        rawValueFromLocalStorage === null ||
        rawValueFromLocalStorage === undefined
      ) {
        return null;
      }
      try {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const parsedValue = JSON.parse(
          rawValueFromLocalStorage,
        ) as RecursivePartial<
        LocalStorageTypes[StorageKey] & LocalStorageTimestamp
        >;
        // Check if the value has expired and return null if so:
        if (maxAge) {
          const { valueSetAtIso } = parsedValue;
          const valueSetAt =
            typeof valueSetAtIso === 'string'
              ? DateTime.fromISO(valueSetAtIso, { setZone: true })
              : undefined;
          if (!valueSetAt) {
            // The value didn't have a timestamp set for some reason.
            // Conisder it expired.
            console.error(
              `A local storage key was set with no "valueSetAt" parameter present. The key was: ${key}`,
            );
            return null;
          }
          const whenValueExpires = valueSetAt.plus(maxAge);
          if (DateTime.now() > whenValueExpires) {
            return null;
          }
        }
        // The value has not expired, so we can return it:
        return parsedValue;
      } catch (e) {
        console.error(
          `error when trying to parse a typed value from local storage for key ${key}:`,
          e,
        );
        return null;
      }
    },
    [key],
  );

  const setValueToLocalStorage = useCallback(
    (value: LocalStorageTypes[StorageKey]) => {
      const valueToSet: LocalStorageTypes[StorageKey] & LocalStorageTimestamp =
        {
          ...value,
          valueSetAtIso: new Date().toISOString(),
        };
      localStorage.setItem(key, JSON.stringify(valueToSet));
    },
    [key],
  );

  const deleteValueFromLocalStorage = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  return {
    getValueFromLocalStorage,
    setValueToLocalStorage,
    deleteValueFromLocalStorage,
  };
}
