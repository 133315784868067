import { ElectionUpdateType } from '../../pages/enrollment-abc/wrapper/enrollment-context';
import type { LoginMethod } from '../../selectors/interfaces';

enum UrlSearchParamKey {
  // Example values (the type system complains if not at least one of each type of key
  // exists in here. Delete these once real uses of each type are added):
  EXAMPLE_STRING_ARRAY = 'example-string-array',
  EXAMPLE_NUMBER = 'example-number',
  EXAMPLE_NUMBER_ARRAY = 'example-number-array',
  // The destination URL to redirect to.
  REDIRECT_TO = 'rd',
  AUTO_SUBMIT_ENROLLMENT = 'ase',
  SIGNED_OUT_DUE_TO_INACTIVITY = 'sdi',
  BACKEND_POST_LOGIN_REDIRECT_TO = 'ce_redirect_to',
  DEBUG_SHOW_CMS_CONTENT_FIELD_NAMES = 'debug_cms',
  HALF_MAGIC_LINK_LEGACY_DEFAULT = 'espp',
  HALF_MAGIC_LINK_LEGACY_VELOCITY = 'vspp',
  HALF_MAGIC_LINK_LEGACY_ARAMARK = 'myShare',
  SKIP_TO_SECOND_ENROLLMENT_STEP = 'enr2',
  HALF_MAGIC_LINK = 'hm',
  COUNTRY = 'country',
  STATE = 'state',
  LANGUAGE = 'lang',
  LOGIN_METHOD = 'login_method',
  EDITING_ELECTION = 'electionUpdateState',
  TOKEN = 'token',
  REFERRAL_TOKEN = 'referral_token',
  SHOW_LOADING_SCREEN_DEBUG = 'loadingDebug',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  WORK_COUNTRY = 'work_country',
  EMPLOYEE_COMPANY_ID = 'employee_company_id',
  EMAIL_ADDRESS = 'email_address',
  IS_TERMINATED = 'is_terminated',
  IS_ENROLLED = 'is_enrolled',
  OFFERING_PERIOD_IDS = 'offering_period_ids',
  ACCEPTED_CASHLESS = 'accepted_cashless',
  IS_ELIGIBLE = 'is_eligible',
  IS_AUTO_ENROLLED = 'is_auto_enrolled',
  CUSTOM_FILTER_COLUMNS = 'custom_cols',
  PATH = 'path',
  MAGIC_LINK_EXPIRATION_ISO_TIMESTAMP = 'magic_expiration',
  MAGIC_LINK_PRIMARY_COLOR = 'magic_primary_color',
  LAST_RETRY_TIMESTAMP = 'retry_timestamp',
  LAST_RETRY_NUMBER = 'retry_number',
  SEARCH = 'search',
  JURISDICTION_COUNTRY_CODE = 'jurisdiction_country_code',
}

export const HALF_MAGIC_LINK_PARAMS: ReadonlyArray<UrlSearchParamKey> = [
  UrlSearchParamKey.HALF_MAGIC_LINK_LEGACY_DEFAULT,
  UrlSearchParamKey.HALF_MAGIC_LINK_LEGACY_VELOCITY,
  UrlSearchParamKey.HALF_MAGIC_LINK_LEGACY_ARAMARK,
  UrlSearchParamKey.HALF_MAGIC_LINK,
];

/** We put these in the URL to avoid having long strings
 * like "EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS" */
export enum ElectionUpdateTypeSearchParamValue {
  NEW_ENROLLMENT = 'n_e',
  SCHEDULED_ENROLLMENT = 's_n_e',
  EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS = 'e_c_p_p',
  EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS = 'e_f_p_p',
  EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS = 'e_f_o_p',
}

export const ELECTION_UPDATE_TYPE_SEARCH_PARAM_TO_ENUM_MAP: {
  [urlSearchParamValue in ElectionUpdateTypeSearchParamValue]: ElectionUpdateType;
} = {
  [ElectionUpdateTypeSearchParamValue.NEW_ENROLLMENT]:
    ElectionUpdateType.NEW_ENROLLMENT,
  [ElectionUpdateTypeSearchParamValue.SCHEDULED_ENROLLMENT]:
    ElectionUpdateType.SCHEDULED_ENROLLMENT,
  [ElectionUpdateTypeSearchParamValue.EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS]:
    ElectionUpdateType.EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS,
  [ElectionUpdateTypeSearchParamValue.EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS]:
    ElectionUpdateType.EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS,
  [ElectionUpdateTypeSearchParamValue.EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS]:
    ElectionUpdateType.EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS,
};

export const ELECTION_UPDATE_TYPE_ENUM_TO_SEARCH_PARAM_MAP: {
  [electionUpdateTypeEnum in ElectionUpdateType]: ElectionUpdateTypeSearchParamValue;
} = {
  [ElectionUpdateType.NEW_ENROLLMENT]:
    ElectionUpdateTypeSearchParamValue.NEW_ENROLLMENT,
  [ElectionUpdateType.SCHEDULED_ENROLLMENT]:
    ElectionUpdateTypeSearchParamValue.SCHEDULED_ENROLLMENT,
  [ElectionUpdateType.EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS]:
    ElectionUpdateTypeSearchParamValue.EDITING_CURRENT_PURCHASE_PERIOD_ELECTIONS,
  [ElectionUpdateType.EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS]:
    ElectionUpdateTypeSearchParamValue.EDITING_FUTURE_PURCHASE_PERIOD_ELECTIONS,
  [ElectionUpdateType.EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS]:
    ElectionUpdateTypeSearchParamValue.EDITING_FUTURE_OFFERING_PERIOD_ELECTIONS,
};

type SearchParamValuePossibleTypes =
  | boolean
  | string
  | string[]
  | number
  | number[];

// This EnforceSearchParamTypeValues type enforces that all of the types
// in UrlSearchParamsTypeDefs are valid SearchParamValuePossibleTypes:
// https://stackoverflow.com/a/74022234/5602521
type EnforceSearchParamTypeValues<T extends SearchParamValuePossibleTypes> = T;

/**
 * For each URL search param key, this defines the type of its value
 * that will be stored in the URL.
 *
 * It can either be a boolean, string, or string array.
 *
 * Example: ?myBooleanParam&myStringParam="foo"&myArrayParam="bar1"&myArrayParam="bar2"
 *
 * BE VERY CAREFUL CHANGING TYPES for existing keys. It can easily break backwards
 * compatibility, if e.g. someone bookmarked a URL with the old style of param,
 * or someone visits a link from an email that we sent before changing how the param
 * works.
 */
type UrlSearchParamsTypeDefs = {
  [UrlSearchParamKey.EXAMPLE_NUMBER]: number;
  [UrlSearchParamKey.EXAMPLE_NUMBER_ARRAY]: number[];
  [UrlSearchParamKey.EXAMPLE_STRING_ARRAY]: string[];
  [UrlSearchParamKey.REDIRECT_TO]: string;
  [UrlSearchParamKey.AUTO_SUBMIT_ENROLLMENT]: boolean;
  [UrlSearchParamKey.COUNTRY]: string;
  [UrlSearchParamKey.BACKEND_POST_LOGIN_REDIRECT_TO]: string;
  [UrlSearchParamKey.SIGNED_OUT_DUE_TO_INACTIVITY]: boolean;
  [UrlSearchParamKey.DEBUG_SHOW_CMS_CONTENT_FIELD_NAMES]: boolean;
  [UrlSearchParamKey.HALF_MAGIC_LINK_LEGACY_DEFAULT]: boolean;
  [UrlSearchParamKey.HALF_MAGIC_LINK_LEGACY_VELOCITY]: boolean;
  [UrlSearchParamKey.HALF_MAGIC_LINK_LEGACY_ARAMARK]: boolean;
  [UrlSearchParamKey.HALF_MAGIC_LINK]: boolean;
  [UrlSearchParamKey.LANGUAGE]: string;
  [UrlSearchParamKey.LOGIN_METHOD]: LoginMethod | string;
  [UrlSearchParamKey.STATE]: string;
  [UrlSearchParamKey.EDITING_ELECTION]:
  | ElectionUpdateTypeSearchParamValue
  | string;
  [UrlSearchParamKey.TOKEN]: string;
  [UrlSearchParamKey.SKIP_TO_SECOND_ENROLLMENT_STEP]: boolean;
  [UrlSearchParamKey.REFERRAL_TOKEN]: string;
  [UrlSearchParamKey.SHOW_LOADING_SCREEN_DEBUG]: boolean;
  [UrlSearchParamKey.FIRST_NAME]: string;
  [UrlSearchParamKey.LAST_NAME]: string;
  [UrlSearchParamKey.WORK_COUNTRY]: string;
  [UrlSearchParamKey.EMPLOYEE_COMPANY_ID]: string;
  [UrlSearchParamKey.EMAIL_ADDRESS]: string;
  [UrlSearchParamKey.IS_TERMINATED]: string;
  [UrlSearchParamKey.IS_ENROLLED]: string;
  [UrlSearchParamKey.ACCEPTED_CASHLESS]: string;
  [UrlSearchParamKey.OFFERING_PERIOD_IDS]: string;
  [UrlSearchParamKey.IS_ELIGIBLE]: string;
  [UrlSearchParamKey.IS_AUTO_ENROLLED]: string;
  [UrlSearchParamKey.CUSTOM_FILTER_COLUMNS]: string;
  [UrlSearchParamKey.PATH]: string;
  [UrlSearchParamKey.MAGIC_LINK_EXPIRATION_ISO_TIMESTAMP]: string;
  [UrlSearchParamKey.MAGIC_LINK_PRIMARY_COLOR]: string;
  [UrlSearchParamKey.LAST_RETRY_TIMESTAMP]: number;
  [UrlSearchParamKey.LAST_RETRY_NUMBER]: number;
  [UrlSearchParamKey.SEARCH]: string;
  [UrlSearchParamKey.JURISDICTION_COUNTRY_CODE]: string;
};

/**
 * This uses the type system to enforce that:
 * 1. All keys defined in UrlSearchParamKey have value types defined in UrlSearchParamsTypeDefs
 * 2. All value types in UrlSearchParamsTypeDefs are SearchParamValuePossibleTypes
 *
 * If you get a weird type error in here, you probably added a new key to UrlSearchParamKey
 * but didn't define a type for its value in UrlSearchParamsTypeDefs.
 *
 * https://stackoverflow.com/a/74022349/5602521
 */
export type UrlSearchParamsTypes = {
  [Key in UrlSearchParamKey as UrlSearchParamsTypeDefs[Key] extends SearchParamValuePossibleTypes
    ? Key
    : never]: EnforceSearchParamTypeValues<UrlSearchParamsTypeDefs[Key]>;
};

export default UrlSearchParamKey;
