import { BooleanFeatureFlag } from '../featureFlags/booleanFlags';
import { useFeatureFlagEnabledBoolean } from '../featureFlags/use-boolean-flag';

export const useDownForMaintenance = () => {
  const downForMaintenanceAllIssuers = useFeatureFlagEnabledBoolean(
    BooleanFeatureFlag.DOWN_FOR_MAINTENANCE_ALL_ISSUERS,
  );

  return downForMaintenanceAllIssuers;
};
