import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import Typography, {
  TypographyVariant,
} from '../../../component_library/components/Typography/Typography';
import {
  Button,
  ButtonVariant,
  UnstyledButton,
} from '../../../component_library/components/Button';
import {
  manageCookies,
  COOKIES_ALLOWED_ALL,
  COOKIES_ALLOWED_NECESSARY,
} from '../../../utils/manageCookies';
import { DataElementId } from '../../../constants/data-element-tracking-ids';
import { useGetTheme } from '../../../hooks/use-get-theme';
import { useBuildersToProcessGqlData } from '../../../dotcms/use-builders-to-process-gql-data';
import { CookieSelectionAndPolicyGqlHelper } from '../../../dotcms/dotcms-models/dotcms-cookie-selection-and-policy';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../dotcms/use-fetch-from-dotcms-with-default-fallback';
import { GlobalLoadingStateOperation } from '../global-loading-context/global-loading-context';
import { AnchorLink } from '../../../component_library/components/Link';
import { ElementZIndexOrdering } from '../../../component_library/utils/element-z-iIndex-ordering';

interface ICookieBanner {
  openCookieBanner: boolean;
  setCookieBannerOpen: (newValue: boolean) => void;
}

const CookieBanner: React.FC<ICookieBanner> = ({
  openCookieBanner,
  setCookieBannerOpen,
}) => {
  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(CookieSelectionAndPolicyGqlHelper);
  const { data, loading } = useFetchIssuerDotcmsContentWithDefaultFallback(
    createGqlQueryForSingle,
    processGqlDataAsSingle,
    GlobalLoadingStateOperation.COOKIE_BANNER_DOTCMS_CONTENT_FETCH,
    contentTypeName,
  );
  const theme = useGetTheme();
  const handleAcceptNecessary = useCallback(() => {
    localStorage.setItem('cookieAccept', String(COOKIES_ALLOWED_NECESSARY));
    manageCookies({ isLoading: false, isControlled: true });
    setCookieBannerOpen(false);
  }, [setCookieBannerOpen]);

  const handleAcceptAll = useCallback(() => {
    localStorage.setItem('cookieAccept', String(COOKIES_ALLOWED_ALL));
    manageCookies({ isLoading: false, isControlled: true });
    setCookieBannerOpen(false);
  }, [setCookieBannerOpen]);

  const handleClose = () => {
    handleAcceptNecessary();
    setCookieBannerOpen(false);
  };

  if (!openCookieBanner || loading) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        bottom: '0',
        left: '0',
        width: '100%',
        backgroundColor: theme.colors.bannerYellow,
        zIndex: ElementZIndexOrdering.COOKIE_BANNER,
        padding: '12px 94px 12px 24px',
        alignItems: 'center',
      }}
    >
      <Typography
        variant={TypographyVariant.MEDIUM_BODY_REGULAR}
        // Width inherit makes this take up all the rest of the space
        // not occupied by the buttons:
        // https://stackoverflow.com/a/46819284/5602521
        additionalStyles={{ width: 'inherit', display: 'inline' }}
      >
        {/* Fall back to English values if there was an error fetching the translated version from
        the network, since this banner is a legal requirement: */}
        {data?.cookieBannerText ??
          'We use necessary cookies to make this website work and performance cookies that help us improve our site and services.'}
        {' '}
        <AnchorLink
          href={data?.cookiePolicySectionLink?.url ?? '#'}
          dataElementId={DataElementId.COOKIE_BANNER_COOKIE_POLICY_LINK}
          target="_blank"
          referrerPolicy="no-referrer"
          additionalStyles={{ display: 'inline' }}
        >
          {data?.learnMoreLinkText ?? 'Learn more'}
        </AnchorLink>
      </Typography>
      <Button
        onPress={handleAcceptAll}
        type="button"
        variant={ButtonVariant.PRIMARY}
        dataElementId={DataElementId.COOKIE_BANNER_ACCEPT_ALL_BUTTON}
        theme={theme}
        size="default"
        additionalStyles={{ margin: '0 12px 0 8px' }}
      >
        {data?.acceptAllCookiesButtonText ?? 'Accept'}
      </Button>
      <UnstyledButton
        onPress={handleClose}
        type="button"
        dataElementId={DataElementId.COOKIE_BANNER_CLOSE_BUTTON}
        additionalStyles={{
          minWidth: '32px',
          minHeight: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        aria-label={
          data?.closeBannerButtonAltText ??
          'Accept only necessary cookies and close'
        }
      >
        <FontAwesomeIcon icon={faXmark} />
      </UnstyledButton>
    </Box>
  );
};

export default CookieBanner;
