import { useGetJurisdictionData } from './use-get-jurisdiction-data';

export const useCurrencyConversion = () => {
  const jurisdictionData = useGetJurisdictionData();
  const conversionRateRaw = Number(jurisdictionData?.exchange_rate ?? 'NaN');
  const conversionRate = Number.isNaN(conversionRateRaw)
    ? 1
    : conversionRateRaw;
  return {
    rate: conversionRate,
    symbol: jurisdictionData?.exchange_rate_symbol ?? 'USD/USD',
  };
};
