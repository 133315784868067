import { DateTime } from 'luxon';
import { useMemo } from 'react';
import type { IPurchasePeriodInfo } from '../../../selectors/interfaces';
import type { StartEndDates } from '../use-get-all-periods';
import { useGetAllPurchasePeriods } from '../use-get-all-periods';

type StartEndDatesWithPurchaseDate = StartEndDates & {
  purchaseDate: DateTime;
  lastDayToMakeChanges: DateTime | null;
  lastDayToWithdraw: DateTime | null;
};

export interface IPurchasePeriodDates {
  currentPurchaseDates: StartEndDatesWithPurchaseDate | null;
  futurePurchaseDates: StartEndDatesWithPurchaseDate;
  closestPurchaseDates: StartEndDatesWithPurchaseDate;
}

const getDates = (
  periodInfo: IPurchasePeriodInfo,
): StartEndDatesWithPurchaseDate => ({
  start: DateTime.fromISO(periodInfo?.purchasePeriodStartDate ?? '', {
    setZone: true,
  }),
  end: DateTime.fromISO(periodInfo?.purchasePeriodEndDate ?? '', {
    setZone: true,
  }),
  purchaseDate: DateTime.fromISO(periodInfo?.purchaseDate ?? '', {
    setZone: true,
  }),
  lastDayToMakeChanges: periodInfo?.lastDayToMakeChanges
    ? DateTime.fromISO(periodInfo.lastDayToMakeChanges, { setZone: true })
    : null,
  lastDayToWithdraw: periodInfo?.lastDayToWithdraw
    ? DateTime.fromISO(periodInfo.lastDayToWithdraw, { setZone: true })
    : null,
});

const getDatesOrNull = (
  periodInfo: IPurchasePeriodInfo | null | undefined,
): StartEndDatesWithPurchaseDate | null =>
  (periodInfo?.purchasePeriodStartDate && periodInfo?.purchasePeriodEndDate
    ? getDates(periodInfo)
    : null);

export const usePurchasePeriodDates = (): IPurchasePeriodDates => {
  const { currentPeriod, futurePeriod, closestPeriod } =
    useGetAllPurchasePeriods();

  return useMemo(
    () => ({
      currentPurchaseDates: getDatesOrNull(currentPeriod),
      futurePurchaseDates: getDates(futurePeriod),
      closestPurchaseDates: getDates(closestPeriod),
    }),
    [currentPeriod, futurePeriod, closestPeriod],
  );
};
