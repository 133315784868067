import {
  convertDotcmsString,
  convertDotcmsResponseToRecord,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';
import { buildGqlFieldsQuery } from './gql-builders';
import type { INonImageFileData } from './dotcms-non-image-file';
import { NonImageFileGqlHelper } from './dotcms-non-image-file';
import type { ILinkData } from './dotcms-link';
import { LinkGqlHelper } from './dotcms-link';

export type ICookieSelectionAndPolicyData = IBaseObjectData & {
  cookieBannerText?: string;
  learnMoreLinkText?: string;
  acceptAllCookiesButtonText?: string;
  closeBannerButtonAltText?: string;
  cookieBannerPdf?: INonImageFileData;
  cookiePolicySectionLink?: ILinkData;
};

export const CookieSelectionAndPolicyGqlHelper: IGqlHelper<ICookieSelectionAndPolicyData> =
  {
    contentTypeName: 'CookieSelectionAndPolicy',
    gqlFieldsQuery: `
    cookieBannerText
    learnMoreLinkText
    acceptAllCookiesButtonText
    closeBannerButtonAltText
    cookieBannerPdf {${buildGqlFieldsQuery(NonImageFileGqlHelper)}}
    cookiePolicySectionLink { ${buildGqlFieldsQuery(LinkGqlHelper)} }
              `,
    processGqlData: (gqlResponse: unknown) => {
      if (!gqlResponse) {
        return undefined;
      }

      const {
        cookieBannerText,
        learnMoreLinkText,
        acceptAllCookiesButtonText,
        closeBannerButtonAltText,
        cookieBannerPdf,
        cookiePolicySectionLink,
      } = convertDotcmsResponseToRecord(gqlResponse);

      return {
        cookieBannerText: convertDotcmsString(cookieBannerText),
        learnMoreLinkText: convertDotcmsString(learnMoreLinkText),
        acceptAllCookiesButtonText: convertDotcmsString(
          acceptAllCookiesButtonText,
        ),
        closeBannerButtonAltText: convertDotcmsString(closeBannerButtonAltText),
        cookieBannerPdf: NonImageFileGqlHelper.processGqlData(cookieBannerPdf),
        cookiePolicySectionLink: LinkGqlHelper.processGqlData(
          cookiePolicySectionLink,
        ),
      };
    },
  };
