import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventLogger } from './use-event-logger';
import { ClientEvent } from './client-events';
import type { DataElementId } from '../constants/data-element-tracking-ids';
import { ELEMENT_ID_DATA_ATTRIBUTE } from '../constants/data-element-tracking-ids';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';
import { authSelector, userSelector } from '../selectors';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';
import { padAmplitudeUserId } from './pad-amplitude-user-id';

/** This only should be called in one place in the whole application.
 * It logs any global event types (for example, route changes)
 */
export const useGlobalEventLoggerSingleton = () => {
  const { logEvent } = useEventLogger();
  const { isAuthenticated } = useSelector(authSelector);
  const {
    id: userId,
    name: { email },
  } = useSelector(userSelector);
  const issuerShortName = useGetIssuerShortname();

  useLayoutEffect(() => {
    if (issuerShortName) {
      const identifyObject = new amplitude.Identify();
      identifyObject.set('employer_shortname', issuerShortName);
      amplitude.identify(identifyObject);
      // This event doesn't really mean anything;
      // we just need to send an event because user
      // properties only get updated on the Amplitude backend
      //  when an event is sent, not immediately after
      // calling .identify:
      amplitude.logEvent('THIS_EVENT_DOESNT_MEAN_ANYTHING');
    }
  }, [issuerShortName]);

  const logElementClicked = useCallback(
    (dataElementId: DataElementId) => {
      logEvent(ClientEvent.CLICKED_BUTTON_OR_ELEMENT, {
        data_element_id: dataElementId,
      });
    },
    [logEvent],
  );

  useLayoutEffect(() => {
    // Register click handler to log clicks on elements with data-element-id attributes:
    const handleMaybeDataElementClicked = (event: MouseEvent) => {
      try {
        if (event && event.target && event.target instanceof HTMLElement) {
          // This is an element that might have a data-element-id
          const targetElement = event.target;
          // If we have something like <a data-element-id="foo"><div>text</div></a>,
          // a click on the link will have a target of the div, not the link.
          // We need to check whether the click target's parents have a data-element-id
          // instead.
          const maybeParentElementWithDataElementId = targetElement.closest(
            `[${ELEMENT_ID_DATA_ATTRIBUTE}]`,
          );
          if (maybeParentElementWithDataElementId instanceof HTMLElement) {
            const dataElementId =
              maybeParentElementWithDataElementId.dataset?.elementId ??
              'error: data-element-id not found';
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            logElementClicked(dataElementId as DataElementId);
          }
        }
      } catch (error) {
        captureErrorInSentryWithCustomMessage(
          error,
          'Error when trying to log element click event',
        );
      }
    };
    // useCapture must be true because some of our buttons have preventDefault
    // and so won't trigger the listener if this is false:
    window.addEventListener('click', handleMaybeDataElementClicked, true);
    // Cleanup function to remove old handlers:
    return () =>
      window.removeEventListener('click', handleMaybeDataElementClicked, true);
  }, [logElementClicked]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (typeof userId === 'number' || typeof userId === 'string')
    ) {
      // Amplitude has a minimum string length requirement for user IDs,
      // so we must pad short ones:
      amplitude.setUserId(padAmplitudeUserId(String(userId)));
      const identifyObject = new amplitude.Identify();
      amplitude.identify(identifyObject);
      // This event doesn't really mean anything;
      // we just need to send an event because user
      // properties only get updated on the Amplitude backend
      // when an event is sent, not immediately after
      // calling .identify:
      amplitude.logEvent('THIS_EVENT_DOESNT_MEAN_ANYTHING');
    } else {
      amplitude.setUserId(undefined);
      amplitude.logEvent('THIS_EVENT_DOESNT_MEAN_ANYTHING');
    }
  }, [email, isAuthenticated, userId]);
};
