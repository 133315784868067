import React from 'react';
import { Helmet } from 'react-helmet';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';
import { useFavicon } from '../hooks/useFavicon';
import { ASSETS_CDN_URL } from '../utils/constants';

const SITE_NAME = 'ESPP.com';

const useOgMetaInfo = () => {
  const issuerShortName = useGetIssuerShortname();
  const { favicon } = useFavicon();

  return {
    title: document.title ?? SITE_NAME,
    type: 'website',
    siteName: SITE_NAME,
    url: window.location.href,
    image: issuerShortName
      ? `${ASSETS_CDN_URL}/${issuerShortName ?? ''}/site-logo.svg`
      : favicon.href,
    imageType: issuerShortName ? 'image/svg+xml' : favicon.type,
  };
};

// See https://ogp.me/
export const OgMetaDataProvider: React.FC = () => {
  const {
    title, type, siteName, url, image, imageType,
  } = useOgMetaInfo();

  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content={imageType} />
    </Helmet>
  );
};
