import { useCallback, useMemo } from 'react';
import { logClientEvent_internalDontUse } from './log-event';
import useGetIssuerShortname from '../hooks/use-get-issuer-shortname/use-get-issuer-shortname';
import { urlSearchParamsToObject } from '../utils/url-search-params/url-search-params-to-object';
import { useCookie } from '../hooks/useCookie';
import { useGetUserLocation } from '../hooks/use-get-user-location/use-get-user-location';
import { useLocale } from '../locale/use-locale';
import type { BaseEvent, ClientEvent, ClientEventTypes } from './client-events';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';
import { useEnrollmentPeriodOpenInfo } from '../hooks/use-get-periods-info/use-enrollment-period/use-enrollment-period-open-info';

export const useEventLogger = () => {
  const issuerShortname = useGetIssuerShortname();
  const [isMagicLinkRaw] = useCookie('magic_link', false);
  const isMagicLink = !!isMagicLinkRaw;
  const { countryCode, stateCode } = useGetUserLocation();
  const { locale } = useLocale();
  const { isEnrollmentOpen } = useEnrollmentPeriodOpenInfo();

  // Omit URL related params here, because if we memoize the URL, there may be a race condition
  // causing us to log a stale URL if we try to log an event immediately during a route
  // change. Not 100% clear that can happen, but better safe than sorry:
  const baseEventData: Omit<BaseEvent, 'url_search_params'> = useMemo(
    () => ({
      ...(issuerShortname ? { employer_shortname: issuerShortname } : {}),
      is_magic_link: isMagicLink,
      country_code: countryCode,
      state_code: stateCode,
      language_code: locale,
      enrollment_open: isEnrollmentOpen,
      event_source: 'CASHLESS-CASHFRONT',
    }),
    [
      countryCode,
      isEnrollmentOpen,
      isMagicLink,
      issuerShortname,
      locale,
      stateCode,
    ],
  );

  const logEvent = useCallback(
    <ClientEventKey extends ClientEvent>(
      eventType: ClientEventKey,
      eventData: ClientEventTypes[ClientEventKey],
    ) => {
      try {
        const mergedEventData = {
          ...eventData,
          ...baseEventData,
          event_name: eventType,
          url_search_params: urlSearchParamsToObject(
            new URLSearchParams(window.location.search),
          ),
        };
        logClientEvent_internalDontUse(eventType, mergedEventData);
      } catch (error) {
        captureErrorInSentryWithCustomMessage(error, 'Failed to log event');
      }
    },
    [baseEventData],
  );

  return { logEvent };
};
