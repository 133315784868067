import { match } from 'ts-pattern';
import type { IPlanDetails } from '../../selectors/interfaces';
import type { ICategoryData } from './dotcms-category';

/** THE STRING VALUES IN HERE MUST MATCH WHAT IS IN DOTCMS!!!
 *
 * These values are used in dropdowns in certain content types
 * in dotcms to only show certain content to users belonging
 * to certain plan types.
 */
export enum DotcmsPlanType {
  ANY_CASHLESS = 'allCashlessPlans',
  CASHLESS_ONLY = 'cashlessOnly',
  DSP_BASED = 'dspBased',
  ESPP_ONLY = 'esppOnly',
  FEE_BASED = 'feeBased',
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const DOTCMS_PLAN_TYPE_VALUES = Object.values(DotcmsPlanType) as string[];

const isDotcmsPlanType = (input: string): input is DotcmsPlanType =>
  DOTCMS_PLAN_TYPE_VALUES.includes(input);

export const getActiveDotcmsPlanType = (
  planTypeCmsResponse:
  | ReadonlyArray<Omit<ICategoryData, 'identifier' | 'contentType'>>
  | null
  | undefined
  | '',
): DotcmsPlanType | null => {
  if (!planTypeCmsResponse) {
    return null;
  }
  const activeCategoriesList = planTypeCmsResponse.filter(
    ({ active }) => active,
  );
  if (activeCategoriesList.length > 1) {
    const categoriesToLog = activeCategoriesList
      .map(({ key }) => ` ${key}`)
      .join(', ');
    console.error(
      'A dotcms contentlet had a restrictToPlanType with multiple active categories. This should never happen. The active categories were: ',
      categoriesToLog,
    );
  }
  const activeCategoryName: string | undefined = activeCategoriesList[0]?.key;
  const maybeMatchingEnumKey: DotcmsPlanType | undefined = Object.entries(
    DotcmsPlanType,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  ).find(([, value]) => (value as string) === activeCategoryName)?.[1];

  return maybeMatchingEnumKey ?? null;
};

export type PlanDetailsForFilteringContent = Pick<
IPlanDetails,
'cashlessMethodology' | 'cashless_revenue_model'
>;

export const isContentAllowedByPlan = (
  contentPlanType: DotcmsPlanType | string | null,
  planDetails: PlanDetailsForFilteringContent | null,
  isCashlessOnlyIssuer: boolean,
) => {
  if (contentPlanType === null) {
    // Null value for content plan type restriction means
    // there is no restriction.
    return true;
  }

  if (!planDetails) {
    // If we don't have the plan details yet, assume the content
    // is not allowed by the plan
    return false;
  }

  if (isDotcmsPlanType(contentPlanType)) {
    // These fields are only defined for plans with cashless:
    const planIncludesCashless =
      !!planDetails.cashlessMethodology || !!planDetails.cashless_revenue_model;

    return match({ planType: contentPlanType })
      .with(
        { planType: DotcmsPlanType.ANY_CASHLESS },
        () => planIncludesCashless,
      )
      .with(
        { planType: DotcmsPlanType.CASHLESS_ONLY },
        () => isCashlessOnlyIssuer,
      )
      .with(
        { planType: DotcmsPlanType.DSP_BASED },
        () => planDetails.cashless_revenue_model?.toUpperCase() === 'DSP',
      )
      .with({ planType: DotcmsPlanType.ESPP_ONLY }, () => !planIncludesCashless)
      .with(
        { planType: DotcmsPlanType.FEE_BASED },
        () => planDetails.cashless_revenue_model?.toUpperCase() === 'FEE',
      )
      .exhaustive();
  }

  // Otherwise, we don't recognize this plan type. So we should default to hiding this
  // content and log an error:
  console.error(
    'The input to isContentAllowedByPlan was not a valid DotcmsPlanType value. The input was: ',
    contentPlanType,
  );
  return false;
};
