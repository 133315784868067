import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { Issuer } from '../../constants/issuers';
import { useGetIssuerShortname } from '../../hooks/use-get-issuer-shortname';
import { captureErrorInSentryWithCustomMessage } from '../../utils/capture-error-in-sentry-with-custom-message';

// Normally we would use breakpoints from the MUI
// theme for this, but since this is an error page,
// we need to write really simple code, since the error
// may have occurred in MUI itself:
const MOBILE_MEDIA_QUERY = '@media (max-width: 900px)';

const Header = styled.p({
  margin: '40px 0',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '900',
  fontSize: '56px',
  lineHeight: '120%',
  textAlign: 'center',
  letterSpacing: '1.34px',
  [MOBILE_MEDIA_QUERY]: {
    margin: '32px 0',
    fontSize: '32px',
  },
});
const SubHeader = styled.p({
  margin: '0',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '160%',
  textAlign: 'center',
  letterSpacing: '0.5px',
  maxWidth: '900px',
  [MOBILE_MEDIA_QUERY]: {
    fontSize: '16px',
  },
});
const SpinningGear = styled(FontAwesomeIcon)({
  height: '48px',
  width: '48px',
  [MOBILE_MEDIA_QUERY]: {
    height: '35px',
    width: '35px',
  },
});

const InternalErrorOccuredComponentWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Box
    sx={{
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '32px 16px',
    }}
  >
    <SpinningGear icon={faExclamationTriangle} color="#979797" />
    {/* This text intentionally is not sourced from the CMS, because the
        error might be caused by e.g. the CMS being down, and this still
        needs to be able to display in that case */}
    <Header>An error occurred</Header>
    <SubHeader>
      Our site encountered an error. We apologize for the inconvenience. Our
      systems have automatically reported this error to our staff, and we will
      work on a fix.
      <br />
      <br />
      {children}
    </SubHeader>
  </Box>
);

// This component should never fail
const InternalErrorOccurredComponentWithoutHooks: React.FC = () => (
  <InternalErrorOccuredComponentWrapper>
    In the meantime, try refreshing the page.
    <br />
    <br />
    If you still have problems, please contact us at
    {' '}
    <a href="mailto:support@espp.com"> support@espp.com </a>
  </InternalErrorOccuredComponentWrapper>
);

const InternalErrorOccurredComponent: React.FC = () => {
  const issuerShortname = useGetIssuerShortname();
  const shortnameIsRecognized =
    issuerShortname !== undefined &&
    Object.values(Issuer).some(
      (knownIssuer) =>
        knownIssuer.toLocaleLowerCase() === issuerShortname.toLocaleLowerCase(),
    );
  // Fall back to "support@espp.com" if the issuer shortname is not recognized
  const shouldFallBackToGenericEmail =
    !issuerShortname || !shortnameIsRecognized;
  const supportEmailPrefix = shouldFallBackToGenericEmail
    ? 'support'
    : issuerShortname.toLocaleLowerCase();

  return (
    <InternalErrorOccuredComponentWrapper>
      In the meantime, try refreshing the page or
      {' '}
      <a href={`${window.location.origin}/${issuerShortname ?? ''}`}>
        go back to the homepage
      </a>
      .
      <br />
      <br />
      If you still have problems, please contact us at
      {' '}
      <a href={`mailto:${supportEmailPrefix}@espp.com`}>
        {supportEmailPrefix}
        @espp.com
      </a>
    </InternalErrorOccuredComponentWrapper>
  );
};

/** For using within a smaller section of a page */
export const ErrorOccurredComponent: React.FC = () => (
  // ErrorBoundary for cases where an error is thrown even inside ErrorOccurredComponent
  <ErrorBoundary
    fallbackRender={InternalErrorOccurredComponentWithoutHooks}
    onError={(e) =>
      captureErrorInSentryWithCustomMessage(
        e,
        'ErrorOccurredComponent throws an error, the error handler component is not shown!',
      )}
  >
    <InternalErrorOccurredComponent />
  </ErrorBoundary>
);

/** For full-screen errors or filling up an entire div on its own */
export const ErrorOccurredScreen: React.FC = () => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ErrorOccurredComponent />
  </Box>
);
