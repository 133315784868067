import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../selectors';
import { useGetPeriodsOpenInfo } from '../use-get-periods-info';

// if withdrawn === true, then enrolledEspp === false
// if enrolledEspp === true, then withdrawn === false
export interface IUserStateInPeriod {
  enrolledEspp: boolean;
  contributionPercentage: number | undefined;
  enrolledCashless: boolean;
  externalEnrollmentPending: boolean;
  withdrawn: boolean;
  isScheduled: boolean;
}

const getPercentage = (
  percent: number | null | undefined,
): number | undefined => percent ?? undefined;

const getEnrolled = (enrolled: boolean | null | undefined): boolean =>
  !!enrolled;

/** This hook returns the user's main choices made for different enrollment periods.
 * These states are calculated based on values from BE,
 * which usually do not have a clear and consistent logic in different periods
 * as to why certain values are set that way.
 * This hook provides a kind of layer between the values returned by the BE
 * and the values used in other FE code to avoid confusion. */
const useGetUserStatesInPeriods = () => {
  const {
    pastContributionPercentage,
    contributionPercentageCurrent,
    contributionPercentageFuture,
    pastEnrollCashlessParticipation,
    enrollCashlessParticipationFromDB,
    enrollCashlessParticipationFuture,
    externalEnrollmentPending,
    pastEnrolled,
    enrolled,
    enrolledInFuture,
    withdrawn,
    withdrawnFromFuture,
    futureOpScheduledEnrollment,
  } = useSelector(userSelector);
  const { isOfferOpen, isEnrollmentOpenDuringOfferingPeriod } =
    useGetPeriodsOpenInfo();

  const shouldUsePreviousPeriod = useMemo(
    () => isEnrollmentOpenDuringOfferingPeriod && pastEnrolled,
    [isEnrollmentOpenDuringOfferingPeriod, pastEnrolled],
  );

  const shouldUsePreviousWithdrawValue = useMemo(
    () => !isOfferOpen,
    [isOfferOpen],
  );

  const userStateInPastPeriodOpOrPp: IUserStateInPeriod = useMemo(
    () => ({
      enrolledEspp: getEnrolled(pastEnrolled),
      contributionPercentage: getPercentage(pastContributionPercentage),
      enrolledCashless: getEnrolled(pastEnrollCashlessParticipation),
      externalEnrollmentPending: false,
      withdrawn: false,
      isScheduled: false,
    }),
    [pastEnrolled, pastContributionPercentage, pastEnrollCashlessParticipation],
  );

  const userStateInCurrentPeriodOpOrPp: IUserStateInPeriod = useMemo(
    () =>
      (shouldUsePreviousPeriod
        ? userStateInPastPeriodOpOrPp
        : {
          enrolledEspp: getEnrolled(enrolled),
          contributionPercentage: getPercentage(
            isOfferOpen
              ? contributionPercentageCurrent ?? pastContributionPercentage
              : pastContributionPercentage,
          ),
          enrolledCashless: getEnrolled(
            isOfferOpen
              ? enrollCashlessParticipationFromDB ??
                    pastEnrollCashlessParticipation
              : pastEnrollCashlessParticipation,
          ),
          externalEnrollmentPending: false,
          withdrawn:
              !enrolled && (shouldUsePreviousWithdrawValue ? false : withdrawn),
          isScheduled: false,
        }),
    [
      enrolled,
      contributionPercentageCurrent,
      pastContributionPercentage,
      enrollCashlessParticipationFromDB,
      pastEnrollCashlessParticipation,
      withdrawn,
      isOfferOpen,
      shouldUsePreviousPeriod,
      userStateInPastPeriodOpOrPp,
      shouldUsePreviousWithdrawValue,
    ],
  );

  const userStateInScheduledOp: IUserStateInPeriod | null = useMemo(
    () =>
      (futureOpScheduledEnrollment
        ? {
          enrolledEspp: true,
          contributionPercentage: getPercentage(
            futureOpScheduledEnrollment.contribution_percentage,
          ),
          enrolledCashless: getEnrolled(
            futureOpScheduledEnrollment.accepted_cashless,
          ),
          externalEnrollmentPending: false,
          withdrawn: false,
          isScheduled: true,
        }
        : null),
    [futureOpScheduledEnrollment],
  );

  const enrolledEsppInFuture = useMemo(
    () =>
      (!isOfferOpen || !withdrawnFromFuture) && getEnrolled(enrolledInFuture),
    [enrolledInFuture, isOfferOpen, withdrawnFromFuture],
  );
  const userStateInFuturePeriodOpOrPp: IUserStateInPeriod = useMemo(
    () =>
      userStateInScheduledOp ?? {
        enrolledEspp: enrolledEsppInFuture,
        contributionPercentage: getPercentage(
          isOfferOpen
            ? contributionPercentageFuture ?? contributionPercentageCurrent
            : contributionPercentageCurrent,
        ),
        enrolledCashless: getEnrolled(
          isOfferOpen
            ? enrollCashlessParticipationFuture ??
                enrollCashlessParticipationFromDB
            : enrollCashlessParticipationFromDB,
        ),
        externalEnrollmentPending,
        withdrawn:
          !enrolledEsppInFuture &&
          (shouldUsePreviousWithdrawValue ? withdrawn : withdrawnFromFuture),
        isScheduled: false,
      },
    [
      userStateInScheduledOp,
      enrolledEsppInFuture,
      contributionPercentageFuture,
      contributionPercentageCurrent,
      enrollCashlessParticipationFuture,
      enrollCashlessParticipationFromDB,
      externalEnrollmentPending,
      withdrawnFromFuture,
      withdrawn,
      isOfferOpen,
      shouldUsePreviousWithdrawValue,
    ],
  );

  return useMemo(
    () => ({
      userStateInPastPeriodOpOrPp,
      userStateInCurrentPeriodOpOrPp,
      userStateInFuturePeriodOpOrPp,

      userStateInClosestPeriodOpOrPp: isOfferOpen
        ? userStateInCurrentPeriodOpOrPp
        : userStateInFuturePeriodOpOrPp,
    }),
    [
      isOfferOpen,
      userStateInPastPeriodOpOrPp,
      userStateInCurrentPeriodOpOrPp,
      userStateInFuturePeriodOpOrPp,
    ],
  );
};

export default useGetUserStatesInPeriods;
