import { useLocation, useHistory } from 'react-router';
import { useCallback } from 'react';
import type { UrlSearchParamsTypes } from './url-search-params';
import type UrlSearchParamKey from './url-search-params';
import { setSearchParamInnerImpl } from './set-search-param-inner-impl';
import type { StringSearchParamKeys } from './use-query-url-search-params';

/** Returns [setValue, deleteValue] */
export function useSetUrlSearchParam<SearchKey extends UrlSearchParamKey>(
  key: SearchKey,
): [(value: UrlSearchParamsTypes[SearchKey]) => void, () => void] {
  const location = useLocation();
  const history = useHistory();

  const setValue = useCallback(
    (value: UrlSearchParamsTypes[SearchKey]) => {
      const urlSearchParams = new URLSearchParams(location.search);

      setSearchParamInnerImpl(urlSearchParams, key, value);

      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [key, location.search, location.pathname, history],
  );

  const deleteValue = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete(key);

    history.replace({
      pathname: location.pathname,
      search: urlSearchParams.toString(),
    });
  }, [history, key, location.pathname, location.search]);

  return [setValue, deleteValue];
}

export const useSetMultipleUrlSearchParams = <
  SearchParamKey extends keyof StringSearchParamKeys,
>() => {
  const location = useLocation();
  const history = useHistory();

  const getUrlSearchParamsAsStr = useCallback(
    (params: Partial<Record<SearchParamKey, string>>) => {
      // Must pull search params directly from window.location.search,
      // instead of from useLocation. Otherwise there's a race condition where
      // if two components both try to set search params, this function will
      // return a stale value and cause only the second change to take effect:
      const searchParams = new URLSearchParams(window.location.search);
      Object.keys(params).forEach((key) => {
        if (key in params) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const value = params[key as SearchParamKey];
          if (value !== undefined && value.length > 0) {
            searchParams.set(key, value);
          } else {
            searchParams.delete(key);
          }
        }
      });
      return searchParams.toString();
    },
    [],
  );

  const setUrlSearchParams = useCallback(
    (params: Partial<Record<SearchParamKey, string>>) => {
      const search = getUrlSearchParamsAsStr(params);
      history.replace({
        pathname: location.pathname,
        search,
      });
    },
    [history, location.pathname, getUrlSearchParamsAsStr],
  );

  const setUrlSearchParamsWithReload = useCallback(
    (params: Partial<Record<SearchParamKey, string>>) => {
      const search = getUrlSearchParamsAsStr(params);
      history.push({
        pathname: location.pathname,
        search,
      });
    },
    [history, location.pathname, getUrlSearchParamsAsStr],
  );

  return { setUrlSearchParams, setUrlSearchParamsWithReload };
};
