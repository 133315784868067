import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { match } from 'ts-pattern';
import { authSelector } from '../../selectors';

export enum UserAuthenticationState {
  UserNotAuthenticated = 'NOT_AUTHENTICATED',
  UserNotVerified = 'NOT_VERIFIED',
  UserLocked = 'LOCKED',
  UserAuthenticated = 'AUTHENTICATED',
}

export const useGetUserAuthState = (): UserAuthenticationState => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isAuthenticated, error, isLocked } = useSelector(authSelector);
  return useMemo(
    () =>
      match({
        isAuthenticated,
        error,
        isLocked,
      })
        .with(
          { isAuthenticated: true },
          () => UserAuthenticationState.UserAuthenticated,
        )
        .with(
          { error: 'verifyUnable' },
          () => UserAuthenticationState.UserNotVerified,
        )
        .with({ isLocked: true }, () => UserAuthenticationState.UserLocked)
        .otherwise(() => UserAuthenticationState.UserNotAuthenticated),
    [isAuthenticated, error, isLocked],
  );
};
