import type { PropsWithChildren } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface IHeaderNavigationContext {
  navbarHeaderHeightPx: number;
  setNavbarHeaderHeightPx: React.Dispatch<React.SetStateAction<number>>;
  stickyNavbarHeaderHeightPx: number;
  setStickyNavbarHeaderHeightPx: React.Dispatch<React.SetStateAction<number>>;
}

const HEADER_NAVIGATION_CONTEXT_DATA: IHeaderNavigationContext = {
  navbarHeaderHeightPx: 0,
  setNavbarHeaderHeightPx: () => {},
  stickyNavbarHeaderHeightPx: 0,
  setStickyNavbarHeaderHeightPx: () => {},
};

export const HeaderNavigationContext = createContext<IHeaderNavigationContext>(
  HEADER_NAVIGATION_CONTEXT_DATA,
);

export const HeaderNavigationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [navbarHeaderHeightPx, setNavbarHeaderHeightPx] = useState(0);
  const [stickyNavbarHeaderHeightPx, setStickyNavbarHeaderHeightPx] =
    useState(0);

  const contextData = useMemo(
    () => ({
      navbarHeaderHeightPx,
      setNavbarHeaderHeightPx,
      stickyNavbarHeaderHeightPx,
      setStickyNavbarHeaderHeightPx,
    }),
    [navbarHeaderHeightPx, stickyNavbarHeaderHeightPx],
  );

  return (
    <HeaderNavigationContext.Provider value={contextData}>
      {children}
    </HeaderNavigationContext.Provider>
  );
};

export const useHeaderNavigationHeights = () => {
  const { setNavbarHeaderHeightPx, setStickyNavbarHeaderHeightPx } = useContext(
    HeaderNavigationContext,
  );

  const [headerRef, setHeaderRef] = useState<HTMLDivElement | undefined>(
    undefined,
  );
  const [stickyHeaderRef, setStickyHeaderRef] = useState<
  HTMLDivElement | undefined
  >(undefined);

  const setNavbarHeights = useCallback(() => {
    // If the sticky header is not set, then we assume there is no sticky header
    const stickyHeaderHeight =
      stickyHeaderRef?.getBoundingClientRect()?.height ?? 0;
    // If the general header is not set,
    // then we assume the general header is the same as the sticky header
    const generalHeaderHeight =
      headerRef?.getBoundingClientRect()?.height ?? stickyHeaderHeight;
    setNavbarHeaderHeightPx(generalHeaderHeight);
    setStickyNavbarHeaderHeightPx(stickyHeaderHeight);
  }, [
    headerRef,
    stickyHeaderRef,
    setNavbarHeaderHeightPx,
    setStickyNavbarHeaderHeightPx,
  ]);

  useEffect(() => {
    const refToObserve = headerRef ?? stickyHeaderRef;
    if (refToObserve) {
      const resizeObserver = new ResizeObserver(setNavbarHeights);
      resizeObserver.observe(refToObserve);

      return () => {
        resizeObserver.unobserve(refToObserve);
        resizeObserver.disconnect();
      };
    }

    // Since we return a cleanup function in one branch, the type system demands
    // this no-op function here too:
    return () => {};
  }, [headerRef, stickyHeaderRef, setNavbarHeights]);

  return useMemo(
    () => ({ setHeaderRef, setStickyHeaderRef }),
    [setHeaderRef, setStickyHeaderRef],
  );
};
