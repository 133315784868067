import { useSelector } from 'react-redux';
import type { JurisdictionData } from '../selectors/interfaces';
import { useGetJurisdictionData } from './use-get-jurisdiction-data';
import { uiSelector } from '../selectors';

interface Currency {
  currencyCode: string;
  currencyDisplaySymbolLeft: string;
  currencyDisplaySymbolSpaceLeft: boolean;
  currencyDisplaySymbolRight: string;
  currencyDisplaySymbolSpaceRight: boolean;
  currencyDisplayIncludeDecimal: boolean;
}

const getCurrencyFromJurisdictionData = (
  jurisdictionData: JurisdictionData | undefined,
  currencyCode?: string,
) => {
  const currencyCodeFromJurisdictionData =
    jurisdictionData?.currency_code ?? currencyCode;

  const currencyDisplaySymbolLeftFromJurisdictionData =
    jurisdictionData?.currency_display_symbol_left;

  const currencyDisplaySymbolSpaceLeftFromJurisdictionData =
    jurisdictionData?.currency_display_symbol_space_left;

  const currencyDisplaySymbolRightFromJurisdictionData =
    jurisdictionData?.currency_display_symbol_right;

  const currencyDisplaySymbolSpaceRightFromJurisdictionData =
    jurisdictionData?.currency_display_symbol_space_right;

  const currencyDisplayIncludeDecimalFromJurisdictionData =
    jurisdictionData?.currency_display_include_decimal;

  return {
    currencyCode: currencyCodeFromJurisdictionData ?? '',
    currencyDisplaySymbolLeft:
      currencyDisplaySymbolLeftFromJurisdictionData ?? '',
    currencyDisplaySymbolSpaceLeft:
      currencyDisplaySymbolSpaceLeftFromJurisdictionData ?? false,
    currencyDisplaySymbolRight:
      currencyDisplaySymbolRightFromJurisdictionData ?? '',
    currencyDisplaySymbolSpaceRight:
      currencyDisplaySymbolSpaceRightFromJurisdictionData ?? false,
    currencyDisplayIncludeDecimal:
      currencyDisplayIncludeDecimalFromJurisdictionData ?? false,
  };
};

export const useCurrencyByCode = (currencyCode: string | undefined) => {
  const { jurisdictionByCountryCode } = useSelector(uiSelector);
  const jurisdictionData = useGetJurisdictionData();
  if (!currencyCode || jurisdictionData?.currency_code === currencyCode) {
    return getCurrencyFromJurisdictionData(jurisdictionData);
  }

  const maybeJurisdictionData = Object.values(jurisdictionByCountryCode).find(
    (x) => x.currency_code === currencyCode,
  );
  // If the length of the values is 0, get_assets just isn't done loading yet.
  if (
    !maybeJurisdictionData &&
    Object.values(jurisdictionByCountryCode).length > 0
  ) {
    console.error(
      `There is no jurisdiction in which the requested currency is used: ${currencyCode}`,
    );
  }

  return getCurrencyFromJurisdictionData(maybeJurisdictionData, currencyCode);
};

// TODO(CP-3871): Get this from the backend
export const useIssuerCurrency = (): Currency => useCurrencyByCode(undefined);
