import { convertDotcmsResponseToRecord } from '../converters-from-dotcms';
import type { IBaseObjectData } from '../dotcms-base-object';
import { buildGqlFieldsQuery } from '../gql-builders';
import type { IGqlHelper } from '../gql-helper';
import type { IDialogData } from '../dotcms-dialog';
import { DialogGqlHelper } from '../dotcms-dialog';

type IInactivityPopups = IBaseObjectData & {
  automaticSignoutPopup?: IDialogData;
};

export const InactivityPopupsGqlHelper: IGqlHelper<IInactivityPopups> = {
  contentTypeName: 'InactivityPopups',
  gqlFieldsQuery: `
    automaticSignoutPopup {${buildGqlFieldsQuery(DialogGqlHelper)}}
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { automaticSignoutPopup } =
      convertDotcmsResponseToRecord(gqlResponse);

    return {
      automaticSignoutPopup: DialogGqlHelper.processGqlData(
        automaticSignoutPopup,
      ),
    };
  },
};
