import { useSelector } from 'react-redux';
import { match, P } from 'ts-pattern';
import { useMemo } from 'react';
import { userSelector } from '../../selectors';
import { UserAuthenticationState } from './use-get-user-auth-state';
import { usePlanDetails } from '../usePlanDetails';
import { UserEnrolledState } from './use-get-user-enrolled-state';
import type { IUserStateInPeriod } from './use-get-user-states-in-periods';
import type { IPlanDetails } from '../../selectors/interfaces';

/** This enum is used to determine the state of the user in the current enrollment
 *  if we are waiting for the next step the same as in the Traditional plan
 *  (full: ESPP -> Cashless).
 *
 * USER_ALREADY_ENROLLED_IN_CASHLESS
 * <-
 * USER_INELIGIBLE_TO_ADD_CASHLESS_MAX_PERCENT /
 * USER_INELIGIBLE_TO_ADD_CASHLESS /
 * USER_ALREADY_ENROLLED_IN_ESPP
 * <-
 * USER_INELIGIBLE_FOR_ESPP /
 * USER_NOT_ENROLLED
 */
export enum UserEnrollmentState {
  UserNotEnrolled = 'USER_NOT_ENROLLED',
  UserIneligibleForEspp = 'USER_INELIGIBLE_FOR_ESPP',
  UserAlreadyEnrolledInESPP = 'USER_ALREADY_ENROLLED_IN_ESPP',
  UserIneligibleToAddCashlessParticipation = 'USER_INELIGIBLE_TO_ADD_CASHLESS',
  UserIneligibleToAddCashlessParticipationDueToMaxPercent = 'USER_INELIGIBLE_TO_ADD_CASHLESS_MAX_PERCENT',
  UserAlreadyEnrolledInCashlessParticipation = 'USER_ALREADY_ENROLLED_IN_CASHLESS',
}

export const isSelectedPercentAllowsCashless = (
  planDetails: Partial<IPlanDetails> | null,
  selectedContributionPercent: number | undefined,
) =>
  !!planDetails?.cashless_allowed_at_cashless_cap ||
  !planDetails?.cashlessCapPercent ||
  (selectedContributionPercent ?? 0) <= planDetails.cashlessCapPercent;

export const useGetUserEnrollmentState = (
  userAuthState: UserAuthenticationState,
  userEnrolledState: UserEnrolledState | undefined,
  userStateInPeriod: IUserStateInPeriod,
): UserEnrollmentState | undefined => {
  const { accessCashlessParticipation, accessESPP } = useSelector(userSelector);
  const planDetails = usePlanDetails();

  return useMemo(
    () =>
      match({
        userAuthState,
        accessESPP,
        accessCashlessParticipation,
        userEnrolledState,
        enrolledCashless: userStateInPeriod.enrolledCashless,
        isContributionPercentAllowsCashless: isSelectedPercentAllowsCashless(
          planDetails,
          userStateInPeriod.contributionPercentage,
        ),
      })
        .with(
          {
            userAuthState: P.not(UserAuthenticationState.UserAuthenticated),
          },
          () => undefined,
        )
        .with(
          {
            userEnrolledState: P.union(
              UserEnrolledState.UserEnrolled,
              UserEnrolledState.ScheduledEnrollment,
            ),
            accessCashlessParticipation: true,
            enrolledCashless: true,
          },
          () => UserEnrollmentState.UserAlreadyEnrolledInCashlessParticipation,
        )
        .with(
          {
            userEnrolledState: P.union(
              UserEnrolledState.UserEnrolled,
              UserEnrolledState.ScheduledEnrollment,
            ),
            accessCashlessParticipation: true,
            isContributionPercentAllowsCashless: false,
          },
          () =>
            UserEnrollmentState.UserIneligibleToAddCashlessParticipationDueToMaxPercent,
        )
        .with(
          {
            userEnrolledState: P.union(
              UserEnrolledState.UserEnrolled,
              UserEnrolledState.ScheduledEnrollment,
            ),
            accessCashlessParticipation: false,
            accessESPP: true,
          },
          () => UserEnrollmentState.UserIneligibleToAddCashlessParticipation,
        )
        .with(
          {
            userEnrolledState: P.union(
              UserEnrolledState.UserEnrolled,
              UserEnrolledState.ScheduledEnrollment,
            ),
            accessESPP: true,
          },
          () => UserEnrollmentState.UserAlreadyEnrolledInESPP,
        )
        .with(
          {
            accessESPP: false,
          },
          () => UserEnrollmentState.UserIneligibleForEspp,
        )
        .otherwise(() => UserEnrollmentState.UserNotEnrolled),
    [
      userAuthState,
      accessESPP,
      accessCashlessParticipation,
      planDetails,
      userEnrolledState,
      userStateInPeriod.enrolledCashless,
      userStateInPeriod.contributionPercentage,
    ],
  );
};
