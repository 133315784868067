import { useCallback, useMemo } from 'react';
import compress from 'graphql-query-compress';
import { GraphQLClient } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { useGetDotcmsLucerneQuery } from './use-get-issuer-specific-dotcms-query';
import { removeEmptyKeysForDotcmsFallback } from './remove-empty-keys';
import type { JSONSerializableObject } from '../utils/json-types';
import type { GlobalLoadingStateOperation } from '../components/abc/global-loading-context/global-loading-context';
import { mergeObjectsForDotcmsFallback } from './recursively-merge-objects';
import { recursivelyFilterIneligibleItems } from './recursively-filter-ineligible-items';
import { usePlanDetails } from '../hooks/usePlanDetails';
import type { RecursiveObject } from './recursive-types';
import { useGetUserLocation } from '../hooks/use-get-user-location/use-get-user-location';
import { useGetEnrollmentPlan } from '../hooks/use-get-enrollment-plan';
import { EnrollmentPlanEspp } from '../hooks/use-get-enrollment-plan/use-get-enrollment-plan';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';
import { useParseDynamicContent } from './parse-dynamic-content';
import { DOTCMS_CLOUD_URL } from '../utils/constants';

const client = new GraphQLClient(`${DOTCMS_CLOUD_URL}/api/v1/graphql`, {
  // Confusingly, 'none' for errorPolicy means 'expect no errors and throw if there is one'.
  // ('all' would mean 'return all errors without throwing'):
  errorPolicy: 'none',
});

interface FetchFromDotcmsResult<TExpectedResponseType> {
  data: TExpectedResponseType | undefined;
  loading: boolean;
  error: unknown;
}

const DEFAULT_HOST_QUERY_NAME = 'defaultHost';
const ISSUER_HOST_QUERY_NAME = 'issuerHost';

/**
 * Complicated function. Explanation follows:
 *
 * For most issuers, most of their content on our site will be the same.
 * So we have a "default host" in dotcms which hosts a sort of "default site"
 * that can be used whenever an issuer does not need customization in a particular
 * area.
 *
 * For a given issuer, we know we should fall back on the default if a given
 * field is unset for that issuer in the cms.
 *
 * So, for example, if an issuer's FooPage looks like this:
 * { fooHeader: "Welcome to your new ESPP, valued employee!", fooDetails: "" }
 * , and the default site's FooPage looks like this:
 * { fooHeader: "Welcome to the ESPP!", fooDetails: "description" }
 * , we should render the page as though the response was:
 * { fooHeader: "Welcome to your new ESPP, valued employee!", fooDetails: "description" }
 *
 * To do this, we essentially need to make the same gql query to dotcms twice: once
 * to get the issuer's specific cms content, and once to get the default content.
 *
 * A query to do that looks something like:
 * ```
    query TestQuery {
        defaultHost:CashlessParticipationPageCollection(query: "+conhost:ABC", limit: 1) {
            identifier
            someFieldWeWantToFetch
        }
        issuerHost:CashlessParticipationPageCollection(query: "+conhost:XYZ", limit: 1) {
            identifier
            someFieldWeWantToFetch
        }
    }
    ```
 * The only difference between these being the "conhost" (dotcms 'site') they're querying from.
 *
 * This function constructs a double-query like the above, makes the request, and merges the
 * issuer-specific response with the fallback default response before returning the result.
 *
 * @param buildGqlSubQuery
 * This should take a Lucerne query as an argument, and return a GQL query in the form of:
 * `GqlYouWantToUse(query: "${theLucerneQueryArgument}", limit: 1) { theFieldsYouWantToRequest }`
 *
 * @param delayedCustomDynamicVariables
 * This allows errors to be ignored when custom dynamic variables are not set,
 * as they are expected to be set later.
 */
export const useFetchIssuerDotcmsContentWithDefaultFallback = <
  TExpectedResponseType extends
  | RecursiveObject<unknown>
  | RecursiveObject<unknown>[],
>(
    buildGqlSubQuery: (lucerneFilter: string) => string,
    processResponse: (
      gqlResponse: JSONSerializableObject[],
    ) => TExpectedResponseType | undefined,
    loadingStateOperationName: GlobalLoadingStateOperation,
    contentTypeName: string,
    customDynamicVariables?: Record<string, string>,
    delayedCustomDynamicVariables?: string[],
    customDynamicConditionals?: Record<string, boolean>,
    delayedCustomDynamicConditionals?: string[],
  ): FetchFromDotcmsResult<TExpectedResponseType> => {
  const { queries: lucerneQueries, dotcmsLanguageId } =
    useGetDotcmsLucerneQuery();
  const { countryCode } = useGetUserLocation();
  const { insertDynamicVariables } = useParseDynamicContent({
    customMapping: customDynamicVariables,
    ignoredCustomMapping: delayedCustomDynamicVariables,
    contentTypeName,
    customDynamicConditionals,
    ignoredCustomDynamicConditionals: delayedCustomDynamicConditionals,
  });
  // Trimming whitespace/newlines from the result of buildGqlSubQuery
  // is very important! It's easy to accidentally make it return a string
  // wrapped in newlines or whitespace, but the string that we are constructing
  // below needs to look like: "ourCustomNameForTheQuery:TheQueryWeAreMaking"
  // with NO whitespace around the colon.

  // See also https://stackoverflow.com/a/59262110/5602521
  const gqlQuery = compress(`
          query FetchContent {
            ${DEFAULT_HOST_QUERY_NAME}:
              ${buildGqlSubQuery(lucerneQueries.defaultContentQuery).trim()}
            ${ISSUER_HOST_QUERY_NAME}:
              ${buildGqlSubQuery(lucerneQueries.issuerSpecificQuery).trim()}
          }
    `);

  const planDetails = usePlanDetails();
  const enrollmentPlan = useGetEnrollmentPlan();

  const processResponseInternal = useCallback(
    (response: JSONSerializableObject): TExpectedResponseType | undefined => {
      try {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
        const responseAsAny = response as Record<
        string,
        JSONSerializableObject[] | undefined
        >;

        if (!responseAsAny[DEFAULT_HOST_QUERY_NAME]?.[0]) {
          const errorToReportToSentry = new Error(
            `default response for gql query was not present for content type ${contentTypeName} and language ID ${
              dotcmsLanguageId ?? 'undefined'
            }.`,
          );
          captureErrorInSentryWithCustomMessage(
            errorToReportToSentry,
            `Query:\n${
              gqlQuery ?? ''
            }\n\n---------\n\nResponse:\n${JSON.stringify(responseAsAny)}`,
          );
        }

        // We got two copies of the requested query. One for the current issuer,
        // and one set of "defaults". If a field is non-empty for the issuer
        // query's response, that issuer-specific field should be used.
        // If a field is empty in the issuer's response, the default
        // response's value for that field should be used.
        const issuerResponseWithOnlyNonEmptyKeys =
          responseAsAny[ISSUER_HOST_QUERY_NAME]?.map((x) =>
            removeEmptyKeysForDotcmsFallback(x ?? {})) ?? [];

        const isSingleResponse =
          responseAsAny[DEFAULT_HOST_QUERY_NAME]?.length === 1 &&
          issuerResponseWithOnlyNonEmptyKeys.length <= 1;

        // If only a single response item was expected, combine the fields of that 1 response item.
        // Otherwise, concatenate the lists of responses.
        const combinedResponse = isSingleResponse
          ? [
            mergeObjectsForDotcmsFallback(
              responseAsAny[DEFAULT_HOST_QUERY_NAME]?.[0] ?? {},
              issuerResponseWithOnlyNonEmptyKeys[0] ?? {},
            ),
          ]
          : mergeObjectsForDotcmsFallback(
            responseAsAny[DEFAULT_HOST_QUERY_NAME] ?? [],
            issuerResponseWithOnlyNonEmptyKeys,
          );

        // Do other post-processing:
        const processedResponseWithTypes = processResponse(
          insertDynamicVariables(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            combinedResponse as JSONSerializableObject[],
          ),
        );

        if (
          processedResponseWithTypes === undefined ||
          processedResponseWithTypes === null
        ) {
          return undefined;
        }

        // Filter out items that should not be shown based on the current
        // plan details:
        const filteredResponse = recursivelyFilterIneligibleItems(
          processedResponseWithTypes,
          planDetails,
          enrollmentPlan.espp === EnrollmentPlanEspp.EXTERNAL_ESPP,
          countryCode,
        );

        return filteredResponse;
      } catch (e) {
        console.error(JSON.stringify(e));
        return undefined;
      }
    },
    [
      processResponse,
      insertDynamicVariables,
      planDetails,
      enrollmentPlan.espp,
      countryCode,
      contentTypeName,
      dotcmsLanguageId,
      gqlQuery,
    ],
  );

  const fetchResponse = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = await client.request(gqlQuery);
    if (!result || typeof result !== 'object') {
      throw new Error(
        `dotcms gql result was an unexpected type. Type was: "${typeof result}" and result was: ${JSON.stringify(
          result,
        )}`,
      );
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return result as JSONSerializableObject;
  }, [gqlQuery]);

  const {
    isPending,
    error,
    data: rawJsonData,
  } = useQuery({
    // Lucerne queries must be part of the query key so that the query re-fetches
    // if the language ID changes:
    queryKey: [loadingStateOperationName, lucerneQueries?.issuerSpecificQuery],
    queryFn: fetchResponse,
  });

  const data = useMemo(
    () => (rawJsonData ? processResponseInternal(rawJsonData) : undefined),
    [processResponseInternal, rawJsonData],
  );

  return {
    data,
    loading: isPending,
    error,
  };
};
