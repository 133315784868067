import { match } from 'ts-pattern';

type EnvironmentConfig = {
  api: string;
  assetsCdnUrl: string;
  cmsUrl: string;
};

type Config = EnvironmentConfig & {
  isProd: boolean;
  isLocalDev: boolean;
  environmentName: string;
  launchDarklyUrl: string;
  amplitudeUrl: string | undefined;
};

type EnvironmentName = 'dev' | 'test' | 'stage' | 'prod' | 'demo' | 'localhost';

const ENV_BY_HOSTNAME: Record<string, EnvironmentName | undefined> = {
  'dev.espp.com': 'dev',
  'test.espp.com': 'test',
  'stage.espp.com': 'stage',
  'espp.com': 'prod',
  'demo.espp.com': 'demo',
};

const getEnvironment = (): EnvironmentName | undefined => {
  const { hostname } = window.location;
  const env = ENV_BY_HOSTNAME[hostname];
  return env;
};

export const ESPP_HOST = 'espp.com';
export const LOCALHOST = 'localhost';
const LOCALHOST_IP = '127.0.0.1';
// 10.0.2.2 is the IP to connect to the host machine from an Android emulator
const ANDROID_EMULATOR_IP = '10.0.2.2';

const LAUNCHDARKLY_TEST_CLIENT_SIDE_ID = '632374b2b55f9f11ab78f68c';
const LAUNCHDARKLY_PROD_CLIENT_SIDE_ID = '632374b2b55f9f11ab78f68d';

const AMPLITUDE_PROD_PUBLIC_API_ID = 'eb8c4ea957013e382198fb60ac7549b9';
// const AMPLITUDE_DEV_PUBLIC_API_ID = '7ff481838c34e6f75ef5f8ec42a2c0c5';

const DEV_CONFIG: EnvironmentConfig = {
  api: 'https://devapp.espp.com/api-v2',
  assetsCdnUrl: 'https://assets.dev.espp.com',
  cmsUrl: 'https://cms.dev.espp.com',
};

const TEST_CONFIG: EnvironmentConfig = {
  api: 'https://testapp.espp.com/api-v2',
  assetsCdnUrl: 'https://assets.test.espp.com',
  cmsUrl: 'https://cms.test.espp.com',
};

const STAGE_CONFIG: EnvironmentConfig = {
  api: 'https://stageapp.espp.com/api-v2',
  assetsCdnUrl: 'https://assets.stage.espp.com',
  cmsUrl: 'https://cms.stage.espp.com',
};

const PROD_CONFIG: EnvironmentConfig = {
  api: 'https://prodapp.espp.com/api-v2',
  assetsCdnUrl: 'https://assets.espp.com',
  cmsUrl: 'https://cms.espp.com',
};

const DEMO_CONFIG: EnvironmentConfig = {
  api: 'https://demoapp.espp.com/api-v2',
  assetsCdnUrl: 'https://assets.demo.espp.com',
  cmsUrl: 'https://cms.demo.espp.com',
};

const getEnvironmentConfig = (): Config => {
  const detectedEnvironment = getEnvironment();
  const isProd = detectedEnvironment === 'prod';
  const isLocalDev = [ANDROID_EMULATOR_IP, LOCALHOST_IP, LOCALHOST].includes(
    window.location.hostname,
  );

  const partialConfig = {
    isProd,
    isLocalDev,
    // Default to the environment name. Local dev server has no env returned from getEnvironment(),
    // so in that case, we fall back to 'localhost'.
    environmentName:
      detectedEnvironment ?? (isLocalDev ? LOCALHOST : 'UNKNOWN_ENVIRONMENT'),
    launchDarklyUrl: isProd
      ? LAUNCHDARKLY_PROD_CLIENT_SIDE_ID
      : LAUNCHDARKLY_TEST_CLIENT_SIDE_ID,
    // Temporarily disabled Amplitude logging in nonprod to save quota:
    amplitudeUrl: isProd ? AMPLITUDE_PROD_PUBLIC_API_ID : undefined,
  };

  if (process.env.NODE_ENV !== 'production') {
    // This means we're running a local dev server.
    return {
      ...TEST_CONFIG,
      ...partialConfig,
      api:
        // 10.0.2.2 forwards to the host machine's `localhost` when
        // running an Android emulator,
        // Else, we're just a regular local dev situation.
        `http://${
          window.location.hostname === ANDROID_EMULATOR_IP
            ? ANDROID_EMULATOR_IP
            : LOCALHOST
        }/api-v2`,
    };
  }

  // This is true not just in prod env, but any time that the frontend is deployed
  // on a server, so e.g. test.espp.com will also hit this branch.
  const configForEnv = match({ detectedEnvironment })
    .with({ detectedEnvironment: 'dev' }, () => DEV_CONFIG)
    .with({ detectedEnvironment: 'test' }, () => TEST_CONFIG)
    .with({ detectedEnvironment: 'stage' }, () => STAGE_CONFIG)
    .with({ detectedEnvironment: 'prod' }, () => PROD_CONFIG)
    .with({ detectedEnvironment: 'demo' }, () => DEMO_CONFIG)
    .otherwise(() => {
      console.error(
        'Could not determine config for environment. Detected environment was: ',
        detectedEnvironment,
      );

      return TEST_CONFIG;
    });

  return {
    ...configForEnv,
    ...partialConfig,
  };
};

export const config = getEnvironmentConfig();
