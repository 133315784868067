// The language id can be found via the doCMS UI
// Menu: Types & Tags / Languages
// Right-click the language and select "Edit Language"

const EN_US_DOTCMS_LANGUAGE_ID = 1;

const DOTCMS_LANGUAGE_ID_MAPPING: Record<string, number | undefined> = {
  'en-US': EN_US_DOTCMS_LANGUAGE_ID,
  'es-ES': 4791063,
  'de-DE': 4660718,
  'fr-FR': 4560110,
  'fr-CA': 4600065,
  'zh-CN': 5443967,
  // Disabled for now because most content is not translated
  // into en-GB. Fall back to en-US:
  // 'en-GB': 4585917,
  'en-GB': EN_US_DOTCMS_LANGUAGE_ID,
};

export const getDotcmsLanguageId = (locale: string): number => {
  const dotcmsLanguageId = DOTCMS_LANGUAGE_ID_MAPPING[locale];

  if (dotcmsLanguageId === undefined) {
    console.error(
      `dotcms language mapping did not exist for ${locale}. Falling back to English (US).`,
    );
    return EN_US_DOTCMS_LANGUAGE_ID;
  }
  return dotcmsLanguageId;
};
