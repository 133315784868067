import { processGqlDataAsArray } from '../use-builders-to-process-gql-data';
import {
  convertDotcmsString,
  convertDotcmsResponseToRecord,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type INavigationItemLink = IBaseObjectData & {
  label: string;
  path: string;
  icon: string;
  childrenNavigationItems: INavigationItemLink[];
};

export const NavigationItemLinkHelper: IGqlHelper<INavigationItemLink> = {
  contentTypeName: 'NavigationItem',
  gqlFieldsQuery: `
      label
      path
      icon
      childrenNavigationItems {
        label
        path
        icon
      }
    `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const {
      label, path, icon, childrenNavigationItems,
    } =
      convertDotcmsResponseToRecord(gqlResponse);

    return {
      label: convertDotcmsString(label) || '',
      path: convertDotcmsString(path) || '',
      icon: convertDotcmsString(icon) || '',
      childrenNavigationItems:
        processGqlDataAsArray(
          NavigationItemLinkHelper,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          childrenNavigationItems,
        ) ?? [],
    };
  },
};
