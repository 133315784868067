import useLocale from '../locale/use-locale/use-locale';
import { useGetConhostForIssuer } from './dotcms-issuer-conhost-mapping';
import { getDotcmsLanguageId } from './dotcms-language-id-mapping';

const DOTCMS_DEFAULT_CONTENT_HOST_ID = '7fed0e20b3d184a29a5e471300dc3a97'; // site: 'default-issuer-template'

export interface DotcmsLucerneQueryParams {
  queries: {
    issuerSpecificQuery: string;
    defaultContentQuery: string;
  };
  dotcmsLanguageId?: number;
}

/** This returns a Lucerne query that you can give to a dotcms graphql query
 * to get the result for the selected issuer and language.
 *
 * Example:
 * const {issuerSpecificQuery} = useGetDotcmsLucerneQuery();
 * const gqlRequest = gql`{CashlessParticipationPageCollection(query: ${issuerSpecificQuery}) {...}`
 */
export const useGetDotcmsLucerneQuery = (): DotcmsLucerneQueryParams => {
  const { locale } = useLocale();
  const dotcmsLanguageId = getDotcmsLanguageId(locale);
  const dotcmsIssuerHost = useGetConhostForIssuer();

  // By making conhost be "FAKE_HOST" if the issuer host is not found, this query
  // will return no results, which will make rendered content fall back to the default
  // content query:
  const issuerSpecificQuery = `+deleted:false +languageId:${dotcmsLanguageId} +conhost:${
    dotcmsIssuerHost ?? 'FAKE_HOST'
  }`;

  const defaultContentQuery = `+deleted:false +languageId:${dotcmsLanguageId} +conhost:${DOTCMS_DEFAULT_CONTENT_HOST_ID}`;

  return {
    queries: { issuerSpecificQuery, defaultContentQuery },
    dotcmsLanguageId,
  };
};
