import { DateTime } from 'luxon';
import { useMemo } from 'react';
import type { StartEndDates } from '../use-get-all-periods';
import { useGetAllEnrollmentPeriods } from '../use-get-all-periods';
import type { IEnrollmentPeriodInfo } from '../../../selectors/interfaces';

type StartEndDatesWithOverlappingInfo = StartEndDates & {
  isDatesOverlappingWithNextPeriod: boolean;
};

export interface IEnrollmentPeriodDates {
  closestEnrollmentDates: StartEndDatesWithOverlappingInfo;
  futureEnrollmentDates: StartEndDatesWithOverlappingInfo;
  closestEsppEnrollmentDates: StartEndDatesWithOverlappingInfo | null;
  futureEsppEnrollmentDates: StartEndDatesWithOverlappingInfo | null;
  closestCashlessEnrollmentDates: StartEndDatesWithOverlappingInfo | null;
  futureCashlessEnrollmentDates: StartEndDatesWithOverlappingInfo | null;
  closestExternalEnrollmentDates: StartEndDatesWithOverlappingInfo | null;
}

const getDates = (
  periodInfo: IEnrollmentPeriodInfo,
): StartEndDatesWithOverlappingInfo => ({
  start: DateTime.fromISO(periodInfo.startDate, { setZone: true }),
  end: DateTime.fromISO(periodInfo.endDate, { setZone: true }),
  isDatesOverlappingWithNextPeriod: periodInfo.nextEnrollmentPeriodIsConnected,
});

const getDatesOrNull = (
  periodInfo: IEnrollmentPeriodInfo | null,
): StartEndDatesWithOverlappingInfo | null =>
  (periodInfo ? getDates(periodInfo) : null);

export const useEnrollmentPeriodDates = (): IEnrollmentPeriodDates => {
  const { allPeriods, closestPeriod, futurePeriod } =
    useGetAllEnrollmentPeriods();

  return useMemo(() => {
    const closestEsppEnrollmentPeriod =
      allPeriods.find((period) => period?.traditionalEnrollment) ?? null;
    const futureEsppEnrollmentPeriod = futurePeriod.traditionalEnrollment
      ? futurePeriod
      : null;
    const closestCashlessEnrollmentPeriod =
      allPeriods.find((period) => period?.cashlessEnrollment) ?? null;
    const futureCashlessEnrollmentPeriod = futurePeriod.cashlessEnrollment
      ? futurePeriod
      : null;
    const closestExternalEnrollmentPeriod =
      allPeriods.find((period) => period?.traditionalEnrollmentIsExternal) ??
      null;

    return {
      closestEnrollmentDates: getDates(closestPeriod),
      futureEnrollmentDates: getDates(futurePeriod),
      closestEsppEnrollmentDates: getDatesOrNull(closestEsppEnrollmentPeriod),
      futureEsppEnrollmentDates: getDatesOrNull(futureEsppEnrollmentPeriod),
      closestCashlessEnrollmentDates: getDatesOrNull(
        closestCashlessEnrollmentPeriod,
      ),
      futureCashlessEnrollmentDates: getDatesOrNull(
        futureCashlessEnrollmentPeriod,
      ),
      closestExternalEnrollmentDates: getDatesOrNull(
        closestExternalEnrollmentPeriod,
      ),
    };
  }, [allPeriods, closestPeriod, futurePeriod]);
};
