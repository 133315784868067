import { UrlRouteToken } from './router-utils';

// If you update this, you will also need to update the
// login requirement list in pages-requiring-login.ts
// and the requirement list in pages-requiring-condition.ts
// Keep alphabetized:
export enum Page {
  ABOUT_CASHLESS = 'ABOUT_CASHLESS',
  ACCOUNT_PAGE = 'ACCOUNT_PAGE',
  ACCOUNT_PAGE_CONTACT_INFORMATION = 'ACCOUNT_PAGE_CONTACT_INFORMATION',
  ACCOUNT_PAGE_DOCUMENTS = 'ACCOUNT_PAGE_DOCUMENTS',
  ACCOUNT_PAGE_MANAGE_ELECTION = 'ACCOUNT_PAGE_MANAGE_ELECTION',
  ACCOUNT_PAGE_PURCHASE_DETAILS = 'ACCOUNT_PAGE_PURCHASE_DETAILS',
  ACCOUNT_PAGE_STATUS_SUMMARY = 'ACCOUNT_PAGE_STATUS_SUMMARY',
  ACCOUNT_PAGE_STOCK_INFORMATION = 'ACCOUNT_PAGE_STOCK_INFORMATION',
  ADMIN_DATA_CONTRIBUTIONS = 'ADMIN_DATA_CONTRIBUTIONS',
  ADMIN_DATA_EXCHANGE = 'ADMIN_DATA_EXCHANGE',
  ADMIN_DATA_UPLOAD = 'ADMIN_DATA_UPLOAD',
  ADMIN_EMPLOYEE_LOOKUP = 'ADMIN_EMPLOYEE_LOOKUP',
  ADMIN_ENROLLMENT_STATS = 'ADMIN_ENROLLMENT_STATS',
  ADMIN_EXCHANGE_RATES = 'ADMIN_EXCHANGE_RATES',
  ADMIN_PAGE = 'ADMIN_PAGE',
  ADMIN_PLAN_OVERVIEW = 'ADMIN_PLAN_OVERVIEW',
  ADMIN_PURCHASE_LOOKUP = 'ADMIN_PURCHASE_LOOKUP',
  ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS = 'ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS',
  ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP = 'ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP',
  ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW = 'ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW',
  ADMIN_PLAN_ENROLLMENT_CONFIGURATION = 'ADMIN_PLAN_ENROLLMENT_CONFIGURATION',
  ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN = 'ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN',
  ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT = 'ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT',
  ADMIN_STOCK_BASED_COMPENSATION = 'ADMIN_STOCK_BASED_COMPENSATION',
  ADMIN_SURVEY_RESULTS = 'ADMIN_SURVEY_RESULTS',
  ANTI_BOT_BROKER_QUESTIONS_REDIRECT = 'ANTI_BOT_BROKER_QUESTIONS_REDIRECT',
  ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME = 'ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME',
  AUTO_SALE_BROKER_QUESTIONS = 'AUTO_SALE_BROKER_QUESTIONS',
  CALCULATOR = 'CALCULATOR',
  CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER = 'CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER',
  DEFAULT = 'DEFAULT',
  EMAIL_VERIFICATION_COMPLETE = 'EMAIL_VERIFICATION_COMPLETE',
  ENROLLMENT_PAGE = 'ENROLLMENT_PAGE',
  HOMEPAGE = 'HOMEPAGE',
  IMPORTANT_INFORMATION = 'IMPORTANT_INFORMATION',
  LOGIN_PAGE = 'LOGIN_PAGE',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  REFERRAL = 'REFERRAL',
  RESOURCE_LIBRARY = 'RESOURCE_LIBRARY',
  RESOURCE_LIBRARY_ID = 'RESOURCE_LIBRARY_ID',
  STANDALONE_BROKER_QUESTIONS = 'STANDALONE_BROKER_QUESTIONS',
  TAX_INFORMATION = 'TAX_INFORMATION',
  TEMP_PDF_VIEWER_TEST = 'TEMP_PDF_VIEWER_TEST',
  TERM_OF_SERVICE = 'TERM_OF_SERVICE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  WEALTH_MANAGEMENT = 'WEALTH_MANAGEMENT',
}

export const enum PageLoginRequirement {
  NO_LOGIN_REQUIRED = 'NO_LOGIN_REQUIRED',
  AT_LEAST_HALF_MAGIC_REQUIRED = 'AT_LEAST_HALF_MAGIC_REQUIRED',
  AT_LEAST_FULL_MAGIC_LINK = 'AT_LEAST_FULL_MAGIC_LINK',
  FULL_NON_MAGIC_LOGIN_REQUIRED = 'FULL_NON_MAGIC_LOGIN_REQUIRED',
  TWO_FACTOR_AUTHENTICATION_REQUIRED = 'TWO_FACTOR_AUTHENTICATION_REQUIRED',
}

// Keep alphabetized:
export const PageUrlPath: Readonly<{ [key in Page]: string }> = {
  [Page.ABOUT_CASHLESS]: `/:${UrlRouteToken.TICKER}/about-cashless`,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: `/:${UrlRouteToken.TICKER}/admin/contributions`,
  [Page.ADMIN_DATA_EXCHANGE]: `/:${UrlRouteToken.TICKER}/admin/data-exchange`,
  [Page.ADMIN_DATA_UPLOAD]: `/:${UrlRouteToken.TICKER}/admin/data-upload`,
  [Page.ADMIN_ENROLLMENT_STATS]: `/:${UrlRouteToken.TICKER}/admin/enrollment-stats`,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: `/:${UrlRouteToken.TICKER}/admin/employee-lookup`,
  [Page.ADMIN_PURCHASE_LOOKUP]: `/:${UrlRouteToken.TICKER}/admin/purchase-details`,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: `/:${UrlRouteToken.TICKER}/admin/purchase-details/overview`,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]: `/:${UrlRouteToken.TICKER}/admin/purchase-details/highlights`,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: `/:${UrlRouteToken.TICKER}/admin/purchase-details/lookup`,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: `/:${UrlRouteToken.TICKER}/admin/configuration`,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]: `/:${UrlRouteToken.TICKER}/admin/configuration/plan`,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]: `/:${UrlRouteToken.TICKER}/admin/configuration/enrollment`,
  [Page.ADMIN_EXCHANGE_RATES]: `/:${UrlRouteToken.TICKER}/admin/exchange-rates`,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]: `/:${UrlRouteToken.TICKER}/admin/stock-based-compensation`,
  [Page.ADMIN_SURVEY_RESULTS]: `/:${UrlRouteToken.TICKER}/admin/survey-results`,
  [Page.ADMIN_PLAN_OVERVIEW]: `/:${UrlRouteToken.TICKER}/admin/plan-overview`,
  [Page.ADMIN_PAGE]: `/:${UrlRouteToken.TICKER}/admin`,
  [Page.ACCOUNT_PAGE]: `/:${UrlRouteToken.TICKER}/account`,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: `/:${UrlRouteToken.TICKER}/account/personal-information`,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: `/:${UrlRouteToken.TICKER}/account/documents`,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: `/:${UrlRouteToken.TICKER}/account/election`,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: `/:${UrlRouteToken.TICKER}/account/purchase-details`,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: `/:${UrlRouteToken.TICKER}/account/status-summary`,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: `/:${UrlRouteToken.TICKER}/account/stock-information`,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: `/:${UrlRouteToken.TICKER}/broker-q-landing/:${UrlRouteToken.MAGIC_LINK_REDIRECT_DESTINATION}`,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: `/:${UrlRouteToken.TICKER}/magic/:${UrlRouteToken.MAGIC_LINK_REDIRECT_DESTINATION}`,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: `/:${UrlRouteToken.TICKER}/auto-sale-broker-questions`,
  [Page.CALCULATOR]: `/:${UrlRouteToken.TICKER}/multimedia-tools`,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: `/:${UrlRouteToken.TICKER}/ce-internal/dynamic-variables`,
  [Page.DEFAULT]: '/',
  [Page.EMAIL_VERIFICATION_COMPLETE]: `/:${UrlRouteToken.TICKER}/verify`,
  [Page.ENROLLMENT_PAGE]: `/:${UrlRouteToken.TICKER}/enrollment`,
  [Page.HOMEPAGE]: `/:${UrlRouteToken.TICKER}`,
  [Page.IMPORTANT_INFORMATION]: `/:${UrlRouteToken.TICKER}/disclaimer`,
  [Page.LOGIN_PAGE]: `/:${UrlRouteToken.TICKER}/log-in`,
  [Page.PRIVACY_POLICY]: `/:${UrlRouteToken.TICKER}/privacy`,
  [Page.REFERRAL]: `/:${UrlRouteToken.TICKER}/account/refer`,
  [Page.RESOURCE_LIBRARY]: `/:${UrlRouteToken.TICKER}/resource-library`,
  [Page.RESOURCE_LIBRARY_ID]: `/:${UrlRouteToken.TICKER}/resource-library/:${UrlRouteToken.LIBRARY_RESOURCE_ID}`,
  [Page.STANDALONE_BROKER_QUESTIONS]: `/:${UrlRouteToken.TICKER}/broker-questions`,
  [Page.TAX_INFORMATION]: `/:${UrlRouteToken.TICKER}/tax-information`,
  [Page.TEMP_PDF_VIEWER_TEST]: '/ce-internal/pdf-test',
  [Page.TERM_OF_SERVICE]: `/:${UrlRouteToken.TICKER}/terms-of-service`,
  [Page.UNSUBSCRIBE]: `/:${UrlRouteToken.TICKER}/unsubscribe`,
  [Page.WEALTH_MANAGEMENT]: `/:${UrlRouteToken.TICKER}/wealth-management`,
};
