import { Box, styled } from '@mui/material';
import React, { useMemo, useContext, useState } from 'react';
import { useGetTheme } from '../../../hooks/use-get-theme';
import type { GlobalLoadingContextData } from '../global-loading-context/global-loading-context';
import { GlobalLoadingContext } from '../global-loading-context/global-loading-context';
import { useBooleanUrlSearchParam } from '../../../utils/url-search-params/use-query-url-search-params';
import UrlSearchParamKey from '../../../utils/url-search-params/url-search-params';

export type Temp = keyof Pick<
GlobalLoadingContextData,
'currentLoadingOperationsForDebugging' | 'displayLoadingScreenOverlay'
>;

const ANIMATION_LOOP_DURATION_IN_SECONDS = 1.2;

const getCurrentEpochTimeInSeconds = () => new Date().getTime() / 1000;

const formatNegativeAnimationDelay = (animationDelaySeconds: number) =>
  `-${animationDelaySeconds.toFixed(4)}s`;

export const LoadingSpinner: React.FC = () => {
  const {
    currentLoadingOperationsForDebugging,
    displayLoadingScreenOverlay,
    displayRenderBlockingLoadingScreen,
  } = useContext(GlobalLoadingContext);
  const theme = useGetTheme();
  const shouldTrackDebugInfo = useBooleanUrlSearchParam(
    UrlSearchParamKey.SHOW_LOADING_SCREEN_DEBUG,
  );
  const [animationStartTimeInEpochMilliseconds] = useState(
    getCurrentEpochTimeInSeconds(),
  );

  // This makes the animation always start relative to the
  // current clock time. If the clock time modulo the animation
  // duration is 0, the animation should start at 0% complete.
  // If the clock time modulo the animation duration is 50% of the
  // duration, the animation should start at 50% complete. Etc.

  // Starting an animation as x% complete is done by giving it
  // a negative animation delay. If you want to start the
  // animation as 60% complete, you need to pass a negative
  // animation delay of 40%.
  const timeAlreadyPassedSinceStart =
    ANIMATION_LOOP_DURATION_IN_SECONDS -
    (animationStartTimeInEpochMilliseconds %
      ANIMATION_LOOP_DURATION_IN_SECONDS);
  const negativeAnimationDelayToAdd =
    ANIMATION_LOOP_DURATION_IN_SECONDS - timeAlreadyPassedSinceStart;

  // https://loading.io/css/
  const LoadingSpinnerContainer = useMemo(
    () =>
      styled(Box)({
        '.lds-ring': {
          display: 'inline-block',
          position: 'relative',
          width: '80px',
          height: '80px',
        },
        '.lds-ring div': {
          boxSizing: 'border-box',
          display: 'block',
          position: 'absolute',
          width: '64px',
          height: '64px',
          margin: '8px',
          border: `8px solid ${theme.colors.secondary}`,
          borderRadius: '50%',
          animation: `lds-ring ${ANIMATION_LOOP_DURATION_IN_SECONDS}s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
          animationDelay: formatNegativeAnimationDelay(
            negativeAnimationDelayToAdd,
          ),
          borderColor: `${theme.colors.secondary} transparent transparent transparent`,
        },
        '.lds-ring div:nth-of-type(1)': {
          animationDelay: formatNegativeAnimationDelay(
            0.45 + negativeAnimationDelayToAdd,
          ),
        },
        '.lds-ring div:nth-of-type(2)': {
          animationDelay: formatNegativeAnimationDelay(
            0.3 + negativeAnimationDelayToAdd,
          ),
        },
        '.lds-ring div:nth-of-type(3)': {
          animationDelay: formatNegativeAnimationDelay(
            0.15 + negativeAnimationDelayToAdd,
          ),
        },
        '@keyframes lds-ring': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      }),
    [negativeAnimationDelayToAdd, theme.colors.secondary],
  );

  if (shouldTrackDebugInfo) {
    return (
      <>
        <p>
          displayLoadingScreenOverlay:
          {' '}
          {String(displayLoadingScreenOverlay)}
        </p>
        <p>
          displayRenderBlockingLoadingScreen:
          {' '}
          {String(displayRenderBlockingLoadingScreen)}
        </p>
        {currentLoadingOperationsForDebugging.length ? (
          <>
            {currentLoadingOperationsForDebugging.map((operationInfo) => (
              <div key={operationInfo}>{operationInfo}</div>
            ))}
          </>
        ) : (
          <>
            Debug: Loading spinner is rendering but there are no global loading
            states in the list!
          </>
        )}
      </>
    );
  }

  return (
    <LoadingSpinnerContainer>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </LoadingSpinnerContainer>
  );
};
