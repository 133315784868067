import { DateTime } from 'luxon';
import { useMemo } from 'react';
import type { IOfferingPeriodInfo } from '../../../selectors/interfaces';
import type { StartEndDates } from '../use-get-all-periods';
import { useGetAllOfferingPeriods } from '../use-get-all-periods';

type StartEndDatesWithResetChangeWindow = StartEndDates & {
  resetChangeWindowDates: StartEndDates | null;
};

export interface IOfferingPeriodDates {
  currentOfferingDates: StartEndDatesWithResetChangeWindow | null;
  futureOfferingDates: StartEndDatesWithResetChangeWindow;
  closestOfferingDates: StartEndDatesWithResetChangeWindow;
}

const getDates = (
  periodInfo: IOfferingPeriodInfo,
): StartEndDatesWithResetChangeWindow => ({
  start: DateTime.fromISO(periodInfo.offeringPeriodStartDate ?? '', {
    setZone: true,
  }),
  end: DateTime.fromISO(periodInfo.offeringPeriodEndDate ?? '', {
    setZone: true,
  }),
  resetChangeWindowDates:
    periodInfo?.resetChangeWindowStart && periodInfo?.resetChangeWindowEnd
      ? {
        start: DateTime.fromISO(periodInfo.resetChangeWindowStart, {
          setZone: true,
        }),
        end: DateTime.fromISO(periodInfo.resetChangeWindowEnd, {
          setZone: true,
        }),
      }
      : null,
});

const getDatesOrNull = (
  periodInfo: IOfferingPeriodInfo | null | undefined,
): StartEndDatesWithResetChangeWindow | null =>
  (periodInfo?.offeringPeriodStartDate && periodInfo?.offeringPeriodEndDate
    ? getDates(periodInfo)
    : null);

export const useOfferingPeriodDates = (): IOfferingPeriodDates => {
  const { currentPeriod, futurePeriod, closestPeriod } =
    useGetAllOfferingPeriods();

  return useMemo(
    () => ({
      currentOfferingDates: getDatesOrNull(currentPeriod),
      futureOfferingDates: getDates(futurePeriod),
      closestOfferingDates: getDates(closestPeriod),
    }),
    [currentPeriod, futurePeriod, closestPeriod],
  );
};
