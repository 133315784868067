import { createContext } from 'react';

export type PageTitleContextData = {
  setOverridePageTitle_internalDontUse: (title: string | undefined) => void;
};

const PageTitleContext = createContext<PageTitleContextData>({
  setOverridePageTitle_internalDontUse: () => {},
});

export default PageTitleContext;
