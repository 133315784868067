/** @jsxImportSource @emotion/react */
import type { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import type { IButtonProps } from './button-style-helper';
import { getButtonStyles } from './button-style-helper';
import { ELEMENT_ID_DATA_ATTRIBUTE } from '../../../constants/data-element-tracking-ids';

const StyledLink = styled.a`
  text-decoration: none;
`;

/** Only use this for links to external sites! Otherwise use LinkButton.tsx */
const AnchorButton: React.FC<
PropsWithChildren<
Omit<AnchorHTMLAttributes<unknown>, 'style'> & Omit<IButtonProps, 'type'>
>
> = ({
  children,
  variant,
  color,
  size,
  icon,
  theme,
  additionalStyles = {},
  href,
  dataElementId,
  target = '_blank',
  ...props
}) => {
  const buttonClasses = getButtonStyles({
    variant,
    theme,
    size,
    color,
  });

  return (
    <StyledLink
      css={[buttonClasses, { color: 'inherit' }, additionalStyles]}
      {...{ [ELEMENT_ID_DATA_ATTRIBUTE]: dataElementId }}
      href={href}
      target={target}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {icon && (
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FontAwesomeIcon {...icon} style={{ marginRight: '8px' }} />
        </div>
      )}
      {children}
    </StyledLink>
  );
};

export default AnchorButton;
