import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import type { ITheme, ThemeColor } from '../../component_library/styles/theme';
import { theme } from '../../component_library/styles/theme';
import { uiSelector } from '../../selectors/index';
import { useStringUrlSearchParam } from '../../utils/url-search-params/use-query-url-search-params';
import UrlSearchParamKey from '../../utils/url-search-params/url-search-params';

// TODO: [CP-1888] Create a way to get theme values dynamically per issuer
const useGetTheme = (): ITheme => {
  const { companyColor } = useSelector(uiSelector);
  const primaryColorFromUrl = useStringUrlSearchParam(
    UrlSearchParamKey.MAGIC_LINK_PRIMARY_COLOR,
  );

  return useMemo(() => {
    if (!companyColor && !primaryColorFromUrl) {
      return theme;
    }
    return {
      ...theme,
      colors: {
        ...theme.colors,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        primary: (primaryColorFromUrl ?? companyColor) as ThemeColor,
      },
    };
  }, [companyColor, primaryColorFromUrl]);
};

export default useGetTheme;
