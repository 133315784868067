const AMPLITUDE_ID_MIN_LENGTH = 5;

/** Amplitude rejects user IDs shorter than 5 characters. We must
 *  left-pad with 0's if the ID is too short.
 */
export const padAmplitudeUserId = (unPaddedId: string | number) => {
  const unpaddedString = String(unPaddedId);
  const missingCharacterCount = Math.max(
    0,
    AMPLITUDE_ID_MIN_LENGTH - unpaddedString.length,
  );
  const extraZeros = Array(missingCharacterCount).fill(0).join('');
  return `${extraZeros}${unpaddedString}`;
};
