import type { PropsWithChildren } from 'react';
import React, { createContext, useMemo } from 'react';
import type { IIssuerFeatureResultFromApi } from '../hooks/use-fetch-issuer-feature';
import { useFetchIssuerFeature } from '../hooks/use-fetch-issuer-feature';

type IssuerFeaturesData = Partial<IIssuerFeatureResultFromApi> & {
  loaded: boolean;
};

const ISSUER_FEATURES_CONTEXT_FALLBACK: IssuerFeaturesData = {
  allowed_features: [],
  loaded: false,
};

export const IssuerFeaturesContext = createContext<IssuerFeaturesData>(
  ISSUER_FEATURES_CONTEXT_FALLBACK,
);

export const IssuerFeaturesContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data, loading } = useFetchIssuerFeature();
  const contextValue = useMemo(
    () => ({
      ...(data ?? ISSUER_FEATURES_CONTEXT_FALLBACK),
      loaded: !loading && !!data,
    }),
    [data, loading],
  );

  return (
    <IssuerFeaturesContext.Provider value={contextValue}>
      {children}
    </IssuerFeaturesContext.Provider>
  );
};
