import { useMemo } from 'react';
import { useJsonLaunchDarklyFlag } from './launchDarkly';
import useGetIssuerShortname from '../hooks/use-get-issuer-shortname/use-get-issuer-shortname';

interface IEnabledPerIssuerFeatureFlagValue {
  availableToIssuers: string[];
}

/** When creating a new flag to enable a feature per issuer, the flag
    in launchdarkly should have a value that's shaped like this:
    {
      "availableToIssuers": [
        "testco"
      ]
    }
*/
export enum EnabledPerIssuerFeatureFlag {
  // This enum has to contain at least one value, or Typescript will complain
  // when we try to use a variable of type BooleanFeatureFlag in a place that
  // expects a string.
  MY_EXAMPLE_FEATURE = 'some-issuer-example',
}

export const PER_ISSUER_FLAG_DEFAULT_VALUES: {
  [key in EnabledPerIssuerFeatureFlag]: IEnabledPerIssuerFeatureFlagValue;
} = {
  [EnabledPerIssuerFeatureFlag.MY_EXAMPLE_FEATURE]: {
    availableToIssuers: [],
  },
};

interface IEnabledPerIssuerFeatureFlagData {
  data: { enabledIssuers: string[] };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useFeatureEnabledIssuers_internalDontUse = (
  feature: EnabledPerIssuerFeatureFlag,
): IEnabledPerIssuerFeatureFlagData => {
  const flagValueFromLaunchDarkly = useJsonLaunchDarklyFlag(feature);
  const memoizedEmptyArray = useMemo(() => [], []);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const enabledIssuersRaw = flagValueFromLaunchDarkly?.availableToIssuers as
    | string[]
    | undefined;
  const enabledIssuers = enabledIssuersRaw || memoizedEmptyArray;
  const data = useMemo(() => ({ enabledIssuers }), [enabledIssuers]);
  return { data };
};

export const useIsFeatureFlagEnabledForIssuer = (
  feature: EnabledPerIssuerFeatureFlag,
) => {
  const {
    data: { enabledIssuers },
  } = useFeatureEnabledIssuers_internalDontUse(feature);
  const issuerShortName = useGetIssuerShortname() ?? '';
  return enabledIssuers.includes(issuerShortName);
};
