import type { HtmlFromDotcms } from './converters-from-dotcms';
import {
  convertDotcmsResponseToRecord,
  wrapDotcmsHtml,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type InactivityNoticeModal = IBaseObjectData & {
  headline: HtmlFromDotcms;
  content: HtmlFromDotcms;
  buttonSign: HtmlFromDotcms;
};

export const InactivityNoticeModalGqlHelper: IGqlHelper<InactivityNoticeModal> =
  {
    contentTypeName: 'InactivityNoticeModal',
    gqlFieldsQuery: `
      headline
      content
      buttonSign
    `,
    processGqlData: (gqlResponse: unknown) => {
      if (!gqlResponse) {
        return undefined;
      }

      const { headline, content, buttonSign } =
        convertDotcmsResponseToRecord(gqlResponse);

      return {
        headline: wrapDotcmsHtml(headline),
        content: wrapDotcmsHtml(content),
        buttonSign: wrapDotcmsHtml(buttonSign),
      };
    },
  };
