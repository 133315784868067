/* eslint-disable */
import { combineReducers } from 'redux';

import authReducer from './auth';
import userReducer from './user';
import uiReducer from './ui';

const rootReducer = combineReducers({
  authState: authReducer,
  userState: userReducer,
  uiState: uiReducer,
});

export default rootReducer;
