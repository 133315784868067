import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type IStockPriceLayoutData = IBaseObjectData & {
  timeWithTimezone: string | undefined;
  marketOpenText: string | undefined;
  marketCloseText: string | undefined;
};

export const StockPriceLayoutGqlHelper: IGqlHelper<IStockPriceLayoutData> = {
  contentTypeName: 'StockPriceLayout',
  gqlFieldsQuery: `
    timewithtimezone
    marketopentext
    marketclosetext
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { timewithtimezone, marketopentext, marketclosetext } =
      convertDotcmsResponseToRecord(gqlResponse);

    return {
      timeWithTimezone: convertDotcmsString(timewithtimezone),
      marketOpenText: convertDotcmsString(marketopentext),
      marketCloseText: convertDotcmsString(marketclosetext),
    };
  },
};
