import { useLocation } from 'react-router-dom';
import UrlSearchParamKey from '../../../utils/url-search-params/url-search-params';
import { useGetIssuerShortname } from '../../../hooks/use-get-issuer-shortname';
import {
  useGetCurrentPageLoginRequirement,
  useGetPageLoginRequirementResult,
} from '../../../app/pages-requiring-login';
import { useIsInitialDataLoaded } from '../../initial-page-load-handler/initial-page-load-handler';
import { useGetPageConditionRequirementResult } from '../../../app/pages-requiring-condition';
import { useGetCurrentPage } from '../../../app/get-current-page';
import { useGetRouteToIssuerPage } from '../../../app/get-route-to-page';
import { Page } from '../../../app/page-url-path';

interface RedirectToLoginIfNotAuthedData {
  loginUrlToRedirectTo: string | undefined;
  shouldRedirectToLogin: boolean;
  /** This can be true even if shouldRedirectToLogin is false, e.g. if
   * a non-admin user is logged in and tries to visit an admin page.
   */
  currentPageUnavailable: boolean;
  homepageRoute: string;
}

const useRedirectIfPageNotAvailable = (): RedirectToLoginIfNotAuthedData => {
  const location = useLocation();
  const issuer = useGetIssuerShortname();
  const initialDataIsLoaded = useIsInitialDataLoaded();
  const pageLoginRequirement = useGetCurrentPageLoginRequirement();
  const currentPage = useGetCurrentPage();
  const redirectToLoginPageRequired =
    !useGetPageLoginRequirementResult(pageLoginRequirement) &&
    // Login page never requires login redirect. (We have had a few bugs relating
    // to redirect loops around the login page. This makes sure that never
    // happens):
    currentPage !== Page.LOGIN_PAGE;

  const currentPageRequirementsMet =
    useGetPageConditionRequirementResult(currentPage) ||
    // Login page never requires login redirect. (We have had a few bugs relating
    // to redirect loops around the login page. This makes sure that never
    // happens):
    currentPage === Page.LOGIN_PAGE;
  const currentPageUnavailable =
    redirectToLoginPageRequired || !currentPageRequirementsMet;
  const homepageRoute = useGetRouteToIssuerPage(Page.HOMEPAGE);

  const postLoginRedirectDestination = encodeURIComponent(
    location.pathname + location.search + location.hash,
  );

  const loginUrlToRedirectTo = issuer
    ? `/${issuer}/log-in?${UrlSearchParamKey.REDIRECT_TO}=${postLoginRedirectDestination}`
    : undefined;

  if (!initialDataIsLoaded) {
    // We can't tell yet if the user is logged in, so should
    // not redirect.
    return {
      shouldRedirectToLogin: false,
      loginUrlToRedirectTo,
      currentPageUnavailable: false,
      homepageRoute,
    };
  }

  if (!redirectToLoginPageRequired) {
    return {
      shouldRedirectToLogin: false,
      loginUrlToRedirectTo,
      currentPageUnavailable,
      homepageRoute,
    };
  }

  return {
    shouldRedirectToLogin: true,
    loginUrlToRedirectTo,
    currentPageUnavailable,
    homepageRoute,
  };
};

export default useRedirectIfPageNotAvailable;
