export enum BREAKPOINT {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  TABLET_MINI = 'TABLET_MINI',
  MOBILE = 'MOBILE',
}

export const BREAKPOINT_BOUNDARIES: {
  [breakpoint in BREAKPOINT]: number;
} = {
  [BREAKPOINT.DESKTOP]: 1080,
  [BREAKPOINT.TABLET]: 840,
  [BREAKPOINT.TABLET_MINI]: 600,
  [BREAKPOINT.MOBILE]: 0,
};
