import { useSelector } from 'react-redux';
import { authSelector, userSelector } from '../../selectors';
import usePlanIsEsppOnly from './use-plan-is-espp-only';

const useIsEsppOnly = () => {
  const { accessCashlessParticipation, terminated } = useSelector(userSelector);
  const { isAuthenticated } = useSelector(authSelector);
  const planIsEsppOnly = usePlanIsEsppOnly();
  if (planIsEsppOnly) {
    return true;
  }
  const userIsEsppOnly =
    isAuthenticated && (terminated ? false : !accessCashlessParticipation);
  if (userIsEsppOnly) {
    return true;
  }

  // Otherwise, it's not ESPP only:
  return false;
};

export default useIsEsppOnly;
