import {
  convertDotcmsString,
  convertDotcmsResponseToRecord,
} from './converters-from-dotcms';
import type { IGqlHelper } from './gql-helper';

export type IBaseObjectData = {
  identifier?: string;
  contentType?: string;
};

export const BaseObjectGqlHelper: IGqlHelper<IBaseObjectData> = {
  contentTypeName: null,
  gqlFieldsQuery: `  
    identifier
    contentType
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { identifier, contentType } =
      convertDotcmsResponseToRecord(gqlResponse);

    return {
      identifier: convertDotcmsString(identifier),
      contentType: convertDotcmsString(contentType),
    };
  },
};
