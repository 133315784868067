import { convertDotcmsResponseToRecord } from '../converters-from-dotcms';
import type { IBaseObjectData } from '../dotcms-base-object';
import { buildGqlFieldsQuery } from '../gql-builders';
import type { IGqlHelper } from '../gql-helper';
import type { IDialogData } from '../dotcms-dialog';
import { DialogGqlHelper } from '../dotcms-dialog';

type ISessionTimeoutPopupData = IBaseObjectData & {
  sessionTimeoutWarningPopup?: IDialogData;
  sessionTimeoutPopup?: IDialogData;
};

export const SessionTimeoutPopupGqlHelper: IGqlHelper<ISessionTimeoutPopupData> =
  {
    contentTypeName: 'SessionTimeoutPopups',
    gqlFieldsQuery: `
        sessionTimeoutWarningPopup {${buildGqlFieldsQuery(DialogGqlHelper)}}
        sessionTimeoutPopup {${buildGqlFieldsQuery(DialogGqlHelper)}}
    `,
    processGqlData: (gqlResponse: unknown) => {
      if (!gqlResponse) {
        return undefined;
      }

      const { sessionTimeoutWarningPopup, sessionTimeoutPopup } =
        convertDotcmsResponseToRecord(gqlResponse);

      return {
        sessionTimeoutWarningPopup: DialogGqlHelper.processGqlData(
          sessionTimeoutWarningPopup,
        ),
        sessionTimeoutPopup:
          DialogGqlHelper.processGqlData(sessionTimeoutPopup),
      };
    },
  };
