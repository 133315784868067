import { Box, styled } from '@mui/material';
import {
  GRID_CONFIGS_FOR_BREAKPOINTS,
  GRID_MARGIN_PIXELS,
  convertCssPixelSizeToNumber,
} from '../../utils/gridHelper';

const DESKTOP_GUTTER_PIXEL_WIDTH = convertCssPixelSizeToNumber(
  GRID_CONFIGS_FOR_BREAKPOINTS.DESKTOP.spacing,
);

const TOTAL_PIXELS_OCCUPIED_BY_GUTTERS_ON_DESKTOP =
  DESKTOP_GUTTER_PIXEL_WIDTH *
    GRID_CONFIGS_FOR_BREAKPOINTS.DESKTOP.columnCount -
  1;

const TOTAL_PIXELS_OCCUPIED_BY_MARGINS_ON_DESKTOP = GRID_MARGIN_PIXELS * 2;

const PIXELS_OCCUPIED_BY_MARGINS_AND_GUTTERS_ON_DESKTOP =
  TOTAL_PIXELS_OCCUPIED_BY_GUTTERS_ON_DESKTOP +
  TOTAL_PIXELS_OCCUPIED_BY_MARGINS_ON_DESKTOP;

const SIZE_OF_ONE_COLUMN_ON_DESKTOP = `calc(calc(1/${GRID_CONFIGS_FOR_BREAKPOINTS.DESKTOP.columnCount}) * calc(min(100%, 1280px) - ${PIXELS_OCCUPIED_BY_MARGINS_AND_GUTTERS_ON_DESKTOP}px))`;
const WIDTH_OF_ONE_MARGIN_PLUS_ONE_GUTTER_PLUS_ONE_COLUMN = `calc(${
  GRID_MARGIN_PIXELS + DESKTOP_GUTTER_PIXEL_WIDTH
}px + ${SIZE_OF_ONE_COLUMN_ON_DESKTOP})`;
const EXTRA_MARGIN_TO_KEEP_THINGS_NARROWER_THAN_1280PX =
  'max(0px, calc(calc(100% - 1280px) / 2))';
const TOTAL_SIDE_PADDING = `calc(${WIDTH_OF_ONE_MARGIN_PLUS_ONE_GUTTER_PLUS_ONE_COLUMN} + ${EXTRA_MARGIN_TO_KEEP_THINGS_NARROWER_THAN_1280PX})`;

const FullPageWidthWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '0 24px',
  [theme.breakpoints.up('tabletMini')]: {
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  [theme.breakpoints.up('desktop')]: {
    // This uses padding to satisfy 2 conditions:
    // 1. If the screen size is 1280px or less, the padding will be the width
    // of (1 margin + 1 gutter + 1 column).
    // 2. If the screen size is greater than 1280px, we also add some extra padding
    // so the width of 12 columns + margins + gutters does not exceed 1280px.
    paddingLeft: TOTAL_SIDE_PADDING,
    paddingRight: TOTAL_SIDE_PADDING,
  },
}));

export default FullPageWidthWrapper;
