import type { Interpolation, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type React from 'react';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { Theme as MuiTheme } from '@mui/material';
import { hexToHSL } from '../../utils/hexTohsl';
import { changeLightnessHLS } from '../../utils/changeLightnessHLS';
import type { ITheme, ThemeColor } from '../../styles/theme';
import type { DataElementId } from '../../../constants/data-element-tracking-ids';

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GHOST = 'ghost',
  FLAT = 'flat',
  PRIMARY_INVERTED = 'primary_inverted',
}

export type ButtonSize = 'default' | 'large';

export interface IButtonProps {
  variant: ButtonVariant;
  color?: ThemeColor;
  theme: ITheme;
  muiTheme?: MuiTheme;
  size: ButtonSize;
  icon?: FontAwesomeIconProps;
  additionalStyles?: Interpolation<Theme>;
  type: NonNullable<React.ButtonHTMLAttributes<HTMLButtonElement>['type']>;
  dataElementId: DataElementId;
}

// A bug in iOS < 16 makes 'unset: all' not work if you try setting
// properties afterwards which have Webkit equivalent properties.
// https://stackoverflow.com/q/56248747/5602521
// The solution is to specify all webkit properties in addition
// to the regular property names

export const getButtonStyles = ({
  variant,
  color,
  theme,
  size,
}: Pick<IButtonProps, 'variant' | 'theme' | 'size' | 'color'>) => {
  const { colors } = theme;
  const primaryColor = color ?? colors.primary;
  // "active" color is 20% of primary color.
  const activeColor = changeLightnessHLS(hexToHSL(primaryColor), -20);
  const styles: Interpolation<Theme> = {
    [ButtonVariant.PRIMARY]: `
              background-color: ${primaryColor};
              color: ${colors.grayscale.white};
              /*
                // A bug in iOS < 16 makes 'unset: all' not work if you try setting
                // properties afterwards which have Webkit equivalent properties.
                // https://stackoverflow.com/q/56248747/5602521
                // The solution is to specify all webkit properties in addition
                // to the regular property names
              */
              -webkit-text-fill-color: ${colors.grayscale.white};
              @media (hover: hover) {
                &:hover {
                    background-color: transparent;
                    color: ${primaryColor};
                    -webkit-text-fill-color: ${primaryColor};
                }
              }
              &:active {
                  background-color: ${activeColor};
                  border: 2px solid ${activeColor};
                  color: ${colors.grayscale.white};
                  -webkit-text-fill-color: ${colors.grayscale.white};
              }
              &:disabled, &[aria-disabled = "true"] {
                  background-color: ${colors.grayscale.inactiveGrey2};
                  border: 2px solid ${colors.grayscale.inactiveGrey2};
                  color: ${colors.grayscale.inactiveGrey1};
                  -webkit-text-fill-color: ${colors.grayscale.inactiveGrey1};
                  pointer-events: none;
              }
          `,
    [ButtonVariant.SECONDARY]: `
              background-color: transparent;
              border: 2px solid ${primaryColor};
              color: ${primaryColor}; 
              -webkit-text-fill-color: ${primaryColor};
              @media (hover: hover) {
                 &:hover {
                  background-color: ${primaryColor};
                  color: ${colors.grayscale.white};
                  -webkit-text-fill-color: ${colors.grayscale.white};
                }
              }
              &:active {
                  background-color: ${activeColor};
                  border: 2px solid ${activeColor};
                  color: ${colors.grayscale.white};
                  -webkit-text-fill-color: ${colors.grayscale.white};
              }
              &:disabled, &[aria-disabled = "true"] {
                  background-color: ${colors.grayscale.white};
                  border: 2px solid ${colors.grayscale.inactiveGrey1};
                  color: ${colors.grayscale.inactiveGrey1};
                  -webkit-text-fill-color: ${colors.grayscale.inactiveGrey1};
              }
          `,
    [ButtonVariant.TERTIARY]: `
              background-color: transparent;
              color: ${primaryColor};
              -webkit-text-fill-color: ${primaryColor};
              border: 2px solid transparent;
              @media (hover: hover) {
                &:hover {
                    background-color: ${colors.grayscale.inactiveGrey2};
                }
              }
              &:active {
                color: ${activeColor};
                -webkit-text-fill-color: ${activeColor};
              }
              &:disabled, &[aria-disabled = "true"] {
                color: ${colors.grayscale.inactiveGrey1};
                -webkit-text-fill-color: ${colors.grayscale.inactiveGrey1};
              }
          `,
    [ButtonVariant.GHOST]: `
              background-color: transparent;
              border: 2px solid ${colors.grayscale.white};
              color: ${colors.grayscale.white};
              -webkit-text-fill-color: ${colors.grayscale.white};
              @media (hover: hover) {
                &:hover {
                    background-color: ${colors.grayscale.white};
                    border: 2px solid ${colors.grayscale.white};
                    color: ${primaryColor};
                    -webkit-text-fill-color: ${primaryColor};
                }
              }
              &:disabled, &[aria-disabled = "true"] {
                  color: ${colors.grayscale.inactiveGrey1};
                  -webkit-text-fill-color: ${colors.grayscale.inactiveGrey1};
                  border: 2px solid ${colors.grayscale.inactiveGrey1};
              }
          `,
    [ButtonVariant.FLAT]: `
              background-color: transparent;
              color: ${colors.secondary};
              -webkit-text-fill-color: ${colors.secondary};
              border: 2px solid transparent;
              text-decoration: underline;
              -webkit-text-decoration-line: underline;
              font-weight: 500;
              @media (hover: hover) {
                &:hover {
                    background-color: ${colors.grayscale.inactiveGrey2};
                }
              }
              &:disabled, &[aria-disabled = "true"] {
                  color: ${colors.grayscale.inactiveGrey1};
                  -webkit-text-fill-color: ${colors.grayscale.inactiveGrey1};
              }
          `,
    [ButtonVariant.PRIMARY_INVERTED]: `
              background-color: ${colors.grayscale.white};
              color: ${primaryColor};
              -webkit-text-fill-color: ${primaryColor};
              @media (hover: hover) {
                &:hover {
                    background-color: transparent;
                    border: 2px solid ${colors.grayscale.white};
                    color: ${colors.grayscale.white};
                    -webkit-text-fill-color: ${colors.grayscale.white};
                }
              }
              &:active {
                  background-color: ${activeColor};
                  border: 2px solid ${activeColor};
                  color: ${colors.grayscale.white};
                  -webkit-text-fill-color: ${colors.grayscale.white};
              }
              &:disabled, &[aria-disabled = "true"] {
                  background-color: ${colors.grayscale.inactiveGrey2};
                  border: 2px solid ${colors.grayscale.inactiveGrey2};
                  color: ${colors.grayscale.inactiveGrey1};
                  -webkit-text-fill-color: ${colors.grayscale.inactiveGrey1};
              }
          `,
  };
  const sizeStyles = {
    default: `
              font-size: 14px;
          `,
    large: `
              font-size: 16px;
          `,
  };
  return css`
    font-family: 'Barlow', sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    text-align: center;
    border: 2px solid ${primaryColor};
    border-radius: 8px;
    font-weight: bold;
    transition: 0.1s;
    ${styles[variant]}
    ${sizeStyles[size]}
  `;
};
