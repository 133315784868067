import { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { useLocalStorage } from '../../utils/storage/localstorage/use-local-storage';
import { LocalStorageKey } from '../../utils/storage/localstorage/localstorage';
import { useSetMultipleUrlSearchParams } from '../../utils/url-search-params/use-set-url-search-param';
import UrlSearchParamKey from '../../utils/url-search-params/url-search-params';
import { useNumericUrlSearchParam } from '../../utils/url-search-params/use-query-url-search-params';

// TODO: Config values
const MAX_RETRY_COUNT_FOR_PARTIAL_RESET = 3;
const MAX_RETRY_COUNT = 5;
const MIN_INTERVAL_BETWEEN_RETRIES_IN_MILLIS = 1000;

export const usePageRetryWithTimeInterval = () => {
  const {
    getValueFromLocalStorage,
    setValueToLocalStorage,
    deleteValueFromLocalStorage,
  } = useLocalStorage(LocalStorageKey.LAST_PAGE_RETRY_INFO);
  const { setUrlSearchParams } = useSetMultipleUrlSearchParams();
  const timestampFromUrl = useNumericUrlSearchParam(
    UrlSearchParamKey.LAST_RETRY_TIMESTAMP,
  );
  const retryNumberFromUrl = useNumericUrlSearchParam(
    UrlSearchParamKey.LAST_RETRY_NUMBER,
  );
  const [timestampForReset, setTimestampForReset] = useState<number | null>(
    null,
  );

  const getCurrentTimeInMs = () => DateTime.now().toMillis();

  const getPrevRetryInfo = useCallback(() => {
    const lastRetryInfoFromStorage = getValueFromLocalStorage();
    return (
      lastRetryInfoFromStorage ??
      (timestampFromUrl
        ? {
          lastRetryTimestamp: timestampFromUrl,
          lastRetryNumber: retryNumberFromUrl ?? 0,
        }
        : null)
    );
  }, [getValueFromLocalStorage, retryNumberFromUrl, timestampFromUrl]);

  const saveNewRetryInfo = useCallback(
    (lastRetryNumber: number | undefined) => {
      const timestamp = getCurrentTimeInMs();
      const retryNumber = (lastRetryNumber ?? 0) + 1;
      setValueToLocalStorage({
        lastRetryTimestamp: timestamp,
        lastRetryNumber: retryNumber,
      });
      const valueForUrlParams: Partial<Record<UrlSearchParamKey, string>> = {
        [UrlSearchParamKey.LAST_RETRY_TIMESTAMP]: timestamp.toString(),
        [UrlSearchParamKey.LAST_RETRY_NUMBER]: retryNumber.toString(),
      };
      setUrlSearchParams(valueForUrlParams);
    },
    [setValueToLocalStorage, setUrlSearchParams],
  );

  const resetRetryInfoIfNewPageWithoutRetriesOpen = useCallback(() => {
    // Reset retry count on new pages without errors
    if (!timestampFromUrl && !retryNumberFromUrl) {
      deleteValueFromLocalStorage();
    }
  }, [timestampFromUrl, retryNumberFromUrl, deleteValueFromLocalStorage]);

  // The auto-retry should save a last refresh timestamp as a URL param and in local storage,
  // and not refresh if either of those is too recent, to avoid DoSing ourselves.
  // (Local storage is nice because then this is shared across tabs,
  // but we can’t only use local storage because some people disable that in their browser,
  // so we need a URL param as a backup solution)
  const initiateRetry = useCallback(
    (partialRetryFunc: () => void): boolean => {
      if (timestampForReset) {
        return false;
      }

      let timeoutId: ReturnType<typeof setTimeout> | undefined;
      const lastRetryTimestamp = getPrevRetryInfo()?.lastRetryTimestamp ?? null;
      const nowInMs = getCurrentTimeInMs();
      const timeoutInMillis = lastRetryTimestamp
        ? MIN_INTERVAL_BETWEEN_RETRIES_IN_MILLIS -
          (nowInMs - lastRetryTimestamp)
        : 0;
      if (timeoutInMillis > 0) {
        timeoutId = setTimeout(
          () => initiateRetry(partialRetryFunc),
          timeoutInMillis,
        );
        return true;
      }

      clearTimeout(timeoutId);
      const lastRetryNumber = getPrevRetryInfo()?.lastRetryNumber ?? 0;
      if (lastRetryNumber >= MAX_RETRY_COUNT) {
        setTimestampForReset(lastRetryTimestamp);
        return false;
      }

      setTimestampForReset(null);
      saveNewRetryInfo(lastRetryNumber);
      if (lastRetryNumber >= MAX_RETRY_COUNT_FOR_PARTIAL_RESET) {
        window.location.reload();
      } else {
        partialRetryFunc();
      }
      return true;
    },
    [getPrevRetryInfo, saveNewRetryInfo, timestampForReset],
  );

  return { initiateRetry, resetRetryInfoIfNewPageWithoutRetriesOpen };
};
