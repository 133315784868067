import type { PropsWithChildren } from 'react';
import React, { useState, useMemo, useCallback } from 'react';
import type { IFooterData } from './footer-context';
import FooterContext from './footer-context';
import type { HtmlFromDotcms } from '../../../dotcms/dotcms-models/converters-from-dotcms';
import useFetchFooterSection from './hooks/use-footer/footer-helpers';

const FooterContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [footnotes, setFootnotes] = useState<HtmlFromDotcms[]>([]);
  const [isLightFooterMode, setIsLightFooterMode] = useState<
  boolean | undefined
  >(undefined);

  const onAddFootnote = useCallback(
    (footnote: HtmlFromDotcms) => {
      // eslint-disable-next-line no-underscore-dangle
      const footnoteHtml = footnote.__html;
      setFootnotes((prevFootnotes) => [
        ...prevFootnotes,
        // eslint-disable-next-line no-underscore-dangle
        ...(!prevFootnotes.find((x) => x.__html === footnoteHtml)
          ? [footnote]
          : []),
      ]);
      const cleanUpFootnoteForUnmount = () =>
        setFootnotes((prevFootnotes) =>
          // eslint-disable-next-line no-underscore-dangle
          prevFootnotes.filter((x) => x.__html !== footnoteHtml));
      return { cleanUpFootnoteForUnmount };
    },
    [setFootnotes],
  );

  const { data: footerDataFromDotcms, loading: footerDataLoading } =
    useFetchFooterSection();

  const footnoteContextValue = useMemo<IFooterData>(
    () => ({
      footnotes,
      setFootnote: onAddFootnote,
      isLightFooterMode,
      cmsData: footerDataFromDotcms,
      cmsLoading: footerDataLoading,
      setIsLightFooterMode,
    }),
    [
      footnotes,
      onAddFootnote,
      isLightFooterMode,
      footerDataFromDotcms,
      footerDataLoading,
    ],
  );

  return (
    <FooterContext.Provider value={footnoteContextValue}>
      {children}
    </FooterContext.Provider>
  );
};

export default FooterContextProvider;
