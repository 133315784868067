import { convertDotcmsResponseToRecord } from '../converters-from-dotcms';
import type { IBaseObjectData } from '../dotcms-base-object';
import type { IDialogData } from '../dotcms-dialog';
import { DialogGqlHelper } from '../dotcms-dialog';
import { buildGqlFieldsQuery } from '../gql-builders';
import type { IGqlHelper } from '../gql-helper';

type IReminderToEnrollPopupsData = IBaseObjectData & {
  popup?: IDialogData;
};

export const ReminderToEnrollPopupsGqlHelper: IGqlHelper<IReminderToEnrollPopupsData> =
  {
    contentTypeName: 'ReminderToEnrollPopups',
    gqlFieldsQuery: `
    popup {${buildGqlFieldsQuery(DialogGqlHelper)}}
  `,
    processGqlData: (gqlResponse: unknown) => {
      if (!gqlResponse) {
        return undefined;
      }

      const { popup } = convertDotcmsResponseToRecord(gqlResponse);

      return {
        popup: DialogGqlHelper.processGqlData(popup),
      };
    },
  };
