/** @jsxImportSource @emotion/react */
// see - https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=79%3A2079
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import React from 'react';
import type { SxProps, Theme } from '@mui/material';
import {
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  Box,
  useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import type { ITheme } from '../../styles/theme';
import Typography, { TypographyVariant } from '../Typography';
import type { IDialogButton } from './DialogButton';
import { DialogButton } from './DialogButton';
import { useSettingBasedOnBreakpoints } from '../../../hooks/use-setting-based-on-breakpoints';

interface IProps {
  id: string;
  open: boolean;
  onDialogClose?: () => void;

  headlineContent?: ReactNode;
  extraElement?: {
    element: ReactElement;
    position: 'top' | 'bottom';
  };
  primaryButton?: IDialogButton;
  secondaryButton?: IDialogButton;

  theme: ITheme;
  muiTheme: Theme;
  centeredContent?: boolean;
  additionalStyles?: SxProps<Theme>;
}

const Dialog: React.FC<PropsWithChildren<IProps>> = ({
  id,
  open,
  children,
  headlineContent,
  extraElement,
  primaryButton,
  secondaryButton,
  onDialogClose,
  theme,
  muiTheme,
  centeredContent = false,
  additionalStyles = {},
}) => {
  const { isMobile } = useSettingBasedOnBreakpoints(muiTheme);
  const isSmallMobile = useMediaQuery(muiTheme.breakpoints.down(350));
  const isLargeSize = !isMobile;

  const DIALOG_PADDING_PX = 4;
  const CLOSE_ICON_WIDTH_PX = 44;
  const GAP_BETWEEN_BLOCKS_PX = isLargeSize ? 16 : 14;
  const CONTENT_HORIZONTAL_PADDING_PX = 24;

  const DIALOG_TOP_PADDING_WITHOUT_CLOSE_ICON_PX = 40;
  const DIALOG_TOP_PADDING_PX =
    onDialogClose && isLargeSize
      ? CLOSE_ICON_WIDTH_PX + DIALOG_PADDING_PX
      : DIALOG_TOP_PADDING_WITHOUT_CLOSE_ICON_PX -
        (onDialogClose ? GAP_BETWEEN_BLOCKS_PX : 0);
  const DIALOG_BOTTOM_PADDING_PX =
    primaryButton || secondaryButton
      ? DIALOG_PADDING_PX
      : DIALOG_TOP_PADDING_WITHOUT_CLOSE_ICON_PX;

  const element = extraElement ? (
    <Box
      sx={{
        '>*': { width: '100%', maxHeight: '200px', objectFit: 'contain' },
      }}
    >
      {extraElement.element}
    </Box>
  ) : null;

  return (
    <DialogMui
      id={id}
      open={open}
      onClose={onDialogClose}
      maxWidth="tabletMini"
      sx={{
        '.MuiDialog-paper': {
          padding: `${DIALOG_TOP_PADDING_PX}px ${DIALOG_PADDING_PX}px ${DIALOG_BOTTOM_PADDING_PX}px ${DIALOG_PADDING_PX}px`,
          '>:not(:first-of-type)': {
            marginTop: `${GAP_BETWEEN_BLOCKS_PX}px`,
          },
        },
        ...additionalStyles,
      }}
    >
      {onDialogClose && (
        <Box
          onClick={onDialogClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: `${CLOSE_ICON_WIDTH_PX}px`,
            height: `${CLOSE_ICON_WIDTH_PX}px`,
            cursor: 'pointer',
            position: 'absolute',
            top: `${DIALOG_PADDING_PX}px`,
            right: `${DIALOG_PADDING_PX}px`,
          }}
        >
          <FontAwesomeIcon
            icon={faClose}
            color={theme.colors.grayscale.inactiveGrey1}
          />
        </Box>
      )}
      {extraElement?.position === 'top' && element}
      <DialogContent
        sx={{
          padding: `0 ${isLargeSize ? 60 : CONTENT_HORIZONTAL_PADDING_PX}px`,
          '>:not(:first-of-type)': {
            marginTop: `${GAP_BETWEEN_BLOCKS_PX}px`,
          },
        }}
      >
        <Box
          sx={{
            padding: '8px 0',
            '>:not(:first-of-type)': {
              marginTop: `${GAP_BETWEEN_BLOCKS_PX}px`,
            },
          }}
        >
          {!!headlineContent && (
            <Typography
              theme={theme}
              variant={
                isLargeSize
                  ? TypographyVariant.H3
                  : TypographyVariant.LARGE_BODY_BOLD
              }
              additionalStyles={{
                textAlign: centeredContent ? 'center' : undefined,
              }}
            >
              {headlineContent}
            </Typography>
          )}
          <Typography
            theme={theme}
            variant={TypographyVariant.MEDIUM_BODY_REGULAR}
          >
            {children}
          </Typography>
        </Box>
        {extraElement?.position === 'bottom' && element}
      </DialogContent>
      {(primaryButton || secondaryButton) && (
        <DialogActions
          sx={{
            padding: `${
              centeredContent || isSmallMobile ? 16 : 20
            }px ${CONTENT_HORIZONTAL_PADDING_PX}px`,
            display: 'flex',
            flexDirection:
              centeredContent || isSmallMobile ? 'column-reverse' : 'row',
            '>*': {
              justifyContent: 'center',
              width:
                (centeredContent && !isLargeSize) || isSmallMobile
                  ? '-webkit-fill-available'
                  : undefined,
            },
            '>:not(:first-of-type)': {
              marginBottom:
                centeredContent || isSmallMobile ? '20px' : undefined,
              marginLeft:
                centeredContent || isSmallMobile
                  ? 0
                  : `${CONTENT_HORIZONTAL_PADDING_PX}px`,
            },
          }}
        >
          <DialogButton button={secondaryButton} theme={theme} />
          <DialogButton button={primaryButton} isPrimaryButton theme={theme} />
        </DialogActions>
      )}
    </DialogMui>
  );
};

export default Dialog;
