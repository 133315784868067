import { BooleanFeatureFlag } from './booleanFlags';
import { useFeatureFlagEnabledBoolean } from './use-boolean-flag';

export const useAreFeatureFlagsLoading = () => {
  const flagsLoading = useFeatureFlagEnabledBoolean(
    BooleanFeatureFlag.LAUNCHDARKLY_FLAGS_LOADING,
  );

  return flagsLoading;
};
