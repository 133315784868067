import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { match } from 'ts-pattern';
import { convertChangesDuringOfferingFromApi } from './convert-changes-during-offering';
import { ASSETS_CDN_URL } from '../utils/constants';
import type {
  AgreementStatus,
  Calculator,
  TermsConditions,
  User,
  Company,
  Compensation,
  EffectiveDates,
  EmployeeResidence,
  EnrollMinMax,
  EtradeQuestions,
  IPdfAgreementStatus,
  IGenericPeriodInfo,
  IUserSelector,
  Name,
  ICashlessReserve,
  PotentialOfferingPeriodAction,
  ChangesDuringOfferingFromApi,
  IEnrollmentPeriodInfoFromApi,
  IOfferingPeriodInfo,
  IPurchasePeriodInfo,
  DocumentsData,
} from '../selectors/interfaces';
import { convertEnrollmentPeriodInfo } from '../selectors/interfaces';
import type { DeepWriteable } from '../utils/deep-writable-type';

interface IOfferingPeriodInfoFromApi {
  enrollmentEndDate: string | null;
  enrollmentStartDate: string | null;
  lastDayToMakeChanges: string | null;
  offeringPeriodEndDate: string | null;
  offeringPeriodStartDate: string | null;
  reset_change_window_end: string | null;
  reset_change_window_start: string | null;
  offering_period_id: number | null;
}

interface IPurchasePeriodInfoFromApi {
  purchaseDate: string | null;
  purchasePeriodEndDate: string | null;
  purchasePeriodStartDate: string | null;
  lastDayToWithdraw: string | null;
  offering_period_id: number | null;
}

const convertOfferingPeriodInfo = (
  period: IOfferingPeriodInfoFromApi,
): IOfferingPeriodInfo => ({
  offeringPeriodStartDate: period.offeringPeriodStartDate,
  offeringPeriodEndDate: period.offeringPeriodEndDate,
  resetChangeWindowStart: period.reset_change_window_start,
  resetChangeWindowEnd: period.reset_change_window_end,
  enrollmentStartDate: period.enrollmentStartDate,
  enrollmentEndDate: period.enrollmentEndDate,
  lastDayToMakeChanges: period.lastDayToMakeChanges,
  offeringPeriodId: period.offering_period_id,
});

const convertPurchasePeriodInfo = (
  period: IPurchasePeriodInfoFromApi,
  offeringPeriod: IOfferingPeriodInfoFromApi | null,
): IPurchasePeriodInfo => ({
  purchaseDate: period.purchaseDate,
  purchasePeriodStartDate: period.purchasePeriodStartDate,
  purchasePeriodEndDate: period.purchasePeriodEndDate,
  lastDayToMakeChanges: offeringPeriod?.lastDayToMakeChanges ?? null,
  lastDayToWithdraw: period.lastDayToWithdraw,
  offeringPeriodId: period.offering_period_id,
});

const initialState: IUserSelector = {
  userDataHasLoaded: false,
  accessCashlessParticipation: true,
  accessESPP: false,
  adminAccessOnly: false,
  cashlessEnhancement: 0,
  cashlessEnhancementPercent: 0,
  isEligibleForNextOfferingAfterWithdrawingFromFuture: false,
  futureChangePeriodType: null,
  calculator: {
    cashlessCapDollar: 10000,
    federalLimit: 25000,
    cashlessCapPercent: 7,
    discount: 0.15,
    frequency: 'weekly',
    lookback: true,
    perOfferingShareLimit: 1500,
    planLimitPercent: 0.1,
    cashlessEnhancement: 216,
    employerMaxSellPricePercent: 15,
    salaried: false,
    paycheckAmountInCurrencyHundredths: 0,
    scheduledWeeklyHours: 0,
  },
  company: {
    ticker: '',
    shortname: '',
    adminEmail: '',
    productName: '',
    contactEmail: '',
  },
  changesDuringOffering: {
    canMakeRolloverChange: false,
    cashlessChangeAllowed: true,
    changeDirection: 'both',
    changesDuringOffering: true,
    changesFrequencyAnyDirection: 2,
    changesFrequencyDown: 0,
    changesFrequencyUp: 0,
    remainingChangesAnyDirection: null,
    remainingChangesDown: null,
    remainingChangesUp: null,
    resumptionAllowed: false,
    resumptionCountsAsIncrease: false,
    suspensionAllowed: false,
    suspensionCountsAsDecrease: false,
    isInResetChangeWindow: false,
  },
  inTradeRestrictionWindow: false,
  // All compensation values should only be set by BE and not be updated
  compensation: {
    salaried: true, // salary or hourly
    rate: 100000, // hourly rate if non-salaried, yearly amount is salaried
    scheduledWeeklyHours: 40,
    cashlessEnhancementPercentageLimit: 6,
    frequency: 'bi-weekly',
  },
  contributionPercentageCurrent: undefined,
  pastContributionPercentage: undefined,
  declineCashlessParticipation: true,
  etradeQuestions: {
    question1: {
      question:
        'Are you a control person of a publicly traded company? (Director, Officer, or minimum 10% Stock Holder)',
      answer: {
        response: false,
        additionInfo: null,
      },
    },
    question2: {
      question:
        'Are you affiliated with, work with or work for a member firm of a Stock Exchange or FINRA?',
      answer: {
        response: false,
        additionInfo: null,
      },
    },
    question3: {
      question:
        'Are you a current or former Public Official or someone with a high-profile political role entrusted with a prominent public function?',
      answer: {
        response: false,
        additionInfo: null,
      },
    },
    question4: {
      question: 'Are you a United States Citizen?',
      answer: {
        response: 'yes',
        permanentResident: null,
        additionInfo: {
          country: null,
          birthCountry: null,
          visa: null,
          expirationDate: {
            month: null,
            day: null,
            year: null,
          },
        },
      },
    },
    question5: {
      question:
        'Is your total net worth (excluding your residence) greater than $5,000?',
      answer: {
        response: true,
        additionInfo: null,
      },
    },
  },
  effectiveDates: {
    enrollmentEndDate: null,
    enrollmentOpen: false,
    enrollmentStartDate: null,
    offerEndDate: null,
    offerStartDate: null,
    offerOpen: false,
    pastOfferEndDate: null,
  },
  eligibleCompensation: 1,
  enhancedOwnership: 0,
  enrollMinMax: {
    min: 1,
    max: 10,
  },
  enrollCashlessParticipationFromDB: true,
  enrollCashlessParticipation: true,
  enrolledFromReset: false,
  pastEnrollCashlessParticipation: false,
  pastEnrolled: false,
  employeeWorkCountry: '',
  employeeWorkState: '',
  hasTaxDocuments: false,
  hasProvidedUsSsn: false,
  hasProvidedUsTin: false,
  everBeenInPurchase: false,
  everHadCashlessGoingIntoPurchase: false,
  name: {
    first: '',
    last: '',
    email: '',
    login_email: '',
    company_provided_email: '',
    alternate_email_preferred: false,
    alternateEmailCanBeUsedForLogin: false,
  },
  special_user: false,
  displayResetPopup: false,
  // productName: `myEquity`,
  payrollContribution: 0,
  purchaseDate: null,
  // purchaseDate: 1617313842000,
  termsConditions: {
    esppAgreement: `${ASSETS_CDN_URL}/pavmed/CE_PDF.pdf`,
    enrollmentAgreement: `${ASSETS_CDN_URL}/pavmed/CE_PDF.pdf`,
    cashlessAgreement: `${ASSETS_CDN_URL}/pavmed/CE_PDF.pdf`,
    puertoRicoAgreement: `${ASSETS_CDN_URL}/pavmed/CE_PDF.pdf`,
  },
  agreementStatus: {
    enrollmentAgreement: true,
    empploymentAgreement: true,
    cashlessAgreement: true,
  },
  pdfAgreementStatus: {
    enrollmentAgreed: true,
    employeeAgreed: true,
    cashlessAgreed: true,
    puertoRicoAgreed: true,
  },
  user: {
    home: {
      homeAddress1: '',
      homeAddress2: null,
      homeCity: '',
      homeCountry: '',
      homeState: '',
      homeZip: '',
      homePhone: '',
    },
  },
  totalBuyingPower: 0,
  verifyEmail: false,
  emailDateVerified: '',
  employeeResidence: {
    livesInPR: false,
    worksInPR: false,
  },
  referralProgramEnabled: false,
  referralProgramCurrentlyActive: false,
  fullyExercisedPositions: null,
  id: 0,
  apexStatus: true,
  enrolled: false,
  enrolledInFuture: false,
  enrollmentRequiresVerifiedEmail: true,
  terminated: false,
  withdrawn: false,
  e_sign: '',
  withdrawnFromFuture: false,
  reenrollmentRequired: false,
  magicLinkRestricted: false,
  magicLoginEnrollmentRequiresVerification: false,
  // What to use along with birthday for veirfying ID.
  // E.g. tax_id (SSN) or employee_company_id
  verificationMethod: 'tax_id',
  cashlessReserve: {
    canAddCashlessReserve: false,
    cashlessReserveCeiling: null,
    cashlessReserveChangeDirection: 'no',
    usedCashlessReserve: false,
    cashlessCeilingNotEqualToCashlessMax: false,
  },
  completedFlag: false,
  accordionStateOpen: false,
  contributionPercentageFuture: undefined,
  enrollCashlessParticipationFuture: false,
  referralRewardInUserCurrencyWholeUnits: 0,
  suspended: false,
  externalEnrollmentPending: false,
  executive: false,
  defaultTalkToWealthManagerAnswer: false,
  personAssociatedWithGdprCountry: false,
  isAutoEnrolledIntoOp: false,
  isIssuerAdmin: false,
  isIssuerAdminAuthenticated: false,
  currentOpAllowedActions: [],
  futureOpAllowedActions: [],
  futureOpScheduledEnrollment: null,
  tradeRestrictionWindowStartDatetime: null,
  tradeRestrictionWindowEndDatetime: null,
  refinerSurveyResultsIframeUrl: null,
  documentsByLanguage: null,
  sellToCoverEnabled: false,
  sellToCoverTaxPercent: null,
  canSelectAutoSalePercent: false,
  autoSaleElectionPercent: null,
};

export interface IUserInfoFromAPI {
  accessCashlessParticipation: boolean;
  accessESPP: boolean;
  adminAccessOnly: boolean;
  accordionStateOpen: boolean;
  cashlessEnhancement: number;
  cashlessEnhancementPercent: number;
  calculator: Calculator;
  company: Company;
  changesDuringOffering: ChangesDuringOfferingFromApi;
  inTradeRestrictionWindow: boolean;
  compensation: Compensation;
  contributionPercentage: number;
  contributionPercentageFuture: number | null | undefined;
  contributionPercentageFromDB: number;
  pastContributionPercentage?: number;
  declineCashlessParticipation: boolean;
  etradeQuestions: EtradeQuestions;
  effectiveDates: EffectiveDates;
  employee_company_id?: string | null;
  period_info?: IGenericPeriodInfo<
  IEnrollmentPeriodInfoFromApi,
  IOfferingPeriodInfoFromApi,
  IPurchasePeriodInfoFromApi
  >;
  eligibleCompensation: number;
  enhancedOwnership: number;
  enrollMinMax: EnrollMinMax;
  enrollCashlessParticipationFromDB: boolean;
  enrollCashlessParticipationFuture: boolean;
  enrollCashlessParticipation: boolean;
  enrolledFromReset: boolean;
  pastEnrollCashlessParticipation: boolean;
  pastEnrolled: boolean;
  employeeWorkCountry: string;
  employeeWorkState: string;
  everBeenInPurchase: boolean;
  everHadCashlessGoingIntoPurchase: boolean;
  hasTaxDocuments: boolean;
  has_provided_us_ssn: boolean;
  has_provided_us_tin: boolean;
  referralProgramEnabled: boolean;
  referralRewardInUserCurrencyWholeUnits: number;
  name: Name;
  newUser: boolean;
  special_user: boolean;
  displayResetPopup: boolean;
  payrollContribution: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  purchaseDate?: any;
  termsConditions: TermsConditions;
  agreementStatus: AgreementStatus;
  user: User;
  totalBuyingPower: number;
  verifyEmail: boolean;
  emailDateVerified?: string;
  employeeResidence: EmployeeResidence;
  id: number;
  apexStatus: boolean;
  enrolled: boolean;
  enrolledInFuture: boolean;
  enrollmentRequiresVerifiedEmail: boolean;
  suspended: boolean;
  terminated: boolean;
  withdrawn: boolean;
  withdrawnFromFuture: boolean;
  reenrollmentRequired: boolean;
  magicLinkRestricted: boolean;
  magic_login_enrollment_requires_verification: boolean;
  verificationMethod: string;
  externalEnrollmentPending: boolean;
  executive: boolean;
  cashlessReserve: ICashlessReserve;
  e_sign: string;
  completedFlag?: boolean;
  userIsAnOrganization?: boolean;
  fullyExercisedPositions: boolean | null | undefined;
  defaultTalkToWealthManagerAnswer: boolean;
  selectedTalkToWealthManager?: boolean | null;
  sell_to_cover_enabled: boolean;
  sell_to_cover_tax_percent: number | null;
  can_select_auto_sale_percent: boolean;
  auto_sale_election_percent: number | null;
  shouldAskAboutWealthManager?: boolean;
  person_associated_with_gdpr_country: boolean;
  pdfAgreementStatus: IPdfAgreementStatus;
  successful_referral_reward: number;
  officialLanguage: string;
  is_auto_enrolled_into_op: boolean;
  is_issuer_admin: boolean;
  issuer_admin_authenticated: boolean;
  referral_program_active: boolean;
  current_op_allowed_actions: PotentialOfferingPeriodAction[];
  current_op_potentially_allowed_actions: PotentialOfferingPeriodAction[];
  future_op_allowed_actions: PotentialOfferingPeriodAction[];
  future_op_potentially_allowed_actions: PotentialOfferingPeriodAction[];
  future_op_scheduled_enrollment: null | {
    accepted_cashless: boolean;
    contribution_percentage: number;
    offering_period_id: number;
  };
  tradeRestrictionWindowStartDatetime: string;
  tradeRestrictionWindowEndDatetime: string;
  refiner_survey_results_iframe_url?: string | null;
  future_change_period_type: 'OFFERING' | 'PURCHASE' | null;
  is_eligible_for_next_offering_after_withdrawing_from_future: boolean;
  documents_by_lang: null | {
    [languageCode: string]: DeepWriteable<DocumentsData>;
  };
}

export interface IUserInformationFromApi {
  information: IUserInfoFromAPI;
  employee_id?: string;
  message?: string;
}

/* eslint-disable no-param-reassign */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedTalkToWealthManager: (
      state,
      { payload }: PayloadAction<boolean | null | undefined>,
    ) => {
      state.selectedTalkToWealthManager = payload;
    },
    setUserInformation: (
      state,
      { payload }: PayloadAction<IUserInfoFromAPI>,
    ) => {
      state.userDataHasLoaded = true;
      state.special_user = payload.special_user;
      state.user = payload.user;
      state.calculator = payload.calculator;
      state.accordionStateOpen = payload.accordionStateOpen;
      state.accessCashlessParticipation = payload.accessCashlessParticipation;
      state.accessESPP = payload.accessESPP;
      state.adminAccessOnly = payload.adminAccessOnly;
      state.enrollCashlessParticipation = payload.enrollCashlessParticipation;
      state.enrollCashlessParticipationFromDB =
        payload.enrollCashlessParticipation;
      state.pastEnrollCashlessParticipation =
        payload.pastEnrollCashlessParticipation;
      state.pastEnrolled = payload.pastEnrolled;
      state.employeeWorkCountry = payload.employeeWorkCountry;
      state.employeeWorkState = payload.employeeWorkState;
      state.enrolledFromReset = payload.enrolledFromReset;
      state.periodInfo =
        payload.period_info !== undefined
          ? {
            currentEnrollmentPeriod: payload.period_info
              .currentEnrollmentPeriod
              ? convertEnrollmentPeriodInfo(
                payload.period_info.currentEnrollmentPeriod,
              )
              : null,
            currentOfferingPeriod: payload.period_info.currentOfferingPeriod
              ? convertOfferingPeriodInfo(
                payload.period_info.currentOfferingPeriod,
              )
              : null,
            currentPurchasePeriod: payload.period_info.currentPurchasePeriod
              ? convertPurchasePeriodInfo(
                payload.period_info.currentPurchasePeriod,
                payload.period_info.currentOfferingPeriod,
              )
              : null,
            futureEnrollmentPeriod: convertEnrollmentPeriodInfo(
              payload.period_info.futureEnrollmentPeriod,
            ),
            futureOfferingPeriod: convertOfferingPeriodInfo(
              payload.period_info.futureOfferingPeriod,
            ),
            futurePurchasePeriod: convertPurchasePeriodInfo(
              payload.period_info.futurePurchasePeriod,
              payload.period_info.futureOfferingPeriod,
            ),
          }
          : undefined;
      state.everBeenInPurchase = payload.everBeenInPurchase;
      state.everHadCashlessGoingIntoPurchase =
        payload.everHadCashlessGoingIntoPurchase;
      state.hasTaxDocuments = payload.hasTaxDocuments;
      state.hasProvidedUsSsn = payload.has_provided_us_ssn;
      state.hasProvidedUsTin = payload.has_provided_us_tin;
      state.compensation = payload.compensation;
      state.company = payload.company;
      state.changesDuringOffering = convertChangesDuringOfferingFromApi(
        payload.changesDuringOffering,
      );
      state.inTradeRestrictionWindow = payload.inTradeRestrictionWindow;
      state.tradeRestrictionWindowStartDatetime =
        payload.tradeRestrictionWindowStartDatetime;
      state.tradeRestrictionWindowEndDatetime =
        payload.tradeRestrictionWindowEndDatetime;
      state.refinerSurveyResultsIframeUrl =
        payload.refiner_survey_results_iframe_url;
      state.effectiveDates = payload.effectiveDates;
      state.enrollMinMax = payload.enrollMinMax;
      state.name = payload.name;
      state.displayResetPopup = payload.displayResetPopup;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.purchaseDate = payload.purchaseDate;
      state.completedFlag = payload.completedFlag;
      state.termsConditions =
        payload.termsConditions !== null ? payload.termsConditions : undefined;
      state.id = payload.id;
      state.employeeCompanyId = payload.employee_company_id;
      state.special_user = false;
      state.verifyEmail = payload.verifyEmail;
      state.isAutoEnrolledIntoOp = payload.is_auto_enrolled_into_op;
      state.isIssuerAdmin = payload.is_issuer_admin;
      state.isIssuerAdminAuthenticated = payload.issuer_admin_authenticated;
      state.emailDateVerified = payload.emailDateVerified;
      // TODO: Never used and is not defined in IUserSelector
      // state.micrositeImages = payload.micrositeImages;
      state.withdrawn = payload.withdrawn;
      state.etradeQuestions = payload.etradeQuestions;
      state.employeeResidence = payload.employeeResidence;
      state.pdfAgreementStatus = payload.pdfAgreementStatus;
      state.enrolled = payload.enrolled;
      state.enrolledInFuture = payload.enrolledInFuture;
      state.enrollmentRequiresVerifiedEmail =
        payload.enrollmentRequiresVerifiedEmail;
      state.terminated = payload.terminated;
      state.futureChangePeriodType = payload.future_change_period_type;
      state.isEligibleForNextOfferingAfterWithdrawingFromFuture =
        payload.is_eligible_for_next_offering_after_withdrawing_from_future;
      state.withdrawnFromFuture = payload.withdrawnFromFuture;
      state.reenrollmentRequired = payload.reenrollmentRequired;
      state.magicLinkRestricted = payload.magicLinkRestricted;
      state.magicLoginEnrollmentRequiresVerification =
        payload.magic_login_enrollment_requires_verification;
      state.verificationMethod = payload.verificationMethod;
      state.personAssociatedWithGdprCountry =
        payload.person_associated_with_gdpr_country;
      state.referralProgramEnabled = payload.referralProgramEnabled;
      state.referralProgramCurrentlyActive = payload.referral_program_active;
      state.referralRewardInUserCurrencyWholeUnits =
        payload.successful_referral_reward;
      state.currentOpAllowedActions = payload.current_op_allowed_actions;
      state.currentOpPotentiallyAllowedActions =
        payload.current_op_potentially_allowed_actions;
      state.futureOpAllowedActions = payload.future_op_allowed_actions;
      state.futureOpPotentiallyAllowedActions =
        payload.future_op_potentially_allowed_actions;
      state.futureOpScheduledEnrollment =
        payload.future_op_scheduled_enrollment;

      state.pastContributionPercentage = payload.pastContributionPercentage;
      state.contributionPercentageCurrent = payload.contributionPercentage;
      state.contributionPercentageFuture = payload.contributionPercentageFuture;
      state.enrollCashlessParticipationFuture =
        payload.enrollCashlessParticipationFuture;
      state.apexStatus = payload.apexStatus;
      state.suspended = payload.suspended;
      state.externalEnrollmentPending = payload.externalEnrollmentPending;
      state.executive = payload.executive;
      state.cashlessReserve = payload.cashlessReserve ?? {};
      state.userIsAnOrganization = payload.userIsAnOrganization;
      state.fullyExercisedPositions = payload.fullyExercisedPositions;
      state.documentsByLanguage = payload.documents_by_lang;

      // If the server gave us a non-nullish value for selectedTalkToWealthManager
      // for this user, we should set the user's state to that value.
      // Otherwise, if shouldAskAboutWealthManager is true but the
      // selectedTalkToWealthManager value from the server is nullish,
      // we should default that value to false.
      // Otherwise, if shouldAskAboutWealthManager is false,
      // selectedTalkToWealthManager value should be null.
      const selectedTalkToWealthManagerIsSet =
        payload.selectedTalkToWealthManager !== null &&
        payload.selectedTalkToWealthManager !== undefined;
      const selectedTalkToWealthManager = match({
        selectedTalkToWealthManagerIsSet,
        shouldAskAboutWealthManager: payload.shouldAskAboutWealthManager,
        defaultTalkToWealthManagerAnswer:
          payload.defaultTalkToWealthManagerAnswer,
      })
        .with(
          { selectedTalkToWealthManagerIsSet: true },
          () => payload.selectedTalkToWealthManager,
        )
        .with(
          { shouldAskAboutWealthManager: true },
          () => payload.defaultTalkToWealthManagerAnswer,
        )
        .otherwise(() => null);
      state.selectedTalkToWealthManager = selectedTalkToWealthManager;
      state.shouldAskAboutWealthManager = payload.shouldAskAboutWealthManager;
      state.sellToCoverEnabled = payload.sell_to_cover_enabled;
      state.sellToCoverTaxPercent = payload.sell_to_cover_tax_percent;
      state.canSelectAutoSalePercent = payload.can_select_auto_sale_percent;
      state.autoSaleElectionPercent = payload.auto_sale_election_percent;
      state.defaultTalkToWealthManagerAnswer =
        payload.defaultTalkToWealthManagerAnswer;

      if (!payload.accessCashlessParticipation) {
        state.declineCashlessParticipation = true;
        // } else if (payload.newUser || payload.withdrawn) {
      } else if (payload.newUser) {
        state.declineCashlessParticipation = false;
        state.enrollCashlessParticipation = true;
      } else {
        state.declineCashlessParticipation =
          !payload.enrollCashlessParticipation;
      }
    },
    resetUserState: () => initialState,
  },
});
/* eslint-enable no-param-reassign */

export const {
  setSelectedTalkToWealthManager,
  setUserInformation,
  resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
