import type { JSONSerializableObject } from '../json-types';

// https://stackoverflow.com/a/52539264/5602521
const groupParamsByKey = (params: URLSearchParams) =>
  [...params.entries()].reduce<{ [key: string]: string | string[] }>(
    (acc, tuple) => {
      // getting the key and value from each tuple
      const [key, val] = tuple;
      if (Object.prototype.hasOwnProperty.call(acc, key)) {
        // This key has already had a value added to the result once, so
        // it should be converted to an array result if it's not already.
        const currentValForKey = acc[key];
        // if the current key is already an array, we'll add the value to it
        if (Array.isArray(currentValForKey)) {
          acc[key] = [...currentValForKey, val];
        } else {
          // if it's not an array, but contains a value, we'll convert it into an array
          // and add the current value to it
          acc[key] = [currentValForKey, val];
        }
      } else {
        // plain assignment if no special case is present
        acc[key] = val;
      }

      return acc;
    },
    {},
  );

export const urlSearchParamsToObject = (
  params: URLSearchParams,
): JSONSerializableObject => groupParamsByKey(params);
