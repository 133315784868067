import { useSelector } from 'react-redux';
import { usePurchasePeriodOpenInfo } from '../../../../../../hooks/use-get-periods-info/use-purchase-period';
import {
  useGetUserStatesInEnrollment,
  UserEnrolledState,
} from '../../../../../../hooks/use-get-user-states-in-enrollment';
import { userSelector } from '../../../../../../selectors';

export const useAutoSaleSectionAvailable = () => {
  const { isPurchaseOpen } = usePurchasePeriodOpenInfo();
  const userStates = useGetUserStatesInEnrollment();
  const { canSelectAutoSalePercent } = useSelector(userSelector);

  return (
    canSelectAutoSalePercent &&
    isPurchaseOpen &&
    userStates.userStatesInClosestPeriodOpOrPp.userEnrolledState ===
      UserEnrolledState.UserEnrolled
  );
};
