import * as amplitude from '@amplitude/analytics-browser';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';
import type { BaseEvent, ClientEvent, ClientEventTypes } from './client-events';

/** Don't directly call this. There's a hook that provides a lot of the
 * base event data; use that instead.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function logClientEvent_internalDontUse<
  ClientEventKey extends ClientEvent,
>(
  eventName: ClientEventKey,
  eventData: BaseEvent &
  ClientEventTypes[ClientEventKey] & { event_name: string },
) {
  try {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const dataForAmplitude = { ...eventData } as Record<string, unknown>;
    // Delete properties that would be duplicative to send
    // to Amplitude
    delete dataForAmplitude.event_name;
    delete dataForAmplitude.enrolled;
    delete dataForAmplitude.user_id;
    delete dataForAmplitude.device_id;
    delete dataForAmplitude.fullOccurredAtUrl;
    delete dataForAmplitude.occurredAtUrlPath;
    delete dataForAmplitude.eventUuid;
    delete dataForAmplitude.event_source;
    amplitude.logEvent(eventName, dataForAmplitude);
  } catch (error) {
    captureErrorInSentryWithCustomMessage(error, 'Failed to log event');
  }
}
