/** @jsxImportSource @emotion/react */
import React from 'react';
import type { Interpolation, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export interface IHtmlBox {
  html:
  | {
    __html: string;
  }
  | undefined;
  additionalStyles?: Interpolation<Theme>;
}

const HtmlBox: React.FC<IHtmlBox> = ({ html, additionalStyles = {} }) => {
  /* Styles to suppress global styles for some html elements
  that can be included in HTML, such as <p>. This component must inherit parent styles
  because it can be inside Typography, Link, or other base components. */
  const mainStyles = css({
    '*': {
      margin: 'inherit',
      fontFamily: 'inherit',
    },
  });

  if (!html) {
    return null;
  }

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={html} css={[mainStyles, additionalStyles]} />
  );
};

export default HtmlBox;
