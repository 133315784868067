import {
  convertDotcmsString,
  convertDotcmsResponseToRecord,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type ILinkData = IBaseObjectData & {
  text: string;
  url?: string;
};

export const LinkGqlHelper: IGqlHelper<ILinkData> = {
  contentTypeName: 'Links',
  gqlFieldsQuery: `
    linkText
    url
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { linkText, url } = convertDotcmsResponseToRecord(gqlResponse);

    return {
      text: convertDotcmsString(linkText) ?? '',
      url: convertDotcmsString(url),
    };
  },
};
