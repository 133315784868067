import { useSelector } from 'react-redux';
import { userSelector } from '../../selectors';
import { useIssuerIsWithSellToCover } from './use-issuer-is-with-sell-to-cover';

export const useIsSellToCoverEnabled = () => {
  const { sellToCoverEnabled } = useSelector(userSelector);
  const isIssuerWithSellToCover = useIssuerIsWithSellToCover();

  return isIssuerWithSellToCover && sellToCoverEnabled;
};
