import type { UserAuthenticationState } from './use-get-user-auth-state';
import { useGetUserAuthState } from './use-get-user-auth-state';
import { useGetUserFlowState } from './use-get-user-flow-state';
import type { UserFlowState } from './use-get-user-flow-state';
import {
  UserEnrollmentState,
  useGetUserEnrollmentState,
} from './use-get-user-enrollment-state';
import type { UserEnrolledState } from './use-get-user-enrolled-state';
import { useGetUserEnrolledState } from './use-get-user-enrolled-state';
import { useGetEnrollmentPlan } from '../use-get-enrollment-plan';
import { useGetPeriodsOpenInfo } from '../use-get-periods-info';
import type { IUserStateInPeriod } from './use-get-user-states-in-periods';
import useGetUserStatesInPeriods from './use-get-user-states-in-periods';

export interface IUserInPeriodStates {
  isScheduled: boolean;
  userStateInPeriodOpOrPp: IUserStateInPeriod;
  userEnrolledState?: UserEnrolledState;
  userEnrollmentState?: UserEnrollmentState;
}

export interface IUserStatesInEnrollment {
  userAuthState: UserAuthenticationState;
  userStatesInClosestPeriodOpOrPp: IUserInPeriodStates;
  userStatesInFuturePeriodOpOrPp: IUserInPeriodStates;
  userFlowState: {
    state: UserFlowState | undefined;
    userStatesInPeriodOpOrPp: IUserInPeriodStates;
  };
}

const useGetUserStatesInPeriodOpOrPp = (
  userAuthState: UserAuthenticationState,
  isEnrollmentOpen: boolean,
  userStateInPeriod: IUserStateInPeriod,
) => {
  const userEnrolledStateInPeriod = useGetUserEnrolledState(
    userAuthState,
    isEnrollmentOpen,
    userStateInPeriod,
  );

  const userEnrollmentStateInPeriod = useGetUserEnrollmentState(
    userAuthState,
    userEnrolledStateInPeriod,
    userStateInPeriod,
  );

  return {
    isScheduled: userStateInPeriod.isScheduled,
    userStateInPeriodOpOrPp: userStateInPeriod,
    userEnrolledState: userEnrolledStateInPeriod,
    userEnrollmentState: userEnrollmentStateInPeriod,
  };
};

/** This helper hook contains information about the user's enrollment states (current and future):
 * if the user is enrolled, what is the next available enrollment step for the user, etc. */
export default function useGetUserStatesInEnrollment(): IUserStatesInEnrollment {
  const enrollmentPlan = useGetEnrollmentPlan();
  const {
    isEnrollmentOpen,
    isEsppEnrollmentOpen,
    isCashlessEnrollmentOpen,
    isScheduledEnrollmentOpen,
    isEnrollmentOpenDuringOfferingPeriod: isUserFlowForUserStateInFuture,
  } = useGetPeriodsOpenInfo();
  const { userStateInClosestPeriodOpOrPp, userStateInFuturePeriodOpOrPp } =
    useGetUserStatesInPeriods();

  const userAuthState = useGetUserAuthState();

  const userStatesInClosestPeriodOpOrPp = useGetUserStatesInPeriodOpOrPp(
    userAuthState,
    isEnrollmentOpen,
    userStateInClosestPeriodOpOrPp,
  );
  const userStatesInFuturePeriodOpOrPp = useGetUserStatesInPeriodOpOrPp(
    userAuthState,
    isEnrollmentOpen,
    userStateInFuturePeriodOpOrPp,
  );

  const userStatesForUserFlow = isUserFlowForUserStateInFuture
    ? userStatesInFuturePeriodOpOrPp
    : userStatesInClosestPeriodOpOrPp;
  const userFlowState = useGetUserFlowState(
    enrollmentPlan,
    isEsppEnrollmentOpen,
    isCashlessEnrollmentOpen,
    isScheduledEnrollmentOpen,
    userStatesForUserFlow.userEnrollmentState ??
      UserEnrollmentState.UserNotEnrolled,
  );

  return {
    userAuthState,
    userStatesInClosestPeriodOpOrPp,
    userStatesInFuturePeriodOpOrPp,
    userFlowState: {
      state: userFlowState,
      userStatesInPeriodOpOrPp: userStatesForUserFlow,
    },
  };
}
