import { createContext } from 'react';
import type { INavigationData } from '../../../dotcms/dotcms-models/dotcms-navigation';

export interface INavigationFromDotcms {
  data: INavigationData | undefined;
  loading: boolean;
  error: unknown;
}

const NAVIGATION_DEFAULT_DATA: INavigationFromDotcms = {
  data: undefined,
  loading: false,
  error: null,
};

const NavigationMenuContext = createContext<INavigationFromDotcms>(
  NAVIGATION_DEFAULT_DATA,
);

export default NavigationMenuContext;
