import { gql } from 'graphql-request';
import type { IBaseObjectData } from './dotcms-base-object';
import { BaseObjectGqlHelper } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export const buildGqlFieldsQuery = <TExpectedType extends IBaseObjectData>(
  gqlHelper: IGqlHelper<TExpectedType>,
) => {
  if (!gqlHelper.contentTypeName) {
    return gqlHelper.gqlFieldsQuery;
  }

  return gql`
      ${BaseObjectGqlHelper.gqlFieldsQuery}
      ...on ${gqlHelper.contentTypeName} {
          ${gqlHelper.gqlFieldsQuery}
      }
      `;
};
