import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import type { IFetchResult } from '../../../../../hooks/API/use-fetch-from-api';
import { useFetchFromApi } from '../../../../../hooks/API/use-fetch-from-api';
import type { JSONSerializableObject } from '../../../../../utils/json-types';
import { authSelector, userSelector } from '../../../../../selectors';
import { GlobalLoadingStateOperation } from '../../../global-loading-context/global-loading-context';

export enum IssuerFeatureFromApi {
  ANALYTICS = 'ANALYTICS',
  EMPLOYEE_LOOKUP = 'EMPLOYEE_LOOKUP',
  PURCHASE_LOOKUP = 'PURCHASE_LOOKUP',
  DATA_EXCHANGE = 'DATA_EXCHANGE',
  DATA_EXCHANGE_UPLOAD = 'DATA_EXCHANGE_UPLOAD',
  SURVEY_RESULTS = 'SURVEY_RESULTS',
  PLAN_OVERVIEW = 'PLAN_OVERVIEW',
  TRADINGVIEW_EXCHANGE_RATES = 'TRADINGVIEW_EXCHANGE_RATES',
  CONTRIBUTION_OVERVIEW = 'CONTRIBUTION_OVERVIEW',
  PLAN_CONFIGURATION = 'PLAN_CONFIGURATION',
  STOCK_BASED_COMPENSATION_OVERVIEW = 'STOCK_BASED_COMPENSATION_OVERVIEW',
}

export type IIssuerFeatureResultFromApi = {
  allowed_features: ReadonlyArray<{
    feature_name: IssuerFeatureFromApi;
    is_beta: boolean;
  }>;
};

export const useFetchIssuerFeature =
  (): IFetchResult<IIssuerFeatureResultFromApi> => {
    const { isAuthenticated } = useSelector(authSelector);
    const { isIssuerAdmin } = useSelector(userSelector);
    const {
      data, error, loading, doRequest,
    } =
      useFetchFromApi<IIssuerFeatureResultFromApi>({
        relativeApiUrl: '/issuer/feature',
        method: 'GET',
        shouldExpectSuccessfulMessage: false,
        initialLoadingValue: false,
      });

    useLayoutEffect(() => {
      if (isAuthenticated && isIssuerAdmin) {
        doRequest(
          {
            globalLoadingState:
              GlobalLoadingStateOperation.APIV2_ISSUER_FEATURE,
          },
          (responseData: JSONSerializableObject) =>
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            responseData as unknown as IIssuerFeatureResultFromApi,
        );
      }
    }, [doRequest, isAuthenticated, isIssuerAdmin]);

    return { data, error, loading };
  };
