import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Duration } from 'luxon';
import { authSelector, uiSelector, userSelector } from '../../selectors';
import { useLocalStorage } from '../../utils/storage/localstorage/use-local-storage';
import { LocalStorageKey } from '../../utils/storage/localstorage/localstorage';
import { useStringUrlSearchParam } from '../../utils/url-search-params/use-query-url-search-params';
import UrlSearchParamKey from '../../utils/url-search-params/url-search-params';

export const isUsaCountry = (countryCodeToCheck: string) =>
  ['US', 'USA'].includes(countryCodeToCheck.toUpperCase());

/** Removes all non-alphabetic characters from the string.
 * We can safely assume that all country and state codes have only alphabetic chars.
 * This function must be declared in the same file as where it's used, for Snyk to
 * be able to detect it as a sanitizing function, based on Snyk's documentation
 * as of August 2024:
 * https://support.snyk.io/hc/en-us/articles/19794826547229-Snyk-Code-interfile-support-of-santization
 * */
const sanitizeUserLocation = (rawLocation: string | undefined) => {
  if (!rawLocation) {
    return rawLocation;
  }
  return rawLocation.replace(/\W/gi, '');
};

export const useGetUserLocation = (): {
  countryCode: string;
  stateCode: string;
  isUsa: boolean;
} => {
  const { detectedCountryCode, detectedStateCode } = useSelector(uiSelector);
  const { employeeWorkCountry, employeeWorkState } = useSelector(userSelector);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isAuthenticated } = useSelector(authSelector);

  const maybeCountryOverrideFromUrlParams = useStringUrlSearchParam(
    UrlSearchParamKey.COUNTRY,
  )?.toLocaleUpperCase();
  const maybeStateOverrideFromUrlParams = useStringUrlSearchParam(
    UrlSearchParamKey.STATE,
  )?.toLocaleUpperCase();

  const { getValueFromLocalStorage, setValueToLocalStorage } = useLocalStorage(
    LocalStorageKey.LOCATION_FROM_URL_PARAMS,
  );

  useEffect(() => {
    // Ignore state override if country is not set.
    if (maybeCountryOverrideFromUrlParams) {
      setValueToLocalStorage({
        countryCode: maybeCountryOverrideFromUrlParams,
        ...(maybeStateOverrideFromUrlParams
          ? { stateCode: maybeStateOverrideFromUrlParams }
          : {}),
      });
    }
  }, [
    maybeCountryOverrideFromUrlParams,
    maybeStateOverrideFromUrlParams,
    setValueToLocalStorage,
  ]);

  const { maybeCountryOverride, maybeStateOverride } = useMemo(() => {
    if (maybeCountryOverrideFromUrlParams) {
      // If this is set, use the URL values and ignore the ones from local storage.
      return {
        maybeCountryOverride: maybeCountryOverrideFromUrlParams,
        maybeStateOverride: maybeStateOverrideFromUrlParams ?? undefined,
      };
    }
    const valuesFromLocalStorage = getValueFromLocalStorage({
      maxAge: Duration.fromObject({ days: 1 }),
    });
    return {
      // We realistically don't actually need to sanitize these, except that Snyk flags
      // 170 false positive XSS vulnarabilities all over our codebase if we don't:
      maybeCountryOverride: sanitizeUserLocation(
        valuesFromLocalStorage?.countryCode,
      ),
      maybeStateOverride: sanitizeUserLocation(
        valuesFromLocalStorage?.stateCode,
      ),
    };
  }, [
    getValueFromLocalStorage,
    maybeCountryOverrideFromUrlParams,
    maybeStateOverrideFromUrlParams,
  ]);

  if (isAuthenticated) {
    // If the user is authed, the backend knows their jurisdictional info and
    // it should take precedence over any other way of determining jurisdiction.
    return {
      countryCode: employeeWorkCountry,
      stateCode: employeeWorkState,
      isUsa: isUsaCountry(employeeWorkCountry),
    };
  }
  if (maybeCountryOverride) {
    // Magic link override from URL params should take precedence over IP address
    // location detection.
    return {
      countryCode: maybeCountryOverride,
      stateCode: maybeStateOverride ?? '',
      isUsa: isUsaCountry(maybeCountryOverride),
    };
  }

  const detectedCountryCodeOrUsa = detectedCountryCode || 'USA';
  return {
    countryCode: detectedCountryCodeOrUsa,
    stateCode: detectedStateCode,
    isUsa: isUsaCountry(detectedCountryCodeOrUsa),
  };
};
