import type { PropsWithChildren } from 'react';
import React from 'react';
import useLocaleContextProvider from './hooks/use-locale-context-provider';
import { LocaleContext } from './locale-context';

export const LocaleContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const localeContext = useLocaleContextProvider();
  return (
    <LocaleContext.Provider value={localeContext}>
      {children}
    </LocaleContext.Provider>
  );
};
