import { useSelector } from 'react-redux';
import { useContext, useMemo } from 'react';
import { Page } from './page-url-path';
import type { IEnrollmentPlan } from '../hooks/use-get-enrollment-plan/use-get-enrollment-plan';
import {
  EnrollmentPlanCashless,
  EnrollmentPlanEspp,
  useGetEnrollmentPlan,
} from '../hooks/use-get-enrollment-plan/use-get-enrollment-plan';
import { UserFlowState } from '../hooks/use-get-user-states-in-enrollment/use-get-user-flow-state';
import { authSelector, userSelector } from '../selectors';
import { UserEnrollmentState } from '../hooks/use-get-user-states-in-enrollment/use-get-user-enrollment-state';
import { UserEnrolledState } from '../hooks/use-get-user-states-in-enrollment/use-get-user-enrolled-state';
import { UserAuthenticationState } from '../hooks/use-get-user-states-in-enrollment/use-get-user-auth-state';
import { PotentialOfferingPeriodAction } from '../selectors/interfaces';
import { useIsInitialDataLoaded } from '../components/initial-page-load-handler/initial-page-load-handler';
import type { IUserStatesInEnrollment } from '../hooks/use-get-user-states-in-enrollment/use-get-user-states-in-enrollment';
import useGetUserStatesInEnrollment from '../hooks/use-get-user-states-in-enrollment/use-get-user-states-in-enrollment';
import { useGetPeriodsOpenInfo } from '../hooks/use-get-periods-info';
import type { Issuer } from '../constants/issuers';
import { IssuerFeatureFromApi } from '../components/abc/admin-experience/admin-page-navigation/hooks/use-fetch-issuer-feature';
import { IssuerFeaturesContext } from '../components/abc/admin-experience/admin-page-navigation/contexts/issuer-features-context-provider';
import { isAdminOnlyUserAllowedToAccessPage } from './pages-requiring-login';
import { useAutoSaleSectionAvailable } from '../components/abc/account/account-page-manage-election/auto-sale-section-module/hooks/use-auto-sale-section-available';

export const ISSUER_FEATURES_PAGES: Readonly<{
  [key in Page]: IssuerFeatureFromApi | undefined;
}> = {
  [Page.ADMIN_ENROLLMENT_STATS]: IssuerFeatureFromApi.ANALYTICS,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: IssuerFeatureFromApi.CONTRIBUTION_OVERVIEW,
  [Page.ADMIN_DATA_EXCHANGE]: IssuerFeatureFromApi.DATA_EXCHANGE,
  [Page.ADMIN_DATA_UPLOAD]: IssuerFeatureFromApi.DATA_EXCHANGE_UPLOAD,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: IssuerFeatureFromApi.EMPLOYEE_LOOKUP,
  [Page.ADMIN_EXCHANGE_RATES]: IssuerFeatureFromApi.TRADINGVIEW_EXCHANGE_RATES,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]:
    IssuerFeatureFromApi.STOCK_BASED_COMPENSATION_OVERVIEW,
  [Page.ADMIN_SURVEY_RESULTS]: IssuerFeatureFromApi.SURVEY_RESULTS,
  [Page.ADMIN_PLAN_OVERVIEW]: IssuerFeatureFromApi.PLAN_OVERVIEW,
  [Page.ADMIN_PURCHASE_LOOKUP]: IssuerFeatureFromApi.PURCHASE_LOOKUP,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]:
    IssuerFeatureFromApi.PURCHASE_LOOKUP,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]:
    IssuerFeatureFromApi.PURCHASE_LOOKUP,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: IssuerFeatureFromApi.PURCHASE_LOOKUP,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]:
    IssuerFeatureFromApi.PLAN_CONFIGURATION,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]:
    IssuerFeatureFromApi.PLAN_CONFIGURATION,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]:
    IssuerFeatureFromApi.PLAN_CONFIGURATION,
  // Pages that don't correspond to an issuer feature:
  [Page.ABOUT_CASHLESS]: undefined,
  [Page.ACCOUNT_PAGE]: undefined,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: undefined,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: undefined,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: undefined,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: undefined,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: undefined,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: undefined,
  [Page.ADMIN_PAGE]: undefined,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: undefined,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: undefined,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: undefined,
  [Page.CALCULATOR]: undefined,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: undefined,
  [Page.DEFAULT]: undefined,
  [Page.EMAIL_VERIFICATION_COMPLETE]: undefined,
  [Page.ENROLLMENT_PAGE]: undefined,
  [Page.HOMEPAGE]: undefined,
  [Page.IMPORTANT_INFORMATION]: undefined,
  [Page.LOGIN_PAGE]: undefined,
  [Page.PRIVACY_POLICY]: undefined,
  [Page.REFERRAL]: undefined,
  [Page.RESOURCE_LIBRARY]: undefined,
  [Page.RESOURCE_LIBRARY_ID]: undefined,
  [Page.STANDALONE_BROKER_QUESTIONS]: undefined,
  [Page.TAX_INFORMATION]: undefined,
  [Page.TEMP_PDF_VIEWER_TEST]: undefined,
  [Page.TERM_OF_SERVICE]: undefined,
  [Page.UNSUBSCRIBE]: undefined,
  [Page.WEALTH_MANAGEMENT]: undefined,
};

type ParamsToCheckRequirements = {
  issuer?: Issuer;
  enrollmentPlan: IEnrollmentPlan;
  userStates: IUserStatesInEnrollment;
  everBeenInPurchase: boolean;
  referralProgramCurrentlyActive: boolean;
  enrolledInEsppCurrentOrFuture: boolean;
  enrolledInCashlessCurrentOrFuture: boolean;
  enrollmentOpen: boolean;
  isIssuerAdmin: boolean;
  isIssuerAdminAuthenticated: boolean;
  isAuthenticated: boolean;
  futureOpAllowedActions?: ReadonlyArray<PotentialOfferingPeriodAction>;
  currentOpAllowedActions?: ReadonlyArray<PotentialOfferingPeriodAction>;
  refinerSurveyResultsIframeUrl: string | null | undefined;
  magicLinkRestricted: boolean;
  isAutoSaleSectionAvailable: boolean;
};

// Excluding the login requirements, we expect the login to have already been verified before.
const PAGE_REQUIREMENTS: Readonly<{
  [key in Page]: null | ((params: ParamsToCheckRequirements) => boolean);
}> = {
  [Page.ABOUT_CASHLESS]: ({ enrollmentPlan }) =>
    enrollmentPlan.cashless !== EnrollmentPlanCashless.NONE,
  [Page.ADMIN_PAGE]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_ENROLLMENT_STATS]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_EXCHANGE_RATES]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_DATA_EXCHANGE]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_DATA_UPLOAD]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_PLAN_OVERVIEW]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_SURVEY_RESULTS]: ({
    isIssuerAdmin,
    refinerSurveyResultsIframeUrl,
  }) => isIssuerAdmin && !!refinerSurveyResultsIframeUrl,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_PURCHASE_LOOKUP]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: ({ isIssuerAdmin }) =>
    isIssuerAdmin,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]: ({ isIssuerAdmin }) =>
    isIssuerAdmin,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: ({ isIssuerAdmin }) => isIssuerAdmin,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: ({ isIssuerAdmin }) =>
    isIssuerAdmin,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]: ({ isIssuerAdmin }) =>
    isIssuerAdmin,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]: ({
    isIssuerAdmin,
  }) => isIssuerAdmin,
  [Page.ACCOUNT_PAGE]: ({ userStates, everBeenInPurchase }) =>
    userStates.userAuthState === UserAuthenticationState.UserAuthenticated &&
    (everBeenInPurchase ||
      userStates.userStatesInClosestPeriodOpOrPp.userEnrolledState !==
        UserEnrolledState.UserNotEnrolled ||
      userStates.userFlowState.state !==
        UserFlowState.NoEnrollmentActionsAvailableDueToIneligible),
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: null,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: null,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: ({
    userStates,
    currentOpAllowedActions,
    futureOpAllowedActions,
    isAutoSaleSectionAvailable,
  }) => {
    // The user can visit this page if at least one of "update", "suspend", or "withdraw"
    // is available for the current or future OP:
    const userHasAvailableChangeActions =
      [
        ...(currentOpAllowedActions ?? []),
        ...(futureOpAllowedActions ?? []),
      ].filter(
        (action) =>
          [
            PotentialOfferingPeriodAction.SuspendElectionAction,
            PotentialOfferingPeriodAction.ResumeElectionAction,
            PotentialOfferingPeriodAction.UpdateElectionAction,
            PotentialOfferingPeriodAction.WithdrawElectionAction,
            PotentialOfferingPeriodAction.UndoFutureWithdraw,
            PotentialOfferingPeriodAction.CancelScheduledEnrollmentAction,
          ].includes(action) ||
          (action === PotentialOfferingPeriodAction.ScheduledEnrollmentAction &&
            userStates.userFlowState.userStatesInPeriodOpOrPp
              .userEnrolledState === UserEnrolledState.ScheduledEnrollment) ||
          isAutoSaleSectionAvailable,
      ).length > 0;
    // An already-enrolled user may be marked as ineligible for ESPP if the user is
    // currently affected by a closed insider trading window of their employer.
    // The manage elections page should be hidden for those people.
    return (
      userHasAvailableChangeActions &&
      userStates.userStatesInClosestPeriodOpOrPp.userEnrollmentState !==
        UserEnrollmentState.UserIneligibleForEspp
    );
  },
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: ({ everBeenInPurchase }) =>
    everBeenInPurchase,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: null,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: null,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: null,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: null,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: ({ isAutoSaleSectionAvailable }) =>
    isAutoSaleSectionAvailable,
  [Page.CALCULATOR]: null,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: null,
  [Page.DEFAULT]: null,
  [Page.EMAIL_VERIFICATION_COMPLETE]: null,
  [Page.ENROLLMENT_PAGE]: null,
  [Page.HOMEPAGE]: null,
  [Page.IMPORTANT_INFORMATION]: null,
  // Even if someone is authenticated, they might be authenticated using a method
  // that restricts access to some pages. If they try to nagivate to a page that's
  // restricted for their login method, we still want to allow them to visit the
  // login page. (E.g.: authenticating via magic link does not grant access to
  // the account page. If someone logs in with a magic link, isAuthenticated will
  // be true, but if they try to navigate to the account page, we still redirect
  // them to the login page so they can do a full login and gain access to the account
  // page)
  [Page.LOGIN_PAGE]: ({
    isAuthenticated,
    magicLinkRestricted,
    isIssuerAdmin,
    isIssuerAdminAuthenticated,
  }) =>
    !isAuthenticated ||
    magicLinkRestricted ||
    (isIssuerAdmin && !isIssuerAdminAuthenticated),
  [Page.PRIVACY_POLICY]: null,
  [Page.REFERRAL]: ({
    referralProgramCurrentlyActive,
    enrollmentPlan,
    enrolledInCashlessCurrentOrFuture,
    enrolledInEsppCurrentOrFuture,
  }) => {
    if (!referralProgramCurrentlyActive) {
      return false;
    }
    if (enrollmentPlan.espp === EnrollmentPlanEspp.EXTERNAL_ESPP) {
      return enrolledInCashlessCurrentOrFuture;
    }
    return enrolledInEsppCurrentOrFuture;
  },
  [Page.STANDALONE_BROKER_QUESTIONS]: null,
  [Page.RESOURCE_LIBRARY]: null,
  [Page.RESOURCE_LIBRARY_ID]: null,
  [Page.TAX_INFORMATION]: null,
  [Page.TEMP_PDF_VIEWER_TEST]: null,
  [Page.TERM_OF_SERVICE]: null,
  [Page.UNSUBSCRIBE]: null,
  [Page.WEALTH_MANAGEMENT]: null,
};

/** True if a terminated user should be allowed to access this page */
const PAGE_FOR_TERMINATED_USER_REQUIREMENT: Readonly<{
  [key in Page]: boolean;
}> = {
  [Page.ABOUT_CASHLESS]: false,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: false,
  [Page.ADMIN_DATA_EXCHANGE]: false,
  [Page.ADMIN_DATA_UPLOAD]: false,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: false,
  [Page.ADMIN_PURCHASE_LOOKUP]: false,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: false,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]: false,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: false,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: false,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]: false,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]: false,
  [Page.ADMIN_ENROLLMENT_STATS]: false,
  [Page.ADMIN_EXCHANGE_RATES]: false,
  [Page.ADMIN_PLAN_OVERVIEW]: false,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]: false,
  [Page.ADMIN_SURVEY_RESULTS]: false,
  [Page.ADMIN_PAGE]: false,
  [Page.ACCOUNT_PAGE]: true,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: true,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: true,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: false,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: true,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: true,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: true,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: true,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: true,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: false,
  [Page.CALCULATOR]: false,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: false,
  [Page.DEFAULT]: false,
  [Page.EMAIL_VERIFICATION_COMPLETE]: false,
  [Page.ENROLLMENT_PAGE]: false,
  [Page.HOMEPAGE]: true,
  [Page.IMPORTANT_INFORMATION]: true,
  [Page.LOGIN_PAGE]: false,
  [Page.PRIVACY_POLICY]: true,
  [Page.REFERRAL]: false,
  [Page.RESOURCE_LIBRARY]: false,
  [Page.RESOURCE_LIBRARY_ID]: false,
  [Page.STANDALONE_BROKER_QUESTIONS]: true,
  [Page.TAX_INFORMATION]: true,
  [Page.TEMP_PDF_VIEWER_TEST]: false,
  [Page.TERM_OF_SERVICE]: true,
  [Page.UNSUBSCRIBE]: true,
  [Page.WEALTH_MANAGEMENT]: false,
};

export const useGetPageConditionRequirementResult = (
  page: Page | undefined,
) => {
  const {
    everBeenInPurchase,
    referralProgramCurrentlyActive,
    isIssuerAdmin,
    isIssuerAdminAuthenticated,
    terminated,
    currentOpAllowedActions,
    futureOpAllowedActions,
    refinerSurveyResultsIframeUrl,
    magicLinkRestricted,
    adminAccessOnly,
  } = useSelector(userSelector);
  const { isAuthenticated } = useSelector(authSelector);
  const { allowed_features: allowedFeatures, loaded: allowedFeaturesLoaded } =
    useContext(IssuerFeaturesContext);
  const isAutoSaleSectionAvailable = useAutoSaleSectionAvailable();
  const enrollmentPlan = useGetEnrollmentPlan();
  const { isEnrollmentOpen } = useGetPeriodsOpenInfo();
  const userStates = useGetUserStatesInEnrollment();
  const {
    userStatesInClosestPeriodOpOrPp: {
      userStateInPeriodOpOrPp: userStatesInClosestPeriod,
    },
    userStatesInFuturePeriodOpOrPp: {
      userStateInPeriodOpOrPp: userStatesInFuturePeriod,
    },
  } = userStates;
  const initialDataLoaded = useIsInitialDataLoaded();

  const isPageAvailableByConditions = useMemo(() => {
    if (!page || !initialDataLoaded) {
      return true;
    }

    const issuerFeatureForPage = ISSUER_FEATURES_PAGES[page];
    if (
      issuerFeatureForPage &&
      allowedFeaturesLoaded &&
      !allowedFeatures?.find((x) => x.feature_name === issuerFeatureForPage)
    ) {
      return false;
    }

    const pageRequirementCheck = PAGE_REQUIREMENTS[page];
    return pageRequirementCheck
      ? pageRequirementCheck({
        enrollmentPlan,
        userStates,
        everBeenInPurchase,
        referralProgramCurrentlyActive,
        enrolledInEsppCurrentOrFuture:
            userStatesInClosestPeriod.enrolledEspp ||
            userStatesInFuturePeriod.enrolledEspp,
        enrolledInCashlessCurrentOrFuture:
            userStatesInClosestPeriod.enrolledCashless ||
            userStatesInFuturePeriod.enrolledCashless ||
            false,
        enrollmentOpen: isEnrollmentOpen,
        isIssuerAdmin,
        isIssuerAdminAuthenticated,
        isAuthenticated,
        currentOpAllowedActions,
        futureOpAllowedActions,
        refinerSurveyResultsIframeUrl,
        magicLinkRestricted,
        isAutoSaleSectionAvailable,
      })
      : true;
  }, [
    page,
    initialDataLoaded,
    allowedFeaturesLoaded,
    allowedFeatures,
    enrollmentPlan,
    userStates,
    everBeenInPurchase,
    referralProgramCurrentlyActive,
    userStatesInClosestPeriod.enrolledEspp,
    userStatesInClosestPeriod.enrolledCashless,
    userStatesInFuturePeriod.enrolledEspp,
    userStatesInFuturePeriod.enrolledCashless,
    isEnrollmentOpen,
    isIssuerAdmin,
    isIssuerAdminAuthenticated,
    isAuthenticated,
    currentOpAllowedActions,
    futureOpAllowedActions,
    refinerSurveyResultsIframeUrl,
    magicLinkRestricted,
    isAutoSaleSectionAvailable,
  ]);

  if (!page) {
    return true;
  }

  if (adminAccessOnly) {
    const userForbiddenFromAccess = !isAdminOnlyUserAllowedToAccessPage(page);
    // If the user is forbidden, return false. But otherwise, we still need
    // to check the other requirements below, so don't return true yet.
    if (userForbiddenFromAccess) {
      return false;
    }
  }

  return (
    isPageAvailableByConditions &&
    (!terminated || PAGE_FOR_TERMINATED_USER_REQUIREMENT[page])
  );
};
