import { useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { BREAKPOINT } from '../component_library/utils/breakpoints';

export interface SettingBasedOnBreakpoints<T> {
  [BREAKPOINT.DESKTOP]: T;
  [BREAKPOINT.TABLET]?: T;
  [BREAKPOINT.TABLET_MINI]?: T;
  [BREAKPOINT.MOBILE]?: T;
}

export const useSettingBasedOnBreakpoints = (muiTheme: Theme) => {
  const isDesktop = useMediaQuery(muiTheme.breakpoints.only('desktop'));
  const isTablet = useMediaQuery(muiTheme.breakpoints.only('tablet'));
  const isTabletMini = useMediaQuery(muiTheme.breakpoints.only('tabletMini'));
  const isMobile = useMediaQuery(muiTheme.breakpoints.only('mobile'));

  function getSettingBasedOnBreakpoint<T>(
    setting: SettingBasedOnBreakpoints<T>,
  ): T {
    const valueForDesktop = setting[BREAKPOINT.DESKTOP];
    const valueForTablet = setting[BREAKPOINT.TABLET] ?? valueForDesktop;
    const valueForTabletMini =
      setting[BREAKPOINT.TABLET_MINI] ?? valueForTablet;
    const valueForMobile = setting[BREAKPOINT.MOBILE] ?? valueForTabletMini;

    if (isTablet) {
      return valueForTablet;
    }
    if (isTabletMini) {
      return valueForTabletMini;
    }
    if (isMobile) {
      return valueForMobile;
    }

    return valueForDesktop;
  }

  const breakpoint = getSettingBasedOnBreakpoint({
    [BREAKPOINT.DESKTOP]: BREAKPOINT.DESKTOP,
    [BREAKPOINT.TABLET]: BREAKPOINT.TABLET,
    [BREAKPOINT.TABLET_MINI]: BREAKPOINT.TABLET_MINI,
    [BREAKPOINT.MOBILE]: BREAKPOINT.MOBILE,
  });

  return {
    isDesktop,
    isTablet,
    isTabletMini,
    isMobile,
    breakpoint,
    getSettingBasedOnBreakpoint,
  };
};
