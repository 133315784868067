// Any HTML element that we want to track data about should
// have its own tag declared in here and be tagged with it
export enum DataElementId {
  ACCOUNT_PAGE_MENU_ITEM_UNDEFINED = 'ACCOUNT_PAGE_MENU_ITEM_UNDEFINED',
  ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_STATUS_SUMMARY = 'ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_STATUS_SUMMARY',
  ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_DOCUMENTS = 'ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_DOCUMENTS',
  ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_MANAGE_ELECTION = 'ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_MANAGE_ELECTION',
  ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_PURCHASE_DETAILS = 'ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_PURCHASE_DETAILS',
  ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_CONTACT_INFORMATION = 'ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_CONTACT_INFORMATION',
  ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_STOCK_INFORMATION = 'ACCOUNT_PAGE_MENU_ITEM_ACCOUNT_PAGE_STOCK_INFORMATION',
  ACCOUNT_PAGE_MENU_ITEM_ABOUT_CASHLESS = 'ACCOUNT_PAGE_MENU_ITEM_ABOUT_CASHLESS',
  ACCOUNT_PAGE_MENU_ITEM_REFERRAL = 'ACCOUNT_PAGE_MENU_ITEM_REFERRAL',
  ACCOUNT_PAGE_BUTTON_OPEN_WIRE_INSTRUCTIONS = 'ACCOUNT_PAGE_BUTTON_OPEN_WIRE_INSTRUCTIONS',
  ACCOUNT_PAGE_SECTION_TOOLTIP = 'ACCOUNT_PAGE_SECTION_TOOLTIP',
  ACCOUNT_PAGE_STATUS_AUTO_ENROLLED_ALERT = 'ACCOUNT_PAGE_STATUS_AUTO_ENROLLED_ALERT',
  ACCOUNT_PAGE_STATUS_WILL_BE_WITHDRAWN_ALERT = 'ACCOUNT_PAGE_STATUS_WILL_BE_WITHDRAWN_ALERT',
  ACCOUNT_PAGE_STATUS_WILL_BE_WITHDRAWN_UNDO_BUTTON = 'ACCOUNT_PAGE_STATUS_WILL_BE_WITHDRAWN_UNDO_BUTTON',
  ACCOUNT_PAGE_STATUS_SELL_TO_COVER_ALERT = 'ACCOUNT_PAGE_STATUS_SELL_TO_COVER_ALERT',
  ACCOUNT_PAGE_STATUS_VERIFY_EMAIL_ALERT = 'ACCOUNT_PAGE_STATUS_VERIFY_EMAIL_ALERT',
  ACCOUNT_PAGE_STATUS_ENROLLMENT_OPEN_ALERT = 'ACCOUNT_PAGE_STATUS_ENROLLMENT_OPEN_ALERT',
  ACCOUNT_PAGE_STATUS_ENROLLMENT_CLOSED_ALERT = 'ACCOUNT_PAGE_STATUS_ENROLLMENT_CLOSED_ALERT',
  ACCOUNT_PAGE_STATUS_PAST_PERIOD_ACCORDION = 'ACCOUNT_PAGE_STATUS_PAST_PERIOD_ACCORDION',
  ACCOUNT_PAGE_STATUS_CURRENT_PERIOD_ACCORDION = 'ACCOUNT_PAGE_STATUS_CURRENT_PERIOD_ACCORDION',
  ACCOUNT_PAGE_STATUS_UPCOMING_PERIOD_ACCORDION = 'ACCOUNT_PAGE_STATUS_UPCOMING_PERIOD_ACCORDION',
  ACCOUNT_PAGE_STATUS_USER_STATUS_TEXT = 'ACCOUNT_PAGE_STATUS_USER_STATUS_TEXT',
  ACCOUNT_PAGE_STATUS_CONTRIBUTION_PERCENTAGE_VALUE_TEXT = 'ACCOUNT_PAGE_STATUS_CONTRIBUTION_PERCENTAGE_VALUE_TEXT',
  ACCOUNT_PAGE_STATUS_CONTRIBUTION_PERCENTAGE_VALUE_LINK = 'ACCOUNT_PAGE_STATUS_CONTRIBUTION_PERCENTAGE_VALUE_LINK',
  ACCOUNT_PAGE_STATUS_CASHLESS_PARTICIPATION_VALUE_TEXT = 'ACCOUNT_PAGE_STATUS_CASHLESS_PARTICIPATION_VALUE_TEXT',
  ACCOUNT_PAGE_STATUS_CASHLESS_PARTICIPATION_VALUE_LINK = 'ACCOUNT_PAGE_STATUS_CASHLESS_PARTICIPATION_VALUE_LINK',
  ACCOUNT_PAGE_INFO_BLOCK_TOOLTIP = 'ACCOUNT_PAGE_INFO_BLOCK_TOOLTIP',
  ACCOUNT_PAGE_EXTERNAL_ACCOUNT = 'ACCOUNT_PAGE_EXTERNAL_ACCOUNT',
  ACCOUNT_PAGE_ENROLL = 'ACCOUNT_PAGE_ENROLL',
  ACCOUNT_PAGE_EMAIL_PREFERENCE_RADIO_BUTTON = 'ACCOUNT_PAGE_EMAIL_PREFERENCE_RADIO_BUTTON',
  ACCOUNT_PAGE_WEALTH_MANAGEMENT_LOGIN_EXTERNAL_LINK = 'ACCOUNT_PAGE_WEALTH_MANAGEMENT_LOGIN_EXTERNAL_LINK',
  ACCOUNT_PAGE_WITHDRAW = 'ACCOUNT_PAGE_WITHDRAW',
  ACCOUNT_PAGE_WITHDRAW_UNDO_BUTTON = 'ACCOUNT_PAGE_WITHDRAW_UNDO_BUTTON',
  ACCOUNT_PAGE_WITHDRAW_RADIO_OPTION = 'ACCOUNT_PAGE_WITHDRAW_RADIO_OPTION',
  ACCOUNT_PAGE_WITHDRAWAL_REASON_RADIO_OPTION = 'ACCOUNT_PAGE_WITHDRAWAL_REASON_RADIO_OPTION',
  ACCOUNT_PAGE_WITHDRAWAL_DIALOG_SKIP = 'ACCOUNT_PAGE_WITHDRAWAL_DIALOG_SKIP',
  ACCOUNT_PAGE_WITHDRAWAL_DIALOG_CONFIRM = 'ACCOUNT_PAGE_WITHDRAWAL_DIALOG_CONFIRM',
  ACCOUNT_PAGE_WITHDRAWAL_DIALOG_RETURN_TO_PROFILE = 'ACCOUNT_PAGE_WITHDRAWAL_DIALOG_RETURN_TO_PROFILE',
  ACCOUNT_PAGE_WITHDRAWAL_DIALOG_UNDO = 'ACCOUNT_PAGE_WITHDRAWAL_DIALOG_UNDO',
  ACCOUNT_PAGE_WITHDRAWAL_UNDO_DIALOG_STAY_ENROLLED = 'ACCOUNT_PAGE_WITHDRAWAL_UNDO_DIALOG_STAY_ENROLLED',
  ACCOUNT_PAGE_WITHDRAWAL_UNDO_DIALOG_KEEP_WITHDRAWAL = 'ACCOUNT_PAGE_WITHDRAWAL_UNDO_DIALOG_KEEP_WITHDRAWAL',
  ACCOUNT_PAGE_CHANGE_CONTRIBUTION = 'ACCOUNT_PAGE_CHANGE_CONTRIBUTION',
  ACCOUNT_PAGE_CHANGE_CONTRIBUTION_RADIO_OPTION = 'ACCOUNT_PAGE_CHANGE_CONTRIBUTION_RADIO_OPTION',
  ACCOUNT_PAGE_CANCEL_BUTTON = 'ACCOUNT_PAGE_CANCEL_BUTTON',
  ACCOUNT_PAGE_CANCEL_DIALOG_SKIP = 'ACCOUNT_PAGE_CANCEL_DIALOG_SKIP',
  ACCOUNT_PAGE_CANCEL_DIALOG_CONFIRM = 'ACCOUNT_PAGE_CANCEL_DIALOG_CONFIRM',
  ACCOUNT_PAGE_CANCEL_REASON_RADIO_OPTION = 'ACCOUNT_PAGE_CANCEL_REASON_RADIO_OPTION',
  ACCOUNT_PAGE_DOCUMENT_DOWNLOAD_LINK = 'ACCOUNT_PAGE_DOCUMENT_DOWNLOAD_LINK',
  ACCOUNT_PAGE_BOOST_AVAILABLE_ADD_CASHLESS = 'ACCOUNT_PAGE_BOOST_AVAILABLE_ADD_CASHLESS',
  ACCOUNT_PAGE_BOOST_AVAILABLE_CHANGE_CONTRIBUTION = 'ACCOUNT_PAGE_BOOST_AVAILABLE_CHANGE_CONTRIBUTION',
  ACCOUNT_PAGE_PURCHASE_SUMMARY_TOOLTIP = 'ACCOUNT_PAGE_PURCHASE_SUMMARY_TOOLTIP',
  ACCOUNT_PAGE_STOCK_PLAN_ADMIN_SITE = 'ACCOUNT_PAGE_STOCK_PLAN_ADMIN_SITE',
  ACCOUNT_PAGE_SUSPEND_CONTRIBUTION = 'ACCOUNT_PAGE_SUSPEND_CONTRIBUTION',
  ACCOUNT_PAGE_SUSPEND_DIALOG_CONFIRM = 'ACCOUNT_PAGE_SUSPEND_DIALOG_CONFIRM',
  ACCOUNT_PAGE_SUSPEND_DIALOG_CANCEL = 'ACCOUNT_PAGE_SUSPEND_DIALOG_CANCEL',
  ACCOUNT_PAGE_AUTO_SALE_CHANGE_BUTTON = 'ACCOUNT_PAGE_AUTO_SALE_CHANGE_BUTTON',
  ABOUT_CASHLESS_LINK_TO_CALCULATOR = 'ABOUT_CASHLESS_LINK_TO_CALCULATOR',
  ABOUT_CASHLESS_LINK_WHAT_IS_CASHLESS = 'ABOUT_CASHLESS_LINK_WHAT_IS_CASHLESS',
  ABOUT_CASHLESS_LINK_TO_LONG_TERM_CALCULATOR = 'ABOUT_CASHLESS_LINK_TO_LONG_TERM_CALCULATOR',
  ADMIN_PAGE_SIDEBAR = 'ADMIN_PAGE_SIDEBAR',
  ADMIN_PAGE_MENU_SELECT = 'ADMIN_PAGE_MENU_SELECT',
  ADMIN_BACK_TO_EMPLOYEE_EXPERIENCE_LINK = 'ADMIN_BACK_TO_EMPLOYEE_EXPERIENCE_LINK',
  ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME_PROCEED_BUTTON = 'ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME_PROCEED_BUTTON',
  DOWNLOAD_PAGE_BUTTON = 'DOWNLOAD_PAGE_BUTTON',
  ADMIN_SIDEBAR_PAGE_LINK = 'ADMIN_SIDEBAR_PAGE_LINK',
  ADMIN_CONTRIBUTIONS_LOOKUP_DOWNLOAD_CSV_BUTTON = 'ADMIN_CONTRIBUTIONS_LOOKUP_DOWNLOAD_CSV_BUTTON',
  ADMIN_CONTRIBUTIONS_LOOKUP_DOWNLOAD_EXCEL_BUTTON = 'ADMIN_CONTRIBUTIONS_LOOKUP_DOWNLOAD_EXCEL_BUTTON',
  ADMIN_CONTRIBUTIONS_LOOKUP_PURCHASE_DATE_SELECT = 'ADMIN_CONTRIBUTIONS_LOOKUP_PURCHASE_DATE_SELECT',
  ADMIN_CONTRIBUTIONS_LOOKUP_DOWNLOAD_PAGE_BUTTON = 'ADMIN_CONTRIBUTIONS_LOOKUP_DOWNLOAD_PAGE_BUTTON',
  ADMIN_CONTRIBUTIONS_LOOKUP_SEARCH_BUTTON = 'ADMIN_CONTRIBUTIONS_LOOKUP_SEARCH_BUTTON',
  ADMIN_CONTRIBUTIONS_LOOKUP_REMOVE_FILTER_BUTTON = 'ADMIN_CONTRIBUTIONS_LOOKUP_REMOVE_FILTER_BUTTON',
  ADMIN_EMPLOYEE_LOOKUP_DOWNLOAD_PAGE_BUTTON = 'ADMIN_EMPLOYEE_LOOKUP_DOWNLOAD_PAGE_BUTTON',
  ADMIN_EMPLOYEE_LOOKUP_BACK_TO_RESULTS_BUTTON = 'ADMIN_EMPLOYEE_LOOKUP_BACK_TO_RESULTS_BUTTON',
  ADMIN_EMPLOYEE_LOOKUP_SEARCH_BUTTON = 'ADMIN_EMPLOYEE_LOOKUP_SEARCH_BUTTON',
  ADMIN_EMPLOYEE_LOOKUP_DOWNLOAD_ENROLLMENT_LOCATION_CSV_BUTTON = 'ADMIN_EMPLOYEE_LOOKUP_DOWNLOAD_ENROLLMENT_LOCATION_CSV_BUTTON',
  ADMIN_EMPLOYEE_LOOKUP_DOWNLOAD_ENROLLMENT_LOCATION_EXCEL_BUTTON = 'ADMIN_EMPLOYEE_LOOKUP_DOWNLOAD_ENROLLMENT_LOCATION_EXCEL_BUTTON',
  ADMIN_EMPLOYEE_LOOKUP_EMPLOYEE_STATUS_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_EMPLOYEE_STATUS_SELECT',
  ADMIN_EMPLOYEE_LOOKUP_ENROLLMENT_STATUS_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_ENROLLMENT_STATUS_SELECT',
  ADMIN_EMPLOYEE_LOOKUP_CASHLESS_STATUS_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_CASHLESS_STATUS_SELECT',
  ADMIN_EMPLOYEE_LOOKUP_ESPP_ELIGIBILITY_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_ESPP_ELIGIBILITY_SELECT',
  ADMIN_EMPLOYEE_LOOKUP_OFFERING_PERIOD_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_OFFERING_PERIOD_SELECT',
  ADMIN_EMPLOYEE_LOOKUP_AUTO_ENROLLMENT_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_AUTO_ENROLLMENT_SELECT',
  ADMIN_EMPLOYEE_LOOKUP_DYNAMIC_FILTER_SELECT = 'ADMIN_EMPLOYEE_LOOKUP_DYNAMIC_FILTER_SELECT',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_BY_DAY_CSV_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_BY_DAY_CSV_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_BY_DAY_EXCEL_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_BY_DAY_EXCEL_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_BY_DAY_SHOW_MORE_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_BY_DAY_SHOW_MORE_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_OFFERING_PERIOD_CSV_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_OFFERING_PERIOD_CSV_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_OFFERING_PERIOD_EXCEL_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_OFFERING_PERIOD_EXCEL_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_LOCATION_CSV_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_LOCATION_CSV_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_LOCATION_EXCEL_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_ENROLLMENT_LOCATION_EXCEL_BUTTON',
  ADMIN_ENROLLMENT_STATS_DOWNLOAD_PAGE_BUTTON = 'ADMIN_ENROLLMENT_STATS_DOWNLOAD_PAGE_BUTTON',
  ADMIN_ENROLLMENT_STATS_DYNAMIC_EMPLOYEE_CHART_SELECT = 'ADMIN_ENROLLMENT_STATS_DYNAMIC_EMPLOYEE_CHART_SELECT',
  ADMIN_ENROLLMENT_STATS_AVAILIABLE_PERIODS_SELECT = 'ADMIN_ENROLLMENT_STATS_AVAILIABLE_PERIODS_SELECT',
  ADMIN_EXCHANGE_RATES_DOWNLOAD_PAGE_BUTTON = 'ADMIN_EXCHANGE_RATES_DOWNLOAD_PAGE_BUTTON',
  ADMIN_DATA_EXCHANGE_BREADCRUMB_LINK = 'ADMIN_DATA_EXCHANGE_BREADCRUMB_LINK',
  ADMIN_DATA_EXCHANGE_FILE_OR_FOLDER_ITEM = 'ADMIN_DATA_EXCHANGE_FILE_OR_FOLDER_ITEM',
  ADMIN_DATA_EXCHANGE_PAGE_ITEM = 'ADMIN_DATA_EXCHANGE_PAGE_ITEM',
  ADMIN_DATA_UPLOAD_UPLOAD_BUTTON = 'ADMIN_DATA_UPLOAD_UPLOAD_BUTTON',
  ADMIN_DATA_UPLOAD_REFRESH_BUTTON = 'ADMIN_DATA_UPLOAD_REFRESH_BUTTON',
  ADMIN_DATA_UPLOAD_UPLOADED_FILE_ITEM = 'ADMIN_DATA_UPLOAD_UPLOADED_FILE_ITEM',
  ADMIN_DATA_UPLOAD_DIALOG_FILE_TYPE_SELECT = 'ADMIN_DATA_UPLOAD_DIALOG_FILE_TYPE_SELECT',
  ADMIN_DATA_UPLOAD_DIALOG_BROWSE_BUTTON = 'ADMIN_DATA_UPLOAD_DIALOG_BROWSE_BUTTON',
  ADMIN_DATA_UPLOAD_DIALOG_CLOSE = 'ADMIN_DATA_UPLOAD_DIALOG_CLOSE',
  ADMIN_DATA_UPLOAD_DIALOG_UPLOAD = 'ADMIN_DATA_UPLOAD_DIALOG_UPLOAD',
  ADMIN_PLAN_OVERVIEW_SCROLL_BUTTON = 'ADMIN_PLAN_OVERVIEW_SCROLL_BUTTON',
  ADMIN_PURCHASE_DETAILS_PAGE_OVERVIEW_TAB_LINK = 'ADMIN_PURCHASE_DETAILS_PAGE_OVERVIEW_TAB_LINK',
  ADMIN_PURCHASE_DETAILS_PAGE_HIGHLIGHTS_TAB_LINK = 'ADMIN_PURCHASE_DETAILS_PAGE_HIGHLIGHTS_TAB_LINK',
  ADMIN_PURCHASE_DETAILS_PAGE_LOOKUP_TAB_LINK = 'ADMIN_PURCHASE_DETAILS_PAGE_LOOKUP_TAB_LINK',
  ADMIN_PURCHASE_DETAILS_OVERVIEW_TABLE_DOWNLOAD_CSV = 'ADMIN_PURCHASE_DETAILS_OVERVIEW_TABLE_DOWNLOAD_CSV',
  ADMIN_PURCHASE_DETAILS_OVERVIEW_TABLE_DOWNLOAD_XLSX = 'ADMIN_PURCHASE_DETAILS_OVERVIEW_TABLE_DOWNLOAD_XLSX',
  ADMIN_PURCHASE_DETAILS_LOOKUP_TAB_TABLE_DOWNLOAD_CSV = 'ADMIN_PURCHASE_DETAILS_LOOKUP_TAB_TABLE_DOWNLOAD_CSV',
  ADMIN_PURCHASE_DETAILS_LOOKUP_TAB_TABLE_DOWNLOAD_XLSX = 'ADMIN_PURCHASE_DETAILS_LOOKUP_TAB_TABLE_DOWNLOAD_XLSX',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVERAGE_OWNERSHIP_INCREASE_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVERAGE_OWNERSHIP_INCREASE_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_DOWNSIDE_PROTECTION_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_DOWNSIDE_PROTECTION_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_PAID_IN_CAPITAL_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_PAID_IN_CAPITAL_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_ROI_INCREASE_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_ROI_INCREASE_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_NET_DISPOSABLE_INCOME_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_NET_DISPOSABLE_INCOME_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVG_TABLE_PRICE_MOVEMENT_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVG_TABLE_PRICE_MOVEMENT_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVG_TABLE_EXPORT_CSV = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVG_TABLE_EXPORT_CSV',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVG_TABLE_EXPORT_XLSX = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_AVG_TABLE_EXPORT_XLSX',
  ADMIN_PURCHASE_DETAILS_EMPLOYEE_HIGHLIGHTS_TABLE_CSV_EXPORT_BUTTON = 'ADMIN_PURCHASE_DETAILS_EMPLOYEE_HIGHLIGHTS_TABLE_CSV_EXPORT_BUTTON',
  ADMIN_PURCHASE_DETAILS_EMPLOYEE_HIGHLIGHTS_TABLE_EXCEL_EXPORT_BUTTON = 'ADMIN_PURCHASE_DETAILS_EMPLOYEE_HIGHLIGHTS_TABLE_EXCEL_EXPORT_BUTTON',
  ADMIN_PURCHASE_DETAILS_EMPLOYEE_FILTER_SEARCH_BUTTON = 'ADMIN_PURCHASE_DETAILS_EMPLOYEE_FILTER_SEARCH_BUTTON',
  ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_PURCHASE_VALUE_TOOLTIP = 'ADMIN_PURCHASE_DETAILS_HIGHLIGHTS_PURCHASE_VALUE_TOOLTIP',
  ADMIN_PURCHASE_DETAILS_EMPLOYEE_CLEAR_FILTER_BUTTON = 'ADMIN_PURCHASE_DETAILS_EMPLOYEE_CLEAR_FILTER_BUTTON',
  ADMIN_PURCHASE_LOOKUP_PARTICIPANTS_TABLE_CASHLESS_PARTICIPATION_SELECT = 'ADMIN_PURCHASE_LOOKUP_PARTICIPANTS_TABLE_CASHLESS_PARTICIPATION_SELECT',
  ADMIN_PURCHASE_LOOKUP_PARTICIPANTS_TABLE_JURISDICTION_SELECT = 'ADMIN_PURCHASE_LOOKUP_PARTICIPANTS_TABLE_JURISDICTION_SELECT',
  ADMIN_PURCHASE_LOOKUP_PARTICIPANTS_TABLE_OFFERING_PERIOD_SELECT = 'ADMIN_PURCHASE_LOOKUP_PARTICIPANTS_TABLE_OFFERING_PERIOD_SELECT',
  ADMIN_PURCHASE_LOOKUP_PURCHASE_DATE_SELECT = 'ADMIN_PURCHASE_LOOKUP_PURCHASE_DATE_SELECT',
  ADMIN_PURCHASE_LOOKUP_JURISDICTION_SELECT = 'ADMIN_PURCHASE_LOOKUP_JURISDICTION_SELECT',
  ADMIN_PURCHASE_LOOKUP_TAB_YEAR_SELECT = 'ADMIN_PURCHASE_LOOKUP_TAB_YEAR_SELECT',
  ADMIN_PURCHASE_LOOKUP_TAB_JURISDICTION_SELECT = 'ADMIN_PURCHASE_LOOKUP_TAB_JURISDICTION_SELECT',
  ADMIN_STOCK_BASED_COMP_PURCHASE_DETAILS_SELECT = 'ADMIN_STOCK_BASED_COMP_PURCHASE_DETAILS_SELECT',
  ADMIN_STOCK_BASED_COMP_CALCULATE_EXPENSE_BUTTON = 'ADMIN_STOCK_BASED_COMP_CALCULATE_EXPENSE_BUTTON',
  ADMIN_CONFIGURATION_PAGE_PLAN_TAB_LINK = 'ADMIN_CONFIGURATION_PAGE_PLAN_TAB_LINK',
  ADMIN_CONFIGURATION_PAGE_ENROLLMENT_TAB_LINK = 'ADMIN_CONFIGURATION_PAGE_ENROLLMENT_TAB_LINK',
  ADMIN_CONFIGURATION_PAGE_JURISDICTION_SELECT = 'ADMIN_CONFIGURATION_PAGE_JURISDICTION_SELECT',
  ARTICLE_PREVIEW_READ_MORE = 'ARTICLE_PREVIEW_READ_MORE',
  ARTICLES_PREVIEW_VIEW_ALL = 'ARTICLES_PREVIEW_VIEW_ALL',
  CONTENT_MENU_ITEM = 'CONTENT_MENU_ITEM',
  COOKIE_BANNER_ACCEPT_ALL_BUTTON = 'COOKIE_BANNER_ACCEPT_ALL_BUTTON',
  COOKIE_BANNER_CLOSE_BUTTON = 'COOKIE_BANNER_CLOSE_BUTTON',
  COOKIE_BANNER_COOKIE_POLICY_LINK = 'COOKIE_BANNER_COOKIE_POLICY_LINK',
  CALCULATOR_25K_LIMIT_LINK = 'CALCULATOR_25K_LIMIT_LINK',
  CALCULATOR_CALCULATE_BUTTON = 'CALCULATOR_CALCULATE_BUTTON',
  CALCULATOR_STOCK_PRICE_TOOLTIP = 'CALCULATOR_STOCK_PRICE_TOOLTIP',
  CALCULATOR_TAX_INFO_TOOLTIP = 'CALCULATOR_TAX_INFO_TOOLTIP',
  CALCULATOR_TAX_RATE_RADIO_OPTION = 'CALCULATOR_TAX_RATE_RADIO_OPTION',
  CALCULATOR_TOGGLE_ADDITIONAL_INPUTS = 'CALCULATOR_TOGGLE_ADDITIONAL_INPUTS',
  CALCULATOR_TOGGLE_HOURLY_SALARY_BUTTON = 'CALCULATOR_TOGGLE_HOURLY_SALARY_BUTTON',
  CALCULATOR_PAYCHECK_FREQUENCY_SELECT = 'CALCULATOR_PAYCHECK_FREQUENCY_SELECT',
  CALCULATOR_CONTRIBUTION_PERCENT_SELECT = 'CALCULATOR_CONTRIBUTION_PERCENT_SELECT',
  COMPANY_NOT_FOUND_DIALOG_CLOSE_BUTTON = 'COMPANY_NOT_FOUND_DIALOG_CLOSE_BUTTON',
  CONNECT_WEALTH_MANAGEMENT_BUTTON = 'CONNECT_WEALTH_MANAGEMENT_BUTTON',
  EMAIL_VERIFICATION_WITH_TOKEN_BUTTON = 'EMAIL_VERIFICATION_WITH_TOKEN_BUTTON',
  EMAIL_VERIFICATION_COMPLETE_PAGE_REDIRECT_TO_ACCOUNT_BUTTON = 'EMAIL_VERIFICATION_COMPLETE_PAGE_REDIRECT_TO_ACCOUNT_BUTTON',
  EMAIL_UNSUBSCRIBE_REASON_CHECKBOX = 'EMAIL_UNSUBSCRIBE_REASON_CHECKBOX',
  EMAIL_UNSUBSCRIBE_BUTTON = 'EMAIL_UNSUBSCRIBE_BUTTON',
  ENROLLMENT_WELCOME_LINK_BUTTON = 'ENROLLMENT_WELCOME_LINK_BUTTON',
  ENROLLMENT_CALCULATOR_RETURN_TO_ELECTION_BUTTON = 'ENROLLMENT_CALCULATOR_RETURN_TO_ELECTION_BUTTON',
  ENROLLMENT_CONTRACTS_MAKE_CHANGES_LINK_BUTTON = 'ENROLLMENT_CONTRACTS_MAKE_CHANGES_LINK_BUTTON',
  ENROLLMENT_CONTRACTS_SUMMARY_CONTRIBUTION_PERCENTAGE_VALUE_TEXT = 'ENROLLMENT_CONTRACTS_SUMMARY_CONTRIBUTION_PERCENTAGE_VALUE_TEXT',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_UNDEFINED_TYPE = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_UNDEFINED_TYPE',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_CASHLESS_AGREEMENT = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_CASHLESS_AGREEMENT',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_COMBINED_PLAN_AND_PROSPECTUS = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_COMBINED_PLAN_AND_PROSPECTUS',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_ENROLLMENT_AGREEMENT = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_ENROLLMENT_AGREEMENT',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_PAYROLL_AUTHORIZATION_FORM = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_PAYROLL_AUTHORIZATION_FORM',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_1099_B = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_1099_B',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_1099_B_SUPPLEMENT = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_1099_B_SUPPLEMENT',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_1099_B_UNOFFICIAL = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_1099_B_UNOFFICIAL',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_3922 = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_US_TAX_3922',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_ESPP_PLAN = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_ESPP_PLAN',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_S8_PROSPECTUS = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_S8_PROSPECTUS',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_CLEARING_BROKER_STATEMENT = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_CLEARING_BROKER_STATEMENT',
  ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_CLEARING_BROKER_CONFIRM = 'ENROLLMENT_CONTRACTS_EXPAND_CONTRACT_CLEARING_BROKER_CONFIRM',
  ENROLLMENT_CONTRACTS_VIEW_CONTRACT_IN_NEW_TAB_LINK = 'ENROLLMENT_CONTRACTS_VIEW_CONTRACT_IN_NEW_TAB_LINK',
  ENROLLMENT_CONTRACTS_AGREE_MAIN_AGREEMENT_CHECKBOX = 'ENROLLMENT_CONTRACTS_AGREE_MAIN_AGREEMENT_CHECKBOX',
  ENROLLMENT_CONTRACTS_AGREE_FROM_CMS_CHECKBOX = 'ENROLLMENT_CONTRACTS_AGREE_FROM_CMS_CHECKBOX',
  ENROLLMENT_CONTRACTS_AGREE_WEALTH_MANAGEMENT_CHECKBOX = 'ENROLLMENT_CONTRACTS_AGREE_WEALTH_MANAGEMENT_CHECKBOX',
  ENROLLMENT_CONFIRMATION_LINK_BUTTON = 'ENROLLMENT_CONFIRMATION_LINK_BUTTON',
  ENROLLMENT_CONFIRMATION_ADD_REMINDERS_LINK = 'ENROLLMENT_CONFIRMATION_ADD_REMINDERS_LINK',
  ENROLLMENT_CONFIRMATION_DATE_CHEAT_SHEET_LINK = 'ENROLLMENT_CONFIRMATION_DATE_CHEAT_SHEET_LINK',
  ENROLLMENT_DIALOG_ACTION_BUTTON = 'ENROLLMENT_DIALOG_ACTION_BUTTON',
  ENROLLMENT_DIALOG_CLOSE_BUTTON = 'ENROLLMENT_DIALOG_CLOSE_BUTTON',
  ENROLLMENT_ELECTION_CONTRIBUTION_TOOLTIP = 'ENROLLMENT_ELECTION_CONTRIBUTION_TOOLTIP',
  ENROLLMENT_ELECTION_APPLY_TO_FUTURE_CHECKBOX = 'ENROLLMENT_ELECTION_APPLY_TO_FUTURE_CHECKBOX',
  ENROLLMENT_ELECTION_PERCENT_CONTRIBUTION_SELECT = 'ENROLLMENT_ELECTION_PERCENT_CONTRIBUTION_SELECT',
  ENROLLMENT_ELECTIONS_CASHLESS_PARTICIPATION_RADIO_TOGGLE = 'ENROLLMENT_ELECTIONS_CASHLESS_PARTICIPATION_RADIO_TOGGLE',
  ENROLLMENT_EXIT_LINK = 'ENROLLMENT_EXIT_LINK',
  ENROLLMENT_NEXT_STEP = 'ENROLLMENT_NEXT_STEP',
  ENROLLMENT_PREVIOUS_STEP = 'ENROLLMENT_PREVIOUS_STEP',
  ENROLLMENT_ELIGIBILITY_QUESTIONS_CITIZENSHIP_COUNTRY_SELECT = 'ENROLLMENT_ELIGIBILITY_QUESTIONS_CITIZENSHIP_COUNTRY_SELECT',
  ENROLLMENT_ELIGIBILITY_QUESTIONS_BIRTH_COUNTRY_SELECT = 'ENROLLMENT_ELIGIBILITY_QUESTIONS_BIRTH_COUNTRY_SELECT',
  ENROLLMENT_QUESTIONS_AFFILIATED_EXCHANGE_OR_FINRA_RADIO_OPTION = 'ENROLLMENT_QUESTIONS_AFFILIATED_EXCHANGE_OR_FINRA_RADIO_OPTION',
  ENROLLMENT_QUESTIONS_CONTROL_PERSON_RADIO_OPTION = 'ENROLLMENT_QUESTIONS_CONTROL_PERSON_RADIO_OPTION',
  ENROLLMENT_QUESTIONS_CONTROL_PERSON_TOOLTIP = 'ENROLLMENT_QUESTIONS_CONTROL_PERSON_TOOLTIP',
  ENROLLMENT_QUESTIONS_PUBLIC_OFFICIAL_RADIO_OPTION = 'ENROLLMENT_QUESTIONS_PUBLIC_OFFICIAL_RADIO_OPTION',
  ENROLLMENT_QUESTIONS_US_CITIZEN_RADIO_OPTION = 'ENROLLMENT_QUESTIONS_US_CITIZEN_RADIO_OPTION',
  ENROLLMENT_QUESTIONS_US_CITIZEN_FROM_NON_US_SEE_LINK = 'ENROLLMENT_QUESTIONS_US_CITIZEN_FROM_NON_US_SEE_LINK',
  ENROLLMENT_QUESTIONS_US_CITIZEN_FROM_NON_US_RADIO_OPTION = 'ENROLLMENT_QUESTIONS_US_CITIZEN_FROM_NON_US_RADIO_OPTION',
  ENROLLMENT_QUESTIONS_WEALTH_MANAGER_RADIO_OPTION = 'ENROLLMENT_QUESTIONS_WEALTH_MANAGER_RADIO_OPTION',
  ENROLLMENT_QUESTIONS_VISA_TYPE_SELECT = 'ENROLLMENT_QUESTIONS_VISA_TYPE_SELECT',
  ENROLLMENT_SUBMIT_ELECTIONS = 'ENROLLMENT_SUBMIT_ELECTIONS',
  FOOTER_BUTTON_ENROLL_NOW = 'FOOTER_BUTTON_ENROLL_NOW',
  FOOTER_LANGUAGE_SELECT_BUTTON = 'FOOTER_LANGUAGE_SELECT_BUTTON',
  GO_TO_ESPP_BUTTON = 'GO_TO_ESPP_BUTTON',
  HEADER_LANGUAGE_PICKER_LANGUAGE_RADIO_OPTION = 'HEADER_LANGUAGE_PICKER_LANGUAGE_RADIO_OPTION',
  HEADER_LINK_ABOUT_CASHLESS = 'HEADER_LINK_ABOUT_CASHLESS',
  HEADER_LINK_ADMIN_VIEW = 'HEADER_LINK_ADMIN_VIEW',
  HEADER_LINK_CASHLESS_MATCH = 'HEADER_LINK_CASHLESS_MATCH',
  HEADER_LINK_LOGIN = 'HEADER_LINK_LOGIN',
  HEADER_LINK_LOGOUT = 'HEADER_LINK_LOGOUT',
  HEADER_LINK_GENERIC = 'HEADER_LINK_GENERIC',
  HEADER_LINK_RESOURCE_GENERIC = 'HEADER_LINK_RESOURCE_GENERIC',
  HEADER_BUTTON_ENROLL_NOW = 'HEADER_BUTTON_ENROLL_NOW',
  HEADER_BUTTON_OPEN_MOBILE_MENU = 'HEADER_BUTTON_OPEN_MOBILE_MENU',
  HOMEPAGE_LINK_LEARN_MORE_CTA = 'HOMEPAGE_LINK_LEARN_MORE_CTA',
  HOMEPAGE_LINK_REACH_MORE_CTA = 'HOMEPAGE_LINK_REACH_MORE_CTA',
  HOMEPAGE_LINK_WELCOME_ENROLL_CTA = 'HOMEPAGE_LINK_WELCOME_ENROLL_CTA',
  HOMEPAGE_LINK_WHAT_IS_ESPP = 'HOMEPAGE_LINK_WHAT_IS_ESPP',
  HOMEPAGE_LINK_HOW_DOES_IT_WORK = 'HOMEPAGE_LINK_HOW_DOES_IT_WORK',
  INACTIVITY_NOTICE_MODAL_WINDOW_BUTTON = 'INACTIVITY_NOTICE_MODAL_WINDOW_BUTTON',
  LANGUAGE_PICKER_BUTTON_DROPDOWN = 'LANGUAGE_PICKER_BUTTON_DROPDOWN',
  LINK_FROM_CONVERTED_HTML = 'LINK_FROM_CONVERTED_HTML',
  LOGIN_PAGE_LOGIN_BUTTON = 'LOGIN_PAGE_LOGIN_BUTTON',
  LOGIN_PAGE_RETURN_TO_MAIN_BUTTON = 'LOGIN_PAGE_RETURN_TO_MAIN_BUTTON',
  LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_UNDEFINED_METHOD = 'LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_UNDEFINED_METHOD',
  LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_SSO = 'LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_SSO',
  LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_BUSINESS_GROUP = 'LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_BUSINESS_GROUP',
  LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_PASSWORDLESS_EMAIL = 'LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_PASSWORDLESS_EMAIL',
  LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_PASSWORDLESS_EMAIL_TERMINATED = 'LOGIN_PAGE_PROVIDER_LOGIN_BUTTON_PASSWORDLESS_EMAIL_TERMINATED',
  LOGIN_PAGE_ADMIN_LOGIN = 'LOGIN_PAGE_ADMIN_LOGIN',
  LOGIN_PAGE_FORMER_EMPLOYEE_LOGIN = 'LOGIN_PAGE_FORMER_EMPLOYEE_LOGIN',
  LOGIN_PAGE_DBO_TO_FORM_BUTTON = 'LOGIN_PAGE_DBO_TO_FORM_BUTTON',
  LOGIN_PAGE_DBO_LOGIN_BUTTON = 'LOGIN_PAGE_DBO_LOGIN_BUTTON',
  LOGIN_PAGE_DBO_EMPLOYEE_ID_TOOLTIP = 'LOGIN_PAGE_DBO_EMPLOYEE_ID_TOOLTIP',
  LOGIN_PAGE_DBO_LAST4_TOOLTIP = 'LOGIN_PAGE_DBO_LAST4_TOOLTIP',
  LOGIN_PAGE_DBO_UNABLE_TO_VERIFY_CLOSE_BUTTON = 'LOGIN_PAGE_DBO_UNABLE_TO_VERIFY_CLOSE_BUTTON',
  LOGIN_PAGE_DBO_UNABLE_TO_ELIGIBILITY_CLOSE_BUTTON = 'LOGIN_PAGE_DBO_UNABLE_TO_ELIGIBILITY_CLOSE_BUTTON',
  LOGIN_PAGE_DBO_ALREADY_ENROLLED_CLOSE_BUTTON = 'LOGIN_PAGE_DBO_ALREADY_ENROLLED_CLOSE_BUTTON',
  LOGIN_PAGE_DOB_ORGANIZATION_SELECT = 'LOGIN_PAGE_DOB_ORGANIZATION_SELECT',
  LOGIN_PAGE_CONTACT_US_LINK = 'LOGIN_PAGE_CONTACT_US_LINK',
  LONG_TERM_CALC_ADDITIONAL_INPUTS_BUTTON = 'LONG_TERM_CALC_ADDITIONAL_INPUTS_BUTTON',
  LONG_TERM_CALC_CALCULATE_BUTTON = 'LONG_TERM_CALC_CALCULATE_BUTTON',
  LONG_TERM_CALC_SALARIED_HOURLY_TOGGLE_BUTTON = 'LONG_TERM_CALC_SALARIED_HOURLY_TOGGLE_BUTTON',
  LONG_TERM_CALC_SIMULATED_STOCK_PRICE_TOOLTIP = 'LONG_TERM_CALC_SIMULATED_STOCK_PRICE_TOOLTIP',
  LONG_TERM_CALC_TAX_RATE_RADIO_OPTION = 'LONG_TERM_CALC_TAX_RATE_RADIO_OPTION',
  LONG_TERM_CALC_CONTRIBUTION_PERCENTAGE_SELECT = 'LONG_TERM_CALC_CONTRIBUTION_PERCENTAGE_SELECT',
  MERGE_LINK_PAGE_CONNECT_BUTTON = 'MERGE_LINK_PAGE_CONNECT_BUTTON',
  NAV_USER_CONTEXT_MENU_BUTTON_DROPDOWN = 'NAV_USER_CONTEXT_MENU_BUTTON_DROPDOWN',
  OPTIONS_EXERCISE_BUTTON_CONFIRM_CASH_EXERCISE_AMOUNTS = 'OPTIONS_EXERCISE_BUTTON_CONFIRM_CASH_EXERCISE_AMOUNTS',
  OPTIONS_EXERCISE_BUTTON_EDIT_CASH_EXERCISE_AMOUNTS = 'OPTIONS_EXERCISE_BUTTON_EDIT_CASH_EXERCISE_AMOUNTS',
  OPTIONS_EXERCISE_BUTTON_SUBMIT_WIRE_EXERCISE = 'OPTIONS_EXERCISE_BUTTON_SUBMIT_WIRE_EXERCISE',
  OPTIONS_EXERCISE_BUTTON_SWITCH_TO_WIRE_PAYMENT = 'OPTIONS_EXERCISE_BUTTON_SWITCH_TO_WIRE_PAYMENT',
  READY_TO_ENROLL_BANNER_LINK_ENROLL_CTA = 'READY_TO_ENROLL_BANNER_LINK_ENROLL_CTA',
  REFER_COWORKER_PAGE_REFER_COWORKER_BUTTON = 'REFER_COWORKER_PAGE_REFER_COWORKER_BUTTON',
  REFER_COWORKER_PAGE_REFER_ANOTHER = 'REFER_COWORKER_PAGE_REFER_ANOTHER',
  REMINDER_TO_ENROLL_POPUP_REDIRECT_TO_STOCK_PANEL_BUTTON = 'REMINDER_TO_ENROLL_POPUP_REDIRECT_TO_STOCK_PANEL_BUTTON',
  REMINDER_TO_ENROLL_POPUP_CLOSE_BUTTON = 'REMINDER_TO_ENROLL_POPUP_CLOSE_BUTTON',
  RESOURCE_LIB_FEATURED_ARTICLE_READ_MORE = 'RESOURCE_LIB_FEATURED_ARTICLE_READ_MORE',
  RESOURCE_LIB_PLAN_DOCUMENT_READ_MORE = 'RESOURCE_LIB_PLAN_DOCUMENT_READ_MORE',
  WIRE_TRANSFER_INSTRUCTIONS_CLOSE_BUTTON = 'WIRE_TRANSFER_INSTRUCTIONS_CLOSE_BUTTON',
  HEADER_LINK_ACCOUNT = 'HEADER_LINK_ACCOUNT',
  HEADER_RESOURCE_DROPDOWN = 'HEADER_RESOURCE_DROPDOWN',
  RESOURCE_LINK = 'RESOURCE_LINK',
  FOOTER_MENU_ITEM_LINK = 'FOOTER_MENU_ITEM_LINK',
  PERSONAL_INFO_ADD_MY_DETAILS_BUTTON = 'PERSONAL_INFO_ADD_MY_DETAILS_BUTTON',
  PERSONAL_INFO_EDIT_MODE_BUTTON = 'PERSONAL_INFO_EDIT_MODE_BUTTON',
  PERSONAL_INFO_EDIT_MODE_ALTERNATE_EMAIL_OPTION_TOOLTIP = 'PERSONAL_INFO_EDIT_MODE_ALTERNATE_EMAIL_OPTION_TOOLTIP',
  PERSONAL_INFO_EDIT_MODE_BACK_BUTTON = 'PERSONAL_INFO_EDIT_MODE_BACK_BUTTON',
  PERSONAL_INFO_EDIT_MODE_SUBMIT_BUTTON = 'PERSONAL_INFO_EDIT_MODE_SUBMIT_BUTTON',
  PERSONAL_INFO_EDIT_MODE_MODAL_WINDOW_BUTTON = 'PERSONAL_INFO_EDIT_MODE_MODAL_WINDOW_BUTTON',
  PERSONAL_INFO_RESEND_EMAIL_BUTTON = 'PERSONAL_INFO_RESEND_EMAIL_BUTTON',
  IDENTITY_VERIFICATION_POPUP_CLOSE_BUTTON = 'IDENTITY_VERIFICATION_POPUP_CLOSE_BUTTON',
  SESSION_TIMEOUT_WARNING_POPUP_CLOSE_BUTTON = 'SESSION_TIMEOUT_WARNING_POPUP_CLOSE_BUTTON',
  SESSION_TIMEOUT_POPUP_CLOSE_BUTTON = 'SESSION_TIMEOUT_POPUP_CLOSE_BUTTON',
  STANDALONE_BROKER_QUESTIONS_SUBMIT_BUTTON = 'STANDALONE_BROKER_QUESTIONS_SUBMIT_BUTTON',
  AUTO_SALE_BROKER_QUESTIONS_SUBMIT_BUTTON = 'AUTO_SALE_BROKER_QUESTIONS_SUBMIT_BUTTON',
  STANDALONE_BROKER_QUESTIONS_BACK_BUTTON = 'STANDALONE_BROKER_QUESTIONS_BACK_BUTTON',
  EMPLOYEE_LOOKUP_RESULTS_EXCESS_POPUP_CLOSE_BUTTON = 'EMPLOYEE_LOOKUP_RESULTS_EXCESS_POPUP_CLOSE_BUTTON',
  EMPLOYEE_LOOKUP_INDIVIDUAL_EMPLOYEE_DETAILS_BUTTON = 'EMPLOYEE_LOOKUP_INDIVIDUAL_EMPLOYEE_DETAILS_BUTTON',
  EMPLOYEE_LOOKUP_REMOVE_FILTER_BUTTON = 'EMPLOYEE_LOOKUP_REMOVE_FILTER_BUTTON',
  SOMETHING_WENT_WRONG_POPUP_CLOSE_BUTTON = 'SOMETHING_WENT_WRONG_POPUP_CLOSE_BUTTON',
  EOI_NOTICE_BEFORE_ENROLLMENT_POPUP_PROCEED = 'EOI_NOTICE_BEFORE_ENROLLMENT_POPUP_PROCEED',
  EOI_NOTICE_BEFORE_ENROLLMENT_POPUP_SUBMIT = 'EOI_NOTICE_BEFORE_ENROLLMENT_POPUP_SUBMIT',
  EOI_NOTICE_AFTER_DEADLINE_POPUP_PROCEED = 'EOI_NOTICE_AFTER_DEADLINE_POPUP_PROCEED',
  EOI_NOTICE_AFTER_DEADLINE_POPUP_CLOSE = 'EOI_NOTICE_AFTER_DEADLINE_POPUP_CLOSE',
  AUTOMATIC_SIGNOUT_POPUP_SIGN_IN_BUTTON = 'AUTOMATIC_SIGNOUT_POPUP_SIGN_IN_BUTTON',
}

export const ELEMENT_ID_DATA_ATTRIBUTE = 'data-element-id';

export const getDataElementIdForSpecialCase = (
  dataElementId: DataElementId,
  specialСaseName: string,
) => `${dataElementId}_${specialСaseName.toUpperCase()}`;
