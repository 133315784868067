import type { DataElementId } from '../constants/data-element-tracking-ids';
import type { EnrollmentStep } from '../pages/enrollment-abc/wrapper/enrollment-context';
import type { JSONSerializableObject } from '../utils/json-types';
import type { ISubmitStandaloneBrokerQuestions } from '../pages/standalone-broker-questions/hooks/use-submit-standalone-broker-questions';

export enum ClientEvent {
  CLICKED_BUTTON_OR_ELEMENT = 'CLICKED_BUTTON_OR_ELEMENT',
  ELECTION_SELECTED_CONTRIBUTION_PERCENT = 'ELECTION_SELECTED_CONTRIBUTION_PERCENT',
  ELECTION_TOGGLED_CASHLESS_ENABLED = 'ELECTION_TOGGLED_CASHLESS_ENABLED',
  ENROLLMENT_VISITED_STEP = 'ENROLLMENT_VISITED_STEP',
  ENROLLMENT_ATTEMPTED = 'ENROLLMENT_ATTEMPTED',
  ENROLLMENT_ATTEMPT_FAILED = 'ENROLLMENT_ATTEMPT_FAILED',
  ELECTION_UPDATE_ATTEMPTED = 'ELECTION_UPDATE_ATTEMPTED',
  ELECTION_UPDATE_ATTEMPT_FAILED = 'ELECTION_UPDATE_ATTEMPT_FAILED',
  REFER_COWORKER_ATTEMPTED = 'REFER_COWORKER_ATTEMPTED',
  CLICKED_RESOURCE_LIBRARY_LINK = 'CLICKED_RESOURCE_LIBRARY_LINK',
  SUBMIT_STANDALONE_BROKER_QUESTIONS_ATTEMPTED = 'SUBMIT_STANDALONE_BROKER_QUESTIONS_ATTEMPTED',
  SUBMIT_STANDALONE_BROKER_QUESTIONS_FAILED = 'SUBMIT_STANDALONE_BROKER_QUESTIONS_FAILED',
  // LOGGED_IN = 'LOGGED_IN',
  // FORCED_REDIRECT_TO_LOGIN_PAGE = 'FORCED_REDIRECT_TO_LOGIN_PAGE',
}

// Camel case variables are ones that the backend is specifically
// looking for. The rest just go into a json blob.
export type BaseEvent = {
  employer_shortname?: string;
  url_search_params: JSONSerializableObject;
  is_magic_link: boolean;
  country_code: string;
  state_code: string;
  language_code: string;
  enrollment_open: boolean;
  event_source: 'CASHLESS-CASHFRONT';
};

export type EnrollmentOrElectionChangeEventData = {
  contribution_percent: number;
  cashless_enabled: boolean;
  clearing_broker_question_responses?: JSONSerializableObject;
  signature: string;
  selected_talk_to_wealth_manager?: boolean;
  offering_period_status: 'CURRENT' | 'FUTURE' | 'CURRENT_AND_FUTURE';
};

type FailedEnrollmentOrElectionChangeEventData =
  EnrollmentOrElectionChangeEventData & {
    failure_reason: string;
  };

// This EnforceClientEventTypeValues type enforces that all of the event types
// in ClientEventTypeDefs are JSON serializable objects:
// https://stackoverflow.com/a/74022234/5602521
type EnforceClientEventTypeValues<T extends JSONSerializableObject> = T;

// To make sure that it's always safe to use the spread operator on the logged
// data for a given event type, an event that only needs to log BaseEvent data
// should still have a ClientEventTypeDef of "empty object".
// Empty object type representation is from https://stackoverflow.com/a/68208381/5602521
type NoAdditionalLoggedData = Record<string, never>;

// We don't want to log people's SSNs, so ensure that they are not set here:
export type BrokerQuestionsWithRedactedIDNumbers =
  ISubmitStandaloneBrokerQuestions & {
    answers_to_us_tax_questions:
    | null
    | (Omit<
    NonNullable<ISubmitStandaloneBrokerQuestions>['answers_to_us_tax_questions'],
    'us_ssn' | 'us_tin'
    > & {
      us_ssn: null | undefined;
      us_tin: null | undefined;
    });
  };

/**
 * For each client event type, this defines the type of data that
 * will be logged for that event.
 *
 * Each value type in ClientEventTypeDefs MUST NOT HAVE BEEN DEFINED AS AN "interface", it MUST
 * be defined as a "type".
 * E.g. if you say `interface IFoo {...}` and then do this:
 * `[ClientEvent.FOO]: IFoo`
 * it will not compile.
 */
type ClientEventTypeDefs = {
  [ClientEvent.REFER_COWORKER_ATTEMPTED]: NoAdditionalLoggedData;
  [ClientEvent.CLICKED_BUTTON_OR_ELEMENT]: {
    data_element_id: DataElementId;
  };
  [ClientEvent.ELECTION_SELECTED_CONTRIBUTION_PERCENT]: {
    new_contribution_percent: number;
  };
  [ClientEvent.ELECTION_TOGGLED_CASHLESS_ENABLED]: {
    selected_cashless_enabled_value: boolean;
  };
  [ClientEvent.ENROLLMENT_VISITED_STEP]: { step: EnrollmentStep };
  [ClientEvent.ENROLLMENT_ATTEMPTED]: EnrollmentOrElectionChangeEventData;
  [ClientEvent.ENROLLMENT_ATTEMPT_FAILED]: FailedEnrollmentOrElectionChangeEventData;
  [ClientEvent.ELECTION_UPDATE_ATTEMPTED]: EnrollmentOrElectionChangeEventData;
  [ClientEvent.ELECTION_UPDATE_ATTEMPT_FAILED]: FailedEnrollmentOrElectionChangeEventData;
  [ClientEvent.CLICKED_RESOURCE_LIBRARY_LINK]: {
    unique_id: string;
  };
  [ClientEvent.SUBMIT_STANDALONE_BROKER_QUESTIONS_ATTEMPTED]: BrokerQuestionsWithRedactedIDNumbers;
  [ClientEvent.SUBMIT_STANDALONE_BROKER_QUESTIONS_FAILED]: {
    failure_reason: string;
  };
  // [ClientEvent.LOGGED_IN]: NoAdditionalLoggedData;
  // [ClientEvent.FORCED_REDIRECT_TO_LOGIN_PAGE]: NoAdditionalLoggedData;
};

/**
 * This uses the type system to enforce that:
 * 1. All keys defined in ClientEvent have value types defined in ClientEventTypeDefs
 * 2. All value types in ClientEventTypeDefs are JSON-serializable
 *
 * If you get a weird type error in here, you probably added a new key to ClientEvent
 * but didn't define a type for its value in ClientEventTypeDefs.
 *
 * https://stackoverflow.com/a/74022349/5602521
 */
export type ClientEventTypes = {
  [Key in ClientEvent as ClientEventTypeDefs[Key] extends JSONSerializableObject
    ? Key
    : never]: EnforceClientEventTypeValues<ClientEventTypeDefs[Key]>;
};
