/* eslint-disable */
import { useMediaQuery } from '@mui/material';

export function UseIsSmallScreen() {
  const isSmallScreenQuery = useMediaQuery('(max-width: 640px)');

  return isSmallScreenQuery;
}

export default function UseIsMobile() {
  const isMobileQuery = useMediaQuery('(max-width: 900px)');

  return isMobileQuery;
}

export function UseIsDevView() {
  const isDevViewQuery = useMediaQuery('(max-width: 910px)');

  return isDevViewQuery;
}

export function UseIsSmallTab() {
  const isSmallTabQuery = useMediaQuery('(max-width: 1000px)');

  return isSmallTabQuery;
}

export function UseIsTab() {
  const isTabQuery = useMediaQuery('(max-width: 1050px)');

  return isTabQuery;
}

export function UseIsLaptop() {
  const isLaptopQuery = useMediaQuery('(max-width: 1366px)');

  return isLaptopQuery;
}
