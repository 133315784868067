import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useBuildersToProcessGqlData } from '../../../../../dotcms/use-builders-to-process-gql-data';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';
import { GlobalLoadingStateOperation } from '../../../global-loading-context/global-loading-context';
import { useAppSelector } from '../../../../../app/hooks';
import {
  authSelector,
  uiSelector,
  userSelector,
} from '../../../../../selectors';
import { ReminderToEnrollPopupsGqlHelper } from '../../../../../dotcms/dotcms-models/popup/dotcms-reminder-to-enroll-popups';
import { getItem } from '../../../../../hooks/useCookie';
import { COOKIES_SESSION_EXPIRATION_KEY } from '../../../../../utils/manageCookies';
import { useLocalStorage } from '../../../../../utils/storage/localstorage/use-local-storage';
import { LocalStorageKey } from '../../../../../utils/storage/localstorage/localstorage';
import {
  EnrollmentPlanCashless,
  EnrollmentPlanEspp,
  useGetEnrollmentPlan,
} from '../../../../../hooks/use-get-enrollment-plan/use-get-enrollment-plan';
import { UserEnrolledState } from '../../../../../hooks/use-get-user-states-in-enrollment/use-get-user-enrolled-state';
import { useEnrollmentPeriodOpenInfo } from '../../../../../hooks/use-get-periods-info/use-enrollment-period/use-enrollment-period-open-info';
import { useGetUserStatesInEnrollment } from '../../../../../hooks/use-get-user-states-in-enrollment';

export const REMINDER_TO_ENROLL_POPUP_SESSION_STORAGE_KEY =
  'ce_reminder-to-enroll-popup-shown';

const useReminderToEnrollPopup = () => {
  const { isAuthenticated } = useAppSelector(authSelector);
  const { stockPlanAdmin, externalEnrollmentSiteUrl } =
    useAppSelector(uiSelector);
  const { externalEnrollmentPending } = useAppSelector(userSelector);
  const { isEsppEnrollmentOpen } = useEnrollmentPeriodOpenInfo();
  const enrollmentPlan = useGetEnrollmentPlan();
  const userStates = useGetUserStatesInEnrollment();
  const { getValueFromLocalStorage, setValueToLocalStorage } = useLocalStorage(
    LocalStorageKey.REMINDER_TO_ENROLL_POPUP_LAST_SESSION,
  );

  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(ReminderToEnrollPopupsGqlHelper);
  const {
    data,
    loading,
    error: cmsError,
  } = useFetchIssuerDotcmsContentWithDefaultFallback(
    createGqlQueryForSingle,
    processGqlDataAsSingle,
    GlobalLoadingStateOperation.REMINDER_TO_ENROLL_POPUP_DOTCMS_FETCH,
    contentTypeName,
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (
      isAuthenticated &&
      enrollmentPlan.espp === EnrollmentPlanEspp.EXTERNAL_ESPP &&
      enrollmentPlan.cashless ===
        EnrollmentPlanCashless.INTERNAL_CASHLESS_PARTICIPATION &&
      isEsppEnrollmentOpen &&
      (userStates.userFlowState.userStatesInPeriodOpOrPp.userEnrolledState ===
        UserEnrolledState.UserNotEnrolled ||
        externalEnrollmentPending)
    ) {
      const currentSessionId = getItem(COOKIES_SESSION_EXPIRATION_KEY);
      const popupShownSession = getValueFromLocalStorage();
      if (
        !popupShownSession?.sessionId ||
        (currentSessionId && popupShownSession.sessionId !== currentSessionId)
      ) {
        setDialogOpen(true);
        setValueToLocalStorage({ sessionId: currentSessionId });
      }
    }
  }, [
    isAuthenticated,
    isEsppEnrollmentOpen,
    enrollmentPlan,
    userStates.userStatesInClosestPeriodOpOrPp.userEnrolledState,
    userStates.userStatesInFuturePeriodOpOrPp?.userEnrolledState,
    userStates.userFlowState.userStatesInPeriodOpOrPp.userEnrolledState,
    externalEnrollmentPending,
    getValueFromLocalStorage,
    setValueToLocalStorage,
  ]);

  const handleOnClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return useMemo(
    () => ({
      dialogData: data?.popup,
      dialogOpen,
      loading,
      cmsError,
      handleOnClose,
      externalEnrollmentRoute:
        externalEnrollmentSiteUrl ??
        stockPlanAdmin?.login_url ??
        data?.popup?.primaryButton?.url,
    }),
    [
      data?.popup,
      dialogOpen,
      loading,
      cmsError,
      handleOnClose,
      externalEnrollmentSiteUrl,
      stockPlanAdmin?.login_url,
    ],
  );
};

export default useReminderToEnrollPopup;
