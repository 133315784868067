import { matchPath } from 'react-router';
import { Page, PageUrlPath } from './page-url-path';

export const getPageByPath = (pathname: string): Page | undefined => {
  const maybeThisPage = Object.keys(Page).find((page) => {
    const thisPageMatches = matchPath(pathname, {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      path: PageUrlPath[page as Page],
      strict: false,
      exact: true,
    });
    return thisPageMatches;
  });

  if (maybeThisPage) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return maybeThisPage as Page;
  }

  // Else, we didn't find the page. This shouldn't happen:
  console.error(
    'Failed to match page route in getPageByPath. The pathname is:',
    pathname,
  );

  return undefined;
};
