import { BREAKPOINT } from './breakpoints';

// https://www.figma.com/file/pv2HV6DTSKY612VWeYWYdL/CE22-Design-System?node-id=291%3A1433

type CssPixelSize = `${number}px`;

export const convertCssPixelSizeToNumber = (pixelSize: CssPixelSize): number =>
  Number(pixelSize.slice(0, pixelSize.length - 2));

interface GridConfig {
  spacing: CssPixelSize;
  columnCount: number;
  marginColumnCount: number;
}

export const getAvailableColumnsCount = (config: GridConfig) =>
  config.columnCount - config.marginColumnCount;

export const GRID_MARGIN_PIXELS = 24;

export const GRID_CONFIGS_FOR_BREAKPOINTS: {
  [breakpoint in BREAKPOINT]: GridConfig;
} = {
  [BREAKPOINT.DESKTOP]: {
    // Desktop is a special case. In theory there are 12 columns, but the outermost 2 columns
    // are not used and are essentially treated as extra margin.
    spacing: '24px',
    columnCount: 12,
    marginColumnCount: 2,
  },
  [BREAKPOINT.TABLET]: {
    spacing: '24px',
    columnCount: 12,
    marginColumnCount: 0,
  },
  [BREAKPOINT.TABLET_MINI]: {
    spacing: '16px',
    columnCount: 8,
    marginColumnCount: 0,
  },
  [BREAKPOINT.MOBILE]: {
    spacing: '16px',
    columnCount: 4,
    marginColumnCount: 0,
  },
};
