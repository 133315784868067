import type { HtmlFromDotcms } from './converters-from-dotcms';
import {
  convertDotcmsResponseToRecord,
  wrapDotcmsHtml,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { ILinkData } from './dotcms-link';
import { LinkGqlHelper } from './dotcms-link';
import { buildGqlFieldsQuery } from './gql-builders';
import type { IGqlHelper } from './gql-helper';

export type IDialogData = IBaseObjectData & {
  headline: HtmlFromDotcms;
  content: HtmlFromDotcms;
  primaryButton?: ILinkData;
  secondaryButton?: ILinkData;
};

export const DialogGqlHelper: IGqlHelper<IDialogData> = {
  contentTypeName: 'Dialog',
  gqlFieldsQuery: `
    headline
    content
    primaryButton { ${buildGqlFieldsQuery(LinkGqlHelper)} }
    secondaryButton { ${buildGqlFieldsQuery(LinkGqlHelper)} }
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const {
      headline, content, primaryButton, secondaryButton,
    } =
      convertDotcmsResponseToRecord(gqlResponse);

    return {
      headline: wrapDotcmsHtml(headline),
      content: wrapDotcmsHtml(content),
      primaryButton: LinkGqlHelper.processGqlData(primaryButton),
      secondaryButton: LinkGqlHelper.processGqlData(secondaryButton),
    };
  },
};
