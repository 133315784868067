export enum UrlRouteToken {
  TICKER = 'ticker',
  LIBRARY_RESOURCE_ID = 'libraryResourceId',
  MAGIC_LINK_REDIRECT_DESTINATION = 'magicRedirectDestination',
}

/**
 * To use with useParams from 'react-router'.
 * E.g.:
 * const { ticker } = useParams<IRouterParams>();
 */
export type IRouterParams = {
  [key in UrlRouteToken]: string | undefined;
};

export const convertRelativeToAbsolutePath = (relativePath: string) => {
  const domainPart = `${window.location.protocol}//${window.location.host}`;
  return `${domainPart}${relativePath}`;
};

export const convertAbsoluteUrlToRelativeUrl = (absoluteUrl: string) => {
  const urlObject = new URL(absoluteUrl);
  return urlObject.toString().substring(urlObject.origin.length);
};
