import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useGetAllPurchasePeriods } from '../use-get-all-periods';
import { usePurchasePeriodDates } from './use-purchase-period-dates';

export interface IPurchasePeriodOpenInfo {
  isPurchaseOpen: boolean;
  isPurchaseDateNow: boolean;
}

export const usePurchasePeriodOpenInfo = (): IPurchasePeriodOpenInfo => {
  const { currentPeriod } = useGetAllPurchasePeriods();
  const { currentPurchaseDates } = usePurchasePeriodDates();

  return useMemo(
    () => ({
      isPurchaseOpen: !!currentPeriod?.purchasePeriodStartDate,
      isPurchaseDateNow:
        currentPurchaseDates?.purchaseDate.toISODate() ===
        DateTime.local().toISODate(),
    }),
    [
      currentPeriod?.purchasePeriodStartDate,
      currentPurchaseDates?.purchaseDate,
    ],
  );
};
