/** @jsxImportSource @emotion/react */
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { AriaButtonProps } from 'react-aria';
import { Link } from '../Link';
import { TypographyVariant } from '../Typography/Typography';
import type { IButtonProps } from './button-style-helper';
import { getButtonStyles } from './button-style-helper';
import { BREAKPOINT } from '../../utils/breakpoints';

const LinkButton: React.FC<
PropsWithChildren<
Omit<LinkProps, 'style' | 'children' | 'color'> &
Omit<IButtonProps, 'type'> &
Pick<AriaButtonProps, 'isDisabled'>
>
> = ({
  children,
  variant,
  color,
  size,
  icon,
  theme,
  muiTheme,
  additionalStyles = {},
  dataElementId,
  isDisabled,
  ...props
}) => {
  const buttonClasses = getButtonStyles({
    variant,
    theme,
    size,
    color,
  });

  return (
    <Link
      additionalStyles={[
        buttonClasses,
        // Fixes issue where button width fills parent if it's flex:
        { display: 'inline-flex' },
        additionalStyles,
      ]}
      color="inherit"
      variant={{
        [BREAKPOINT.DESKTOP]: TypographyVariant.MEDIUM_BODY_BOLD,
        [BREAKPOINT.TABLET_MINI]: TypographyVariant.SMALL_BODY_BOLD,
      }}
      dataElementId={dataElementId}
      muiTheme={muiTheme}
      theme={theme}
      aria-disabled={isDisabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {icon && (
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FontAwesomeIcon {...icon} style={{ marginRight: '8px' }} />
        </div>
      )}
      {children}
    </Link>
  );
};

export default LinkButton;
