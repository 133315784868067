import { createContext } from 'react';

/** This is a list of every operation that should cause a global loading state
 * that blocks the screen.
 *
 * Tracking each one separately avoids the problem where if 2 things set the
 * same "loading" boolean, then it will be marked as not loading once the faster
 * one completes, even though the slower one is still loading.
 *
 * To solve the above problem, we could use an integer counter for"how many things
 * are loading", but with such a counter, if there is ever a bug in the incrementing/
 * decrementing logic, it's extremely hard to debug.
 */
export enum GlobalLoadingStateOperation {
  ABOUT_CASHLESS_DOTCMS_CONTENT_FETCH = 'ABOUT_CASHLESS_DOTCMS_CONTENT_FETCH',
  ADMIN_EXPERIENCE_HEADER_DOTCMS_FETCH = 'ADMIN_EXPERIENCE_HEADER_DOTCMS_FETCH',
  ADMIN_ENROLLMENT_STATS_CMS_FETCH = 'ADMIN_ENROLLMENT_STATS_CMS_FETCH',
  ADMIN_EXCHANGE_RATES_CMS_FETCH = 'ADMIN_EXCHANGE_RATES_CMS_FETCH',
  ADMIN_SURVEY_RESULTS_CMS_FETCH = 'ADMIN_SURVEY_RESULTS_CMS_FETCH',
  ADMIN_PAGE_CONTRIBUTIONS_DOTCMS_CONTENT_FETCH = 'ADMIN_PAGE_CONTRIBUTIONS_DOTCMS_CONTENT_FETCH',
  ADMIN_PAGE_PLAN_OVERVIEW_DOTCMS_CONTENT_FETCH = 'ADMIN_PAGE_PLAN_OVERVIEW_DOTCMS_CONTENT_FETCH',
  ADMIN_PAGE_PURCHASE_LOOKUP_DOTCMS_CONTENT_FETCH = 'ADMIN_PAGE_PURCHASE_LOOKUP_DOTCMS_CONTENT_FETCH',
  ADMIN_PAGE_PLAN_ENROLLMENT_CONFIGURATION_DOTCMS_CONTENT_FETCH = 'ADMIN_PAGE_PLAN_ENROLLMENT_CONFIGURATION_DOTCMS_CONTENT_FETCH',
  ADMIN_EXPERIENCE_EMPLOYEE_LOOKUP_TOOL_DOTCMS_FETCH = 'ADMIN_EXPERIENCE_EMPLOYEE_LOOKUP_TOOL_DOTCMS_FETCH',
  ADMIN_DATA_EXCHANGE_DOTCMS_FETCH = 'ADMIN_DATA_EXCHANGE_DOTCMS_FETCH',
  ADMIN_DATA_UPLOAD_DOTCMS_FETCH = 'ADMIN_DATA_UPLOAD_DOTCMS_FETCH',
  ACCOUNT_PAGE_CONTACT_INFO_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_CONTACT_INFO_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_DOCUMENTS_PAGE_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_DOCUMENTS_PAGE_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_MANAGE_ELECTION_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_MANAGE_ELECTION_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_WITHDRAWAL_REASONS_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_WITHDRAWAL_REASONS_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_PURCHASE_DETAILS_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_PURCHASE_DETAILS_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_ROOT_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_ROOT_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_STATUS_SUMMARY_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PAGE_STATUS_SUMMARY_DOTCMS_CONTENT_FETCH',
  ACCOUNT_PAGE_STOCK_INFORMATION_DOTCMS_CONTENT_FETCH = 'ACCOUNT_PACCOUNT_PAGE_STOCK_INFORMATION_DOTCMS_CONTENT_FETCHAGE_STATUS_SUMMARY_DOTCMS_CONTENT_FETCH',
  ALERT_BANNER_DOTCMS_CONTENT_FETCH = 'ALERT_BANNER_DOTCMS_CONTENT_FETCH',
  ANTI_BOT_BROKER_QUESTIONS_LANDING_CONTENT_FETCH = 'ANTI_BOT_BROKER_QUESTIONS_LANDING_CONTENT_FETCH',
  ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME_CONTENT_FETCH = 'ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME_CONTENT_FETCH',
  APIV2_GET_ASSETS = 'APIV2_GET_ASSETS',
  APIV2_VERIFY_COOKIE = 'APIV2_VERIFY_COOKIE',
  APIV2_ISSUER_FEATURE = 'APIV2_ISSUER_FEATURE',
  ARTICLE_PAGE_ARTICLE_DATA_DOTCMS_FETCH = 'ARTICLE_PAGE_ARTICLE_DATA_DOTCMS_FETCH',
  ARTICLE_PAGE_ROOT_DOTCMS_FETCH = 'ARTICLE_PAGE_ROOT_DOTCMS_FETCH',
  AUTO_SALE_CLEARING_BROKER_APEX_ELIGIBILITY_QUESTIONS = 'AUTO_SALE_CLEARING_BROKER_APEX_ELIGIBILITY_QUESTIONS',
  CALCULATOR_DOTCMS_CONTENT_FETCH = 'CALCULATOR_DOTCMS_CONTENT_FETCH',
  CALCULATOR_PAGE_25K_LIMIT = 'CALCULATOR_PAGE_25K_LIMIT',
  COOKIE_BANNER_DOTCMS_CONTENT_FETCH = 'COOKIE_BANNER_DOTCMS_CONTENT_FETCH',
  DISCLAIMER_DOTCMS_FETCH = 'DISCLAIMER_DOTCMS_FETCH',
  DOCUMENT_TITLE_LIST_DOTCMS_FETCH = 'DOCUMENT_TITLE_LIST_DOTCMS_FETCH',
  EMAIL_VERIFICATION_COMPLETE_PAGE_DOTCMS_CONTENT_FETCH = 'EMAIL_VERIFICATION_COMPLETE_PAGE_DOTCMS_CONTENT_FETCH',
  EMAIL_UNSUBSCRIBE_PAGE_DOTCMS_CONTENT_FETCH = 'EMAIL_UNSUBSCRIBE_PAGE_DOTCMS_CONTENT_FETCH',
  ENROLLMENT_CLEARING_BROKER_APEX_ELIGIBILITY_QUESTIONS = 'ENROLLMENT_CLEARING_BROKER_APEX_ELIGIBILITY_QUESTIONS',
  ENROLLMENT_CONFIRMATION_DOTCMS_FETCH = 'ENROLLMENT_CONFIRMATION_DOTCMS_FETCH',
  ENROLLMENT_ELECTIONS_PAGE_DOTCMS_FETCH = 'ENROLLMENT_ELECTIONS_PAGE_DOTCMS_FETCH',
  ENROLLMENT_REVIEW_CONTRACTS_DOTCMS_FETCH = 'ENROLLMENT_REVIEW_CONTRACTS_DOTCMS_FETCH',
  ENROLLMENT_WELCOME_DOTCMS_FETCH = 'ENROLLMENT_WELCOME_DOTCMS_FETCH',
  ENROLLMENT_WRAPPER_DOTCMS_FETCH = 'ENROLLMENT_WRAPPER_DOTCMS_FETCH',
  ESPP_DEFAULT_PAGE_DOTCMS_FETCH = 'ESPP_DEFAULT_PAGE_DOTCMS_FETCH',
  ESPP_RESOURCES_PAGE_DOTCMS_FETCH = 'ESPP_RESOURCES_PAGE_DOTCMS_FETCH',
  FOOTER_DOTCMS_FETCH = 'FOOTER_DOTCMS_FETCH',
  GLOBAL_TEXT_VARIABLES_DOTCMS_FETCH = 'GLOBAL_TEXT_VARIABLES_DOTCMS_FETCH',
  HEADER_DOTCMS_FETCH = 'HEADER_DOTCMS_FETCH',
  HEADER_NAVIGATION_MENU_DOTCMS_FETCH = 'HEADER_NAVIGATION_MENU_DOTCMS_FETCH',
  HOMEPAGE_DOTCMS_FETCH = 'HOMEPAGE_DOTCMS_FETCH',
  INACTIVITY_NOTICE_MODAL_DOTCMS_FETCH = 'INACTIVITY_NOTICE_MODAL_DOTCMS_FETCH',
  IDENTITY_VERIFICATION_POPUP_DOTCMS_FETCH = 'IDENTITY_VERIFICATION_POPUP_DOTCMS_FETCH',
  LOGIN_INFO_DOTCMS_FETCH = 'LOGIN_INFO_DOTCMS_FETCH',
  LOGIN_FORM_SSO_DOTCMS_FETCH = 'LOGIN_FORM_SSO_DOTCMS_FETCH',
  LOGIN_FORM_DOB_DOTCMS_FETCH = 'LOGIN_FORM_DOB_DOTCMS_FETCH',
  PAGE_TITLE_LIST_HELPER_DOTCMS_CONTENT_FETCH = 'PAGE_TITLE_LIST_HELPER_DOTCMS_CONTENT_FETCH',
  SESSION_TIMEOUT_POPUPS_DOTCMS_FETCH = 'SESSION_TIMEOUT_POPUPS_DOTCMS_FETCH',
  OPERATION_BUTTON_DOTCMS_FETCH = 'OPERATION_BUTTON_DOTCMS_FETCH',
  PRIVACY_POLICY_DOTCMS_FETCH = 'PRIVACY_POLICY_DOTCMS_FETCH',
  REDIRECTING_TO_EXTERNAL_PAGE = 'REDIRECTING_TO_EXTERNAL_PAGE',
  REFER_COWORKER_PAGE_DOTCMS_FETCH = 'REFER_COWORKER_PAGE_DOTCMS_FETCH',
  REMINDER_TO_ENROLL_POPUP_DOTCMS_FETCH = 'REMINDER_TO_ENROLL_POPUP_DOTCMS_FETCH',
  RESOURCES_PAGE_25K_LIMIT = 'RESOURCES_PAGE_25K_LIMIT',
  RESOURCES_PAGE_TAX_GUIDE = 'RESOURCES_PAGE_TAX_GUIDE',
  STOCK_PRICE_INFO_TRANSLATIONS_DOTCMS_FETCH = 'STOCK_PRICE_INFO_TRANSLATIONS_DOTCMS_FETCH',
  TAX_INFORMATION_DOTCMS_FETCH = 'TAX_INFORMATION_DOTCMS_FETCH',
  TERMS_OF_SERVICE_DOTCMS_FETCH = 'TERMS_OF_SERVICE_DOTCMS_FETCH',
  WEALTH_MANAGEMENT_PAGE_DOTCMS_CONTENT_FETCH = 'WEALTH_MANAGEMENT_PAGE_DOTCMS_CONTENT_FETCH',
  EXPRESSION_OF_NOTICE_POPUPS_FETCH = 'EXPRESSION_OF_NOTICE_POPUPS_FETCH',
  AUTOMATIC_SIGNOUT_POPUP_DOTCMS_FETCH = 'AUTOMATIC_SIGNOUT_POPUP_DOTCMS_FETCH',
}

export type GlobalLoadingContextData = {
  displayLoadingScreenOverlay: boolean;
  displayRenderBlockingLoadingScreen: boolean;
  currentLoadingOperationsForDebugging: ReadonlyArray<string>;
  setOperationLoading: (
    operation: GlobalLoadingStateOperation,
    isLoading: boolean,
  ) => void;
};

// Defaults for context are only used if a component tries to fetch context that doesn't
// exist in its parent tree. This variable will probably only ever be read by tests:
const LOADING_DEFAULT: GlobalLoadingContextData = {
  displayLoadingScreenOverlay: false,
  displayRenderBlockingLoadingScreen: false,
  currentLoadingOperationsForDebugging: [],
  setOperationLoading: () => {},
};

export const GlobalLoadingContext =
  createContext<GlobalLoadingContextData>(LOADING_DEFAULT);
