import { createContext } from 'react';

export type HalfMagicContextData = {
  halfMagicLoggedIn: boolean;
  loading: boolean;
};

const HALF_MAGIC_DEFAULT_CONTEXT: HalfMagicContextData = {
  halfMagicLoggedIn: false,
  loading: false,
};

export const HalfMagicContext = createContext<HalfMagicContextData>(
  HALF_MAGIC_DEFAULT_CONTEXT,
);
