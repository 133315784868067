import { useMemo } from 'react';
import { useBuildersToProcessGqlData } from '../../../../dotcms/use-builders-to-process-gql-data';
import { NavigationGqlHelper } from '../../../../dotcms/dotcms-models/dotcms-navigation';
import { GlobalLoadingStateOperation } from '../../global-loading-context/global-loading-context';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';

const useFetchNavigationMenuData = () => {
  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(NavigationGqlHelper);
  const { data, loading, error } =
    useFetchIssuerDotcmsContentWithDefaultFallback(
      createGqlQueryForSingle,
      processGqlDataAsSingle,
      GlobalLoadingStateOperation.HEADER_NAVIGATION_MENU_DOTCMS_FETCH,
      contentTypeName,
    );

  return useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, error, loading],
  );
};

export default useFetchNavigationMenuData;
