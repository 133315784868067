import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router';
import { SessionTimeoutPopupGqlHelper } from '../../../../../dotcms/dotcms-models/popup/dotcms-session-timeout-popups';
import { GlobalLoadingStateOperation } from '../../../global-loading-context/global-loading-context';
import { useBuildersToProcessGqlData } from '../../../../../dotcms/use-builders-to-process-gql-data';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../../../../../dotcms/use-fetch-from-dotcms-with-default-fallback';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { authSelector, uiSelector } from '../../../../../selectors';
import { useGetIssuerShortname } from '../../../../../hooks/use-get-issuer-shortname';
import { getItem } from '../../../../../hooks/useCookie';
import { SESSION_WARNING_OFFSET_MS } from '../../../../../utils/constants';
import {
  setSessionEndModal,
  setSessionWarningModal,
} from '../../../../../slices/ui';
import { logoutSuccess, setAuthError } from '../../../../../slices/auth';
import { COOKIES_SESSION_EXPIRATION_KEY } from '../../../../../utils/manageCookies';

const useSessionTimeoutPopups = () => {
  const { sessionWarningModal, sessionEndModal } = useAppSelector(uiSelector);
  const { isAuthenticated } = useAppSelector(authSelector);
  const companyShortName = useGetIssuerShortname();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(SessionTimeoutPopupGqlHelper);
  const { data, loading, error } =
    useFetchIssuerDotcmsContentWithDefaultFallback(
      createGqlQueryForSingle,
      processGqlDataAsSingle,
      GlobalLoadingStateOperation.SESSION_TIMEOUT_POPUPS_DOTCMS_FETCH,
      contentTypeName,
    );

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setDialogOpen(sessionEndModal || sessionWarningModal);
  }, [sessionEndModal, sessionWarningModal]);

  const handleLogoutUrl = useCallback(() => {
    const path = `/${companyShortName ?? ''}`;
    history.push(path.toLowerCase());
  }, [history, companyShortName]);

  const handleLogoutDueToSessionTimeout = useCallback(() => {
    if (isAuthenticated) {
      dispatch(setSessionWarningModal(false));
      dispatch(logoutSuccess());
      handleLogoutUrl();
      dispatch(setSessionEndModal(true));
    }
  }, [dispatch, isAuthenticated, handleLogoutUrl]);

  useEffect(() => {
    // We need to keep track of the timeouts that we set, so that we can clean
    // them up if the useEffect has to rerun (otherwise we'll schedule duplicates):
    const timeoutsToUnsetOnUnmount: ReturnType<typeof setTimeout>[] = [];

    const sessionExpirationCookie = getItem(COOKIES_SESSION_EXPIRATION_KEY);
    if (sessionExpirationCookie && isAuthenticated) {
      const sessionExpirationUnixEpochMs =
        Number(sessionExpirationCookie.split('.')[0]) * 1000;
      if (new Date() < new Date(sessionExpirationUnixEpochMs)) {
        const timeToExpire =
          sessionExpirationUnixEpochMs - new Date().getTime();
        const warningTimeoutId = setTimeout(() => {
          dispatch(setSessionWarningModal(true));
          timeoutsToUnsetOnUnmount.push(warningTimeoutId);
        }, timeToExpire - SESSION_WARNING_OFFSET_MS);
        const logoutTimeoutId = setTimeout(() => {
          handleLogoutDueToSessionTimeout();
        }, timeToExpire);
        timeoutsToUnsetOnUnmount.push(logoutTimeoutId);
      }
    }
    // Important to clean up these timeouts on unmount!
    return () => timeoutsToUnsetOnUnmount.forEach((timeoutId) => clearTimeout(timeoutId));
  }, [isAuthenticated, dispatch, handleLogoutDueToSessionTimeout]);

  const handleOnClose = useCallback(() => {
    dispatch(setSessionWarningModal(false));
    dispatch(setSessionEndModal(false));
    dispatch(setAuthError(''));
  }, [dispatch]);

  return useMemo(
    () => ({
      // eslint-disable-next-line no-nested-ternary
      dialogData: sessionEndModal
        ? data?.sessionTimeoutPopup
        : sessionWarningModal
          ? data?.sessionTimeoutWarningPopup
          : null,
      error,
      loading,
      dialogOpen,
      isSessionEndModal: sessionEndModal,
      setDialogOpen,
      handleOnClose,
    }),
    [
      data,
      error,
      loading,
      dialogOpen,
      setDialogOpen,
      handleOnClose,
      sessionEndModal,
      sessionWarningModal,
    ],
  );
};

export default useSessionTimeoutPopups;
