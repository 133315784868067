import type {
  DotcmsPlanType,
  PlanDetailsForFilteringContent,
} from './dotcms-models/dotcms-plan-type-category';
import { isContentAllowedByPlan } from './dotcms-models/dotcms-plan-type-category';
import type { RecursiveObject, RecursiveObjectChild } from './recursive-types';

/** Given an array of dotcms response objects with possible 'restrictToPlanType' property,
 *  this returns a copy of that array, after filtering out any objects that were restricted
 *  to a plan type not matching the current plan.
 */
const filterOutRestrictedArrayValuesForPlan = <
  T extends RecursiveObjectChild<unknown>,
>(
    arrayToFilter: T[],
    planDetails: PlanDetailsForFilteringContent | null,
    isCashlessOnly: boolean,
  ): T[] => {
  if (
    arrayToFilter.length === 0 ||
    !Object.prototype.hasOwnProperty.call(
      arrayToFilter?.[0],
      'restrictToPlanType',
    )
  ) {
    // This is not an array containing values that need to be filtered.
    // Only arrays of objects with the 'restrictToPlanType' property should be filtered.
    return arrayToFilter;
  }

  return arrayToFilter.filter((value) => {
    if (typeof value !== 'object') {
      // Type narrowing:
      return true;
    }
    if (value === null) {
      return true;
    }
    if (Array.isArray(value)) {
      return true;
    }
    if (Object.prototype.hasOwnProperty.call(value, 'restrictToPlanType')) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      const restrictToPlanTypeRaw = (value as any).restrictToPlanType;
      if (!restrictToPlanTypeRaw) {
        // A nullish/falsy value for restrictToPlanType
        // means this entry is not restricted to any plan
        // and should not be filtered out.
        return true;
      }
      if (typeof restrictToPlanTypeRaw !== 'string') {
        console.error(
          'restrictToPlanTypeRaw was not string. This should never happen',
        );
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const restrictToPlanType = restrictToPlanTypeRaw as DotcmsPlanType;
      return isContentAllowedByPlan(
        restrictToPlanType,
        planDetails,
        isCashlessOnly,
      );
    }
    // If the object didn't have the 'restrictToPlanType' property,
    // it should never be filtered out:
    return true;
  });
};

const itemHasFilterableCountryAllowList = <
  T extends NonNullable<RecursiveObjectChild<unknown>>,
>(
    item: T,
  ): item is T & { countryAllowList: Readonly<string> } => {
  if (!Object.prototype.hasOwnProperty.call(item, 'countryAllowList')) {
    return false;
  }
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const countryAllowListRaw = (item as any).countryAllowList as unknown;

  if (!Array.isArray(countryAllowListRaw)) {
    return false;
  }

  if (countryAllowListRaw.length === 0) {
    // If the countryAllowList is empty, it should not be used for filtering.
    return false;
  }

  // If the type of the first array element is a string, assume that it's a valid
  // countryAllowList.
  return typeof countryAllowListRaw[0] === 'string';
};

const itemHasFilterableCountryBlockList = <
  T extends NonNullable<RecursiveObjectChild<unknown>>,
>(
    item: T,
  ): item is T & { countryBlockList: Readonly<string> } => {
  if (!Object.prototype.hasOwnProperty.call(item, 'countryBlockList')) {
    return false;
  }
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const countryBlockListRaw = (item as any).countryBlockList as unknown;

  if (!Array.isArray(countryBlockListRaw)) {
    return false;
  }

  if (countryBlockListRaw.length === 0) {
    // If the countryBlockList is empty, it should not be used for filtering.
    return false;
  }

  // If the type of the first array element is a string, assume that it's a valid
  // countryBlockList.
  return typeof countryBlockListRaw[0] === 'string';
};

/** Given an array of dotcms response objects with possible 'countryAllowList' or
 *  'countryBlockList' properties, this returns a copy of that array, after
 *  filtering out any objects that are restricted from being displayed to the current
 *  country.
 */
const filterOutRestrictedArrayValuesForJurisdiction = <
  T extends RecursiveObjectChild<unknown>,
>(
    arrayToFilter: T[],
    currentCountryCode: string,
  ): T[] => {
  if (
    arrayToFilter.length === 0 ||
    (!Object.prototype.hasOwnProperty.call(
      arrayToFilter?.[0],
      'countryAllowList',
    ) &&
      !Object.prototype.hasOwnProperty.call(
        arrayToFilter?.[0],
        'countryBlockList',
      ))
  ) {
    // This is not an array containing values that need to be filtered. We can just return
    // it unchanged.
    return arrayToFilter;
  }

  return arrayToFilter.filter((value) => {
    if (typeof value !== 'object') {
      // Type narrowing:
      return true;
    }
    if (value === null) {
      return true;
    }
    if (Array.isArray(value)) {
      return true;
    }
    if (itemHasFilterableCountryAllowList(value)) {
      const { countryAllowList } = value;
      return countryAllowList.includes(currentCountryCode);
    }
    if (itemHasFilterableCountryBlockList(value)) {
      const { countryBlockList } = value;
      return !countryBlockList.includes(currentCountryCode);
    }
    // If the object didn't have filterable 'countryAllowList' or 'countryBlockList' properties,
    // it should never be filtered out:
    return true;
  });
};

const recursivelyFilterIneligibleItemsInner = <
  TValueType extends RecursiveObjectChild<unknown>,
>(
    inputItemToFilter: TValueType,
    planDetails: PlanDetailsForFilteringContent | null,
    isCashlessOnly: boolean,
    currentCountryCode: string,
  ): TValueType => {
  if (typeof inputItemToFilter !== 'object') {
    return inputItemToFilter;
  }
  if (inputItemToFilter === null) {
    return inputItemToFilter;
  }
  if (Array.isArray(inputItemToFilter)) {
    // Array.isArray casts the value to 'any[]',
    // so we need to narrow the type manually here:
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const valueWithType = inputItemToFilter as RecursiveObjectChild<unknown>[];
    const filteredByPlanDetailsArray = filterOutRestrictedArrayValuesForPlan(
      valueWithType,
      planDetails,
      isCashlessOnly,
    );

    // Now also filter by jurisdiction:
    const filteredArray = filterOutRestrictedArrayValuesForJurisdiction(
      filteredByPlanDetailsArray,
      currentCountryCode,
    );

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return filteredArray.map((itemInInnerArray) =>
      recursivelyFilterIneligibleItemsInner(
        itemInInnerArray,
        planDetails,
        isCashlessOnly,
        currentCountryCode,
      )) as TValueType;
  }

  // Else, if we got here, 'inputItemToFilter' is an Object of type:
  // { [key: string]: RecursiveObjectChild }

  const filteredObjectEntries = Object.entries(inputItemToFilter).map(
    ([key, value]) => [
      key,
      recursivelyFilterIneligibleItemsInner(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value as RecursiveObjectChild<unknown>,
        planDetails,
        isCashlessOnly,
        currentCountryCode,
      ),
    ],
  );

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.fromEntries(filteredObjectEntries) as TValueType;
};

/**
 * This recursively iterates over everything in a given dotcms response,
 * and any time it sees an array, it applies filtering logic to the array based on
 * the elements' "restrictToPlanType", "countryAllowList", or "countryBlockList"
 * fields
 */
export const recursivelyFilterIneligibleItems = <
  TExpectedResponseType extends
  | RecursiveObjectChild<unknown>[]
  | RecursiveObject<unknown>,
>(
    gqlResponse: TExpectedResponseType,
    planDetails: PlanDetailsForFilteringContent | null,
    isCashlessOnly: boolean,
    currentCountryCode: string,
  ): TExpectedResponseType =>
  // We have this inner function broken out separately because it has a less
  // specific type signature, since it has to call itself recursively
    recursivelyFilterIneligibleItemsInner(
      gqlResponse,
      planDetails,
      isCashlessOnly,
      currentCountryCode,
    );
