import { v4 as uuidV4 } from 'uuid';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';

export const generateUuid = (): string => {
  try {
    // We have to check if the function exists because not all browsers support this yet:
    if ('crypto' in window && typeof window.crypto.randomUUID === 'function') {
      return window.crypto.randomUUID();
    }
    return uuidV4();
  } catch (error) {
    // This probably should never actually throw, but better safe than sorry
    captureErrorInSentryWithCustomMessage(error, 'Error generating UUID');
    return 'failed-to-generate-uuid';
  }
};
