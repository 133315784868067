import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';
import type { HtmlFromDotcms } from './converters-from-dotcms';
import {
  convertDotcmsResponseToRecord,
  wrapDotcmsHtml,
} from './converters-from-dotcms';
import type { IDialogData } from './dotcms-dialog';
import { DialogGqlHelper } from './dotcms-dialog';
import { buildGqlFieldsQuery } from './gql-builders';

export type IOperationButtonLabels = IBaseObjectData & {
  enrollNowLabel: HtmlFromDotcms | undefined;
  addCashlessParticipationLabel: HtmlFromDotcms | undefined;
  cashlessMatchLabel: HtmlFromDotcms | undefined;
  scheduleEnrollmentLabel: HtmlFromDotcms | undefined;
  stayEnrolledDialog?: IDialogData;
};

export const OperationButtonLabelsGqlHelper: IGqlHelper<IOperationButtonLabels> =
  {
    contentTypeName: 'OperationButtonLabels',
    gqlFieldsQuery: `
      enrollnowlabel
      addcashlessparticipationlabel
      cashlessmatchlabel
      scheduleenrollmentlabel
      stayEnrolledDialog { ${buildGqlFieldsQuery(DialogGqlHelper)} }
    `,
    processGqlData: (gqlResponse: unknown) => {
      if (!gqlResponse) {
        return undefined;
      }

      const {
        enrollnowlabel,
        addcashlessparticipationlabel,
        cashlessmatchlabel,
        scheduleenrollmentlabel,
        stayEnrolledDialog,
      } = convertDotcmsResponseToRecord(gqlResponse);

      return {
        enrollNowLabel: wrapDotcmsHtml(enrollnowlabel),
        addCashlessParticipationLabel: wrapDotcmsHtml(
          addcashlessparticipationlabel,
        ),
        cashlessMatchLabel: wrapDotcmsHtml(cashlessmatchlabel),
        scheduleEnrollmentLabel: wrapDotcmsHtml(scheduleenrollmentlabel),
        stayEnrolledDialog: DialogGqlHelper.processGqlData(stayEnrolledDialog),
      };
    },
  };
