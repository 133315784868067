import { useMemo } from 'react';
import type { IEnrollmentPeriodDates } from './use-enrollment-period';
import { useEnrollmentPeriodDates } from './use-enrollment-period';
import type { IOfferingPeriodDates } from './use-offering-period';
import { useOfferingPeriodDates } from './use-offering-period';
import type { IPurchasePeriodDates } from './use-purchase-period';
import { usePurchasePeriodDates } from './use-purchase-period';

export interface IPeriodsDatesInfo
  extends IEnrollmentPeriodDates,
  IOfferingPeriodDates,
  IPurchasePeriodDates {}

/** This hook returns the calculated dates for the periods.
 * We can have current dates (dates for the period open right now),
 * future dates (dates for the next future period),
 * and closest dates that can be current or future.
 * The closest dates are typically used to display status that is more relevant to the user. */
const useGetPeriodsDatesInfo = (): IPeriodsDatesInfo => {
  const enrollmentDates = useEnrollmentPeriodDates();
  const offerDates = useOfferingPeriodDates();
  const purchaseDates = usePurchasePeriodDates();

  return useMemo(
    () => ({
      ...enrollmentDates,
      ...offerDates,
      ...purchaseDates,
    }),
    [enrollmentDates, offerDates, purchaseDates],
  );
};

export default useGetPeriodsDatesInfo;
