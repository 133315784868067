export enum BooleanFeatureFlag {
  // Make these the same as the flag names configured in LaunchDarkly:
  DOWN_FOR_MAINTENANCE_ALL_ISSUERS = 'down-for-maintenance-all-issuers',
  PREVENT_INACTIVITY_TIMEOUT_SIGNOUT = 'prevent-inactivity-timeout-signout',
  // This will default to true on the client, but be set to false on the LaunchDarkly
  // server. So we can use it as a loading state:
  LAUNCHDARKLY_FLAGS_LOADING = 'launchdarkly-flags-loading',
  ENABLE_REDUX_DEVTOOLS = 'enable-redux-devtools',
}

export const BOOLEAN_FLAG_DEFAULT_VALUES: {
  [key in BooleanFeatureFlag]: boolean;
} = {
  [BooleanFeatureFlag.DOWN_FOR_MAINTENANCE_ALL_ISSUERS]: false,
  [BooleanFeatureFlag.PREVENT_INACTIVITY_TIMEOUT_SIGNOUT]: false,
  // This will default to true on the client, but be set to false on the LaunchDarkly
  // server. So we can use it as a loading state:
  [BooleanFeatureFlag.LAUNCHDARKLY_FLAGS_LOADING]: true,
  [BooleanFeatureFlag.ENABLE_REDUX_DEVTOOLS]: false,
};
