import { createContext } from 'react';
import type { IStockPriceLayoutData } from '../../../dotcms/dotcms-models/dotcms-stock-price-layout';

export interface IStockPriceLayoutFromDotcms {
  data: IStockPriceLayoutData | undefined;
  loading: boolean;
  error: unknown;
}

const STOCK_PRICE_DEFAULT_DATA: IStockPriceLayoutFromDotcms = {
  data: undefined,
  loading: false,
  error: null,
};

const StockPriceLayoutContext = createContext<IStockPriceLayoutFromDotcms>(
  STOCK_PRICE_DEFAULT_DATA,
);

export default StockPriceLayoutContext;
