// https://stackoverflow.com/a/55292366/5602521
/** like string.trim() but it accepts a list of characters to trim */
export function trimAny(str: string, chars: string[]) {
  let start = 0;
  let end = str.length;

  while (start < end && chars.indexOf(str[start]) >= 0) {
    start += 1;
  }

  while (end > start && chars.indexOf(str[end - 1]) >= 0) {
    end -= 1;
  }

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}
