import type { PreloadedState } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../slices';
import {
  BOOLEAN_FLAG_DEFAULT_VALUES,
  BooleanFeatureFlag,
} from '../featureFlags/booleanFlags';
import { LocalStorageKey } from '../utils/storage/localstorage/localstorage';

// Using JSON.parse instead of useFeatureEnabledBoolean hook, because
// of not being able to use hooks outside of React Components
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const flagOverrides = JSON.parse(
  localStorage?.getItem(LocalStorageKey.LAUNCHDARKLY_FLAG_OVERRIDES) || '{}',
);
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const isReduxDevToolsEnabled: boolean =
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  flagOverrides[BooleanFeatureFlag.ENABLE_REDUX_DEVTOOLS] ??
  BOOLEAN_FLAG_DEFAULT_VALUES[BooleanFeatureFlag.ENABLE_REDUX_DEVTOOLS];

export const setupStore = (
  preloadedState?: PreloadedState<RootState>,
  devTools?: boolean,
) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    preloadedState,
    devTools,
  });

export const store = setupStore(undefined, isReduxDevToolsEnabled);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
