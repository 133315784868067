import { useLayoutEffect } from 'react';
import UrlSearchParamKey from '../utils/url-search-params/url-search-params';
import { useStringUrlSearchParam } from '../utils/url-search-params/use-query-url-search-params';

const SECONDS_IN_ONE_WEEK = 7 * 24 * 60 * 60;

/** If a referral token is present in the URL, this sets the referral token
 * value as a cookie, which the backend can read directly when processing an
 * enrollment request.
 */
export const useHandleReferralToken = () => {
  const referralToken = useStringUrlSearchParam(
    UrlSearchParamKey.REFERRAL_TOKEN,
  );

  useLayoutEffect(() => {
    if (referralToken) {
      const domain = window.location.href.includes('localhost')
        ? 'localhost'
        : '.espp.com';
      document.cookie = `referral_token=${referralToken};path=/;domain=${domain};max-age=${SECONDS_IN_ONE_WEEK};`;
    }
  }, [referralToken]);
};
