import type { HtmlFromDotcms } from './converters-from-dotcms';
import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
  wrapDotcmsHtml,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';
import { buildGqlFieldsQuery } from './gql-builders';
import type { INavigationCategoryData } from './dotcms-navigation-category';
import { NavigationCategoryHelper } from './dotcms-navigation-category';
import { processGqlDataAsArray } from '../use-builders-to-process-gql-data';

export type INavigationData = IBaseObjectData & {
  type: string | undefined;
  resourceLearnMoreTitle: string | undefined;
  resourceLearnMoreComment: string | undefined;
  cashlessParticipationCategory: INavigationCategoryData[] | undefined;
  resourcesDataCategory: INavigationCategoryData[] | undefined;
  viewCategoryTitle: HtmlFromDotcms;
};

export const NavigationGqlHelper: IGqlHelper<INavigationData> = {
  contentTypeName: 'NavigationMenu',
  gqlFieldsQuery: `
    type
    resourcelearnmoretitle
    resourcelearnmorecomment
    resourcesdatacategory {
      ${buildGqlFieldsQuery(NavigationCategoryHelper)}
    }
    cashlessparticipationcategory {
      ${buildGqlFieldsQuery(NavigationCategoryHelper)}
    }
    viewCategoryTitle
    `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const {
      type,
      resourcelearnmoretitle,
      resourcelearnmorecomment,
      cashlessparticipationcategory,
      resourcesdatacategory,
      viewCategoryTitle,
    } = convertDotcmsResponseToRecord(gqlResponse);

    return {
      type: convertDotcmsString(type),
      resourceLearnMoreTitle: convertDotcmsString(resourcelearnmoretitle),
      resourceLearnMoreComment: convertDotcmsString(resourcelearnmorecomment),
      cashlessParticipationCategory: processGqlDataAsArray(
        NavigationCategoryHelper,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        cashlessparticipationcategory,
      ),
      resourcesDataCategory: processGqlDataAsArray(
        NavigationCategoryHelper,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        resourcesdatacategory,
      ),
      viewCategoryTitle: wrapDotcmsHtml(viewCategoryTitle),
    };
  },
};
