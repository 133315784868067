import type {
  ChangesDuringOffering,
  ChangesDuringOfferingFromApi,
} from '../selectors/interfaces';

export const convertChangesDuringOfferingFromApi = (
  dataFromApi: ChangesDuringOfferingFromApi,
): ChangesDuringOffering => ({
  canMakeRolloverChange: dataFromApi.can_make_rollover_change,
  cashlessChangeAllowed: dataFromApi.cashlessChangeAllowed,
  changeDirection: dataFromApi.changeDirection,
  changesDuringOffering: dataFromApi.changesDuringOffering,
  resumptionAllowed: dataFromApi.resumptionAllowed,
  resumptionCountsAsIncrease: dataFromApi.resumptionCountsAsIncrease,
  suspensionAllowed: dataFromApi.suspensionAllowed,
  suspensionCountsAsDecrease: dataFromApi.suspensionCountsAsDecrease,
  isInResetChangeWindow: dataFromApi.is_in_reset_change_window,
  changesFrequencyAnyDirection:
    dataFromApi.changeDirection === 'both' &&
    !dataFromApi.limitDirectionSpecific
      ? dataFromApi.changesFrequency
      : null,
  remainingChangesAnyDirection:
    dataFromApi.changeDirection === 'both' &&
    !dataFromApi.limitDirectionSpecific
      ? dataFromApi.remainingChanges
      : null,
  changesFrequencyDown:
    dataFromApi.changeDirection === 'down'
      ? dataFromApi.changesFrequency
      : dataFromApi.changesFrequencyDown,
  remainingChangesDown:
    dataFromApi.changeDirection === 'down'
      ? dataFromApi.remainingChanges
      : dataFromApi.remainingChangesDown,
  changesFrequencyUp:
    dataFromApi.changeDirection === 'up'
      ? dataFromApi.changesFrequency
      : dataFromApi.changesFrequencyUp,
  remainingChangesUp:
    dataFromApi.changeDirection === 'up'
      ? dataFromApi.remainingChanges
      : dataFromApi.remainingChangesUp,
});
