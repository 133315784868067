import { Page } from './page-url-path';

// These should correspond to the field names in the PageTitleList content type
// in the cms
export enum PageTitleKey {
  ABOUT_CASHLESS = 'CashlessParticipationPage',
  ACCOUNT_PAGE_CONTACT_INFORMATION = 'ContactInformationPage',
  ACCOUNT_PAGE_DOCUMENTS = 'DocumentsPage',
  ACCOUNT_PAGE_MANAGE_ELECTION = 'ManageElectionPage',
  ACCOUNT_PAGE_PURCHASE_DETAILS = 'PurchaseSummaryPage',
  ACCOUNT_PAGE_STATUS_SUMMARY = 'AccountStatusPage',
  ACCOUNT_PAGE_STOCK_INFORMATION = 'StockInformationPage',
  ADMIN_CONTRIBUTIONS = 'AdminContributionsPage',
  ADMIN_DATA_EXCHANGE = 'AdminDataExchangePage',
  ADMIN_DATA_UPLOAD = 'AdminDataUploadPage',
  ADMIN_EMPLOYEE_LOOKUP = 'AdminEmployeeLookupPage',
  ADMIN_ENROLLMENT_STATS = 'AdminEnrollmentStatsPage',
  ADMIN_EXCHANGE_RATES = 'AdminExchangeRatesPage',
  ADMIN_PLAN_OVERVIEW = 'AdminPlanOverviewPage',
  ADMIN_PURCHASE_LOOKUP = 'AdminPurchaseLookup',
  ADMIN_PLAN_ENROLLMENT_CONFIGURATION = 'AdminConfiguration',
  ADMIN_STOCK_BASED_COMPENSATION = 'AdminStockBasedCompensationPage',
  ADMIN_SURVEY_RESULTS = 'AdminSurveyResultsPage',
  ARTICLE_PAGES_SUFFIX = 'SuffixArticlesPages',
  AUTO_SALE_BROKER_QUESTIONS = 'AutoSaleBrokerQuestionsPage',
  CALCULATOR = 'EsppCalculatorPage',
  DEFAULT = 'Default',
  EMAIL_VERIFICATION_COMPLETE = 'EmailVerificationCompletePage',
  ENROLLMENT_PAGE = 'EnrollmentPage',
  ENROLLMENT_PAGE_CONFIRMATION = 'EnrollmentConfirmationPage',
  ENROLLMENT_PAGE_WELCOME = 'EnrollmentWelcomePage',
  HOMEPAGE = 'Homepage',
  IMPORTANT_INFORMATION = 'ImportantInformationPage',
  LOGIN_PAGE = 'LoginPage',
  PRIVACY_POLICY = 'PrivacyPage',
  REFERRAL = 'ReferralPage',
  RESOURCE_LIBRARY = 'EsppResourcesPage',
  TAX_INFORMATION = 'TaxInformationPage',
  TERM_OF_SERVICE = 'TermsOfServicePage',
  UNSUBSCRIBE = 'UnsubscribePage',
  WEALTH_MANAGEMENT = 'WealthManagementPage',
}

export const PageTitleKeyForPage: Readonly<{ [key in Page]: PageTitleKey }> = {
  [Page.ABOUT_CASHLESS]: PageTitleKey.ABOUT_CASHLESS,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: PageTitleKey.ADMIN_CONTRIBUTIONS,
  [Page.ADMIN_DATA_EXCHANGE]: PageTitleKey.ADMIN_DATA_EXCHANGE,
  [Page.ADMIN_DATA_UPLOAD]: PageTitleKey.ADMIN_DATA_UPLOAD,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: PageTitleKey.ADMIN_EMPLOYEE_LOOKUP,
  [Page.ADMIN_ENROLLMENT_STATS]: PageTitleKey.ADMIN_ENROLLMENT_STATS,
  [Page.ADMIN_EXCHANGE_RATES]: PageTitleKey.ADMIN_EXCHANGE_RATES,
  [Page.ADMIN_PLAN_OVERVIEW]: PageTitleKey.ADMIN_PLAN_OVERVIEW,
  [Page.ADMIN_PURCHASE_LOOKUP]: PageTitleKey.ADMIN_PURCHASE_LOOKUP,
  // Admin purchase tabs have same title as overall purchase page for now:
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]:
    PageTitleKey.ADMIN_PURCHASE_LOOKUP,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: PageTitleKey.ADMIN_PURCHASE_LOOKUP,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: PageTitleKey.ADMIN_PURCHASE_LOOKUP,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]:
    PageTitleKey.ADMIN_PLAN_ENROLLMENT_CONFIGURATION,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]:
    PageTitleKey.ADMIN_PLAN_ENROLLMENT_CONFIGURATION,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]:
    PageTitleKey.ADMIN_PLAN_ENROLLMENT_CONFIGURATION,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]:
    PageTitleKey.ADMIN_STOCK_BASED_COMPENSATION,
  [Page.ADMIN_SURVEY_RESULTS]: PageTitleKey.ADMIN_SURVEY_RESULTS,
  [Page.ADMIN_PAGE]: PageTitleKey.DEFAULT,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: PageTitleKey.DEFAULT,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: PageTitleKey.DEFAULT,
  [Page.ACCOUNT_PAGE]: PageTitleKey.DEFAULT,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]:
    PageTitleKey.ACCOUNT_PAGE_CONTACT_INFORMATION,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: PageTitleKey.ACCOUNT_PAGE_DOCUMENTS,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]:
    PageTitleKey.ACCOUNT_PAGE_MANAGE_ELECTION,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]:
    PageTitleKey.ACCOUNT_PAGE_PURCHASE_DETAILS,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: PageTitleKey.ACCOUNT_PAGE_STATUS_SUMMARY,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]:
    PageTitleKey.ACCOUNT_PAGE_STOCK_INFORMATION,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: PageTitleKey.AUTO_SALE_BROKER_QUESTIONS,
  [Page.CALCULATOR]: PageTitleKey.CALCULATOR,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: PageTitleKey.DEFAULT,
  [Page.DEFAULT]: PageTitleKey.DEFAULT,
  [Page.EMAIL_VERIFICATION_COMPLETE]: PageTitleKey.EMAIL_VERIFICATION_COMPLETE,
  [Page.ENROLLMENT_PAGE]: PageTitleKey.ENROLLMENT_PAGE,
  [Page.HOMEPAGE]: PageTitleKey.HOMEPAGE,
  [Page.IMPORTANT_INFORMATION]: PageTitleKey.IMPORTANT_INFORMATION,
  [Page.LOGIN_PAGE]: PageTitleKey.LOGIN_PAGE,
  [Page.PRIVACY_POLICY]: PageTitleKey.PRIVACY_POLICY,
  [Page.REFERRAL]: PageTitleKey.REFERRAL,
  [Page.RESOURCE_LIBRARY]: PageTitleKey.RESOURCE_LIBRARY,
  [Page.RESOURCE_LIBRARY_ID]: PageTitleKey.RESOURCE_LIBRARY,
  [Page.STANDALONE_BROKER_QUESTIONS]: PageTitleKey.DEFAULT,
  [Page.TAX_INFORMATION]: PageTitleKey.TAX_INFORMATION,
  [Page.TEMP_PDF_VIEWER_TEST]: PageTitleKey.DEFAULT,
  [Page.TERM_OF_SERVICE]: PageTitleKey.TERM_OF_SERVICE,
  [Page.UNSUBSCRIBE]: PageTitleKey.UNSUBSCRIBE,
  [Page.WEALTH_MANAGEMENT]: PageTitleKey.WEALTH_MANAGEMENT,
};
