/** CMS fields whose names are in this list will not have their contents replaced when
 * the debug_cms flag is active.
 * (A field should be added here if, for example, it's a configuration value read only by our
 * code, instead of a string that is displayed to the user.)
 */
export const NON_HUMAN_READABLE_CMS_FIELDS: ReadonlySet<string> = new Set([
  'triggerToDisplayAlert',
  'displayConditionsTime',
  'displayConditionsUserState',
  'resourceType',
  'versionPath',
  'focalPoint',
  'width',
  'height',
  'showImage',
  'contentType',
  'identifier',
  'url',
  'urlTitle',
  'imageHorizontalAlignment',
  'path',
  'icon',
  'isMarkedWithLabelNew',
  'topOfPageImageHtml',
]);
