import { useMemo } from 'react';
import { useGetAllOfferingPeriods } from '../use-get-all-periods';

export interface IOfferingPeriodOpenInfo {
  isOfferOpen: boolean;
}

export const useOfferingPeriodOpenInfo = (): IOfferingPeriodOpenInfo => {
  const { currentPeriod } = useGetAllOfferingPeriods();

  return useMemo(
    () => ({
      isOfferOpen: !!currentPeriod?.offeringPeriodStartDate,
    }),
    [currentPeriod?.offeringPeriodStartDate],
  );
};
