import { useSelector } from 'react-redux';
import { uiSelector } from '../selectors';
import { useGetUserLocation } from './use-get-user-location/use-get-user-location';
import type { IPlanDetails } from '../selectors/interfaces';

export const usePlanDetails = (): IPlanDetails | null => {
  const { jurisdictionByCountryCode } = useSelector(uiSelector);
  const { countryCode } = useGetUserLocation();

  return jurisdictionByCountryCode?.[countryCode]?.plan_details ?? null;
};

/**
 * @deprecated Use usePlanDetails instead. This one returns Partial
 * plan details for legacy reasons and that can cause typescript problems
 */
export const usePlanDetailsOldImpl = (): Partial<IPlanDetails> =>
  usePlanDetails() ?? {};
