import { DateTime } from 'luxon';
import {
  getKeyFromConditionalWrapperBrackets,
  processConditionalCmsStringsInContentFromCms,
} from '../conditional-cms-strings';

export type HtmlFromDotcms = {
  __html: string;
};

const checkIfBoolean = (inputValue: unknown): boolean | undefined => {
  const inputIsValid = typeof inputValue === 'boolean';
  return inputIsValid ? inputValue : undefined;
};

const checkIfString = (
  inputValue: unknown,
  inputValueName: string,
): string | undefined => {
  const inputIsValid =
    inputValue === undefined ||
    inputValue === null ||
    typeof inputValue === 'string';
  if (!inputIsValid) {
    console.error(
      `${inputValueName} expected to receive a string but got an argument of type: ${typeof inputValue}. The unexpected value was: ${JSON.stringify(
        inputValue,
      )}`,
    );
    return undefined;
  }

  return inputValue ?? undefined;
};

export const extractAndSanitizeText = (html: unknown): string => {
  if (typeof html !== 'string') {
    return '';
  }

  // https://stackoverflow.com/a/28900362/5602521
  const parsedHtmlDocument = new DOMParser().parseFromString(
    html,
    'text/html',
  ).documentElement;

  return parsedHtmlDocument.textContent || parsedHtmlDocument.innerText;
};

export const extractAndSanitizeTextFromWrappedHtml = (
  wrappedHtml: HtmlFromDotcms,
): string =>
  // eslint-disable-next-line no-underscore-dangle
  extractAndSanitizeText(wrappedHtml.__html);

export const convertDotcmsResponseToRecord = (
  response: unknown,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-explicit-any
): Record<string, any> => response as Record<string, any>;

export const convertDotcmsResponseToRecords = (
  response: unknown,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-explicit-any
): Record<string, any>[] => response as Record<string, any>[];

export const wrapDotcmsHtmlOrUndefined = (
  rawHtmlStringFromDotcms: unknown,
): HtmlFromDotcms | undefined => {
  const inputAsAtring = checkIfString(
    rawHtmlStringFromDotcms,
    'wrapDotcmsHtml',
  );
  return inputAsAtring ? { __html: inputAsAtring } : undefined;
};

export const wrapDotcmsHtml = (
  rawHtmlStringFromDotcms: unknown,
): HtmlFromDotcms => {
  const html = wrapDotcmsHtmlOrUndefined(rawHtmlStringFromDotcms);
  return html || { __html: '' };
};

export const convertDotcmsString = (str: unknown): string | undefined => {
  if (!str) {
    return undefined;
  }

  const inputAsAtring = checkIfString(str, 'string');
  return inputAsAtring;
};

export const convertDotcmsStringNonNullable = (str: unknown): string => {
  if (!str) {
    return '';
  }

  const inputAsAtring = checkIfString(str, 'string');
  return inputAsAtring ?? '';
};

export const convertDotcmsNumber = (num: unknown): number | undefined => {
  if (!num) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return num as number;
};

export const convertDotcmsDateTime = (
  dateTime: unknown,
): DateTime | undefined => {
  if (!dateTime) {
    return undefined;
  }

  const inputAsAtring = checkIfString(dateTime, 'dateTime');
  return inputAsAtring
    ? DateTime.fromJSDate(new Date(inputAsAtring))
    : undefined;
};

export const convertDotcmsBoolean = (bool: unknown): boolean | undefined => {
  if (bool === undefined || bool === null) {
    return undefined;
  }

  const inputAsBool = checkIfBoolean(bool);
  if (inputAsBool !== undefined) {
    return inputAsBool;
  }

  const inputAsAtring = checkIfString(bool, 'boolean');
  return inputAsAtring?.toLowerCase() === 'true';
};

export const convertDotcmsEnumValue = <TEnum>(
  type: TEnum,
  enumValue: unknown,
): TEnum[keyof TEnum] | undefined => {
  if (!enumValue) {
    return undefined;
  }

  const inputAsString = checkIfString(enumValue, 'enumValue');
  if (!inputAsString) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return type[inputAsString as keyof TEnum];
};

export const convertDotcmsEnumValues = <TEnum>(
  type: TEnum,
  enumValues: unknown,
): TEnum[keyof TEnum][] | undefined => {
  if (!enumValues) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (enumValues as unknown[])
    .map((x) => convertDotcmsEnumValue(type, x))
    .filter((x) => x !== undefined) as TEnum[keyof TEnum][];
};

const updateTextContentInHtmlNode = (
  node: ChildNode,
  updateTextContent: (textContent: string | null) => string | null,
) => {
  if (node.childNodes.length === 0) {
    // eslint-disable-next-line no-param-reassign
    node.textContent = updateTextContent(node.textContent);
  } else {
    node.childNodes.forEach((x) =>
      updateTextContentInHtmlNode(x, updateTextContent));
  }
};

// Maybe there is a better solution to update text if we keep using html instead of strings?
const updateTextContentInHtml = (
  html: HtmlFromDotcms,
  updateTextContent: (textContent: string | null) => string | null,
) => {
  const htmlElement = document.createElement('div');
  // eslint-disable-next-line no-underscore-dangle
  htmlElement.innerHTML = html.__html;
  updateTextContentInHtmlNode(htmlElement, updateTextContent);
  const result = wrapDotcmsHtml(htmlElement.innerHTML);
  // Clean up to avoid memory leak:
  htmlElement.remove();
  return result;
};

export const addStringToHtml = (
  html: HtmlFromDotcms,
  placement: 'start' | 'end',
  newStr: string,
): HtmlFromDotcms =>
  updateTextContentInHtml(html, (textContent) =>
    (textContent
      ? `${placement === 'start' ? newStr : ''}${textContent}${
        placement === 'end' ? newStr : ''
      }`
      : null));

export const addFootnoteIcon = (
  html: HtmlFromDotcms,
  placement: 'start' | 'end',
): HtmlFromDotcms => addStringToHtml(html, placement, '*');

export const mergeHtmlTexts = (
  htmls: HtmlFromDotcms[],
  divider: string,
): HtmlFromDotcms =>
  htmls.reduceRight((prev, curr) => {
    const currInnerText = extractAndSanitizeTextFromWrappedHtml(curr);
    return addStringToHtml(prev, 'start', `${currInnerText}${divider}`);
  });

export const replaceVariablesInString = (
  textContent: string | null,
  dynamicVariables: Record<string, string | number>,
) => {
  let newTextContent = textContent;
  Object.keys(dynamicVariables).forEach((key) => {
    newTextContent =
      newTextContent?.replaceAll(key, dynamicVariables[key].toString()) ?? null;
  });
  return newTextContent;
};

export const replaceVariablesInHtml = (
  html: HtmlFromDotcms | undefined,
  dynamicVariables: Record<string, string | number>,
) => {
  if (!html || Object.keys(dynamicVariables).length === 0) {
    return html;
  }

  return updateTextContentInHtml(html, (textContent) =>
    replaceVariablesInString(textContent, dynamicVariables));
};

export const replaceConditionalsInHtml = (
  html: HtmlFromDotcms | undefined,
  dynamicConditionals: Record<string, boolean>,
  cmsContentTypeTitle: string,
  cmsFieldName: string,
) => {
  if (!html || Object.keys(dynamicConditionals).length === 0) {
    return html;
  }

  const preparedDynamicConditionals = Object.entries(dynamicConditionals).map(
    ([key, value]) => ({
      key: getKeyFromConditionalWrapperBrackets(key),
      value,
    }),
  );
  const conditionalArgs = {
    trueConditions: preparedDynamicConditionals
      .filter(({ value }) => value)
      .map(({ key }) => key),
    falseConditions: preparedDynamicConditionals
      .filter(({ value }) => !value)
      .map(({ key }) => key),
    ignoredConditions: [],
    cmsContentTypeTitle,
    fieldNames: [cmsFieldName],
  };
  return updateTextContentInHtml(html, (textContent) =>
    processConditionalCmsStringsInContentFromCms(
      textContent ?? '',
      conditionalArgs,
    ));
};
