export enum Issuer {
  ARAMARK = 'aramark',
  COMPANY_A = 'companya',
  COMPANY_ABC = 'companyabc',
  DENALI = 'denali',
  ZEVRA = 'zevra',
  LUCIDDX = 'luciddx',
  OUTSET_MEDICAL = 'outsetmedical',
  PAVMED = 'pavmed',
  VELFINANCE = 'velfinance',
  HTT = 'htt',
  RBGLOBAL = 'rbglobal',
  AMEXGBT = 'amexgbt',
  TESTCO = 'testco',
  TESTCO_ENROLLMENT = 'testco-enrollment',
  TESTCO_ENROLLMENT_CASHLESS_ONLY = 'testco-enrollment-co',
  RESMED = 'resmed',
  SAMPLECO = 'sampleco',
  ICF = 'icf',
  EBAY = 'ebay',
  MKINDUSTRIES = 'mkindustries',
  OMADA = 'omada',
  PELOTON = 'peloton',
  DOLBY = 'dolby',
  PAYPAL = 'paypal',
}
