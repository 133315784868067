import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { userSelector } from '../../selectors';
import type { IEnrollmentPeriodOpenInfo } from './use-enrollment-period';
import { useEnrollmentPeriodOpenInfo } from './use-enrollment-period';
import type { IOfferingPeriodOpenInfo } from './use-offering-period';
import { useOfferingPeriodOpenInfo } from './use-offering-period';
import type { IPurchasePeriodOpenInfo } from './use-purchase-period';
import { usePurchasePeriodOpenInfo } from './use-purchase-period';

export interface IPeriodsOpenInfo
  extends IEnrollmentPeriodOpenInfo,
  IOfferingPeriodOpenInfo,
  IPurchasePeriodOpenInfo {
  isQuietPeriodBetweenEnrollmentAndOffering: boolean;
  isQuietPeriodInOfferingPeriod: boolean;
  isEnrollmentOpenDuringOfferingPeriod: boolean;
  isTradeRestrictionWindow: boolean;
}

/** This hook returns a set of flags indicating whether a period is open */
const useGetPeriodsOpenInfo = (): IPeriodsOpenInfo => {
  const { inTradeRestrictionWindow, accessESPP } = useSelector(userSelector);
  const enrollmentOpenInfo = useEnrollmentPeriodOpenInfo();
  const offerOpenInfo = useOfferingPeriodOpenInfo();
  const purchaseOpenInfo = usePurchasePeriodOpenInfo();

  return useMemo(
    () => ({
      ...enrollmentOpenInfo,
      ...offerOpenInfo,
      ...purchaseOpenInfo,
      isQuietPeriodBetweenEnrollmentAndOffering:
        !enrollmentOpenInfo.isEnrollmentOpen && !offerOpenInfo.isOfferOpen,
      isQuietPeriodInOfferingPeriod:
        offerOpenInfo.isOfferOpen && !purchaseOpenInfo.isPurchaseOpen,
      isEnrollmentOpenDuringOfferingPeriod:
        offerOpenInfo.isOfferOpen && enrollmentOpenInfo.isEnrollmentOpen,
      isTradeRestrictionWindow: inTradeRestrictionWindow && !accessESPP,
    }),
    [
      enrollmentOpenInfo,
      offerOpenInfo,
      purchaseOpenInfo,
      inTradeRestrictionWindow,
      accessESPP,
    ],
  );
};

export default useGetPeriodsOpenInfo;
