import {
  useState, useCallback, useMemo, useContext,
} from 'react';
import type { Options } from 'use-http';
import { CachePolicies, useFetch } from 'use-http';
import { match } from 'ts-pattern';
import { BASE_URL, RESPONSE_MESSAGE_FROM_SERVER } from '../../utils/constants';
import type { JSONSerializableObject } from '../../utils/json-types';
import { captureErrorInSentryWithCustomMessage } from '../../utils/capture-error-in-sentry-with-custom-message';
import type { GlobalLoadingStateOperation } from '../../components/abc/global-loading-context/global-loading-context';
import { GlobalLoadingContext } from '../../components/abc/global-loading-context/global-loading-context';

export interface IRequestParameters {
  relativeApiUrl: string;
  method: 'GET' | 'POST';
  /** Set this to true if the fetch is immediately called on page load,
   * to avoid having a brief initial render where loading is false but it hasn't
   * loaded yet, which can look bad.
   */
  initialLoadingValue: boolean;
  extraHeaders?: HeadersInit;
  expectedSuccessfulMessages?: string[];
  shouldExpectSuccessfulMessage?: boolean;
}

export interface ICallParameters {
  queryParams?: object;
  body?: BodyInit | object;
  globalLoadingState?: GlobalLoadingStateOperation;
}

export type ProcessResponseType<TExpectedResponseType> = (
  responseData: JSONSerializableObject,
) => TExpectedResponseType | undefined;

export interface IFetchResult<TExpectedResponseType> {
  data: TExpectedResponseType | undefined;
  loading: boolean;
  error: unknown;
}

export const buildApiEndpointUrl = (relativeApiUrl: string) =>
  `${BASE_URL}${relativeApiUrl}`;

export const useFetchFromApi = <TExpectedResponseType>(
  requestParams: IRequestParameters,
) => {
  const { setOperationLoading: setOperationGlobalLoading } =
    useContext(GlobalLoadingContext);
  const [data, setData] = useState<TExpectedResponseType | undefined>(
    undefined,
  );
  const [responseMessage, setResponseMessage] = useState<string | undefined>(
    undefined,
  );
  const [responseDetails, setResponseDetails] = useState<unknown>(undefined);
  const [responseNotOkError, setResponseNotOkError] = useState<
  string | undefined
  >(undefined);

  const endpoint = buildApiEndpointUrl(requestParams.relativeApiUrl);
  const options: Partial<Options> = useMemo(
    () => ({
      method: requestParams.method,
      mode: requestParams.method === 'POST' ? 'cors' : undefined,
      retries: 0,
      persist: false,
      credentials: 'include',
      cachePolicy: CachePolicies.NO_CACHE,
      loading: requestParams.initialLoadingValue,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(requestParams.extraHeaders ? requestParams.extraHeaders : []),
      },
    }),
    [
      requestParams.extraHeaders,
      requestParams.initialLoadingValue,
      requestParams.method,
    ],
  );

  const {
    get,
    post,
    error: errorFromFetch,
    loading,
  } = useFetch(endpoint, options);

  const doRequest = useCallback(
    (
      callParameters: ICallParameters,
      processResponse: ProcessResponseType<TExpectedResponseType>,
      notSuccessStatusesToLog?: RESPONSE_MESSAGE_FROM_SERVER[],
    ) => {
      if (callParameters?.globalLoadingState) {
        setOperationGlobalLoading(callParameters.globalLoadingState, true);
      }
      setData(undefined);
      setResponseMessage(undefined);
      setResponseDetails(undefined);
      setResponseNotOkError(undefined);
      const urlQueryParams = callParameters.queryParams
        ? `?${Object.entries(callParameters.queryParams)
        // this filtering helps to avoid cases when undefined is passed
        // to query parameters like "endpoint?param=undefined"
          .filter(([, value]) => value !== undefined)
        // Must escape special characters when sending via URL:
          .map(
            ([key, value]) => `${key}=${encodeURIComponent(String(value))}`,
          )
          .join('&')}`
        : undefined;
      const parametersToString = () =>
        `${requestParams.method} ${endpoint}${
          urlQueryParams ?? ''
        } ${JSON.stringify(callParameters)}`;

      const requestPromise = match(requestParams.method)
        .with('GET', () => get(urlQueryParams))
        .with('POST', () => post(urlQueryParams, callParameters.body))
        .exhaustive();

      requestPromise
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        .then((resultData: JSONSerializableObject) => {
          try {
            const message = resultData.message?.toString() ?? '';
            setResponseMessage(resultData.message?.toString() ?? '');
            setResponseDetails(resultData.detail);
            const successfulMessages = [
              RESPONSE_MESSAGE_FROM_SERVER.SUCCESS,
              ...(requestParams.expectedSuccessfulMessages ?? []),
            ];
            if (
              successfulMessages.find(
                (x) => x.toLowerCase() === message.toLowerCase(),
              ) ||
              !requestParams.shouldExpectSuccessfulMessage
            ) {
              const processedResponse = processResponse(resultData);
              if (processedResponse) {
                setData(processedResponse);
              }
            } else {
              const errorMessageDescription = `Failed response ${parametersToString()}: message was not "${
                RESPONSE_MESSAGE_FROM_SERVER.SUCCESS
              }": "${message}"`;
              setResponseNotOkError(errorMessageDescription);
              if (
                (notSuccessStatusesToLog ?? []).includes(
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  message as RESPONSE_MESSAGE_FROM_SERVER,
                )
              ) {
                console.error(errorMessageDescription);
              }
            }
          } catch (e) {
            captureErrorInSentryWithCustomMessage(
              e,
              `Failed to parse JSON even though response was ok ${parametersToString()}:
              ${JSON.stringify(resultData)}`,
            );
          }
        })
        .catch((e) => {
          setResponseMessage(undefined);
          setResponseDetails(undefined);
          setResponseNotOkError(undefined);
          const errorMessage = `Error when executing request ${parametersToString()}`;
          captureErrorInSentryWithCustomMessage(e, errorMessage);
          console.error(`${errorMessage}: ${JSON.stringify(e)}`);
        })
        .finally(() => {
          if (callParameters?.globalLoadingState) {
            setOperationGlobalLoading(callParameters.globalLoadingState, false);
          }
        });
    },
    [
      requestParams.method,
      requestParams.expectedSuccessfulMessages,
      requestParams.shouldExpectSuccessfulMessage,
      endpoint,
      get,
      post,
      setOperationGlobalLoading,
    ],
  );

  const error = useMemo(
    () =>
      (!!errorFromFetch || !!responseNotOkError
        ? {
          messageFromResponse: responseMessage,
          detailsFromResponse: responseDetails,
          errorMessage: errorFromFetch ?? responseNotOkError,
        }
        : undefined),
    [errorFromFetch, responseNotOkError, responseMessage, responseDetails],
  );

  return {
    data,
    loading,
    error,
    doRequest,
  };
};
