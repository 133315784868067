import { useEffect, useMemo, useState } from 'react';
import { LocalStorageKey } from '../../utils/storage/localstorage/localstorage';
import { useLocalStorage } from '../../utils/storage/localstorage/use-local-storage';
import { generateUuid } from '../generate-uuid';
import type { DeviceIdContextData } from './device-id-context';

export const useDeviceIdSingletonContextProvider = (): DeviceIdContextData => {
  const { setValueToLocalStorage, getValueFromLocalStorage } = useLocalStorage(
    LocalStorageKey.DEVICE_INFO_INTERNAL_DONT_USE,
  );

  // Only will be used if an existing UUID is not already set:
  const maybeNewDeviceIdToUse = useMemo(() => generateUuid(), []);
  const maybeUuidFromLocalStorage = useMemo(
    () => getValueFromLocalStorage()?.deviceId,
    [getValueFromLocalStorage],
  );

  useEffect(() => {
    if (!maybeUuidFromLocalStorage) {
      setValueToLocalStorage({ deviceId: maybeNewDeviceIdToUse });
    }
  });

  const [deviceId] = useState<string>(
    maybeUuidFromLocalStorage ?? maybeNewDeviceIdToUse,
  );

  return { deviceId };
};
