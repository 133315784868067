import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { GlobalTextVariablesGqlHelper } from '../dotcms-models/dotcms-global-text-variables';
import { useBuildersToProcessGqlData } from '../use-builders-to-process-gql-data';
import { useFetchIssuerDotcmsContentWithDefaultFallback } from '../use-fetch-from-dotcms-with-default-fallback';
import {
  CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT,
  CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT_NAME,
  CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY,
  CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY_NAME,
  CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY,
  CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY_NAME,
  CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT,
  CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT_NAME,
  GlobalTextVariablesContext,
} from './global-text-variables-context';
import { GlobalLoadingStateOperation } from '../../components/abc/global-loading-context/global-loading-context';

export const GlobalTextVariablesContextProvider: React.FC<
PropsWithChildren
> = ({ children }) => {
  const { createGqlQueryForSingle, processGqlDataAsSingle, contentTypeName } =
    useBuildersToProcessGqlData(GlobalTextVariablesGqlHelper);

  const delayedCustomDynamicVariables = useMemo(
    () => [
      CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT_NAME,
      CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT_NAME,
      CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY_NAME,
      CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY_NAME,
    ],
    [],
  );

  const { data } = useFetchIssuerDotcmsContentWithDefaultFallback(
    createGqlQueryForSingle,
    processGqlDataAsSingle,
    GlobalLoadingStateOperation.GLOBAL_TEXT_VARIABLES_DOTCMS_FETCH,
    contentTypeName,
    undefined,
    delayedCustomDynamicVariables,
  );

  const contextData = useMemo(
    () => ({
      ...(data ?? {}),
      customDynamicVariableDisplayedCount:
        CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT,
      customDynamicVariableTotalCount: CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT,
      customDynamicVariableSourceCurrency:
        CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY,
      customDynamicVariableTargetCurrency:
        CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY,
    }),
    [data],
  );

  return (
    <GlobalTextVariablesContext.Provider value={contextData}>
      {children}
    </GlobalTextVariablesContext.Provider>
  );
};
