import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import UseIsMobile from '../../hooks/use-is-mobile';
import { FullPageCenteredContentWrapper } from '../../component_library/components/Grid';

export const DownForMaintenancePage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isMobile = UseIsMobile();
  const Header = styled.p({
    margin: isMobile ? '32px 0' : '40px 0',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: isMobile ? '32px' : '56px',
    lineHeight: '120%',
    textAlign: 'center',
    letterSpacing: '1.34px',
  });
  const SubHeader = styled.p({
    margin: '0',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: isMobile ? '16px' : '20px',
    lineHeight: '160%',
    textAlign: 'center',
    letterSpacing: '0.5px',
  });
  const SpinningGear = styled(FontAwesomeIcon)({
    height: isMobile ? '35px' : '48px',
    width: isMobile ? '35px' : '48px',
    animation: 'spin 3s infinite linear',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(359deg)',
      },
    },
  });

  return (
    <FullPageCenteredContentWrapper>
      <SpinningGear icon={faGear} color="#979797" />
      <Header>Our site is getting a boost</Header>
      <SubHeader>
        We are currently down for maintenance and will be back up soon.
        <br />
        We apologize for any inconvenience while we make improvements to our
        system!
      </SubHeader>
    </FullPageCenteredContentWrapper>
  );
};
