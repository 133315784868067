import React, {
  useContext, useEffect, useLayoutEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import { ErrorBoundary } from 'react-error-boundary';
import Router from './router';
import { setTempDisabled } from '../slices/auth';
import { LOCKOUT_LENGTH, LOCAL_STORAGE_KEY } from '../utils/constants';
import { uiSelector, authSelector, userSelector } from '../selectors';
import CookieBanner from '../components/abc/cookie-banner';
import { manageCookies } from '../utils/manageCookies';
import ThemeProvider from './theme';
import { useFavicon } from '../hooks/useFavicon';
import { LocaleContextProvider } from '../locale/locale-context/locale-context-provider';
import { NavigationMenuContextProvider } from '../components/abc/navigation-menu';
import StockPriceLayoutContextProvider from '../components/layout/stock-price-layout/stock-price-layout-context-provider';
import OperationButtonContextProvider from '../components/OperationButton/operation-button-context-provider';
import FooterContextProvider from '../components/abc/footer/footer-context-provider';
import { GlobalTextVariablesContextProvider } from '../dotcms/contexts/global-text-variables-context-provider';
import { ConditionalLoadingOverlay } from '../components/abc/loading-overlay/loading-overlay';
import { useHandleInitialPageLoad } from '../components/initial-page-load-handler/initial-page-load-handler';
import { GlobalLoadingContext } from '../components/abc/global-loading-context/global-loading-context';
import { useIsGdpr } from '../hooks/use-is-gdpr';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';
import { useGlobalEventLoggerSingleton } from '../events/use-global-event-logger-singleton';
import SessionTimeoutPopup from '../components/abc/popup/session-timeout-popup/SessionTimeoutPopup';
import { useHandleReferralToken } from '../hooks/use-handle-referral-token';
import { LogrocketUserInfoSync } from './logrocket-user-info-sync';
import { RootErrorBoundaryFallback } from '../components/root-error-boundary/root-error-boundary';
import ReminderToEnrollPopup from '../components/abc/popup/reminder-to-enroll-popup/reminder-to-enroll-popup';
import { useAreFeatureFlagsLoading } from '../featureFlags/useAreFeatureFlagsLoading';
import AutomaticSignoutPopup from '../components/abc/popup/automatic-signout-popup/automatic-signout-popup';
import InactivityWarningModal from '../components/inactivity-modals/warning-modal';
import { IssuerFeaturesContextProvider } from '../components/abc/admin-experience/admin-page-navigation/contexts/issuer-features-context-provider';
import {
  PageTitleContextProvider,
  PageTitleListContextProvider,
  useSetPageTitle,
} from '../components/layout/page-title-list';
import { useGetUserLocation } from '../hooks/use-get-user-location/use-get-user-location';
import { usePageRetryWithTimeInterval } from '../components/root-error-boundary/use-page-retry-with-time-interval';
import { OgMetaDataProvider } from './og-metadata-provider';
import { HeaderNavigationContextProvider } from '../components/abc/header/header-navigation-context-provider';

export default function Cashless() {
  useHandleInitialPageLoad();
  useHandleReferralToken();
  const { displayRenderBlockingLoadingScreen } =
    useContext(GlobalLoadingContext);
  const dispatch = useDispatch();
  const location = useLocation();
  useGlobalEventLoggerSingleton();
  const { resetRetryInfoIfNewPageWithoutRetriesOpen } =
    usePageRetryWithTimeInterval();

  const { gtmId } = useSelector(uiSelector);
  const { countryCode } = useGetUserLocation();
  const {
    name: { email },
  } = useSelector(userSelector);
  const cookieAccept = localStorage?.getItem('cookieAccept');

  const launchdarklyLoading = useAreFeatureFlagsLoading();

  const isGdprCountry = useIsGdpr();
  const cookieBannerOpenDefault = isGdprCountry && !cookieAccept;
  const [openCookieBanner, setCookieBannerOpen] = useState(false);

  const { isAuthenticated } = useSelector(authSelector);
  const { setPageTitle } = useSetPageTitle();

  const renderBlockingShouldBlock = launchdarklyLoading;

  useFavicon();

  useLayoutEffect(() => {
    if (document.title === '') {
      setPageTitle();
    }
  }, [setPageTitle]);

  useLayoutEffect(() => {
    const loading = !countryCode || launchdarklyLoading;
    if (cookieBannerOpenDefault && !openCookieBanner && !loading) {
      setCookieBannerOpen(true);
    }
    manageCookies({
      isLoading: loading,
      isControlled: isGdprCountry,
    });
  }, [
    cookieBannerOpenDefault,
    countryCode,
    launchdarklyLoading,
    isGdprCountry,
    openCookieBanner,
  ]);

  useLayoutEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      '--sans',
      'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    );
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.hash]);

  useLayoutEffect(() => {
    if (gtmId) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      (window as any)._GTM_ID = gtmId;
      TagManager.initialize({ gtmId });
    }
  }, [gtmId]);

  useLayoutEffect(() => {
    const persistedRawValue = localStorage?.getItem(LOCAL_STORAGE_KEY);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const persisted = persistedRawValue
        ? JSON.parse(persistedRawValue)
        : null;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (persisted && persisted.tempDisabled) {
        const current = Date.now();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const elapsedTime = current - persisted.tempDisabled;

        if (elapsedTime <= LOCKOUT_LENGTH) {
          dispatch(setTempDisabled(true));
        }
      }
    } catch (error) {
      captureErrorInSentryWithCustomMessage(
        error,
        `Error when parsing the local storage item with key: ${LOCAL_STORAGE_KEY}`,
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && email) {
      Sentry.setUser({ email });
    }
  }, [email, isAuthenticated]);

  // Try to reset retry count on new pages without errors
  useEffect(resetRetryInfoIfNewPageWithoutRetriesOpen, [
    resetRetryInfoIfNewPageWithoutRetriesOpen,
  ]);

  return (
    <IssuerFeaturesContextProvider>
      <ThemeProvider>
        <LocaleContextProvider>
          <OgMetaDataProvider />
          <LogrocketUserInfoSync>
            <HeaderNavigationContextProvider>
              <FooterContextProvider>
                <NavigationMenuContextProvider>
                  <OperationButtonContextProvider>
                    <StockPriceLayoutContextProvider>
                      <PageTitleListContextProvider>
                        <ConditionalLoadingOverlay />
                        <GlobalTextVariablesContextProvider>
                          {/* We have the same RootErrorBoundary in 2 places. One at the very
                          root of the entire application, in case there's an error
                          in some very fundamental code like cashless.tsx. And one lower
                          down here, so that it has access to Redux variables.
                          This one is the preferred one to fire.
                      */}
                          <ErrorBoundary
                            FallbackComponent={RootErrorBoundaryFallback}
                          >
                            {displayRenderBlockingLoadingScreen &&
                            renderBlockingShouldBlock ? null : (
                              <>
                                <AutomaticSignoutPopup />
                                <InactivityWarningModal />
                                <CookieBanner
                                  openCookieBanner={openCookieBanner}
                                  setCookieBannerOpen={setCookieBannerOpen}
                                />
                                <PageTitleContextProvider>
                                  <Router />
                                </PageTitleContextProvider>
                                <SessionTimeoutPopup />
                                <ReminderToEnrollPopup />
                              </>
                              )}
                          </ErrorBoundary>
                        </GlobalTextVariablesContextProvider>
                      </PageTitleListContextProvider>
                    </StockPriceLayoutContextProvider>
                  </OperationButtonContextProvider>
                </NavigationMenuContextProvider>
              </FooterContextProvider>
            </HeaderNavigationContextProvider>
          </LogrocketUserInfoSync>
        </LocaleContextProvider>
      </ThemeProvider>
    </IssuerFeaturesContextProvider>
  );
}
