import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import {
  BASE_URL,
  LOCAL_STORAGE_KEY,
  RESPONSE_MESSAGE_FROM_SERVER,
} from '../utils/constants';
import type { IUserInformationFromApi } from './user';
import { setUserInformation, resetUserState } from './user';
// eslint-disable-next-line import/no-cycle
import {
  fetchCompanyInfo,
  setUiLogout,
  setInEligiblePopup,
  setTerminatedPopup,
  setAlreadyEnrolledPopup,
} from './ui';
import { clearLocalStoragePostLogout } from '../utils/storage/localstorage/clear-local-storage-post-logout';
import UrlSearchParamKey from '../utils/url-search-params/url-search-params';
import { getEncodedLocation } from '../utils/helpers';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';
import { GlobalLoadingStateOperation } from '../components/abc/global-loading-context/global-loading-context';
import type {
  EffectiveDates,
  IAuthSelector,
  IEmail,
  IUser,
} from '../selectors/interfaces';
import type { AppDispatch } from '../app/hooks';

const initialState: IAuthSelector = {
  currentUser: {
    name: { first: 'First', last: 'Last' },
    officialLanguage: '',
  },
  isAuthenticated: false,
  error: '',
  loading: true,
  isLocked: false,
  attempt: 0,
  resetForm: false,
  verifyingCookie: false,
  viewPortal: false,
  viewEnterSSN: false,
  empID: '',
  emailVerificationLogin: {
    email: '',
    emailExists: false,
    error: null,
    verified: false,
  },
};

/* eslint-disable no-param-reassign */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setAttempt: (state) => {
      const newState = { ...state };
      newState.attempt += 1;

      if (newState.attempt >= 3) {
        newState.attempt = 0;
        newState.isLocked = true;
        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({ tempDisabled: Date.now() }),
        );
      }

      return newState;
    },
    setViewPortal: (state, { payload }: PayloadAction<boolean>) => {
      state.viewPortal = payload;
    },
    setTempDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isLocked = payload;
    },
    setVerifyingCookie: (state, { payload }: PayloadAction<boolean>) => {
      state.verifyingCookie = payload;
    },
    setViewEnterSSN: (state, { payload }: PayloadAction<boolean>) => {
      state.viewEnterSSN = payload;
    },
    setResetForm: (state, { payload }: PayloadAction<boolean>) => {
      state.resetForm = payload;
    },
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }: PayloadAction<IUser>) => {
      state.currentUser = payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.viewPortal = false;
    },
    loginError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isAuthenticated = false;
      state.loading = false;
    },
    logout: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.currentUser = {};
      if (state.error !== 'noMoreAttempts') {
        state.error = '';
      }
      state.loading = false;
    },
    setErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setOfficialLanguage: (state, { payload }: PayloadAction<string>) => {
      state.currentUser.officialLanguage = payload;
    },
    setEmployeeId: (state, { payload }: PayloadAction<string>) => {
      state.empID = payload;
    },
    setEmailVerification: (state, { payload }: PayloadAction<IEmail>) => {
      state.emailVerificationLogin = payload;
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  setAuthError,
  setAttempt,
  setViewPortal,
  setTempDisabled,
  setVerifyingCookie,
  setViewEnterSSN,
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  setResetForm,
  setErrorMessage,
  setOfficialLanguage,
  setEmployeeId,
  setEmailVerification,
} = authSlice.actions;

export default authSlice.reducer;

interface IVerifyCookieParams {
  companyShortName: string;
  maybeFunctionToSetGlobalLoadingState?: (
    globalLoadingStateOperation: GlobalLoadingStateOperation,
    isLoading: boolean,
  ) => void;
}

export const verifyCookie =
  ({ maybeFunctionToSetGlobalLoadingState }: IVerifyCookieParams) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(setVerifyingCookie(true));
        if (maybeFunctionToSetGlobalLoadingState) {
          maybeFunctionToSetGlobalLoadingState(
            GlobalLoadingStateOperation.APIV2_VERIFY_COOKIE,
            true,
          );
        }
        const res = await fetch(`${BASE_URL}/verify_cookie`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'cache-control': 'no-cache, no-store',
          },
          credentials: 'include',
        });

        if (res.ok) {
          const { information, message } =
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (await res.json()) as IUserInformationFromApi;

          if (message && message === 'user authenticated') {
            try {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'loginSuccess',
                  employeeId: information.id,
                  email: information.name.email,
                  firstName: information.name.first,
                  lastName: information.name.last,
                  isAdmin: information.is_issuer_admin,
                  ...(information.employee_company_id && {
                    employeeCompanyId: information.employee_company_id,
                  }),
                  employee_salaried: information.calculator.salaried,
                },
              });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              console.error(
                'verifyCookie TagManager.dataLayer({...}) resulted in error: ',
                error,
              );
            }
            dispatch(setUserInformation(information));
            dispatch(loginSuccess(information));
            window.scrollTo(0, 0);
          } else if (message && message !== 'unauthenticated user') {
            console.warn(
              `verify_cookie did not grant authentication. Response mssage: ${message}`,
            );
          }
        } else {
          console.warn(
            `verify_cookie res.ok was falsy. Status was: ${res.status} and statusText was: ${res.statusText}`,
          );
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('verify_cookie ', error);
        captureErrorInSentryWithCustomMessage(error, 'Error in verify_cookie');
      } finally {
        dispatch(setVerifyingCookie(false));
        if (maybeFunctionToSetGlobalLoadingState) {
          maybeFunctionToSetGlobalLoadingState(
            GlobalLoadingStateOperation.APIV2_VERIFY_COOKIE,
            false,
          );
        }
      }
    };

interface IGetEnrollmentPageByStateParams {
  tickerRaw: string;
  enrollmentOpen: boolean;
  newUser: boolean;
  verifyEmail: boolean;
  withdrawn: boolean;
  effectiveDates: EffectiveDates;
  special_user: boolean;
  accessESPP: boolean;
}

const getEnrollmentPageByStatus = ({
  tickerRaw,
  enrollmentOpen,
  newUser,
  verifyEmail,
  withdrawn,
  effectiveDates,
  special_user,
  accessESPP,
}: IGetEnrollmentPageByStateParams) => {
  const ticker = tickerRaw.toLowerCase();
  const history = window.location;
  const currentPath = history.pathname.toLowerCase();
  if (currentPath === `/${ticker}` || currentPath.includes('enrollment')) {
    if (
      currentPath.includes('learn') ||
      currentPath.includes('about-cashless-participation')
    ) {
      return currentPath;
    }
    if (special_user) {
      if (!newUser && !verifyEmail) {
        return `/${ticker}/enrollment/enrollment-incomplete`;
      }
      if (newUser || withdrawn) {
        return `/${ticker}/enrollment`;
      }
      if (!newUser && !withdrawn) {
        return `/${ticker}/enrollment/returning-user`;
      }
      return '';
    }
    if (
      !enrollmentOpen &&
      effectiveDates &&
      effectiveDates.offerOpen &&
      !newUser
    ) {
      return `/${ticker}/withdraw`;
    }
    if (
      !enrollmentOpen &&
      effectiveDates &&
      effectiveDates.offerOpen &&
      newUser
    ) {
      return `/${ticker}/enrollment/closed-enrollment`;
    }

    if (!enrollmentOpen) {
      return accessESPP ? `/${ticker}/too-early` : `/${ticker}`;
    }

    if (!newUser && !verifyEmail) {
      return `/${ticker}/enrollment/enrollment-incomplete`;
    }
    if (
      effectiveDates &&
      effectiveDates.enrollmentOpen &&
      (withdrawn || newUser)
    ) {
      return `/${ticker}/enrollment`;
    }
    if (
      !newUser &&
      effectiveDates &&
      effectiveDates.enrollmentOpen &&
      !withdrawn
    ) {
      return `/${ticker}/enrollment/returning-user`;
    }
    if (newUser && effectiveDates && !effectiveDates.enrollmentOpen) {
      return `/${ticker}/enrollment/closed-enrollment`;
    }
    if (!newUser && effectiveDates && !effectiveDates.enrollmentOpen) {
      return `/${ticker}/enrollment/returning-closed-enrollment/returning-closed-enrollment`;
    }
    return `/${ticker}/too-early`;
  }
  return currentPath;
};

interface IVerifyUserInputParams {
  day: string;
  month: string;
  year: string;
  last4: string;
  defaultCountry: string;
  companyShortName: string;
  redirect: string;
  isAvocaOrWilsonVale?: boolean;
  // TODO: never passed to verifyUserInput, but used in conditional expression
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  business_group?: any;
  // TODO: never passed to verifyUserInput, but used in conditional expression
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  employee_company_id?: any;
}

export const verifyUserInput =
  ({
    day,
    month,
    year,
    last4,
    defaultCountry,
    companyShortName,
    business_group,
    employee_company_id,
    isAvocaOrWilsonVale,
    redirect = '',
  }: IVerifyUserInputParams) =>
    async (dispatch: AppDispatch): Promise<string> => {
      try {
        const history = window.location;
        const postLoginRedirectDestination = getEncodedLocation();
        const res = await fetch(
          `${BASE_URL}/verify_user_input${redirect !== '' ? `?${redirect}` : ''}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              day,
              month,
              year,
              ...(isAvocaOrWilsonVale
                ? {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  business_group,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  employee_company_id,
                }
                : {
                  last4,
                  defaultCountry,
                }),
              company_short_name: companyShortName,
            }),
          },
        );
        if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const data = (await res.json()) as IUserInformationFromApi;

          if (data.message === 'validateEmail') {
            if (data.employee_id) {
              dispatch(setEmployeeId(data.employee_id));
            }
            return `/${companyShortName.toLowerCase()}/email-verification?${
              UrlSearchParamKey.REDIRECT_TO
            }=${postLoginRedirectDestination}`;
          }
          if (data.message === 'verifyMoreInfoNeeded') {
            dispatch(setAuthError(data.message));
          }

          if (data.message === 'enterSSN') {
            dispatch(setViewEnterSSN(true));
          }
          const ticker = data.information.company.shortname.toLowerCase();
          const { enrollmentOpen } = data.information.effectiveDates;
          const {
            newUser, verifyEmail, withdrawn, effectiveDates,
          } =
          data.information;
          const route = getEnrollmentPageByStatus({
            tickerRaw: ticker,
            enrollmentOpen,
            newUser,
            verifyEmail,
            withdrawn,
            effectiveDates,
            special_user: false,
            accessESPP: data.information.accessESPP,
          });
          dispatch(setInEligiblePopup(!data.information.accessESPP));
          dispatch(setTerminatedPopup(data.information.terminated));

          if (data.message === 'exactMatch') {
            dispatch(setViewEnterSSN(false));
            dispatch(setViewPortal(false));
            dispatch(setAuthError(''));
            dispatch(setUserInformation(data.information));
            dispatch(setAlreadyEnrolledPopup(true));
            dispatch(loginSuccess(data.information));
            await dispatch(fetchCompanyInfo({ ticker }));
            const { terminated } = data.information;

            if (ticker.toLowerCase() !== 'outsetmedical' && terminated) {
              return `/${ticker.toLowerCase()}/account/status-summary`;
            }
            if (ticker.toLowerCase() === 'outsetmedical' && terminated) {
              return `/${ticker.toLowerCase()}/account/purchase-plan`;
            }
            if (
              ticker.toLowerCase() === 'outsetmedical' &&
            !history.pathname.includes('outsetmedical/enrollment') &&
            !history.pathname.includes('outsetmedical/disclaimer')
            ) {
              return route;
            }
            return route;
          }

          if (data.message === 'execSuccess') {
            dispatch(loginSuccess(data.information));
            dispatch(setUserInformation(data.information));
            dispatch(setAlreadyEnrolledPopup(true));
            await dispatch(fetchCompanyInfo({ ticker }));
            const { terminated } = data.information;
            if (terminated) {
              return `/${ticker.toLowerCase()}/account/status-summary`;
            }
            if (
              ticker.toLowerCase() === 'outsetmedical' &&
            !history.pathname.includes('outsetmedical/enrollment') &&
            !history.pathname.includes('outsetmedical/disclaimer')
            ) {
              return `/${ticker}`;
            }
            return route;
          }
        }

        if (!res.ok) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { message }: { message: string } = await res.json();
          dispatch(setAttempt());
          dispatch(setAuthError(message));
          dispatch(setResetForm(true));
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        captureErrorInSentryWithCustomMessage(
          error,
          'error during verifyUserInput',
        );
        console.error('verifyUserInput ', error);
      }
      return '';
    };

interface IVerifySSNParams {
  ssn: string;
  day: string;
  month: string;
  year: string;
  originCountry: string;
  tickerRaw: string;
  redirect?: string;
}

export const verifySSN =
  ({
    ssn,
    day,
    month,
    year,
    originCountry,
    tickerRaw,
    redirect = '',
  }: IVerifySSNParams) =>
    async (dispatch: AppDispatch): Promise<string> => {
      try {
        const history = window.location;
        const res = await fetch(
          `${BASE_URL}/verify_ssn?company_short_name=${tickerRaw}${
            redirect !== '' ? `?${redirect}` : ''
          }`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              ssn,
              day,
              month,
              year,
              originCountry,
            }),
          },
        );

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const data = (await res.json()) as IUserInformationFromApi;

        if (res.ok) {
          if (data.message === 'validateEmail') {
            if (data.employee_id) {
              dispatch(setEmployeeId(data.employee_id));
            }
            return `/${tickerRaw}/email-verification`;
          }

          if (data.message === 'success') {
            dispatch(setInEligiblePopup(!data.information.accessESPP));
            const ticker = data.information.company.shortname;
            dispatch(setViewEnterSSN(false));
            dispatch(setViewPortal(false));
            dispatch(setUserInformation(data.information));
            dispatch(setAlreadyEnrolledPopup(true));
            dispatch(loginSuccess(data.information));
            await dispatch(fetchCompanyInfo({ ticker }));
          }

          const ticker = data.information.company.shortname;
          const { enrollmentOpen } = data.information.effectiveDates;
          const {
            newUser, verifyEmail, withdrawn, effectiveDates,
          } =
          data.information;

          const route = getEnrollmentPageByStatus({
            tickerRaw: ticker,
            enrollmentOpen,
            newUser,
            verifyEmail,
            withdrawn,
            effectiveDates,
            special_user: false,
            accessESPP: data.information.accessESPP,
          });
          if (data.information.terminated) {
            return `/${ticker.toLowerCase()}/account/status-summary`;
          }
          if (
            ticker.toLowerCase() === 'outsetmedical' &&
          !history.pathname.includes('outsetmedical/enrollment') &&
          !history.pathname.includes('outsetmedical/disclaimer')
          ) {
            return `/${ticker}`;
          }
          return route;
        }

        if (!res.ok) {
          if (data.message) {
            dispatch(setAuthError(data.message));
          }
          dispatch(setAttempt());
          dispatch(setViewEnterSSN(false));
          dispatch(setResetForm(true));
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('verifySSN ', error);
      }
      return '';
    };

interface IFetchEmailCaptureParams {
  id: number;
  email: string;
  destination: string;
  phone?: string;
}

export const fetchEmailCapture =
  (params: IFetchEmailCaptureParams) => async (dispatch: AppDispatch) => {
    try {
      const res = await fetch(`${BASE_URL}/email_capture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(params),
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { message }: { message: string } = await res.json();

      if (message === 'emailAlreadyExists') {
        dispatch(setErrorMessage(message));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('fetchEmailCapture ', error);
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      dispatch(loginError(error?.message));
    }
  };

interface IUpdateAccountDetailsParams {
  data: {
    id: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    email: string;
    phone: string;
    destination: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: any;
}

export const updateAccountDetails =
  ({ data, callback }: IUpdateAccountDetailsParams) =>
    async (dispatch: AppDispatch) => {
      try {
        const res = await fetch(`${BASE_URL}/account_details`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ ...data }),
        });
        const { message, information } =
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (await res.json()) as IUserInformationFromApi;
        if (message === 'emailAlreadyExists') {
          dispatch(setErrorMessage(message));
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          callback(message);
        }
        if (res.ok || !!information) {
          dispatch(setUserInformation(information));
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          callback(RESPONSE_MESSAGE_FROM_SERVER.SUCCESS);
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('updateAccountDetails ', error);
      }
    };

interface IFetchVerifyEmailParams {
  verifyCode: string | null;
  shortname: string | null;
}

export const fetchVerifyEmail =
  ({ verifyCode, shortname }: IFetchVerifyEmailParams) =>
    async (dispatch: AppDispatch) => {
      try {
        const res = await fetch(`${BASE_URL}/verify_email`, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            verification: verifyCode,
            short_name: shortname,
          }),
        });

        const { information, message } =
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (await res.json()) as IUserInformationFromApi;

        if (res.ok && message === 'validatedLogin') {
          dispatch(setUserInformation(information));
          dispatch(loginSuccess(information));
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('fetchVerifyEmail ', error);
      }
    };

interface IFetchLogoutParams {
  shortName: string;
  signedOutDueToInactivity?: boolean;
}

export const fetchLogout =
  ({ shortName, signedOutDueToInactivity = false }: IFetchLogoutParams) =>
    async (dispatch: AppDispatch) => {
      try {
        dispatch(logout());

        const res = await fetch(`${BASE_URL}/log_out`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include',
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { redirect }: { redirect: string } = await res.json();
        if (res.ok) {
          dispatch(logoutSuccess());
          dispatch(setUiLogout());
          clearLocalStoragePostLogout();
          // This shouldn't actually do anything since we're doing a hard redirect
          // to reset the Redux store anyway, but better safe than sorry:
          dispatch(resetUserState());
          if (redirect) {
            window.location.href = redirect;
          } else {
          // Redirect with a hard page load, to clear out the Redux store.
          // (Failing to properly clear the Redux store has caused a lot of bugs)
            window.location.href = `/${shortName}${
              signedOutDueToInactivity
                ? `?${UrlSearchParamKey.SIGNED_OUT_DUE_TO_INACTIVITY}`
                : ''
            }`;
          }
        } else {
        // Sometimes the request fails but the user is still
        // logged out. Clear local storage and reload to avoid
        // getting in a weird state:
          clearLocalStoragePostLogout();
          window.location.reload();
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('fetchLogout ', error);
        // Sometimes the request fails but the user is still
        // logged out. Clear local storage and reload to avoid
        // getting in a weird state:
        clearLocalStoragePostLogout();
        window.location.reload();
      }
    };

interface IEmailVerifyParams {
  shortName: string;
  email: string;
  empID: string;
  // TODO: never used
  redirect?: string;
}

export const emailVerify =
  ({
    shortName, email, empID, redirect = '',
  }: IEmailVerifyParams) =>
    async (dispatch: AppDispatch): Promise<string> => {
      try {
        const res = await fetch(
          `${BASE_URL}/validate_email/${shortName}${
            redirect !== '' ? `?${redirect}` : ''
          }`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'cache-control': 'no-cache, no-store',
            },
            credentials: 'include',
            body: JSON.stringify({
              employee_id: empID,
              email,
            }),
          },
        );
        if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const data = (await res.json()) as IUserInformationFromApi;

          if (data.message === 'validateUnable') {
            dispatch(setAttempt());
            return data.message;
          }
          if (data.message === 'validateSuccess') {
            const ticker = data.information.company.shortname.toLowerCase();
            dispatch(setViewEnterSSN(false));
            dispatch(setViewPortal(false));
            dispatch(setAuthError(''));
            dispatch(setUserInformation(data.information));
            dispatch(loginSuccess(data.information));
            await dispatch(fetchCompanyInfo({ ticker }));
            return `/${ticker}`;
          }
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('emailVerify ', error);
      }
      return '';
    };

interface IVerificationCheckEmailParams {
  email: string;
  companyShortName: string;
  resend: boolean;
}

export const verificationCheckEmail =
  ({ email, companyShortName, resend }: IVerificationCheckEmailParams) =>
    async (dispatch: AppDispatch) => {
      try {
        const res = await fetch(`${BASE_URL}/otp/generate/${companyShortName}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ email, resend }),
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { message }: { message: string } = await res.json();
        if (res.ok) {
          dispatch(
            setEmailVerification({ emailExists: true, error: null, email }),
          );
        } else {
          dispatch(
            setEmailVerification({ emailExists: false, error: message, email }),
          );
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        console.error(error?.message);
        dispatch(
          setEmailVerification({
            emailExists: false,
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            error: error?.message,
            email,
          }),
        );
      }
    };

interface IVerificationCheckCodeParams {
  email: string;
  code: string;
  companyShortName: string;
}

export const verificationCheckCode =
  ({ email, code, companyShortName }: IVerificationCheckCodeParams) =>
    async (dispatch: AppDispatch) => {
      try {
        const res = await fetch(`${BASE_URL}/otp/validate/${companyShortName}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ email, otp_token: code }),
        });

        const { message, information } =
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (await res.json()) as IUserInformationFromApi;

        if (res.ok) {
          dispatch(
            setEmailVerification({
              emailExists: true,
              error: null,
              verified: true,
              email,
            }),
          );
          dispatch(setUserInformation(information));
          dispatch(loginSuccess(information));
        } else {
          dispatch(
            setEmailVerification({ emailExists: true, error: message, email }),
          );
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        console.error(error?.message);
        dispatch(
          setEmailVerification({
            emailExists: true,
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            error: error?.message,
            email,
          }),
        );
      }
    };
