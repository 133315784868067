import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';
import { buildGqlFieldsQuery } from './gql-builders';
import type { INavigationItemLink } from './dotcms-navigation-item-link';
import { NavigationItemLinkHelper } from './dotcms-navigation-item-link';
import { processGqlDataAsArray } from '../use-builders-to-process-gql-data';

export type INavigationCategoryData = IBaseObjectData & {
  name: string | undefined;
  isMarkedWithLabelNew: string | undefined;
  navigationItems: INavigationItemLink[];
};

export const NavigationCategoryHelper: IGqlHelper<INavigationCategoryData> = {
  contentTypeName: 'NavigationCategory',
  gqlFieldsQuery: `
    name
    ismarkedwithlabelnew
    navigationItems {
        ${buildGqlFieldsQuery(NavigationItemLinkHelper)}
      }
    `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { name, ismarkedwithlabelnew, navigationItems } =
      convertDotcmsResponseToRecord(gqlResponse);

    return {
      name: convertDotcmsString(name),
      isMarkedWithLabelNew: convertDotcmsString(ismarkedwithlabelnew),
      navigationItems:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        processGqlDataAsArray(NavigationItemLinkHelper, navigationItems) ?? [],
    };
  },
};
