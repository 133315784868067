import React from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { ErrorOccurredScreen } from './error-occurred-screen';
import { captureErrorInSentryWithCustomMessage } from '../../utils/capture-error-in-sentry-with-custom-message';
import { usePageRetryWithTimeInterval } from './use-page-retry-with-time-interval';
import { FullPageCenteredContentWrapper } from '../../component_library/components/Grid';

const ErrorPage = () => (
  <FullPageCenteredContentWrapper>
    <ErrorOccurredScreen />
  </FullPageCenteredContentWrapper>
);

type IErrorBoundaryWithCustomComponent = {
  errorComponent: () => React.JSX.Element;
};

const InternalRootErrorBoundaryRetryFallback: React.FC<
FallbackProps & IErrorBoundaryWithCustomComponent
> = ({ errorComponent, resetErrorBoundary, error }) => {
  const { initiateRetry } = usePageRetryWithTimeInterval();

  console.warn(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `InternalRootErrorBoundaryRetryFallback was called due to an error: ${error}`,
  );

  // Call resetErrorBoundary() to reset the error boundary and retry the partial render.
  const isRetry = initiateRetry(resetErrorBoundary);
  if (isRetry) {
    return null;
  }

  return errorComponent();
};

export const RootErrorBoundaryFallback: React.FC<
FallbackProps & Partial<IErrorBoundaryWithCustomComponent>
> = ({ errorComponent = ErrorPage, ...props }) => (
  // ErrorBoundary for cases where an error is thrown even inside ErrorBoundaryRetryFallback
  <ErrorBoundary
    FallbackComponent={errorComponent}
    onError={(e) =>
      captureErrorInSentryWithCustomMessage(
        e,
        'RootErrorBoundaryFallback throws the error',
      )}
  >
    <InternalRootErrorBoundaryRetryFallback
      errorComponent={errorComponent}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </ErrorBoundary>
);
