import type { PropsWithChildren } from 'react';
import React from 'react';
import { useDeviceIdSingletonContextProvider } from './use-device-id-singleton-context-provider';
import { DeviceIdContext } from './device-id-context';

export const DeviceIdContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const deviceIdContext = useDeviceIdSingletonContextProvider();

  return (
    <DeviceIdContext.Provider value={deviceIdContext}>
      {children}
    </DeviceIdContext.Provider>
  );
};
