import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';
import { UserAuthenticationState } from './use-get-user-auth-state';
import { userSelector } from '../../selectors';
import type { IUserStateInPeriod } from './use-get-user-states-in-periods';

/** This enum is used to determine if the user is enrolled in ESPP. */
export enum UserEnrolledState {
  /** This case is not currently used */
  PendingEnrollmentInternally = 'PENDING_ENROLLMENT_INTERNALLY',
  ScheduledEnrollment = 'SCHEDULED_ENROLLMENT',
  UserNotEnrolled = 'NOT_ENROLLED',
  UserEnrolled = 'ENROLLED',
}

export const useGetUserEnrolledState = (
  userAuthState: UserAuthenticationState,
  isEnrollmentOpen: boolean,
  userStateInPeriod: IUserStateInPeriod,
): UserEnrolledState | undefined => {
  const { enrollmentRequiresVerifiedEmail, verifyEmail } =
    useSelector(userSelector);

  return useMemo(
    () =>
      match({
        userAuthState,
        isEnrollmentOpen,
        isScheduledEnrollmentUserInfo: userStateInPeriod.isScheduled,
        enrolledEspp: userStateInPeriod.enrolledEspp,
        withdrawn: userStateInPeriod.withdrawn,
        enrollmentRequiresVerifiedEmail,
        verifyEmail,
      })
        .with(
          { userAuthState: P.not(UserAuthenticationState.UserAuthenticated) },
          () => undefined,
        )
        .with(
          {
            isScheduledEnrollmentUserInfo: true,
          },
          () => UserEnrolledState.ScheduledEnrollment,
        )
        .with(
          {
            isEnrollmentOpen: true,
            verifyEmail: false,
            enrollmentRequiresVerifiedEmail: true,
          },
          () => UserEnrolledState.PendingEnrollmentInternally,
        )
        .with(
          { enrolledEspp: true, withdrawn: false },
          () => UserEnrolledState.UserEnrolled,
        )
        .otherwise(() => UserEnrolledState.UserNotEnrolled),
    [
      enrollmentRequiresVerifiedEmail,
      isEnrollmentOpen,
      userAuthState,
      userStateInPeriod.enrolledEspp,
      userStateInPeriod.withdrawn,
      userStateInPeriod.isScheduled,
      verifyEmail,
    ],
  );
};
