import { useCallback, useContext } from 'react';
import {
  GlobalLoadingContext,
  GlobalLoadingStateOperation,
} from '../components/abc/global-loading-context/global-loading-context';

export const useRedirectToExternalUrl = () => {
  const { setOperationLoading } = useContext(GlobalLoadingContext);

  const redirectToExternalUrl = useCallback(
    (url: string) => {
      // The page will continue rendering the current URL until the new URL
      // finishes loading, so we want to show a loading screen instead of
      // rendering the login page temporarily:
      setOperationLoading(
        GlobalLoadingStateOperation.REDIRECTING_TO_EXTERNAL_PAGE,
        true,
      );
      window.location.replace(url);
    },
    [setOperationLoading],
  );

  const checkIfExternalUrl = useCallback((url: string) => {
    try {
      return new URL(url).origin !== window.location.origin;
    } catch (error) {
      /*
        `new URL(url)` call throws an error if the URL format is wrong
        (e.g. url has no protocol like http/https).
        This indicates that the URL is internal.
      */
      return false;
    }
  }, []);

  return { redirectToExternalUrl, checkIfExternalUrl };
};
