import { useContext } from 'react';
import { IssuerFeaturesContext } from '../components/abc/admin-experience/admin-page-navigation/contexts/issuer-features-context-provider';
import type { Page } from './page-url-path';
import { ISSUER_FEATURES_PAGES } from './pages-requiring-condition';

export const useBetaPages = () => {
  const { allowed_features: allowedFeatures } = useContext(
    IssuerFeaturesContext,
  );
  const betaPages = allowedFeatures
    ? Object.entries(ISSUER_FEATURES_PAGES)
      .filter(
        ([, value]) =>
          !!value &&
            allowedFeatures?.find((x) => x.feature_name === value)?.is_beta,
      )
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      .map(([key]) => key as Page)
    : [];

  return { betaPages };
};
