import type { UrlSearchParamsTypes } from './url-search-params';
import type UrlSearchParamKey from './url-search-params';

export const setSearchParamInnerImpl = <SearchKey extends UrlSearchParamKey>(
  urlSearchParams: URLSearchParams,
  searchParamKey: SearchKey,
  value: UrlSearchParamsTypes[SearchKey],
) => {
  // Value type can be an array of strings, a string, a boolean, or a number.
  if (Array.isArray(value)) {
    urlSearchParams.delete(searchParamKey);
    value.forEach((subValue) => {
      if (typeof subValue !== 'string' && typeof subValue !== 'number') {
        console.error(
          `Tried to set a URL search param value as an array not containing strings, for searchParamKey: ${searchParamKey} `,
        );
        return;
      }
      // Wrap inside a string template because it may be a number instead
      // of string:
      urlSearchParams.append(searchParamKey, `${subValue}`);
    });
    return;
  }

  if (typeof value === 'string') {
    urlSearchParams.set(searchParamKey, value);
    return;
  }

  if (typeof value === 'number') {
    urlSearchParams.set(searchParamKey, `${value}`);
    return;
  }

  // If we got here:
  // Value is a boolean, because we already ruled out string and array.
  // A boolean value is considered set as true in the params if the searchParamKey exists.
  // If the searchParamKey does not exist, it's considered set as false.
  if (value) {
    // Value is true. Set the searchParamKey in the URL.
    // UrlSearchParams doesn't let you set just a searchParamKey. So we pass an empty string.
    urlSearchParams.set(searchParamKey, '');
  } else {
    // Value was false. Delete it from the URL if it exists.
    urlSearchParams.delete(searchParamKey);
  }
};
