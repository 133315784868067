import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { ThemeConfig } from 'antd';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import {
  BREAKPOINT,
  BREAKPOINT_BOUNDARIES,
} from '../component_library/utils/breakpoints';
import './antd-index.css';
import { useGetTheme } from '../hooks/use-get-theme';

// MUI comes with some breakpoints defined by default that are different
// than the ones we use. This un-defines those MUI breakpoints, and then defines our
// own custom ones instead.
// https://mui.com/material-ui/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tabletMini: true;
    tablet: true;
    desktop: true;
  }
}

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const muiTheme = createTheme({
    breakpoints: {
      values: {
        mobile: BREAKPOINT_BOUNDARIES[BREAKPOINT.MOBILE],
        tabletMini: BREAKPOINT_BOUNDARIES[BREAKPOINT.TABLET_MINI],
        tablet: BREAKPOINT_BOUNDARIES[BREAKPOINT.TABLET],
        desktop: BREAKPOINT_BOUNDARIES[BREAKPOINT.DESKTOP],
      },
    },
  });

  const theme = useGetTheme();
  const antdTheme: ThemeConfig = {
    token: {
      colorPrimary: theme.colors.primary,
      colorTextSecondary: theme.colors.secondary,
      colorBgTextHover: theme.colors.hoverBackground,

      colorInfo: theme.colors.neutral,
      colorInfoHover: theme.colors.neutralHover,
      colorInfoBg: theme.colors.neutralBackgroundColor,

      colorSuccess: theme.colors.positive,
      colorSuccessHover: theme.colors.positiveHover,
      colorSuccessBg: theme.colors.positiveBackground,

      colorWarning: theme.colors.warning,
      colorWarningBg: theme.colors.warningBackground,
      colorWarningOutline: theme.colors.warningOutline,

      colorError: theme.colors.negative,
      colorErrorBg: theme.colors.negativeBackground,
      colorErrorHover: theme.colors.negativeHover,
      colorErrorOutline: theme.colors.negativeHighlight,

      colorWhite: theme.colors.grayscale.white,
    },
  };

  return (
    <AntdConfigProvider theme={antdTheme}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </AntdConfigProvider>
  );
};

export default ThemeProvider;
