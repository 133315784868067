import { useContext, useCallback } from 'react';
import type { PageTitleKey } from '../../../../app/page-title-key';
import { PageTitleListContext } from '../page-title-list-context-provider';
import type { IPageTitleData } from '../../../../dotcms/dotcms-models/dotcms-page-title-list';

export const useGetTitleForPage = () => {
  const { data: pageTitles, loading, error } = useContext(PageTitleListContext);

  const getCmsTitleForPage = useCallback(
    (pageTitleKey: PageTitleKey): string | undefined => {
      if (pageTitles) {
        const pageTitleKeyInCms = 'title'.concat(pageTitleKey);
        const documentTitle =
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          pageTitles[pageTitleKeyInCms as keyof IPageTitleData];

        if (documentTitle && documentTitle.charAt(0) !== ' ') {
          return documentTitle;
        }
      }

      return undefined;
    },
    [pageTitles],
  );

  return {
    getCmsTitleForPage,
    titlesFromCmsLoaded: !loading && (!!pageTitles || !!error),
  };
};
