import { useStringLaunchDarklyFlag_internalDontUse } from './launchDarkly';

export enum StringFeatureFlag {
  // This enum has to contain at least one value, or Typescript will complain
  // when we try to use a variable of type BooleanFeatureFlag in a place that
  // expects a string.
  MY_EXAMPLE_FEATURE = 'some-string-example',
  // Make these the same as the flag names configured in LaunchDarkly:
  MERGE_CONNECT_LINK = 'merge-connect-link',
}

export const STRING_FLAG_DEFAULT_VALUES: {
  [key in StringFeatureFlag]: string;
} = {
  [StringFeatureFlag.MY_EXAMPLE_FEATURE]: 'example string',
  [StringFeatureFlag.MERGE_CONNECT_LINK]: '',
};

export const useStringFlag = (feature: StringFeatureFlag): string =>
  useStringLaunchDarklyFlag_internalDontUse(feature);
