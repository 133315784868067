import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useRedirectIfPageNotAvailable } from './hooks';
import { useRedirectToExternalUrl } from '../../hooks/useRedirectToExternalUrl';

/** Redirects to the login page if the page requires additional authentication.
 * Otherwise, redirects to the homepage if the page is unavailable for a different reason.
 */
const RedirectIfPageNotAvailable = () => {
  const {
    loginUrlToRedirectTo,
    shouldRedirectToLogin,
    currentPageUnavailable,
    homepageRoute,
  } = useRedirectIfPageNotAvailable();
  const { redirectToExternalUrl } = useRedirectToExternalUrl();

  // If it's an external URL, we can't use a <Redirect>, need to do it in a useEffect:
  useEffect(() => {
    if (shouldRedirectToLogin && loginUrlToRedirectTo?.startsWith('http')) {
      redirectToExternalUrl(loginUrlToRedirectTo);
    }
  }, [loginUrlToRedirectTo, redirectToExternalUrl, shouldRedirectToLogin]);

  if (shouldRedirectToLogin) {
    if (!loginUrlToRedirectTo) {
      // If this happens, we should fall out of this if block and redirect to the homepage below
      // instead.
      console.error(
        "Failed to get a login URL! Can't redirect to login, but we were supposed to.",
      );
    } else {
      if (loginUrlToRedirectTo?.startsWith('http')) {
        // This case is handled using redirectToExternalUrl in the above useEffect instead
        return null;
      }
      return <Redirect to={loginUrlToRedirectTo} />;
    }
  }

  if (currentPageUnavailable) {
    // The current page is unavailable, but we shouldn't redirect to the login page,
    // (e.g. because the user is already logged in), so redirect to homepage instead.
    return <Redirect to={homepageRoute} />;
  }

  return null;
};

export default RedirectIfPageNotAvailable;
