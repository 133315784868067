import getCountryISO2 from 'country-iso-3-to-2';

export const getTwoLetterCountryCodeFromMaybeThreeLetter = (countryCode) => {
  if (!countryCode) {
    return undefined;
  }
  if (countryCode?.length === 2) {
    return countryCode;
  }
  if (
    countryCode &&
    typeof countryCode === 'string' &&
    countryCode.length === 3
  ) {
    return getCountryISO2(countryCode);
  }
  return undefined;
};
