import { DOTCMS_CLOUD_URL } from '../../utils/constants';
import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import type { IGqlHelper } from './gql-helper';

export type INonImageFileData = IBaseObjectData & {
  url: string;
};

export const NonImageFileGqlHelper: IGqlHelper<INonImageFileData> = {
  contentTypeName: null,
  // Using versionPath as the URL will make the URL change if a new
  // version of the file is ever uploaded, which will avoid us displaying
  // cached old versions of the image. (Both browsers and dotcms's CDN cache
  // images keyed on URL)
  gqlFieldsQuery: `
    versionPath
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const { versionPath } = convertDotcmsResponseToRecord(gqlResponse);

    if (!versionPath) {
      return undefined;
    }

    const relativeUrl = convertDotcmsString(versionPath);
    if (!relativeUrl) {
      return undefined;
    }

    return {
      url: relativeUrl ? `${DOTCMS_CLOUD_URL}${relativeUrl}` : '',
    };
  },
};
