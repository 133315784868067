/** This defines the relative ordering of all z index elements.
 * If one element should render on top of another, the higher element
 * should be later in this list.
 *
 * DO NOT manually set numbers on any of these enum values!!!
 * The integer values need to be automatically generated for this to work.
 */
export const enum ElementZIndexOrdering {
  HIDDEN_ELEMENT = -1,
  DEFAULT, // This must be the first element to make sure 0 is a reserved value
  SLIDER_MEDIUM,
  CARD_WITH_INFO,
  SELECT_LIST_HOVERED_OPTION,
  SELECT_LIST_SELECTED_OPTION,
  ENROLLMENT_FOOTER_BUTTONS,
  SIDEBAR,
  TOOLTIP_ANCHOR,
  STICKY_SUBHEADER,
  HEADER_WITH_ALERT_BANNER_WRAPPER,
  HEADER_MENU_DROPDOWN,
  HEADER,
  TOOLTIP_FIXED,
  COOKIE_BANNER,
  LOADING_OVERLAY, // This probably always should be the highest z-index element
}
