import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { authSelector, uiSelector } from '../../selectors';
import { setInactivityModal } from '../../slices/ui';
import { INACTIVITY_WARNING_MS } from '../../utils/constants';
import { InactivityNoticeModal } from '../abc/inactivity-notice-modal';
import { useFeatureFlagEnabledBoolean } from '../../featureFlags/use-boolean-flag';
import { BooleanFeatureFlag } from '../../featureFlags/booleanFlags';

export default function InactivityWarningModal() {
  const { isAuthenticated } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { inactivityModal } = useSelector(uiSelector);
  const shouldPreventInactivitySignout = useFeatureFlagEnabledBoolean(
    BooleanFeatureFlag.PREVENT_INACTIVITY_TIMEOUT_SIGNOUT,
  );
  const handleOnIdle = useCallback(() => {
    if (isAuthenticated && !shouldPreventInactivitySignout) {
      dispatch(setInactivityModal('warning'));
    }
  }, [dispatch, isAuthenticated, shouldPreventInactivitySignout]);

  useIdleTimer({
    timeout: INACTIVITY_WARNING_MS,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  if (inactivityModal !== 'warning') return null;

  return <InactivityNoticeModal />;
}
