import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../utils/constants';
/* eslint-disable-next-line import/no-cycle */
import { setOfficialLanguage, setAuthError } from './auth';
import { GlobalLoadingStateOperation } from '../components/abc/global-loading-context/global-loading-context';
import type {
  TimeOfDay,
  UpdatedMarketStatus,
  UpdatedTime,
  IUIselector,
  ILoginInformation,
  IEnrollmentPeriodInfoFromApi,
  IOfferingPeriodInfo,
  IPurchasePeriodInfo,
} from '../selectors/interfaces';
import { convertEnrollmentPeriodInfo } from '../selectors/interfaces';
import type { AppDispatch } from '../app/hooks';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';

interface IOfferingPeriodInfoFromApi {
  offer_end_date: string | null;
  offer_start_date: string | null;
  reset_change_window_end: string | null;
  reset_change_window_start: string | null;
}

interface IPurchasePeriodInfoFromApi {
  purchase_date: string | null;
  purchase_end_date: string | null;
  purchase_start_date: string | null;
  last_day_to_make_changes: string | null;
  last_day_to_withdraw: string | null;
}

export interface IPeriodInfo {
  current_enrollment_period: IEnrollmentPeriodInfoFromApi | null;
  future_enrollment_period: IEnrollmentPeriodInfoFromApi;
  future_offering_period: IOfferingPeriodInfoFromApi;
  future_purchase_period: IPurchasePeriodInfoFromApi;
}

const convertOfferingPeriodInfo = (
  period: IOfferingPeriodInfoFromApi,
): IOfferingPeriodInfo => ({
  offeringPeriodStartDate: period.offer_start_date,
  offeringPeriodEndDate: period.offer_end_date,
  resetChangeWindowStart: period.reset_change_window_start,
  resetChangeWindowEnd: period.reset_change_window_end,
  enrollmentStartDate: null,
  enrollmentEndDate: null,
  lastDayToMakeChanges: null,
  offeringPeriodId: null,
});

const convertPurchasePeriodInfo = (
  period: IPurchasePeriodInfoFromApi,
): IPurchasePeriodInfo => ({
  purchaseDate: period.purchase_date,
  purchasePeriodStartDate: period.purchase_start_date,
  purchasePeriodEndDate: period.purchase_end_date,
  lastDayToMakeChanges: period.last_day_to_make_changes,
  lastDayToWithdraw: period.last_day_to_withdraw,
  offeringPeriodId: null,
});

const initialState: IUIselector = {
  getAssetsHadError: false,
  issuerNotFoundError: false,
  cashlessOnlyEnrollment: false,
  cashlessOnlyEnrollmentIsConcurrent: false,
  companyColor: '',
  companyRegisteredName: '',
  inactivityModal: '',
  sessionWarningModal: false,
  sessionEndModal: false,
  getAssetsLoading: false,
  initialPageLoadHandlerCalled: false,
  inviteColleagueModal: false,
  isPlanInSuspensionState: false,
  favicon: '',
  isEntireIssuerEsppOnly: false,
  isAutoEnrollmentEnabled: false,
  isScheduledEnrollmentAllowed: false,
  returningUser: false,
  serverError: false,
  latestStockPrice: {
    price: '',
    symbol: '',
  },
  timeOfDayInET: { ampm: '' },
  updatedTime: { displayTime: '' },
  updatedMarketStatus: { open: false },
  productName: '',
  language: '',
  contactEmail: '',
  productNameES: '',
  planName: '',
  planNameFRCA: '',
  planNameES: '',
  productNameFRCA: '',
  backupProductName: '',
  enrollmentOnly: false,
  companyTicker: '',
  listing_stock_exchange: '',
  companyShortName: '',
  employerOnboard: true,
  ipAddressInGdprCountry: false,
  regionName: '',
  noticeModal: true,
  selectedLang: '',
  displayName: '',
  displayReenroll: false,
  noticeBanner: true,
  isCPToggled: false,
  inEligiblePopup: true,
  terminatedPopup: true,
  alreadySeenPriceResetPopUp: false,
  alreadyEnrolledPopup: true,
  resetPopup: true,
  stateCode: '',
  employerPlanName: '',
  adminName: '',
  jurisdictionByCountryCode: {},
  languageCodeToLanguageInfo: {},
  planNameByLang: {},
  productNameLongByLang: {},
  productNameShortByLang: {},
  generalProductNameLongByLang: {},
  generalProductNameShortByLang: {},
  stockPlanAdmin: {
    login_url: '',
    privacy_policy_url: '',
    terms_and_conditions_url: '',
    tax_information_url: '',
    wealth_management_firm_name: '',
    show_wealth_management_firm_name: false,
  },
  detectedCountryCode: '',
  detectedStateCode: '',
  first_offer_start_date: '',
  gtmId: '',
  sso: '',
  login: {
    sso_provider_color: null,
    sso_provider_logo: null,
    sso_provider_name: null,
    login_method_config: {},
    show_login_page: false,
    supported_login_methods: null,
  },
  periodInfo: {
    currentEnrollmentPeriod: null,
    currentOfferingPeriod: null,
    currentPurchasePeriod: null,
    futureEnrollmentPeriod: {
      startDate: '',
      endDate: '',
      traditionalEnrollment: false,
      traditionalEnrollmentIsExternal: false,
      cashlessEnrollment: false,
      firstEnrollmentForOffering: false,
      purchasePeriodEnrollment: false,
      resetChangeWindow: false,
      nextEnrollmentPeriodIsConnected: false,
      offeringPeriodId: null,
    },
    futureOfferingPeriod: {
      offeringPeriodEndDate: null,
      offeringPeriodStartDate: null,
      resetChangeWindowStart: null,
      resetChangeWindowEnd: null,
      enrollmentStartDate: null,
      enrollmentEndDate: null,
      lastDayToMakeChanges: null,
      offeringPeriodId: null,
    },
    futurePurchasePeriod: {
      purchaseDate: null,
      purchasePeriodEndDate: null,
      purchasePeriodStartDate: null,
      lastDayToMakeChanges: null,
      lastDayToWithdraw: null,
      offeringPeriodId: null,
    },
  },
};

interface ICompanyInfo {
  enrollment: { open: boolean };
  cashless_only_enrollment: boolean;
  cms_site_id?: string | null;
  /** if it's True, that means there are no external enrollment dates */
  cashless_only_enrollment_is_concurrent: boolean;
  current_price: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jurisdiction_by_country_code: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  language_code_to_language_info: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plan_name_by_lang: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plan_name_short_by_lang: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_name_long_by_lang: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_name_short_by_lang: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  general_product_name_long_by_lang: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  general_product_name_short_by_lang: any;
  is_entire_issuer_espp_only: boolean;
  is_auto_enrollment_enabled: boolean;
  is_scheduled_enrollment_allowed: boolean;
  stock_plan_admin?: {
    login_url: string;
    tax_information_url: string;
    terms_and_conditions_url: string;
    privacy_policy_url: string;
    wealth_management_firm_name: string;
    show_wealth_management_firm_name: boolean;
  };
  detected_country_code: string;
  detected_state_code: string;
  ip_address_in_gdpr_country: boolean;
  first_offer_start_date: string;
  login: ILoginInformation;
  assets: {
    adminName: string;
    color: string;
    companyShortName: string;
    companyTicker: string;
    contactEmail: string;
    displayName: string;
    employerDict: {
      [key: string]: string;
    };
    employerOnboard: boolean;
    employerPlanName: string;
    enrollmentEndDate: string;
    enrollmentOnly: boolean;
    enrollmentStartDate: string;
    externalEnrollmentEndDate: string | null;
    externalEnrollmentOpen: boolean;
    externalEnrollmentStartDate: string | null;
    favico: string;
    frequency: string;
    futureEnrollmentEndDate: string;
    futureEnrollmentStartDate: string;
    gtmId: string;
    listing_stock_exchange: string;
    logo: string;
    offerOpen: boolean;
    offerEndDate: string;
    offerStartDate: string;
    sell_to_cover_enabled: boolean;
    productName: string;
    registeredName: string;
    sso: string;
    supportedLanguages: string[];
  };
  external_enrollment_site_url?: string | null;
  external_enrollment_site_provider?: string | null;
  is_plan_in_suspension_state: boolean;
  period_info: IPeriodInfo;
}

/* eslint-disable no-param-reassign */
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCompanyInfo: (state, { payload }: PayloadAction<ICompanyInfo>) => {
      state.getAssetsHadError = false;
      state.companyColor = payload.assets.color;
      state.companyRegisteredName = payload.assets.registeredName;
      if (payload.assets.employerDict) {
        state.productName = payload.assets.employerDict['en-US-PRN'];
        state.productNameES = payload.assets.employerDict['es-US-PRN'];
        state.productNameFRCA =
          payload.assets.companyShortName === 'companya'
            ? payload.assets.employerDict['fr-US-PRN']
            : payload.assets.employerDict['fr-CA-PRN'];
        state.planNameFRCA =
          payload.assets.companyShortName === 'companya'
            ? payload.assets.employerDict['fr-US-PLN']
            : payload.assets.employerDict['fr-CA-PLN'];

        state.planNameES = payload.assets.employerDict['es-US-PLN'];
        state.planName = payload.assets.employerDict['en-US-PLN'];
      }
      state.backupProductName = payload.assets.productName;
      state.contactEmail = payload.assets.contactEmail;
      state.latestStockPrice = {
        price: payload.current_price ?? '',
        symbol: payload.assets.companyTicker,
      };
      state.cms_site_id = payload.cms_site_id;
      state.employerPlanName = payload.assets.employerPlanName;
      state.adminName = payload.assets.adminName;
      // This asset is literally "favico" because Gamal
      // was too lazy to add the final "n".
      // Edit: Nah, he's a backend guy. Doesn't even know what a favicon is
      state.favicon = payload.assets.favico;
      state.gtmId = payload.assets.gtmId;
      state.enrollmentOnly = payload.assets.enrollmentOnly;
      state.companyTicker = payload.assets.companyTicker;
      state.listing_stock_exchange = payload.assets.listing_stock_exchange;
      state.companyShortName = payload.assets.companyShortName;
      state.displayName = payload.assets.displayName;
      state.employerOnboard = payload.assets.employerOnboard
        ? payload.assets.employerOnboard
        : false;
      state.sso = payload.assets.sso;
      state.login = payload.login;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.jurisdictionByCountryCode = payload.jurisdiction_by_country_code;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.languageCodeToLanguageInfo = payload.language_code_to_language_info;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.planNameByLang = payload.plan_name_by_lang;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.productNameLongByLang = payload.product_name_long_by_lang;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.productNameShortByLang = payload.product_name_short_by_lang;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.generalProductNameLongByLang =
        payload.general_product_name_long_by_lang;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.generalProductNameShortByLang =
        payload.general_product_name_short_by_lang;
      state.stockPlanAdmin = {
        login_url: payload.stock_plan_admin?.login_url ?? '',
        tax_information_url:
          payload.stock_plan_admin?.tax_information_url ?? '',
        privacy_policy_url: payload.stock_plan_admin?.privacy_policy_url ?? '',
        terms_and_conditions_url:
          payload.stock_plan_admin?.terms_and_conditions_url ?? '',
        wealth_management_firm_name:
          payload.stock_plan_admin?.wealth_management_firm_name ?? '',
        show_wealth_management_firm_name:
          payload.stock_plan_admin?.show_wealth_management_firm_name ?? false,
      };
      state.detectedCountryCode = payload.detected_country_code;
      state.detectedStateCode = payload.detected_state_code;
      state.ipAddressInGdprCountry = payload.ip_address_in_gdpr_country;
      state.first_offer_start_date = payload.first_offer_start_date;
      state.cashlessOnlyEnrollment = payload.cashless_only_enrollment;
      state.cashlessOnlyEnrollmentIsConcurrent =
        payload.cashless_only_enrollment_is_concurrent;

      state.externalEnrollmentSiteUrl = payload.external_enrollment_site_url;
      state.externalEnrollmentSiteProvider =
        payload.external_enrollment_site_provider;
      state.isPlanInSuspensionState = payload.is_plan_in_suspension_state;
      state.isEntireIssuerEsppOnly = payload.is_entire_issuer_espp_only;
      state.isAutoEnrollmentEnabled = payload.is_auto_enrollment_enabled;
      state.isScheduledEnrollmentAllowed =
        payload.is_scheduled_enrollment_allowed;
      state.periodInfo = {
        currentEnrollmentPeriod: payload.period_info.current_enrollment_period
          ? convertEnrollmentPeriodInfo(
            payload.period_info.current_enrollment_period,
          )
          : null,
        currentOfferingPeriod: null,
        currentPurchasePeriod: null,
        futureEnrollmentPeriod: convertEnrollmentPeriodInfo(
          payload.period_info.future_enrollment_period,
        ),
        futureOfferingPeriod: convertOfferingPeriodInfo(
          payload.period_info.future_offering_period,
        ),
        futurePurchasePeriod: convertPurchasePeriodInfo(
          payload.period_info.future_purchase_period,
        ),
      };
    },
    set500Error: (state, { payload }: PayloadAction<boolean>) => {
      state.serverError = payload;
    },
    setGetAssetsHadError: (state, { payload }: PayloadAction<boolean>) => {
      state.getAssetsHadError = payload;
    },
    setInactivityModal: (state, { payload }: PayloadAction<string>) => {
      state.inactivityModal = payload;
    },
    setSessionWarningModal: (state, { payload }: PayloadAction<boolean>) => {
      state.sessionWarningModal = payload;
    },
    setSessionEndModal: (state, { payload }: PayloadAction<boolean>) => {
      state.sessionEndModal = payload;
    },
    setIssuerNotFoundError: (state, { payload }: PayloadAction<boolean>) => {
      state.issuerNotFoundError = payload;
    },
    setGetAssetsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.getAssetsLoading = payload;
    },
    setInitialPageLoadHandlerCalled: (state) => {
      state.initialPageLoadHandlerCalled = true;
    },
    setInviteColleague: (state, { payload }: PayloadAction<boolean>) => {
      state.inviteColleagueModal = payload;
    },
    setReturningUser: (state, { payload }: PayloadAction<boolean>) => {
      state.returningUser = payload;
    },
    setUpdatedTime: (state, { payload }: PayloadAction<UpdatedTime>) => {
      state.updatedTime = payload;
    },
    setTimeOfDayInET: (state, { payload }: PayloadAction<TimeOfDay>) => {
      state.timeOfDayInET = payload;
    },
    setUpdatedMarketStatus: (
      state,
      { payload }: PayloadAction<UpdatedMarketStatus>,
    ) => {
      state.updatedMarketStatus = payload;
    },
    setProductName: (state, { payload }: PayloadAction<string>) => {
      state.productName = payload;
    },
    setStateCode: (state, { payload }: PayloadAction<string>) => {
      state.stateCode = payload;
    },
    setRegionName: (state, { payload }: PayloadAction<string>) => {
      state.regionName = payload;
    },
    setNoticeModal: (state, { payload }: PayloadAction<boolean>) => {
      state.noticeModal = payload;
    },
    setSelectedLang: (state, { payload }: PayloadAction<string>) => {
      state.selectedLang = payload;
    },
    setDisplayReenroll: (state, { payload }: PayloadAction<boolean>) => {
      state.displayReenroll = payload;
    },
    setNoticeBanner: (state, { payload }: PayloadAction<boolean>) => {
      state.noticeBanner = payload;
    },
    setUiLogout: (state) => {
      // state.language = initialState.language
      state.isCPToggled = initialState.isCPToggled;
    },
    setCPToggle: (state, { payload }: PayloadAction<boolean>) => {
      state.isCPToggled = payload;
    },
    setInEligiblePopup: (state, { payload }: PayloadAction<boolean>) => {
      state.inEligiblePopup = payload;
    },
    setTerminatedPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.terminatedPopup = payload;
    },
    setAlreadyEnrolledPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.alreadyEnrolledPopup = payload;
    },
    setResetPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.resetPopup = payload;
    },
    setAlreadySeenPriceResetPopUp: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.alreadySeenPriceResetPopUp = payload;
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  setCompanyInfo,
  setInactivityModal,
  setGetAssetsHadError,
  setSessionWarningModal,
  setSessionEndModal,
  setIssuerNotFoundError,
  setGetAssetsLoading,
  setInitialPageLoadHandlerCalled,
  setInviteColleague,
  setReturningUser,
  setUpdatedTime,
  setTimeOfDayInET,
  setUpdatedMarketStatus,
  set500Error,
  setProductName,
  setStateCode,
  setRegionName,
  setNoticeModal,
  setSelectedLang,
  setDisplayReenroll,
  setNoticeBanner,
  setUiLogout,
  setCPToggle,
  setInEligiblePopup,
  setTerminatedPopup,
  setAlreadyEnrolledPopup,
  setResetPopup,
  setAlreadySeenPriceResetPopUp,
} = uiSlice.actions;

export default uiSlice.reducer;

interface IFetchCompanyInfoParams {
  ticker: string;
  maybeFunctionToSetGlobalLoadingState?: (
    globalLoadingStateOperation: GlobalLoadingStateOperation,
    loading: boolean,
  ) => void;
}

interface ICompanyInfoFromApi extends ICompanyInfo {
  message?: string;
}

export const fetchCompanyInfo =
  ({ ticker, maybeFunctionToSetGlobalLoadingState }: IFetchCompanyInfoParams) =>
    async (dispatch: AppDispatch) => {
      try {
        if (maybeFunctionToSetGlobalLoadingState) {
          maybeFunctionToSetGlobalLoadingState(
            GlobalLoadingStateOperation.APIV2_GET_ASSETS,
            true,
          );
        }
        dispatch(setGetAssetsLoading(true));

        const res = await fetch(`${BASE_URL}/get_assets/${ticker}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include',
        });

        if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const companyInfo = (await res.json()) as ICompanyInfoFromApi;
          if (
            companyInfo.message === 'notFound' &&
          ticker !== 'nothing' &&
          ticker !== 'ce-internal'
          ) {
            dispatch(setAuthError(companyInfo.message));
            dispatch(setIssuerNotFoundError(true));
          } else {
            dispatch(setAuthError(''));
            dispatch(setIssuerNotFoundError(false));
            dispatch(setCompanyInfo(companyInfo));
            dispatch(setDisplayReenroll(true));
          }
        } else if (res.status === 404) {
          dispatch(setIssuerNotFoundError(true));
        } else {
          dispatch(setGetAssetsHadError(true));
          console.error('get_assets response was not ok');
        }
      } catch (err) {
        captureErrorInSentryWithCustomMessage(
          err,
          'Error when fetching or processing get_assets',
        );
        dispatch(setGetAssetsHadError(true));
      } finally {
        dispatch(setGetAssetsLoading(false));
        if (maybeFunctionToSetGlobalLoadingState) {
          maybeFunctionToSetGlobalLoadingState(
            GlobalLoadingStateOperation.APIV2_GET_ASSETS,
            false,
          );
        }
      }
    };

interface ILanguageInformationFromApi {
  information: {
    officialLanguage: string;
  };
}
export const updateLanguage =
  ({ preferredLanguage }: { preferredLanguage: string }) =>
    async (dispatch: AppDispatch) => {
      try {
        const res = await fetch(`${BASE_URL}/update_language`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ preferredLanguage }),
        });

        if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const data = (await res.json()) as ILanguageInformationFromApi;
          const lang = data.information.officialLanguage;
          dispatch(setOfficialLanguage(lang));
        // dispatch(setSelectedLang(preferredLanguage))
        }
      } catch (error) {
      // Confusing naming now as everything changes
        console.error('updateLanguage', error);
      }
    };
