import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Cashless from './cashless';
import { PER_ISSUER_FLAG_DEFAULT_VALUES } from '../featureFlags/enabledPerIssuerFlags';
import { BOOLEAN_FLAG_DEFAULT_VALUES } from '../featureFlags/booleanFlags';
import { STRING_FLAG_DEFAULT_VALUES } from '../featureFlags/stringFlags';
import GlobalLoadingContextProvider from '../components/abc/global-loading-context/global-loading-context-provider';
import { DeviceIdContextProvider } from '../events/device-id/device-id-context-provider';
import { store } from './store';
import { RootErrorBoundaryFallback } from '../components/root-error-boundary/root-error-boundary';
import { config } from '../utils/config';

const queryClient = new QueryClient();

function App() {
  return (
    // We have the same RootErrorBoundary in 2 places. One at the very
    // root of the entire application here, in case there's an error
    // in some very fundamental code like cashless.tsx. And one lower
    // down inside of the Redux provider, so that it has access to
    // Redux variables.
    <ErrorBoundary FallbackComponent={RootErrorBoundaryFallback}>
      <QueryClientProvider client={queryClient}>
        <DeviceIdContextProvider>
          <Provider store={store}>
            <BrowserRouter>
              <GlobalLoadingContextProvider>
                <Cashless />
              </GlobalLoadingContextProvider>
            </BrowserRouter>
          </Provider>
        </DeviceIdContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

// Update the export default to use your environment-specific client ID and a sample user:
export default withLDProvider({
  clientSideID: config.launchDarklyUrl,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags: {
    ...PER_ISSUER_FLAG_DEFAULT_VALUES,
    ...BOOLEAN_FLAG_DEFAULT_VALUES,
    ...STRING_FLAG_DEFAULT_VALUES,
  },
})(App);
