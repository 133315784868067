import type { HtmlFromDotcms } from './converters-from-dotcms';
import {
  convertDotcmsResponseToRecord,
  convertDotcmsString,
  wrapDotcmsHtml,
} from './converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-base-object';
import { buildGqlFieldsQuery } from './gql-builders';
import type { IGqlHelper } from './gql-helper';
import type { IHaveQuestionSection } from './dotcms-have-question';
import { HaveQuestionGqlHelper } from './dotcms-have-question';

export type IFooterSectionData = IBaseObjectData & {
  titleForResourceSection: string | undefined;
  titleForLanguagesSection: string | undefined;
  question: IHaveQuestionSection | undefined;
  privacyPolicyLinkTitle: HtmlFromDotcms | undefined;
  taxInformationLinkTitle: HtmlFromDotcms | undefined;
  importantInformationTitle: HtmlFromDotcms | undefined;
  termsOfServiceTitle: HtmlFromDotcms | undefined;
  patentNumberNote: HtmlFromDotcms | undefined;
};

export const FooterGqlHelper: IGqlHelper<IFooterSectionData> = {
  contentTypeName: 'FooterSection',
  gqlFieldsQuery: `
      titleforresourcesection
      titleforlanguagessection
      question { ${buildGqlFieldsQuery(HaveQuestionGqlHelper)} }
      privacyPolicyLinkTitle
      taxInformationLinkTitle
      importantInformationTitle
      termsOfServiceTitle
      patentNumberNote
  `,
  processGqlData: (gqlResponse: unknown) => {
    if (!gqlResponse) {
      return undefined;
    }

    const {
      titleforresourcesection,
      titleforlanguagessection,
      question,
      privacyPolicyLinkTitle,
      taxInformationLinkTitle,
      importantInformationTitle,
      termsOfServiceTitle,
      patentNumberNote,
    } = convertDotcmsResponseToRecord(gqlResponse);

    return {
      titleForResourceSection: convertDotcmsString(titleforresourcesection),
      titleForLanguagesSection: convertDotcmsString(titleforlanguagessection),
      question: HaveQuestionGqlHelper.processGqlData(question),
      privacyPolicyLinkTitle: wrapDotcmsHtml(privacyPolicyLinkTitle),
      taxInformationLinkTitle: wrapDotcmsHtml(taxInformationLinkTitle),
      importantInformationTitle: wrapDotcmsHtml(importantInformationTitle),
      termsOfServiceTitle: wrapDotcmsHtml(termsOfServiceTitle),
      patentNumberNote: wrapDotcmsHtml(patentNumberNote),
    };
  },
};
