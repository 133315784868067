import { trimAny } from '../utils/string/trim-any';

export const CONDITIONAL_CMS_LOGICAL_NOT_PREFIX = 'ce_if_logical_not_';

export enum ConditionalCmsStringKey {
  CASHLESS_MATCH_PLAN = 'ce_if_cashless_match_plan',
  CASHLESS_ONLY_ENROLLMENT = 'ce_if_cashless_only_enrollment',
  ESPP_ONLY_PLAN = 'ce_if_espp_only_plan',
  CASHLESS_DSP_PLAN = 'ce_if_cashless_dsp_plan',
  CASHLESS_FEE_PLAN = 'ce_if_cashless_fee_plan',
  CASHLESS_CAP_PERCENT_PLAN = 'ce_if_cashless_cap_percent_plan',
  SUSPENDED_PLAN = 'ce_if_suspended_plan',
  FRACTIONAL_SHARES_ALLOWED = 'ce_if_fractional_shares_allowed',
  SHOW_WEALTH_MANAGEMENT_FIRM_NAME = 'ce_if_show_wealth_management_firm_name',
  WITH_LAST_DAY_TO_MAKE_CHANGES = 'ce_if_with_last_day_to_make_changes',
  WORK_COUNTRY_USA = 'ce_if_work_country_usa',
  SCHEDULED_CONTRIBUTION_CHANGE_IS_FOR_OP = 'ce_if_scheduled_contribution_change_is_for_op',
  SCHEDULED_CONTRIBUTION_CHANGE_IS_FOR_PP = 'ce_if_scheduled_contribution_change_is_for_pp',
  ENTIRE_ISSUER_ESPP_ONLY = 'ce_if_entire_issuer_espp_only',
  SELL_TO_COVER_ENABLED = 'ce_if_sell_to_cover_enabled',
}

export interface ConditionalCmsConditionArgs {
  isCashlessOnlyEnrollment: boolean;
  isEsppOnlyPlan: boolean;
  isCashlessMatchPlan: boolean;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  cashlessRevenueModel: 'FEE' | 'DSP' | string | null;
  planHasCashlessCapPercent: boolean;
  isPlanInSuspensionState: boolean | null;
  sharePrecision: number | null;
  showWealthManagementFirmName: boolean;
  isWithLastDayToChange: boolean;
  isUserCountryUsa: boolean;
  futureChangePeriodType: 'OFFERING' | 'PURCHASE' | null;
  isEntireIssuerEsppOnly: boolean;
  isSellToCoverEnabled: boolean;
}

export const CONDITIONAL_CMS_STRING_DESCRIPTIONS: {
  [key in ConditionalCmsStringKey]: string;
} = {
  [ConditionalCmsStringKey.CASHLESS_MATCH_PLAN]:
    'True if the plan is Cashless Match',
  [ConditionalCmsStringKey.CASHLESS_ONLY_ENROLLMENT]:
    'True if the plan uses Cashless Only enrollment (regardless of whether enrollment is currently open or closed)',
  [ConditionalCmsStringKey.ESPP_ONLY_PLAN]: 'True if the plan is ESPP only',
  [ConditionalCmsStringKey.CASHLESS_DSP_PLAN]:
    'True if the plan is Cashless with DSP model',
  [ConditionalCmsStringKey.CASHLESS_FEE_PLAN]:
    'True if the plan is Cashless with fee model',
  [ConditionalCmsStringKey.CASHLESS_CAP_PERCENT_PLAN]:
    'True if the plan has a Cashless cap percent',
  [ConditionalCmsStringKey.SUSPENDED_PLAN]:
    'True if the plan is in suspension state',
  [ConditionalCmsStringKey.FRACTIONAL_SHARES_ALLOWED]:
    'True if the plan allows purchasing fractional shares',
  [ConditionalCmsStringKey.SHOW_WEALTH_MANAGEMENT_FIRM_NAME]:
    'True if the wealth management firm allows its name to be displayed in the wealth managment contact opt-in',
  [ConditionalCmsStringKey.WITH_LAST_DAY_TO_MAKE_CHANGES]:
    'True if the issuer has a certain deadline to make changes to the purchase',
  [ConditionalCmsStringKey.WORK_COUNTRY_USA]: 'True if the work country is USA',
  [ConditionalCmsStringKey.SCHEDULED_CONTRIBUTION_CHANGE_IS_FOR_OP]:
    "True if the user has a scheduled contribution change, and it won't take effect until the next OP starts",
  [ConditionalCmsStringKey.SCHEDULED_CONTRIBUTION_CHANGE_IS_FOR_PP]:
    'True if the user has scheduled a contribution change, and it will take effect in the next PP within the current OP',
  [ConditionalCmsStringKey.ENTIRE_ISSUER_ESPP_ONLY]:
    'True if the entire issuer (all their jurisdictions) is ESPP only',
  [ConditionalCmsStringKey.SELL_TO_COVER_ENABLED]:
    'True if "sell to cover" is enabled for the user',
};

export const CONDITIONAL_CMS_STRING_CONDITIONS: {
  [key in ConditionalCmsStringKey]: (
    conditions: ConditionalCmsConditionArgs,
  ) => boolean;
} = {
  [ConditionalCmsStringKey.CASHLESS_MATCH_PLAN]: ({ isCashlessMatchPlan }) =>
    isCashlessMatchPlan,
  [ConditionalCmsStringKey.CASHLESS_ONLY_ENROLLMENT]: ({
    isCashlessOnlyEnrollment,
  }: ConditionalCmsConditionArgs) => isCashlessOnlyEnrollment,
  [ConditionalCmsStringKey.ESPP_ONLY_PLAN]: ({ isEsppOnlyPlan }) =>
    isEsppOnlyPlan,
  [ConditionalCmsStringKey.CASHLESS_DSP_PLAN]: ({ cashlessRevenueModel }) =>
    cashlessRevenueModel === 'DSP',
  [ConditionalCmsStringKey.CASHLESS_FEE_PLAN]: ({ cashlessRevenueModel }) =>
    cashlessRevenueModel === 'FEE',
  [ConditionalCmsStringKey.CASHLESS_CAP_PERCENT_PLAN]: ({
    planHasCashlessCapPercent,
  }) => planHasCashlessCapPercent,
  [ConditionalCmsStringKey.SUSPENDED_PLAN]: ({ isPlanInSuspensionState }) =>
    !!isPlanInSuspensionState,
  [ConditionalCmsStringKey.FRACTIONAL_SHARES_ALLOWED]: ({ sharePrecision }) =>
    !!sharePrecision,
  [ConditionalCmsStringKey.SHOW_WEALTH_MANAGEMENT_FIRM_NAME]: ({
    showWealthManagementFirmName,
  }) => showWealthManagementFirmName,
  [ConditionalCmsStringKey.WITH_LAST_DAY_TO_MAKE_CHANGES]: ({
    isWithLastDayToChange,
  }) => isWithLastDayToChange,
  [ConditionalCmsStringKey.WORK_COUNTRY_USA]: ({ isUserCountryUsa }) =>
    isUserCountryUsa,

  [ConditionalCmsStringKey.SCHEDULED_CONTRIBUTION_CHANGE_IS_FOR_OP]: ({
    futureChangePeriodType,
  }) => futureChangePeriodType === 'OFFERING',
  [ConditionalCmsStringKey.SCHEDULED_CONTRIBUTION_CHANGE_IS_FOR_PP]: ({
    futureChangePeriodType,
  }) => futureChangePeriodType === 'PURCHASE',
  [ConditionalCmsStringKey.ENTIRE_ISSUER_ESPP_ONLY]: ({
    isEntireIssuerEsppOnly,
  }) => isEntireIssuerEsppOnly,
  [ConditionalCmsStringKey.SELL_TO_COVER_ENABLED]: ({ isSellToCoverEnabled }) =>
    isSellToCoverEnabled,
};

interface IConditionalStringProcess {
  trueConditions: ReadonlyArray<string>;
  falseConditions: ReadonlyArray<string>;
  ignoredConditions: ReadonlyArray<string>;
  // For logging errors about unrecognized conditions:
  cmsContentTypeTitle: string;
  fieldNames: ReadonlyArray<string>;
}

/** Takes a string like `{[some_key]}` and returns `some_key`.
 * Supports either open or close tags. Ignores whitespace on either side
 * of the {[ characters
 */
export const getKeyFromConditionalWrapperBrackets = (
  conditionalWrapper: string,
) => trimAny(conditionalWrapper.trim(), ['{', '}', '[', ']', '/']).trim();

/** Processes boolean conditional string wrappers.
 * Conditional tags open like {[some_tag]} and close like {[/some_tag]}.
 * If the condition is true, the text wrapped in between the tags is left in the
 * processed string. If the condition is false, the text is removed.
 */
export const processConditionalCmsStringsInContentFromCms = (
  cmsContentToProcess: string,
  {
    trueConditions,
    falseConditions,
    ignoredConditions,
    cmsContentTypeTitle,
    fieldNames,
  }: IConditionalStringProcess,
) => {
  // Wrap in a set to remove duplicate values:
  const conditionalKeysDetectedInContent = Array.from(
    new Set(
      // This regex matches strings like `{[some_key]}` or `{[/some_key]}`, with whitespace
      // allowed inside the brackets:
      (cmsContentToProcess.match(/{\[\\?.*?\]}/g) ?? []).map(
        (keyInsideBrackets) =>
          getKeyFromConditionalWrapperBrackets(keyInsideBrackets),
      ),
    ),
  );

  const falseAndUnrecognizedConditions =
    conditionalKeysDetectedInContent.filter((key) => {
      // This also logs an error if finding a condition that's unrecognized.
      const isTrueCondition = trueConditions.includes(key);
      const isFalseCondition = falseConditions.includes(key);
      const isIgnoredCondition = ignoredConditions.includes(key);
      if (!isTrueCondition && !isFalseCondition && !isIgnoredCondition) {
        console.error(
          `A conditional variable was used in the cms that the frontend does not recognize: "${key}". This occurred in cms content type: "${cmsContentTypeTitle}" in the field: "${fieldNames.join(
            ' > ',
          )}"`,
        );
      }
      return !isTrueCondition;
    });

  // False/unrecognized conditions: Remove them and the text that they wrap.
  const contentWithFalsyConditionsRemoved =
    falseAndUnrecognizedConditions.reduce<string>(
      (cmsContent, currentFalsyConditionKey) =>
        (ignoredConditions.includes(currentFalsyConditionKey)
          ? cmsContent
          : // This regex matches the open tag (`{[current_key]}`),
        // the close tag (`{[/current_key]}`), and any text in between:
          cmsContent.replace(
            new RegExp(
              `{\\[\\s?${currentFalsyConditionKey}\\s?\\]}.*?{\\[/\\s?${currentFalsyConditionKey}\\s?\\]}`,
              'g',
            ),
            '',
          )),
      cmsContentToProcess,
    );

  // Everything left is either a true condition or an unpaired tag.
  // Remove tags for paired true conditions:
  const contentWithTrueConditionsProcessed = trueConditions.reduce<string>(
    (cmsContent, currentFalsyConditionKey) =>
      // This regex matches the open tag (`{[current_key]}`), the close tag (`{[/current_key]}`),
      // and any text in between. The text in between is a capture group:
      cmsContent.replace(
        new RegExp(
          `{\\[\\s?${currentFalsyConditionKey}\\s?\\]}(.*?){\\[/\\s?${currentFalsyConditionKey}\\s?\\]}`,
          'g',
        ),
        // This replaces the matching string with the contents of the capture group only.
        // (Capture group index 1):
        '$1',
      ),
    contentWithFalsyConditionsRemoved,
  );

  // Now, the only tags left are ignored or unpaired tags
  // (i.e. an open tag with no matching close tag, or a close tag with no matching open tag).
  // Replace all of unpaired tags with empty strings, logging an error for each:
  const finalContent = contentWithTrueConditionsProcessed.replace(
    /{\[\\?.*?\]}/g,
    (ignoredOrUnpairedTag) => {
      const key = getKeyFromConditionalWrapperBrackets(ignoredOrUnpairedTag);
      if (ignoredConditions.includes(key)) {
        return ignoredOrUnpairedTag;
      }

      const isCloseTag = ignoredOrUnpairedTag.includes('[/');
      console.error(
        `A conditional variable was imbalanced (${
          isCloseTag ? 'close' : 'open'
        } tag did not have a corresponding ${
          isCloseTag ? 'open' : 'close'
        } tag): "${ignoredOrUnpairedTag}". This occurred in cms content type: "${cmsContentTypeTitle}" in the field: "${fieldNames.join(
          ' > ',
        )}"`,
      );
      return '';
    },
  );

  return finalContent;
};
