import { Page } from './page-url-path';
import { useGetCurrentPage } from './get-current-page';

export const PageEnglishOnlyStatus: Readonly<{
  [key in Page]: boolean;
}> = {
  [Page.DEFAULT]: false,
  [Page.ABOUT_CASHLESS]: false,
  [Page.ADMIN_PAGE]: true,
  [Page.ADMIN_DATA_CONTRIBUTIONS]: true,
  [Page.ADMIN_DATA_EXCHANGE]: true,
  [Page.ADMIN_DATA_UPLOAD]: true,
  [Page.ADMIN_ENROLLMENT_STATS]: true,
  [Page.ADMIN_EXCHANGE_RATES]: true,
  [Page.ADMIN_STOCK_BASED_COMPENSATION]: true,
  [Page.ADMIN_SURVEY_RESULTS]: true,
  [Page.ADMIN_PLAN_OVERVIEW]: true,
  [Page.ADMIN_EMPLOYEE_LOOKUP]: true,
  [Page.ADMIN_PURCHASE_LOOKUP]: true,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_OVERVIEW]: true,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_HIGHLIGHTS]: true,
  [Page.ADMIN_PURCHASE_LOOKUP_TAB_LOOKUP]: true,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION]: true,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_PLAN]: true,
  [Page.ADMIN_PLAN_ENROLLMENT_CONFIGURATION_TAB_ENROLLMENT]: true,
  [Page.ACCOUNT_PAGE]: false,
  [Page.ACCOUNT_PAGE_CONTACT_INFORMATION]: false,
  [Page.ACCOUNT_PAGE_DOCUMENTS]: false,
  [Page.ACCOUNT_PAGE_MANAGE_ELECTION]: false,
  [Page.ACCOUNT_PAGE_PURCHASE_DETAILS]: false,
  [Page.ACCOUNT_PAGE_STATUS_SUMMARY]: false,
  [Page.ACCOUNT_PAGE_STOCK_INFORMATION]: false,
  [Page.ANTI_BOT_BROKER_QUESTIONS_REDIRECT]: false,
  [Page.ANTI_BOT_MAGIC_LINK_ENROLLMENT_WELCOME]: false,
  [Page.AUTO_SALE_BROKER_QUESTIONS]: false,
  [Page.CALCULATOR]: false,
  [Page.CE_INTERNAL_DYNAMIC_VARIABLE_VIEWER]: false,
  [Page.EMAIL_VERIFICATION_COMPLETE]: false,
  [Page.ENROLLMENT_PAGE]: false,
  [Page.HOMEPAGE]: false,
  [Page.IMPORTANT_INFORMATION]: false,
  [Page.LOGIN_PAGE]: false,
  [Page.PRIVACY_POLICY]: false,
  [Page.REFERRAL]: false,
  [Page.RESOURCE_LIBRARY]: false,
  [Page.RESOURCE_LIBRARY_ID]: false,
  [Page.STANDALONE_BROKER_QUESTIONS]: false,
  [Page.TAX_INFORMATION]: false,
  [Page.TEMP_PDF_VIEWER_TEST]: false,
  [Page.TERM_OF_SERVICE]: false,
  [Page.UNSUBSCRIBE]: false,
  [Page.WEALTH_MANAGEMENT]: false,
};

export const useGetCurrentPageEnglishOnlyStatus = () => {
  const currentPage = useGetCurrentPage();

  if (currentPage) {
    return PageEnglishOnlyStatus[currentPage] ?? false;
  }

  return false;
};
