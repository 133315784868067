import { useCallback } from 'react';
import { useGetStockCurrencyCode } from './use-get-stock-currency-code';
import { useCurrencyByCode } from './useIssuerCurrency';

const formatCurrencyFromHundredths = (
  currencyValueAsHundredths: number | undefined,
  currencyDisplaySymbolLeft: string,
  currencyDisplaySymbolSpaceLeft: boolean,
  currencyDisplaySymbolRight: string,
  currencyDisplaySymbolSpaceRight: boolean,
  precisionDigitsAfterDecimal?: number,
) => {
  const isNegative = currencyValueAsHundredths && currencyValueAsHundredths < 0;
  const positiveNumericPart =
    currencyValueAsHundredths !== undefined
      ? // We use the absolute value of the number
    // to separately show the minus sign in the correct place for formatting
      (Math.abs(currencyValueAsHundredths) / 100).toLocaleString(undefined, {
        // Calling toLocaleString with undefined locale parameter means it will use
        // the locale of the user's browser. This is what we want, because
        // it will format with the same numeric format that the user is used to seeing
        // in their browser.
        minimumFractionDigits:
            precisionDigitsAfterDecimal === undefined
              ? 2
              : precisionDigitsAfterDecimal,
        maximumFractionDigits:
            precisionDigitsAfterDecimal === undefined
              ? 2
              : precisionDigitsAfterDecimal,
      })
      : '-';
  return `${isNegative ? '-' : ''}${currencyDisplaySymbolLeft}${
    currencyDisplaySymbolSpaceLeft ? ' ' : ''
  }${positiveNumericPart}${
    currencyDisplaySymbolSpaceRight ? ' ' : ''
  }${currencyDisplaySymbolRight}`;
};

export const useCurrencyFormatter = (currencyCodeToFormat?: string) => {
  const {
    currencyDisplaySymbolLeft,
    currencyDisplaySymbolSpaceLeft,
    currencyDisplaySymbolRight,
    currencyDisplaySymbolSpaceRight,
  } = useCurrencyByCode(currencyCodeToFormat);
  const { currencyCode: userCompensationCurrency } =
    useCurrencyByCode(undefined);
  const normalCurrencyCodeIsUsd =
    currencyCodeToFormat === 'USD' ||
    (currencyCodeToFormat === undefined && userCompensationCurrency) === 'USD';
  const stockCurrencyCode = useGetStockCurrencyCode();
  const {
    currencyDisplaySymbolLeft: stockCurrencyDisplaySymbolLeft,
    currencyDisplaySymbolSpaceLeft: stockCurrencyDisplaySymbolSpaceLeft,
    currencyDisplaySymbolRight: stockCurrencyDisplaySymbolRight,
    currencyDisplaySymbolSpaceRight: stockCurrencyDisplaySymbolSpaceRight,
  } = useCurrencyByCode(stockCurrencyCode);

  // If all currencies are USD, format like "$1.23".
  // Otherwise, format with the full currency code like "1.00 USD" or "1.00 EUR".
  // Except on the admin pages, where if the issuer has some non-USD jurisdiction,
  // we should always use the full currency code, including for USD:
  const useShortFormatUsd =
    (currencyCodeToFormat === undefined || currencyCodeToFormat === 'USD') &&
    userCompensationCurrency === 'USD' &&
    stockCurrencyCode === 'USD';

  const formatFromHundredths = useCallback(
    (
      currencyValueAsHundredths: number | undefined,
      precisionDigitsAfterDecimal?: number,
    ) =>
      formatCurrencyFromHundredths(
        currencyValueAsHundredths,
        currencyDisplaySymbolLeft,
        currencyDisplaySymbolSpaceLeft,
        currencyDisplaySymbolRight,
        currencyDisplaySymbolSpaceRight,
        precisionDigitsAfterDecimal,
      ),
    [
      currencyDisplaySymbolLeft,
      currencyDisplaySymbolSpaceLeft,
      currencyDisplaySymbolRight,
      currencyDisplaySymbolSpaceRight,
    ],
  );

  const formatCurrencyFromWholeUnits = useCallback(
    (
      currencyValueAsWholeUnits: number | undefined,
      precisionDigitsAfterDecimal?: number,
    ) =>
      formatFromHundredths(
        currencyValueAsWholeUnits !== undefined
          ? currencyValueAsWholeUnits * 100
          : undefined,
        precisionDigitsAfterDecimal,
      ),
    [formatFromHundredths],
  );

  const formatUSDFromHundredths = useCallback(
    (
      currencyValueAsHundredths: number | undefined,
      precisionDigitsAfterDecimal?: number,
    ) =>
      formatCurrencyFromHundredths(
        currencyValueAsHundredths,
        useShortFormatUsd ? '$' : '',
        false,
        useShortFormatUsd ? '' : 'USD',
        !useShortFormatUsd,
        precisionDigitsAfterDecimal,
      ),
    [useShortFormatUsd],
  );

  const formatUSDFromWholeUnits = useCallback(
    (
      currencyValueAsWholeUnits: number | undefined,
      precisionDigitsAfterDecimal?: number,
    ) =>
      formatUSDFromHundredths(
        currencyValueAsWholeUnits !== undefined
          ? currencyValueAsWholeUnits * 100
          : undefined,
        precisionDigitsAfterDecimal,
      ),
    [formatUSDFromHundredths],
  );

  // The default precision after decimal (2 digits) is not correct for stock prices.
  // The formatStockPriceFromHundredths and formatStockPriceFromWholeUnits should not be
  // directly used to format stock prices until we figure out a way to get the correct decimal
  // precision here.
  const formatStockPriceFromHundredths = useCallback(
    (
      currencyValueAsHundredths: number | undefined,
      precisionDigitsAfterDecimal?: number,
    ) =>
      (stockCurrencyCode === 'USD'
        ? formatUSDFromHundredths(
          currencyValueAsHundredths,
          precisionDigitsAfterDecimal,
        )
        : formatCurrencyFromHundredths(
          currencyValueAsHundredths,
          stockCurrencyDisplaySymbolLeft,
          stockCurrencyDisplaySymbolSpaceLeft,
          stockCurrencyDisplaySymbolRight,
          stockCurrencyDisplaySymbolSpaceRight,
          precisionDigitsAfterDecimal,
        )),
    [
      stockCurrencyCode,
      formatUSDFromHundredths,
      stockCurrencyDisplaySymbolLeft,
      stockCurrencyDisplaySymbolSpaceLeft,
      stockCurrencyDisplaySymbolRight,
      stockCurrencyDisplaySymbolSpaceRight,
    ],
  );

  const formatStockPriceFromWholeUnits = useCallback(
    (
      currencyValueAsWholeUnits: number | undefined,
      precisionDigitsAfterDecimal?: number,
    ) =>
      formatStockPriceFromHundredths(
        currencyValueAsWholeUnits !== undefined
          ? currencyValueAsWholeUnits * 100
          : undefined,
        precisionDigitsAfterDecimal,
      ),
    [formatStockPriceFromHundredths],
  );

  // Sometimes the backend returns stock price as a string with the correct
  // number of decimal places. In that case, we can just count the decimal
  // places in the string and use that as the precision:
  const formatStockPriceFromBackendNumericString = useCallback(
    (stockPriceFromBackend: string | null) => {
      // Many callers of this function are in places where the stock price is string | null, so just
      // handle it here.
      if (
        stockPriceFromBackend === null ||
        stockPriceFromBackend === undefined
      ) {
        return '-';
      }
      if (typeof stockPriceFromBackend !== 'string') {
        console.error(
          `Type of stockPriceFromBackend was expected as 'string' but was '${typeof stockPriceFromBackend}'`,
        );
        return '-';
      }
      const detectedDecimalPrecision =
        stockPriceFromBackend.split('.')?.[1]?.length ?? 2;
      // In all cases, we want at least 2 decimal places. But the backend may send a numeric
      // string with less than that:
      const decimalPrecision =
        detectedDecimalPrecision < 2 ? 2 : detectedDecimalPrecision;
      return formatStockPriceFromWholeUnits(
        Number(stockPriceFromBackend),
        decimalPrecision,
      );
    },
    [formatStockPriceFromWholeUnits],
  );

  return {
    formatCurrencyFromHundredths: normalCurrencyCodeIsUsd
      ? formatUSDFromHundredths
      : formatFromHundredths,
    formatCurrencyFromWholeUnits: normalCurrencyCodeIsUsd
      ? formatUSDFromWholeUnits
      : formatCurrencyFromWholeUnits,
    formatUSDFromHundredths,
    formatUSDFromWholeUnits,
    formatStockPriceFromBackendNumericString,
  };
};
