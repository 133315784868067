import { generatePath } from 'react-router';
import { useGetIssuerShortname } from '../hooks/use-get-issuer-shortname';
import type { Page } from './page-url-path';
import { PageUrlPath } from './page-url-path';
import { UrlRouteToken } from './router-utils';
import { captureErrorInSentryWithCustomMessage } from '../utils/capture-error-in-sentry-with-custom-message';

/** For each key in paramsToFill, this replaces any occurrence of `:${key}`
 * with paramsToFill[key].
 *
 * Example: For the page '/:ticker/log-in', if paramsToFill = {ticker: 'example'},
 * it returns "/example/log-in"
 */
export const getRouteToPage = (
  page: Page,
  ticker: string | undefined,
  paramsToFill: Omit<
  Partial<{ [key in UrlRouteToken]: string }>,
  UrlRouteToken.TICKER
  >,
) => {
  const allParamsToFill = {
    ...(paramsToFill ?? {}),
    ...(ticker ? { [UrlRouteToken.TICKER]: ticker } : {}),
  };
  try {
    return generatePath(PageUrlPath[page], allParamsToFill);
  } catch (e) {
    console.error(e);
    captureErrorInSentryWithCustomMessage(
      e,
      `Error when generating a route to page: page - ${
        PageUrlPath[page]
      }, ticker - ${allParamsToFill.ticker ?? 'undefined'}.`,
    );
    return '';
  }
};

/** Get a route to a page. Auto-fills the issuer token in the URL */
export const useGetRouteToIssuerPage = (
  page: Page,
  paramsToFill?: Omit<
  Partial<{ [key in UrlRouteToken]: string }>,
  UrlRouteToken.TICKER
  >,
) => {
  // redux generatePath throws an error if you try to provide an empty string
  // as a param to fill in the URL, so putting a non-empty string here
  // to stop those errors. This should only ever be undefined in circumstances
  // where this generated URL is not actually being used anyway:
  const issuerShortName = useGetIssuerShortname() ?? 'CP-3870';

  return getRouteToPage(page, issuerShortName, paramsToFill ?? {});
};
