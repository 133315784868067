import { useMemo } from 'react';
import { gql } from 'graphql-request';
import { convertDotcmsResponseToRecords } from './dotcms-models/converters-from-dotcms';
import type { IBaseObjectData } from './dotcms-models/dotcms-base-object';
import { BaseObjectGqlHelper } from './dotcms-models/dotcms-base-object';
import { buildGqlFieldsQuery } from './dotcms-models/gql-builders';
import type { IGqlHelper } from './dotcms-models/gql-helper';
import type { JSONSerializableObject } from '../utils/json-types';

const builderToCreateGqlQuery = <TExpectedType extends IBaseObjectData>(
  gqlHelper: IGqlHelper<TExpectedType>,
  limit?: number,
) => {
  if (!gqlHelper.contentTypeName) {
    return () => gql``;
  }

  const limitArg = limit ? ` limit: ${limit}` : '';
  return (lucerneQuery: string) => gql`
        ${
  gqlHelper.contentTypeName
}Collection(query: "${lucerneQuery}"${limitArg}) {
            ${buildGqlFieldsQuery(gqlHelper)}
        }
      `;
};

export const processGqlDataAsArray = <TExpectedType>(
  gqlHelper: IGqlHelper<TExpectedType>,
  gqlResponse: JSONSerializableObject[],
): TExpectedType[] | undefined => {
  if (!gqlResponse) {
    return undefined;
  }

  const gqlRecords = convertDotcmsResponseToRecords(gqlResponse);
  if (gqlRecords.length === 0) {
    return undefined;
  }

  const result: TExpectedType[] = [];
  gqlRecords.forEach((x) => {
    const data = gqlHelper.processGqlData(x);
    const baseInfo = BaseObjectGqlHelper.processGqlData(x);
    if (data) {
      result.push({
        ...baseInfo,
        ...data,
      });
    }
  });

  return result;
};

export const useBuildersToProcessGqlData = <
  TExpectedType extends IBaseObjectData,
>(
    gqlHelper: IGqlHelper<TExpectedType>,
  ) =>
    useMemo(
      () => ({
        createGqlQueryForArray: builderToCreateGqlQuery(gqlHelper),

        createGqlQueryForSingle: builderToCreateGqlQuery(gqlHelper, 1),

        processGqlDataAsArray: (
          gqlResponse: JSONSerializableObject[],
        ): TExpectedType[] | undefined =>
          processGqlDataAsArray(gqlHelper, gqlResponse),

        processGqlDataAsSingle: (
          gqlResponse: JSONSerializableObject[],
        ): TExpectedType | undefined => {
          const result = processGqlDataAsArray(gqlHelper, gqlResponse);
          if (result && result.length > 1) {
            console.error(
              `Received more than 1 result for type ${
                gqlHelper.contentTypeName ?? 'unknown'
              } when only 1 was expected`,
            );
          }
          return result ? result[0] : result;
        },
        contentTypeName: gqlHelper.contentTypeName ?? 'unknown',
      }),
      [gqlHelper],
    );
