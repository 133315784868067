import { useSelector } from 'react-redux';
import { uiSelector } from '../../selectors';

const useIsExistingIssuer = (ticker?: string): boolean => {
  const { issuerNotFoundError } = useSelector(uiSelector);
  if (!ticker) {
    return false;
  }

  if (issuerNotFoundError) {
    return false;
  }

  return true;
};

export default useIsExistingIssuer;
