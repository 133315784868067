/* eslint-disable */
// This function is used to find out the color that lighter or darker in proportion with color passed as first argument. (using hsl format)
// To make color lighter pass as lightness positive number
// f.e: hsl = blue - hsl(205, 100%, 50%). lightness = 20. So, in this case we return lighter color - light blue -  hsl(205, 100%, 70%)
// To make color darker pass as lightness negative number
// f.e: hsl = blue - hsl(205, 100%, 50%). lightness = -20. So, in this case we return darker color - dark blue -  hsl(205, 100%, 30%)

export const changeLightnessHLS = (hls, lightness) => {
  const hlsNumbers = hls.split('(')[1].split(')')[0].split(',');
  const lightnessHLS = hlsNumbers[2].split('%')[0];
  hlsNumbers[2] = `${parseInt(lightnessHLS, 10) + lightness}%`;
  return `hsl(${hlsNumbers})`;
};
