import { createContext } from 'react';
import type { IGlobalTextVariablesData } from '../dotcms-models/dotcms-global-text-variables';

export const CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT_NAME =
  'ce_custom_displayed_count';
export const CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT_NAME = 'ce_custom_total_count';
export const CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT = `{{${CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT_NAME}}}`;
export const CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT = `{{${CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT_NAME}}}`;

export const CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY_NAME =
  'ce_custom_source_currency';
export const CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY_NAME =
  'ce_custom_target_currency';
export const CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY = `{{${CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY_NAME}}}`;
export const CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY = `{{${CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY_NAME}}}`;

export type GlobalTextVariablesData = IGlobalTextVariablesData & {
  customDynamicVariableDisplayedCount: string;
  customDynamicVariableTotalCount: string;
  customDynamicVariableSourceCurrency: string;
  customDynamicVariableTargetCurrency: string;
};

const GLOBAL_TEXT_VARIABLES_CONTEXT_FALLBACK: GlobalTextVariablesData = {
  customDynamicVariableDisplayedCount: CUSTOM_DYNAMIC_VARIABLE_DISLPAYED_COUNT,
  customDynamicVariableTotalCount: CUSTOM_DYNAMIC_VARIABLE_TOTAL_COUNT,
  customDynamicVariableSourceCurrency: CUSTOM_DYNAMIC_VARIABLE_SOURCE_CURRENCY,
  customDynamicVariableTargetCurrency: CUSTOM_DYNAMIC_VARIABLE_TARGET_CURRENCY,
};

export const GlobalTextVariablesContext =
  createContext<GlobalTextVariablesData>(
    GLOBAL_TEXT_VARIABLES_CONTEXT_FALLBACK,
  );
