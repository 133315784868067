import type { DateTimeOptions } from 'luxon';
import { DateTime } from 'luxon';

interface IProps {
  date: number | string;
  month: number | string;
  year: number | string;
}

export const getDateTimeFromObjectDate = (
  { date, month, year }: IProps,
  opts?: DateTimeOptions,
) => DateTime.fromISO(`${year}-${month}-${date}`, { setZone: true, ...opts });
