import { useMemo } from 'react';
import {
  EOI_START_BEFORE_ENROLLMENT_DAYS,
  EOI_BEFORE_DEADLINE_DAYS,
} from '../../pages/enrollment-abc/constants';
import { useGetPeriodsDatesInfo } from '../use-get-periods-info';

const useGetExpressionOfInterestInfo = () => {
  const { closestEnrollmentDates } = useGetPeriodsDatesInfo();

  const eoiStartDate = useMemo(
    () =>
      closestEnrollmentDates.start
        ?.minus({
          days: EOI_START_BEFORE_ENROLLMENT_DAYS,
        })
        .toJSDate(),
    [closestEnrollmentDates.start],
  );

  const eoiDeadlineDateLuxon = useMemo(
    () =>
      closestEnrollmentDates.start?.plus({
        days: EOI_BEFORE_DEADLINE_DAYS,
      }),
    [closestEnrollmentDates.start],
  );

  const eoiDeadlineDate = useMemo(
    () => eoiDeadlineDateLuxon.toJSDate(),
    [eoiDeadlineDateLuxon],
  );

  const endOfEnrollmentDate = useMemo(
    () => closestEnrollmentDates.end.toJSDate(),
    [closestEnrollmentDates.end],
  );

  return useMemo(() => {
    const now = new Date();
    return {
      isBeforeEoiDeadline: now >= eoiStartDate && now < eoiDeadlineDate,
      isAfterEoiDeadline: now >= eoiDeadlineDate && now < endOfEnrollmentDate,
      enrollmentEoiDeadlineDate: eoiDeadlineDateLuxon,
    };
  }, [
    eoiStartDate,
    eoiDeadlineDate,
    endOfEnrollmentDate,
    eoiDeadlineDateLuxon,
  ]);
};

export default useGetExpressionOfInterestInfo;
