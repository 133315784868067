import { createContext } from 'react';
import type IOperationButtonFromDotcms from './types';

const OPERATION_BUTTON_DEFAULT_DATA: IOperationButtonFromDotcms = {
  data: undefined,
  loading: false,
  error: null,
};

const OperationButtonContext = createContext<IOperationButtonFromDotcms>(
  OPERATION_BUTTON_DEFAULT_DATA,
);

export default OperationButtonContext;
