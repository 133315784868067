export const isValueEmpty = (x: unknown): boolean =>
  x === undefined ||
  x === null ||
  x === '' ||
  (Array.isArray(x) && x.length < 1) ||
  (typeof x === 'object' && Object.keys(x).length < 1);

const reduceEmptyValues = (
  result: object,
  value: object,
  mergeResultsIfCorrectValue: (valueWithoutEmptyKeys: unknown) => object,
): object => {
  const valueWithoutEmptyKeys =
    !isValueEmpty(value) && value && typeof value === 'object'
      ? // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeEmptyKeysForDotcmsFallback(value)
      : value;

  return isValueEmpty(valueWithoutEmptyKeys)
    ? result
    : mergeResultsIfCorrectValue(valueWithoutEmptyKeys);
};

/** Returns a copy of a given object, minus any key/value pairs where
 * the value was undefined, null, or an empty string.
 *
 * E.g., if input is { a: 'foo', b: '', c: undefined, d: 1 },
 * this would return { a: 'foo', d: 1 }
 *
 * E.g., if input is
 * { a: ['foo', undefined], b: '', c: undefined, d: { r: [1, [2]], s: 'test', t: null } },
 * this would return { a: ['foo'], d: { r: [1, [2]], s: 'test' } }
 * *
 * We can't just look at whether the object is falsy, because e.g.
 * we don't want a "false" to default-fallback to "true" in the cms. Or
 * "0" to fallback to a different value. Essentially, we only want fallbacks
 * for values that are *unset*
 */
export const removeEmptyKeysForDotcmsFallback = (input: object): object =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (Array.isArray(input) && typeof input !== 'string'
    ? input.reduce(
      (result, value) =>
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
        reduceEmptyValues(result, value, (valueWithoutEmptyKeys) => [
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          ...result,
          valueWithoutEmptyKeys,
        ]),
      [],
    )
    : Object.entries(input).reduce(
      (result, [key, value]) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        reduceEmptyValues(result, value, (valueWithoutEmptyKeys) => ({
          ...result,
          [key]: valueWithoutEmptyKeys,
        })),
      {},
    ));
