import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { uiSelector } from '../../selectors';
import { getDateTimeFromObjectDate } from '../../utils/getDateTimeFromObjectDate';
import { getTwoLetterCountryCodeFromMaybeThreeLetter } from './dateFormatterHelper';
import { useGetUserLocation } from '../use-get-user-location/use-get-user-location';
import { useLocale } from '../../locale/use-locale';
import { DateFormat } from '../../constants/dateFormat';
import { useGetCurrentPage } from '../../app/get-current-page';
import { isAdminPage } from '../../components/abc/admin-experience/admin-page-navigation/admin-page-navigation-utils';

const defaultLocale = 'en';

export const formatToLocale = (
  luxonDateTimeInstance: DateTime,
  dateFormat: DateFormat | string,
  locale: string,
) => {
  switch (dateFormat) {
    case DateFormat.LONG:
      return luxonDateTimeInstance.toLocaleString(
        { dateStyle: 'long' },
        { locale },
      );
    case DateFormat.LONG_NO_YEAR:
      return luxonDateTimeInstance.toLocaleString(
        { day: 'numeric', month: 'long' },
        { locale },
      );
    case DateFormat.NUMERIC:
      return luxonDateTimeInstance.toFormat('yyyy-MM-dd');
    case DateFormat.TIME:
      return luxonDateTimeInstance.toLocaleString(
        { hour: 'numeric', minute: 'numeric' },
        { locale },
      );
    case DateFormat.LONG_DATE_TIME_AND_TIME_ZONE:
      return luxonDateTimeInstance.toLocaleString(
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZoneName: 'short',
        },
        { locale },
      );
    default:
      return luxonDateTimeInstance.setLocale(locale).toFormat(dateFormat);
  }
};

export const useDateFormatter = () => {
  const { companyShortName } = useSelector(uiSelector);
  const { countryCode: rawCountryCode } = useGetUserLocation();
  // rawAbcLocale should look like "en-US":
  const { locale: rawAbcLocale } = useLocale();
  const currentPage = useGetCurrentPage();
  const isCurrentPageAdminPage = currentPage ? isAdminPage(currentPage) : false;

  // Hyperloop has employees from all over the world, so we have to use an unambiguous date format
  const alwaysUseInternationalFormat = companyShortName === 'htt';

  const languageCode = rawAbcLocale.split('-')[0];

  // The library we're using for the country code conversion is a js library
  // without types available, so need to use a type assertion here:
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const countryCode = (getTwoLetterCountryCodeFromMaybeThreeLetter(
    rawCountryCode,
  ) ?? '') as string;
  const locale =
    countryCode && languageCode ? `${languageCode}-${countryCode}` : undefined;

  const formatLuxonDateTime = useCallback(
    (luxonDateTimeInstance: DateTime, dateFormat = DateFormat.NUMERIC) => {
      if (
        locale &&
        !alwaysUseInternationalFormat &&
        dateFormat === DateFormat.NUMERIC &&
        !isCurrentPageAdminPage
      ) {
        if (
          ['en', 'es'].includes(languageCode.toLowerCase()) &&
          ['US', 'USA'].includes(countryCode.toUpperCase())
        ) {
          return formatToLocale(luxonDateTimeInstance, 'MM/dd/yyyy', locale);
        }

        if (['IE', 'IRL', 'GB', 'GBR'].includes(countryCode.toUpperCase())) {
          return formatToLocale(luxonDateTimeInstance, 'dd/MM/yyyy', locale);
        }

        // This unexpectedly always outputs US formatted dates.
        // https://stackoverflow.com/questions/73478040/luxon-tolocalestring-returning-us-formatting-for-non-us-locales
        // We should delete the above locale-specific special-casing and return the below once
        // a fix is found:
        // return luxonDateTimeInstance.toLocaleString({ locale })
      }
      // Return year-month-day format if a locale can't be determined
      // (it is the most unambiguous format):
      return formatToLocale(
        luxonDateTimeInstance,
        dateFormat ?? DateFormat.NUMERIC,
        locale ?? defaultLocale,
      );
    },
    [
      alwaysUseInternationalFormat,
      countryCode,
      languageCode,
      locale,
      isCurrentPageAdminPage,
    ],
  );

  const formatRawObjectDate = useCallback(
    (
      {
        date,
        month,
        year,
      }: {
        date: string | number;
        month: string | number;
        year: string | number;
      },
      dateFormat?: DateFormat,
    ) => {
      const dateTime = getDateTimeFromObjectDate({ date, month, year });
      return formatLuxonDateTime(dateTime, dateFormat);
    },
    [formatLuxonDateTime],
  );

  const formatIsoTime = useCallback(
    (value: string | null, dateFormat?: DateFormat) =>
      formatLuxonDateTime(
        DateTime.fromISO(value ?? '', { setZone: true }),
        dateFormat,
      ),
    [formatLuxonDateTime],
  );

  return {
    formatRawObjectDate,
    formatLuxonDateTime,
    formatIsoTime,
  };
};
