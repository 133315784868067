export const TOP_OF_PAGE_MARGIN_PIXELS = '48';
/*
    This constants will be replaced with data fetched from the BE.

    EOI_START_BEFORE_ENROLLMENT_DAYS indicates the amount of days
    before the enrollment, when the EOI Notice should start being
    displayed

    EOI_DEADLINE_DAYS indicates the amount of days after beginning
    of the enrollment before the Deadline
*/
export const EOI_START_BEFORE_ENROLLMENT_DAYS = 1;
export const EOI_BEFORE_DEADLINE_DAYS = 8;
