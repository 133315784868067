import { createContext } from 'react';
import type { HtmlFromDotcms } from '../../../dotcms/dotcms-models/converters-from-dotcms';
import type { IFooterSectionData } from '../../../dotcms/dotcms-models/dotcms-footer';

export interface IFooterData {
  footnotes: HtmlFromDotcms[];
  setFootnote: (footnote: HtmlFromDotcms) => {
    cleanUpFootnoteForUnmount: () => void;
  };
  cmsData: IFooterSectionData | undefined;
  cmsLoading: boolean;
  isLightFooterMode: boolean | undefined;
  setIsLightFooterMode: React.Dispatch<
  React.SetStateAction<boolean | undefined>
  >;
}

const FooterContext = createContext<IFooterData | undefined>(undefined);

export default FooterContext;
